import React, { useState } from 'react'
import Input from '../../components/Input'
import PaymentsCarousel from '../../components/PaymentsCarousel'
import { translatedText } from '../../services/translatedText'
import useWindowSize from '../../hooks/useWindowSize'
import { useSelector } from 'react-redux'
import { useRef } from 'react'
import axios from 'axios'
import { useCheckPhone } from '../../hooks/useCheckPhone'
import PayModal from '../../components/PayModal'

const generatePayme = async (login, amount) => {
 return axios({
    method: 'POST',
    url: `https://serv.comnet.uz/api/payme/cheque`,
    data:{
      login:login,
      amount:amount,
    }
    
  })
    .then(e => {
      console.log(e.data)
      return e.data.data
    })
    .catch(e => {
      console.warn(e, 'getQrPayme');
    });
}

export default function Replenishment() {
  const isPhone = useCheckPhone()
  const isMobile = useWindowSize().width < 1170
  const { user } = useSelector((state) => state)
  // const sum = useRef()

  const [sum, setSum] = useState(0)
  const id = useRef(user?.login)
  const [chosedPay, setChosedPay] = useState({
    url: '',
    name: '',
    cost: '',
  })

  async function redirectPayment(name) {
    const login = id.current || user.login
    if (!sum || !login) return
    var url = ''
    const ua = navigator.userAgent;
    var dataPayme;
    let checkid;
    let mobileUrl;
    switch (name) {
      
      case 'uzum':
          url = `https://www.apelsin.uz/open-service?serviceId=225&amount=${sum * 100}&customer_id=${login}#Intent;scheme=https;package=uz.kapitalbank.android;end`

        break
      case 'payme':
        dataPayme = await generatePayme(login, sum * 100)
        checkid = dataPayme?.result?.cheque?._id
        url = `https://payme.uz/checkout/${checkid}`
        break
      case 'upay':
        url = `https://pay.smst.uz/prePay.do?personalAccount=${login}&amount=${sum}&serviceId=839&apiVersion=1`
        break
      case 'click':
          url = `https://my.click.uz/services/pay?amount=${sum}&merchant_id=50&service_id=30457&transaction_param=${login}#Intent;scheme=https;package=air.com.ssdsoftwaresolutions.clickuz;end`
        break
      case 'paynet':
        return
    }
    window?.Android?.openPay(url)
    if (url) {
      if (!isPhone) {
        setChosedPay({
          url,
          name,
          cost: sum,
        })
      } else {
        window.open(url, 'noopener,noreferrer')
      }
    }
  }

  return (
    <>
      <div className="container">
        <div style={{ margin: '40px 0 60px 0' }} className={!isMobile ? 'a-center g-3' : 'col g-3'}>
          <div style={isMobile ? {} : { width: '70%' }} className="col g-2">
            <h1 style={{ marginBottom: 15 }}>{translatedText('Refill')}</h1>
            {/* <Input label={translatedText("Transaction") + " #"} /> */}
            <Input
              defaultValue={user?.login}
              readOnly
              label={translatedText('Login')}
              onChange={(_id) => (id.current = _id)}
            />
            <div>
              <Input
                defaultValue="0"
                onChange={(_sum) => setSum(_sum)}
                typeInput="currency"
                label={translatedText('Sum')}
                inputStyle={+sum < 1000 ? { color: 'red', background: '#ff00000d', border: '1px solid red' } : {}}
              />
              <div
                style={{
                  color: 'red',
                  padding: '10px',
                  transition: '0.3s',
                  opacity: +sum < 1000 ? 1 : 0,
                }}
              >
                {translatedText('minAmount')}
              </div>
            </div>
          </div>
          <PaymentsCarousel
            onClick={redirectPayment}
            limit={6}
            styles={{ opacity: +sum < 1000 ? 0.5 : 1, pointerEvents: +sum === 0 ? 'none' : 'all' }}
          />
        </div>
      </div>
      <PayModal chosedPay={chosedPay} setChosedPay={setChosedPay} />
    </>
  )
}
