import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import bonusPlus from './bonusPlus'
import bringFriend from './bringFriend'
import footer from './footer'
import login from './login'
import menu from './menu'
import message from './message'
import tariffs from './tariffs'
import terminateContract from './terminateContract'

const resources = {
  ru: {
    translation: {
      lang: {
        uz: "O'z",
        ru: "Ру"
      },
      archivedTariffs: 'Архивные тарифы',
      payWithQr: 'Оплатите через QR код',
      payWithWebSite: `Оплатить через сайт`,
      addAmount: 'Введите сумму',
      minAmount: 'Минимальная сумма пополнения: 1 000 сум',
      AreYouReallyWantToGetLoan: `Вы действительно хотите воспользоваться услугой оформления кредита?`,
      Tarif: 'Тариф',
      ForUnusedPeriodsAreNotRefundable: `Средства за не использованный период возврату не подлежат.`,
      ForDeactivateTarif: {
        first: `Для того чтобы отключить услугу `,
        second: `необходимо выбрать один из актуальных тарифных планов. Обратите внимание на то, чтобы средств на балансе хватало для активации выбранного тарифного плана. После активации тарифа`,
        third: `будет отключена автоматически.`,
      },
      Success:"Успешно",
      SendedMoney:"Вам зачислено",
      ChooseTarif: `Выбрать тариф`,
      SuccessfullyConnected: `успешно подключена!`,
      CreditAmount: `Сумма кредита:`,
      TheServiceIsFree: `Услуга бесплатна`,
      AccountWillBeDebitedMonthly: `С вашего лицевого счёта ежемесячно будет списываться`,
      ConfirmServiceActivation: `Подтвердите активацию услуги`,
      ConfirmDeactivationService: `Подтвердите отключение услуги`,
      YourRequestHasBeenAccepted: `Ваша заявка принята. В ближайшее время с вами свяжется оператор абонентского отдела.`,
      WhatDnsIs: `Что такое DNS`,
      ComnetZone: `Зона покрытия Comnet`,
      YandexDnsWorkModes: `Режимы работы Яндекс DNS`,
      ParentalControl: `Быстрый и безопасный доступ к домашнему интернету`,
      AboutImmediately: `При выборе «Немедленно» производится  мгновенная смена тарифного плана и списываются средства за новый расчетный период. День списания средств меняется на день смены тарифа. Напоминаем что средства за ранее используемый тариф не подлежат возврату, а не использованные активные дни сгорают так как списываются единоразово в момент активации тарифа.`,
      NextAccountingPeriod: `При выборе «Следующий учетный период» смена тарифного плана произойдет автоматически только после окончания действующего расчетного периода и при наличии средств для активации тарифного плана.`,
      ConnectedServices: 'Подключенные услуги',
      GiveBackDevice: `Сдать оборудование`,
      TotalWithBalance: 'Итого к оплате:',
      Total: 'Итого:',
      PaymentDeferment: 'Отсрочка платежа',
      TarifPlan: 'Тарифный план -',
      GPON: 'GPON Терминал',
      WIFI: 'Wi-Fi Роутер',
      Send: 'Отправить',
      YourIP: `Ваш IP - адрес:`,
      ContactPhoneNumber: `Укажите контактный номер телефона:`,
      ExtraServices: 'Доп.услуги',
      Connected: 'Подключен',
      Enable: 'Подключить',
      OrderRouter: 'Заказать роутер',
      'You have ban': 'Вы были забанены. Подождите до {{date}}',
      FirstName: 'Имя',
      SecondName: 'Фамилия',
      MiddleName: 'Отчество',
      'Reason for suspension': 'Причина приостановления',
      'Service suspension': 'Приостановление услуг',
      'Submit an application': 'Отправить заявку',
      'Application successfully sent': 'Заявка успешно отправлена',
      Alerts: 'Оповещения',
      'Status internet': 'Состояние подключения: {{text}}',
      'Online text': 'Подключен',
      'Offline text': 'Не подключен к интернету',
      'Credit sum': 'Сумма кредита: {{text}} сум',
      'Credit price': 'Стоимость услуги: {{text}} сум',
      'Credit not support': 'Вам кредит не доступен',
      'Credit not access': 'Вы не имеете права брать кредит',
      'Credit set': 'Кредит успешно установлено',
      'Statistics speed': 'Статистика скорости',
      'Detail statistics': 'Детальная статистика по дням',
      Recommended: 'Рекомендуем',
      MostSaled: 'Xит продаж',
      'Valid verif pass': 'Пароли должны совпадать',
      'Valid pass min': 'Пароль должен быть не менее 8 символов',
      Message: 'Сообщение',
      'Valid data': 'Данные введены неверно',
      'Successfully changed password': 'Пароль успешно изменен',
      All: 'Все',
      'Old cabinet': 'Старый кабинет',

      'Card abon': 'Карточка абонента',
      'Num contract': '№ договора',
      Address: 'Адрес',
      'Report a bug': 'Сообщить об ошибке',
      'Activate promo':'Активировать промокод',
      'write promo':'Введите промокод',
      'Current status': 'Текущий статус',
      Discount: 'Скидка',
      More: 'Подробнее',
      'Next write-offs': 'Недостающая сумма для сл. списания',
      Credit: 'Кредит',
      'Set Credit': 'Установить кредит',
      Connect: 'Подключить',
      'Selected tariff': 'Выбранный тариф',
      ERR_SMALL_DEPOSIT: 'На вашем балансе недостаточно средств',
      'By using': 'С помощью',
      'Withdrawal type': 'Тип снятия',
      January: 'Январь',
      February: 'Февраль',
      March: 'Март',
      April: 'Апрель',
      Mayf: 'Май',
      June: 'Июнь',
      Jule: 'Июль',
      August: 'Август',
      September: 'Сентябрь',
      October: 'Октябрь',
      November: 'Ноябрь',
      December: 'Декабрь',
      'crash-text': 'Ведутся технические работы',
      'Enter message': 'Введите ваше сообщение...',
      'Write message': 'Написать сообщение',
      City: 'Город',
      INN: 'ИНН',
      Street: 'Улица',
      House: 'Дом/Квартира',

      Support: 'Тех. поддержка',
      'Subscriber department': 'Абонентский отдел',
      OldCabinet:'Перейти в старый<br/>кабинет',
      Exit: 'Выйти',

      'Personal data': 'Персональные данные',
      Payment: 'Оплата',
      Service: 'Сервис',
      Statistics: 'Статистика',
      'Personal area': 'Личный кабинет',

      Date: 'Дата',
      Login: 'Логин',
      Status1: 'Состояние',

      'New password': 'Новый пароль',
      'Confirm password': 'Подтвердить пароль',
      'Generate password': 'Сгенерировать пароль',
      Copy: 'Копировать',
      Save: 'Сохранить',

      Description: 'Описание',
      Sum: 'Сумма',
      Deposit: 'Депозит',
      Type: 'Тип',
      Transaction: 'Транзакция',

      'Reset password': 'Сменить пароль',
      'Write-offs': 'Списания',
      Replenishment: 'Пополнение',
      Refill: 'Пополнение счета',
      Treaty: 'Договор',
      Day: 'День',
      'Speed kbit/s': 'Скорость Кбит/с',
      'Speed mbit/s': 'Скорость Мбит/с',
      'Session start': 'Начало Сессии',
      'Free set of alerts': 'Бесплатный набор оповещений',
      Active: 'Активно',
      Registration: 'Регистрация',
      Report: 'Отчет',
      Name: 'Название',
      Value: 'Значение',
      Price: 'Цена',
      'Forms and Documents': 'Бланки и Документы',
      Forms: 'Бланки',
      Documents: 'Документы',
      Setup: 'Установить',

      Duration: 'Длительность',
      Received: 'Получено',
      Sent: 'Отправлено',
      Period: 'Статистика за период',
      Today: 'Сегодня',
      Session: 'Сессии',
      Traffic1: 'Трафик',
      Yesterday: 'Вчера',
      Week: 'Неделя',
      Month: 'Месяц',
      'All sessions': 'Все сеансы',
      Filter: 'Фильтр по сессиям',
      Filter1: 'Фильтр',
      'Date from': 'Дата с',
      'Date to': 'Дата до',
      Speed: 'Скорость',
      Rows: 'Рядов',
      Show: 'Показать',
      Apply: 'Применить',
      Reject: 'Отклонить',
      'Last day': 'Последние {{day}} дни',
      'Previous month': 'Предыдущий месяц',
      Other1: 'Другое',
      'Mbps/sec': 'Мбит/с',

      Jan: 'Янв',
      Feb: 'Фев',
      Mar: 'Мар',
      Apr: 'Апр',
      May: 'Май',
      Jun: 'Июн',
      Jul: 'Июл',
      Aug: 'Авг',
      Sep: 'Сен',
      Oct: 'Окт',
      Nov: 'Ноя',
      Dec: 'Дек',
      Mon: 'ПН',
      Tue: 'ВТ',
      Wed: 'СР',
      Thu: 'ЧТ',
      Fri: 'ПТ',
      Sat: 'СБ',
      Sun: 'ВС',

      Balance: 'Баланс',
      PayMoney: 'Сумма пополнения',
      Status: 'Статус',
      'Your login': 'Ваш логин',
      'Phone number': '№ телефона',
      'Date of next write-off': 'Дата следующего списания',
      'Amount of the next write-off': 'Сумма следующего списания',
      Discounts: 'Скидки',

      'Top up': 'Пополнение счета',
      'Your Tariff': 'Ваш Тариф',
      Change: 'Изменить',
      'Promotions and bonuses': 'Акции и бонусы',
      'Speed to the world from time to time': 'Скорость в мир с {{time1}} до {{time2}}:',
      'Speed to the world': 'Скорость в мир круглосуточно:',
      'Traffic from time to time': 'Оплаченный трафик с {{time1}} до {{time2}}:',
      'Speed in TAS-IX: Around the clock': 'Скорость в TAS-IX круглосуточно',
      Traffic: 'Трафик:',
      Overlimit: '{{amount}} МБ сверхлимита:',
      'Overlimit times': '1 МБ сверхлимита {{time1}}-{{time2}}:',
      'Subscription fee:': 'Абонентская плата:',
      'soum/month': 'сум/мес',
      Unlimited: 'Безлимитный',
      MB: 'Мбайт',
      Mbps: 'Мбит/с',
      Kbps: 'Кбит/с',
      Deleted: 'Удален',
      Actived: 'Активен',
      Disabled: 'Отключен',

      FromDays: 'осталось активных дней: {{days}}<br />({{from}})',

      No: 'Нет',
      'No data': 'Нет данных',
      sum: 'сум',
      Bytes: 'Bit',
      'Tariff not exists': 'Тариф не существует',
      'Unlimited tariff': 'ТАРИФ БЕЗЛИМИТНЫЙ ДЛЯ ДОМА',
      'Not allowed change tariff': 'Вам запрещено изменять тарифы',
      Nomination: 'Название',
      'Your caring provider': 'Ваш заботливый провайдер',
      Share: 'Поделиться',
      'Additional services': 'Дополнительные услуги',
      'Connected services': 'Подключенные услуги',
      Reset: 'Сброс',

      'Not activated': 'Не активирован',
      Suspended: 'Приостановлен',
      SMD: 'СМД', //Слишком маленкий депозит

      Immediately: 'Немедленно',
      'Next accounting period': 'Со следующего периода ({{date}})',
      SwitchedOff: 'Выключен',
      'Are you sure disable notification': 'Вы уверены, что хотите отключить оповещения?',
      Disable: 'Отключить',
      'Successfully completed': 'Операция прошла успешно',
      Close: 'Закрыть',
      'Unknown error': 'Неизвестная ошибка',
      'Add new': 'Добавить новое',
      'Current phone number': 'Текущий номер телефона',
      Code: 'Код',
      'Sent code': 'Код отправлен',
      'Sent code1': 'Код отправлен на номер телефона {{actualPhone}}',
      'Sent code email': 'На эту почту отправлен код',
      'Code error': 'Код был введен неправильно',
      'Send again': 'Отправить еще раз',
      'Time expired': 'Время вышло',
      'Current email': 'Текущий email',
      'Download Contract': 'Скачать договор',
      Confirm: 'Подтвердить',
      Text: 'Текст',
      'Sent date': 'Дата отправки',
      'History payments': 'История пополнения',

      'Speed-alert':
        'Скорость выше 100 Мбит/с доступна по технологии GPON и при наличии технической возможности по FTTx',

      'Reset password info': `Примечание! После смены пароля от личного кабинета, вам придется перенастроить свой роутер с учетом нового пароля. 
      Смена пароля в личном кабинете - не является сменой пароля от Wi-Fi роутера 
      Для настройки роутера у нас есть <a style="text-decoration: underline;" href="https://www.youtube.com/@Comnetprovider/videos">видео-инструкция</a>`,

      'Failed to send request': 'Не удалось отправить запрос',
      scan_pay: 'Отсканируйте QR чтобы оплатить через приложение {{app}}',
      'Paynet can not': 'Вы можете оплатить услуги Comnet через приложение Paynet или воспользоваться банкоматом.',
      ...tariffs.ru,
      ...message.ru,
      ...terminateContract.ru,
      ...menu.ru,
      ...login.ru,
      ...bonusPlus.ru,
      ...footer.footer_ru,
      ...bringFriend.bringFriend_ru,
    },
  },
  uz: {
    translation: {
      lang: {
        uz: "O'z",
        ru: "Ру"
      },
      archivedTariffs: 'Arxivlangan tariflar',
      payWithQr: `QR kod orqali to'lash`,
      payWithWebSite: `Sayt orqali to'lash`,
      addAmount: 'Miqdorni kiriting',
      AreYouReallyWantToGetLoan: `Siz haqiqatan kredit xizmatidan foydalanmoqchimisiz?`,
      Tarif: 'Tarif',
      ForUnusedPeriodsAreNotRefundable: `Foydalanilmagan muddatlar uchun mablag'lar qaytarilmaydi.`,
      ForDeactivateTarif: {
        first: `Siz joriy tarif rejalaridan birini tanlashingiz kerak. Tanlangan tarif rejasini faollashtirish uchun balansingizda yetarli mablag‘ mavjudligiga ishonch hosil qiling. Tarifni faollashtirgandan so'ng, `,
        second: ` avtomatik ravishda o'chiriladi.`,
      },
      ChooseTarif: `Tarifni tanlash`,
      SuccessfullyConnected: `xizmati muvaffaqiyatli faollashtirildi!`,
      Success:"Muvaffaqiyatli",
      SendedMoney:"Sizga o'tkazildi",
      CreditAmount: `Kredit miqdori:`,
      TheServiceIsFree: `Xizmat bepul`,
      AccountWillBeDebitedMonthly: `Shaxsiy hisobingizdan har oy to’lov yechib olinadi`,
      ConfirmServiceActivation: `Xizmatni faollashtirishni tasdiqlang`,
      ConfirmDeactivationService: `Xizmatni o'chirishni tasdiqlang`,
      YourRequestHasBeenAccepted: `Sizning arizangiz qabul qilindi. Mijozlarga xizmat ko'rsatuvchi operatorimiz tez orada siz bilan bog'lanadi.`,
      WhatDnsIs: `DNS nima?`,
      ComnetZone: `Comnet qamrov hududi`,
      YandexDnsWorkModes: `Yandex DNS-ning ishlash rejimlari`,
      ParentalControl: `Uy Internetiga tez va xavfsiz kirish`,
      AboutImmediately: `"Hoziroq" tugmasini tanlasangiz, tarif rejangiz o'sha zahotiyoq o'zgaradi va yangi tarif uchun hisobingizdan mablag' yechiladi. To'lov qilish sanasi esa tarif rejasi o'zgartirilgan kunga belgilanadi. 
      Eslatib o'tamiz, avvalgi tarif rejasi uchun sarflangan mablag' qoldig'i qaytarilmaydi, mablag' tarifni bir martalik faollashtirish uchungina ishlaydi. `,
      NextAccountingPeriod: `“Keyingi oydan boshlab” tugmasi tanlanganda, tarif rejasi joriy tarif oyi yakunlangandan so’ng, avtomatik tarzda o’zgartiriladi.`,
      TarifPlan: 'Tarif -',
      ConnectedServices: 'Ulangan xizmatlar',
      GiveBackDevice: `Uskunani qaytarish`,
      TotalWithBalance: `Umumiy to'lov:`,
      Total: 'Jami:',
      PaymentDeferment: 'Kechiktirish',
      GPON: 'GPON Terminal',
      WIFI: 'Wi-Fi Router',
      Send: 'Yuborish',
      YourIP: `Sizning IP - manzilingiz`,
      ContactPhoneNumber: `Aloqa telefon raqamini kiriting:`,
      ExtraServices: `Qo'shimcha xizmatlar`,
      Connected: 'Ulangan',
      Enable: 'Ulanish',

      OrderRouter: 'Buyurtma berish',
      Refill: "Hisobni to'ldirish",
      'Card abon': 'Abonent kartasi',
      'Num contract': 'Shartnoma №',
      Address: 'Manzil',
      'Report a bug': 'Xatolik haqida xabar berish',
      'Activate promo':'Promo kodni faollashtrish',
      'write promo':'Promo kodni kiriting',
      'Current status': 'Joriy holat',
      Discount: 'Chegirma',
      More: 'Batafsil',
      'Next write-offs': "Keyingi to'lov muddati uchun yetishmayotgan summa",
      Credit: 'Kredit',
      'Set Credit': "Kredit o'rnatish",
      Connect: 'Ulanish',
      'Selected tariff': 'Tanlangan tarif',
      ERR_SMALL_DEPOSIT: `Balansingizda mablag yetarli emas`,
      'By using': 'Yordamida',
      'Withdrawal type': 'Yechish turi',
      January: 'Yanvar',
      February: 'Fevral',
      March: 'Mart',
      April: 'Aprel',
      Mayf: 'May',
      June: 'Iyun',
      Jule: 'Iyul',
      August: 'Avgust',
      September: 'Sentabr',
      October: 'Oktabr',
      November: 'Noyabr',
      December: 'Dekabr',
      'crash-text': 'Texnik ishlar olib borilmoqda',
      'Enter message': 'Xabaringizni kiriting...',
      'Write message': 'Xabar yozish uchun',
      City: 'Shahar',
      INN: 'INN',
      Street: "Ko'cha",
      House: 'Uy/Kvartira',
      All: 'Barchasi',
      'Old cabinet': 'Oldingi kabinet',

      Support: "Tex. qo'llab-quvvatlash",
      'Subscriber department': "Abonent bo'limi",
      OldCabinet:'Eski kabinetga<br/>o‘tish',
      Exit: 'Chiqish',

      'Personal data': "Shaxsiy ma'lumot",
      Payment: "To'lov",
      Service: 'Servis',
      Statistics: 'Statistika',
      'Personal area': 'Shaxsiy kabinet',

      Date: 'Sana',
      Login: 'Login',
      Status1: 'Holat',

      Description: 'Tavsif',
      Sum: 'Miqdor',
      Deposit: 'Depozit',
      Type: 'Turi',
      Transaction: 'Tranzaktsiya',

      Day: 'Kun',
      'Speed kbit/s': 'Tezlik Kbit/s',
      'Speed mbit/s': 'Скорость Mbit/s',
      'Session start': 'Boshlanishi sessiyalar',
      'Free set of alerts': 'xabarnomalarning bepul to’plami',
      Active: 'Faol',
      Registration: 'Registratsiya',
      Report: 'Hisobot',
      Name: 'Nomlanishi',
      Value: 'Qiymat',
      Price: 'Narh',
      'Forms and Documents': 'Blanka va hujjatlar',
      Forms: 'Blankalar',
      Documents: 'Hujjatlar',
      Setup: 'O’rnatish',
      Duration: 'Davomiyligi',
      Received: 'Qabul qilindi',
      Sent: 'Yuborildi',
      Period: 'Davr',
      Today: 'Bugun',
      Session: 'Sessiyalar',
      Traffic1: 'Трафик',
      Yesterday: 'Kecha',
      Week: 'Hafta',
      Month: 'Oy',
      'All sessions': 'Barcha seanslar',
      Filter: "Filtr sessiya bo'yicha",
      Filter1: 'Filtr',
      'Date from': 'Dan sana',
      'Date to': 'Gacha sana',
      Speed: 'Tezlik',
      Rows: 'Qatorlar',
      Show: 'Ko’rsatish',
      Apply: 'Qo’llash',
      Reject: 'Bekor qilish',
      'Last day': 'Ohirgi {{day}} kunlar',
      'Previous month': 'O’tgan oy',
      Other1: 'Boshqa',
      'Mbps/sec': 'Mbit/s',
      Jan: 'Yanv',
      Feb: 'Fev',
      Mar: 'Mar',
      Apr: 'Apr',
      May: 'May',
      Jun: 'Iyun',
      Jul: 'Iyul',
      Aug: 'Avg',
      Sep: 'Sen',
      Oct: 'Okt',
      Nov: 'Noy',
      Dec: 'Dek',
      Mon: 'DUSH',
      Tue: 'SESH',
      Wed: 'CHOR',
      Thu: 'PAY',
      Fri: 'JUMA',
      Sat: 'SHAN',
      Sun: 'YAK',

      'New password': 'Yangi parol',
      'Confirm password': 'Parolni tasdiqlash',
      'Generate password': 'Parol yaratish',
      Copy: 'Nusxa olish',
      Save: 'Saqlash',

      'Reset password': "Parolni o'zgartirish",
      'Write-offs': 'Hisobdan chiqarish',
      Replenishment: "To'ldirish",
      Treaty: 'Shartnoma',

      Balance: 'Balans',
      PayMoney: "To'ldirish miqdori",
      Status: 'Holat',
      'Your login': 'Login',
      'Phone number': 'Telefon raqami',
      'Date of next write-off': 'Keyingi hisobdan chiqarish sanasi',
      'Amount of the next write-off': 'Keyingi hisobdan chiqarish summasi',
      Discounts: 'Chegirmalar',

      'Top up': "Hisobni to'ldirish",
      'Your Tariff': 'Sizning tarifingiz',
      Change: "O'zgartirish",
      'Promotions and bonuses': 'Aksiyalar va bonuslar',
      'Speed to the world from time to time': "{{time1}} dan {{time2}} gacha bo'lgan tezlik:",
      'Traffic from time to time': 'Tarif {{time1}} dan {{time2}} gacha:',
      'Speed in TAS-IX: Around the clock': 'TAS-IX da kechayu kunduz tezlik',
      Traffic: 'Trafik:',
      Overlimit: 'Limitdan tashqari {{amount}} MB:',
      'Overlimit times': 'Limitdan tashqari 1 MB {{time1}}-{{time2}}:',

      'Subscription fee:': 'Abonent to‘lovi:',
      'soum/month': "so'm/oy",
      Unlimited: 'Cheksiz',
      MB: 'Mbayt',
      Mbps: 'Mbit/s',
      Kbps: 'Kbit/s',
      Deleted: "O'chirilgan",
      Actived: 'Faol',
      Disabled: 'Faol emas',

      FromDays: 'aktiv kunlar: {{days}}<br />({{from}})',

      No: "Yo'q",
      'No data': "Ma'lumot topilmadi",
      sum: "so'm",
      Bytes: 'Bit',
      'Tariff not exists': 'Tarif mavjud emas',
      'Unlimited tariff': 'UY UCHUN CHEKSIZ TARIF',
      'Not allowed change tariff': "Sizda tariflarni o'zgartirishga ruxsat yo'q!",
      Nomination: 'Nomi',
      'Your caring provider': "Sizning g'amxo'r provayderingiz",
      Share: 'Ulashish',
      'Additional services': "Qo'shimcha xizmatlar",
      Reset: "Qayta o'rnatish",

      'Not activated': 'Faol emas',
      Suspended: "To'xtatilgan",
      SMD: 'SMD',

      Immediately: 'Darhol',
      'Next accounting period': 'Keyingi hisob davridan ({{date}})',
      SwitchedOff: "O'chirilgan",
      Disable: "O'chirish",
      'Are you sure disable notification': 'Haqiqatan ham bildirishnomalarni o‘chirib qo‘ymoqchimisiz?',
      'Successfully completed': 'Operatsiya muvaffaqiyatli amalga oshirildi',
      Close: 'Yopish',
      'Unknown error': "Noma'lum xato",
      'Add new': "Yangi qo'shish",
      'Current phone number': 'Joriy telefon raqami',
      Code: 'Kod',
      'Sent code': 'Telefon raqamga kod yuborildi',
      'Sent code email': 'Pochtaga kod yuborildi',
      'Code error': 'Kod xato kiritildi',
      'Send again': 'Qayta yuborish',
      'Time expired': 'Muddati tugadi',
      'Current email': 'Joriy email',
      'Download Contract': 'Shartnomani yuklab olish',
      Confirm: 'Tasdiqlash',
      Text: 'Matn',
      'Sent date': "Jo'natilgan sana",
      'History payments': "To'ldirish tarixi",

      'Speed-alert':
        "100 Mbit/s dan yuqori tezlik GPON texnologiyasi va texnik jihatdan iloji bo'lgan FTTx orqali mavjud.",

      'You have ban': 'Siz taqiqlangansiz. Iltimos, {{date}} gacha kuting',
      FirstName: 'Ism',
      SecondName: 'Familiya',
      MiddleName: 'Sharif',
      'Reason for suspension': "To'xtatish sababi",
      'Service suspension': "Xizmatlarni to'xtatish",
      'Submit an application': 'Ariza yuborish',
      'Application successfully sent': 'Ariza muvaffaqiyatli yuborildi',
      Alerts: 'Ogohlantirishlar',
      'Status internet': 'Internet holati: {{text}}',
      'Online text': 'Ulangan',
      'Offline text': 'Internetga ulanmagan',
      'Credit sum': "Kredit summasi: {{text}} so'm",
      'Credit price': "Xizmat narxi: {{text}} so'm",
      'Credit not support': 'Sizga kredit mavjud emas',
      'Credit not access': 'Siz kredit olish huquqiga ega emassiz',
      'Credit set': 'Kredit muvaffaqiyatli belgilandi',
      'Statistics speed': 'Tezlik statistikasi',
      'Detail statistics': "Kun bo'yicha batafsil statistika",
      Recommended: 'Tavsiya etilgan',
      MostSaled: 'Xит продаж',
      'Valid verif pass': "Parollar bir xil bo'lishi kerak",
      'Valid pass min': "Parol kamida 8 ta belgidan iborat bo'lishi kerak",
      Message: 'Xabar',
      'Valid data': "Ma'lumotlar noto'g'ri kiritilgan",
      'Successfully changed password': 'Parol muvaffaqiyatli almashtirildi',

      'Reset password info': `Eslatma! Shaxsiy hisobingizdan parolni o'zgartirgandan so'ng, siz routeringizni yangi parol bilan qayta sozlashingiz kerak bo'ladi.
      Shaxsiy hisobingizdagi parolni o'zgartirish Wi-Fi routerdan parolni o'zgartirmaydi
      Routerni sozlash uchun bizda <a target="__blank" style="text-decoration: underline;" href="https://www.youtube.com/@Comnetprovider/videos">video darslik</a>`,

      'Failed to send request': "So'rov yuborishda xatolik mavjud",
      scan_pay: '{{app}} ilovasi orqali toʻlash uchun QR-ni skanerlang',
      'Paynet can not':
        'Comnet xizmatlari uchun Paynet ilovasi orqali toʻlashingiz yoki bankomatdan foydalanishingiz mumkin.',
      ...tariffs.uz,
      ...message.uz,
      ...menu.uz,
      ...bonusPlus.uz,
      ...login.uz,
      ...terminateContract.uz,
      ...footer.footer_uz,
      ...bringFriend.bringFriend_uz,
    },
  },
}

i18n.use(initReactI18next).init({
  resources,
  lng: localStorage.getItem('lang') === 'uz' ? 'uz' : 'ru',

  interpolation: {
    escapeValue: false,
  },
})

export default i18n
