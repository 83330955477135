import React from "react";
import { useRef } from "react";
import { useSelector } from "react-redux";
import Input from "../../../../components/Input";
import { translatedText } from "../../../../services/translatedText";

export default function Step0({ onPossible, onImpossible }) {
  const { pi } = useSelector((state) => state);
  const values = useRef({});

  function onChangeInputs(value, key) {
    if (!key) return;
    values.current[key] = value;
    checkIsPossible();
  }
  function checkIsPossible() {
    const haveValues =
      Object.values(values.current).filter((v) => v).length >= 3;
    if (haveValues && onPossible) onPossible(values.current);
    else if (onImpossible) onImpossible();
  }

  const data = [
    { key: "connection_address", label: "Connection address" },
    { key: "city", label: "City" },
    { key: "area", label: "Area" },
    { key: "street", label: "Street" },
    { key: "home", label: "Home" },
    { key: "appartment", label: "Appartment" },
  ];

  function getDefaulValues(key) {
    switch (key) {
      case "appartment":
        return pi?.addressFlat;
      case "area":
        return pi?.addressDistrict;
      case "street":
        return pi?.addressStreet;
      case "city":
        return pi?.city;
      case "connection_address":
        return pi?.connPoint;
      case "home":
        return pi?.addressBuild;
      default:
        return "";
    }
  }

  return (
    <div className="step">
      <h1 className="step-title">{translatedText("Shutdown address")}</h1>
      <div className="step-grid">
        {data.map((d) => (
          <Input
            defaultValue={getDefaulValues(d.key)}
            key={d.key}
            marginLeftSpan={10}
            onChange={(val) => onChangeInputs(val, d.key)}
            mini
            init
            label={translatedText(d.label)}
          />
        ))}
      </div>
    </div>
  );
}
