import React from "react";
import FileCard from "../../components/FileCard";
import Tabs from "../../components/Tabs";
import { translatedText } from "../../services/translatedText";

export default function FormsAndDocs() {
  return (
    <div className="container">
      <h1 style={{ margin: "50px 0" }}>
        {translatedText("Forms and Documents")}
      </h1>
      <Tabs
        style={{ marginBottom: 50 }}
        titles={[translatedText("Documents"), translatedText("Forms")]}
      >
        {(index) =>
          index === 0 ? (
            <div className="grid">
              <FileCard title="GUVOHNOMA" />
              <FileCard title="Лицензия АА №0006571 Проектирование, строительство, эксплуатация и оказание услуг сетей передачи данных" />
              <FileCard title="Лицензия AA № 0007435 Проектирование, строительство, эксплуатация и оказание услуг местных сетей телекоммуникаций" />
              <FileCard title="Свидетельство на товарный знак и знак обслуживания" />
              <FileCard title="Порядок предоставления услуг Comnet" />
              <FileCard title="Памятка абонента" />
              <FileCard title="ТЕЛЕКОММУНИКАЦИЯ ХИЗМАТЛАРИНИ КЎРСАТИШ ҚОИДАЛАРИНИ ТАСДИҚЛАШ ТЎҒРИСИДА" />
            </div>
          ) : (
            <></>
          )
        }
      </Tabs>
    </div>
  );
}
