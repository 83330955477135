import { translatedText } from "../services/translatedText";

export function convertToCurrency(amount) {
  if (!amount || amount === "") return 0;

  const result = [];
  const temp = amount.toString().replaceAll(" ").split("").reverse();
  for (let i = 0; i < temp.length; i++) {
    const letter = temp[i];
    result.unshift(letter);
    if ((i + 1) % 3 === 0) result.unshift(" ");
  }

  return result.join("");
}

export function parsePhone(phone1) {
  if (!phone1 || phone1 == "") return;
  let phone = phone1.toString();

  let result = "+998 ";
  if (phone.length === 12) phone = phone.slice(3);
  result += phone.toString().slice(0, 2);
  result += ` ${phone.toString().slice(2, 5)}`;
  result += ` ${phone.toString().slice(5, 7)}`;
  result += ` ${phone.toString().slice(7, 9)}`;

  return result;
}

export function formatBytes(
  bytes,
  decimals = 2,
  typeNum = false,
  speedText = false
) {
  if (bytes === 0) return `0 ${translatedText("Bytes")}`;

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = [
    translatedText("Bytes"),
    "KB",
    "MB",
    "GB",
    "TB",
    "PB",
    "EB",
    "ZB",
    "YB",
  ];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return (
    parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) +
    (typeNum ? 0 : " " + sizes[Math.abs(i)] + (speedText ? "/s" : ""))
  );
}

export function convertToPlain(html) {
  const tempDivElement = document.createElement("div");
  tempDivElement.innerHTML = html;
  tempDivElement.innerHTML =
    tempDivElement.textContent || tempDivElement.innerText || "";
  return tempDivElement.textContent || tempDivElement.innerText || "";
}
