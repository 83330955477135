const states = {
  banners: [],
};

const bannerReducer = (state = states, action) => {
  switch (action.type) {
    case "SET_BANNERS":
      return { ...state, banners: [...action.payload] };
    default:
      return state;
  }
};
export { bannerReducer };
