import React from "react";
import CheckboxGroup from "../../../../components/CheckboxGroup";
import { translatedText } from "../../../../services/translatedText";

export default function Step5({ onPossible, onImpossible }) {
  const checkBoxData = () => [
    translatedText("Stay in pacc"),
    translatedText("Return through cash"),
  ];

  return (
    <div className="step">
      <h1 className="step-title">{translatedText("Funds on account")}</h1>
      <CheckboxGroup
        lowPriority
        gap={25}
        onChangeWithData={(data) => {
          var result = data.filter((d) => d?.value === true)[0]?.data;
          if (result) onPossible && onPossible(result);
          else onImpossible && onImpossible();
        }}
        data={checkBoxData()}
      />
    </div>
  );
}
