import React,{ useState } from "react";
import { sentPromoCode } from "../services/http/userAPI";
import { translatedText } from "../services/translatedText";
import FormModal from "./FormModal";
import Input from "./Input";
import Loader from "./Loader";
import success from '../assets/images/svg/success.svg'
import errorIcon from '../assets/images/svg/error2.svg'
export default function SentAboutErrorModal({
  openModal,
  handleCloseModal,
  currentData,
  setReload
}) {
  const [loader, setLoader] = useState(false);
  const [msg, setMsg] = useState({
    msg: "",
  });
  const [isSuccess,setIsSuccess] = useState(false)
  const [error, setError] = useState();

  const [promo, setPromo] = useState();

  const closeModal = () => {
    setMsg({ msg: "" });
    setError(null);
    handleCloseModal();
  };

  const sendPromo = async() =>{
    if (!currentData?.user?.login) {
      setError("login not found");
      return;
    }
    if (!promo) {
      setError(translatedText("Valid data"));
      return;
    }
    setError(null);
    setLoader(true);
    
    try {
      const response = await sentPromoCode(
        currentData.user.login,
        promo
      );
      if (response?.status != "ok")  {
        setMsg({
          msg: response.message,
          error: 1,
        })
        setLoader(false);
        return
      }
    } catch (e) {
      setMsg({
        msg: translatedText("Unknown error"),
        error: 1,
      });
      setLoader(false);
      return
    }

    setIsSuccess(true)
    setLoader(false);
    
  }
 
  return (
    <FormModal open={openModal.open} onClose={closeModal}>
      {!isSuccess?<>
        <div
          style={{ marginTop: 10,width:300,display:'flex',flexDirection:'column',alignItems:'center' }}
          
        >
          {loader ? (
            <Loader center />
          ) : msg.msg && !msg?.code ? (
            <>
              <img style={{width:60}} src={errorIcon} />
            <h2
              style={{ color: msg?.error ? "var(--primary-color)" : null,marginTop:20,marginBottom:30 }}
              className="succes-text"
            >
              {msg.msg}
            </h2>
            </>
            
          ) : (
            <div className="col g-2">
              <div style={{width:'100%',display:'flex',justifyContent:'center'}}>{translatedText("write promo")}</div>
              <Input
                hideLabel={true}
                style={{textTransform:"uppercase",letterSpacing:5,textAlign:"center",fontWeight:"bold"}}
                onChange={(value) => setPromo(value.toUpperCase())}
                label={translatedText("write promo")}
              />

            </div>
          )}
          {error && (
            <p
              style={{ margin: "15px 0 0 25px", fontSize: 16 }}
              className="red-text"
            >
              {error}
            </p>
          )}
        </div>
        {msg.error!=1?<div className="row g-2" style={{ alignItems: "end",justifyContent:'center' }}>
          <button
            onClick={sendPromo}
            style={{ width: 160, padding: 18,fontWeight:400 }}
            className="primary-btn"
          >
            {translatedText("Send")}
          </button>
        </div>:<div className="row g-2" style={{ alignItems: "end",justifyContent:'center' }}>
          <button
            onClick={closeModal}
            style={{ width: 160, padding: 18,fontWeight:400 }}
            className="primary-btn"
          >
            {translatedText("Close")}
          </button>
        </div>}
      </>:<></>}

      {isSuccess?<>
        <div
          style={{ marginTop: 10,width:350 }}
          className={"p-1"}
        >
          <div style={{display:"flex",flexDirection:"column",alignItems:'center'}} className="">
            <img  src={success} />
            <p style={{marginTop:20,textAlign:'center',fontSize:20,marginBottom:40}}>{translatedText("Success")}</p>
            <button
            onClick={()=>{closeModal();setReload(i=>!i)}}
            style={{ width: 160, padding: 18,marginTop:0,fontWeight:400 }}
            className="primary-btn"
          >
            {translatedText("Close")}
          </button>
          </div>

        </div>
      </>:<></>}
      
    </FormModal>
  );
}
