import i18next from "i18next";
import React from "react";
import { Carousel } from "react-responsive-carousel";
import classes from "./style.module.css";

const CaruselPartnerCategory = ({ data, currentType, chose }) => {
  const lang = i18next.language;
  return (
    <div className={classes.News}>
      <div className={classes.News__inner}>
        <div className={classes.News__items}>
          {data && (
            <Carousel
              showThumbs={false}
              showStatus={false}
              showArrows={true}
              centerSlidePercentage={100}
              renderArrowPrev={(onClickHandler, hasPrev, label) =>
                hasPrev && (
                  <button
                    type="button"
                    onClick={onClickHandler}
                    className={classes.arrow}
                    style={{ left: -25 }}
                  >
                    <svg
                      width="11"
                      height="18"
                      viewBox="0 0 11 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.2"
                        d="M0.91394 8.51406L8.58268 1.23117C8.83394 0.992689 9.24073 0.99309 9.49157 1.2324C9.74221 1.47168 9.74156 1.85929 9.49027 2.09795L2.278 8.94738L9.49053 15.7968C9.74179 16.0355 9.74244 16.4228 9.49183 16.6621C9.36609 16.7821 9.20136 16.8421 9.03663 16.8421C8.87232 16.8421 8.70823 16.7825 8.58272 16.6634L0.91394 9.38066C0.792926 9.266 0.725019 9.10996 0.725019 8.94738C0.725019 8.7848 0.79312 8.62894 0.91394 8.51406Z"
                        fill="black"
                        stroke="black"
                      />
                    </svg>
                  </button>
                )
              }
              renderArrowNext={(onClickHandler, hasNext, label) =>
                hasNext && (
                  <button
                    type="button"
                    onClick={onClickHandler}
                    className={classes.arrow}
                    style={{ right: -25 }}
                  >
                    <svg
                      width="11"
                      height="18"
                      viewBox="0 0 11 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.2"
                        d="M10.0861 8.51406L2.41732 1.23117C2.16606 0.992689 1.75927 0.99309 1.50843 1.2324C1.25779 1.47168 1.25844 1.85929 1.50973 2.09795L8.722 8.94738L1.50947 15.7968C1.25821 16.0355 1.25756 16.4228 1.50817 16.6621C1.63391 16.7821 1.79864 16.8421 1.96337 16.8421C2.12768 16.8421 2.29177 16.7825 2.41728 16.6634L10.0861 9.38066C10.2071 9.266 10.275 9.10996 10.275 8.94738C10.275 8.7848 10.2069 8.62894 10.0861 8.51406Z"
                        fill="black"
                        stroke="black"
                      />
                    </svg>
                  </button>
                )
              }
              className={classes.News__slider}
              swipeable
            >
              {data.map((item, index) => (
                <div
                  onClick={() => {
                    chose(item.type);
                  }}
                  className={`${classes.carusel} partners-tabs__list-tab ${
                    classes.item
                  } ${
                    item.type === currentType ? "react-tabs__tab--selected" : ""
                  }`}
                  key={index.toString()}
                >
                  <img
                    className="partners-tabs__list-tab-wrapperImage"
                    src={
                      item.type === currentType
                        ? item.imgActive
                        : item.imgActive
                    }
                    alt=""
                  />
                  <p>{lang === "ru" ? item.title : item.titleuz}</p>
                </div>
              ))}
            </Carousel>
          )}
        </div>
      </div>
    </div>
  );
};

export default CaruselPartnerCategory;
