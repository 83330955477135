import React from "react";
import classes from "./style.module.css";
import PropType from "prop-types";
import Profile from "../Profile";

export default function MainBlock({
  children,
  title,
  hideTitle = false,
  style = {},
  containerStyle = {},
  hasProfile = false,
  isMobile = false,
  checked = false,
  mini = false,
  ...props
}) {
  return (
    <span
      style={{ width: mini ? "100%" : "auto", ...containerStyle }}
      {...props}
    >
      {title && <h1 className={classes.title}>{title}</h1>}
      {hasProfile && isMobile && <Profile isMobile />}
      <div
        className={[
          classes.main_block,
          checked ? classes.checked : "",
          mini ? classes.mini : "",
        ].join(" ")}
        style={style}
      >
        {children}
      </div>
    </span>
  );
}
MainBlock.propTypes = {
  children: PropType.any,
  checked: PropType.bool,
  isMobile: PropType.bool,
  title: PropType.string,
  style: PropType.object,
  containerStyle: PropType.object,
  hasProfile: PropType.bool,
  mini: PropType.bool,
};
