import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { translatedText } from "../services/translatedText";
import PropTypes from "prop-types";
import { getAlertsUser } from "../services/http/alerts";
import Table from "./Table";
import { toStringDate } from "../utils/date";
import { parsePhone } from "../utils/parser";

export default function Report({ user }) {
  const [notifications, setNotifications] = useState();

  useEffect(() => {
    if (!user?.login) return;
    async function fetchData() {
      let res = await getAlertsUser(user?.login);
      res = res.map((r) => [
        r.text,
        parsePhone(r.phone),
        toStringDate(new Date(r.created_at), true),
      ]);
      setNotifications(res);
    }
    fetchData();
  }, [user]);

  return (
    <div>
      <h1 style={{ marginTop: 50, marginBottom: 40 }}>
        {translatedText("Report")}
      </h1>
      <div className="shadow-block">
        <Table
          // theadStyle={{ fontSize: 16 }}
          className="report-table"
          limit={10}
          headers={[
            translatedText("Text"),
            translatedText("Phone number"),
            translatedText("Sent date"),
          ]}
          data={notifications}
          loading={!notifications}
        />
        {/* <table
          style={{
            width: "100%",
            borderCollapse: "separate",
            borderSpacing: "30px 0",
          }}
        >
          <thead>
            <tr>
              <th className="t-start">{translatedText("Active")}</th>
              <th className="t-start">{translatedText("Nomination")}</th>
              <th className="t-start">{translatedText("Value")}</th>
              <th className="t-start">{translatedText("Price")}</th>
              <th className="t-start">{translatedText("Sent")}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <Checkbox />
              </td>
              <td>Остаток предоплаченного трафика ниже</td>
              <td style={{ width: 200 }}>
                <Input clear />
              </td>
              <td>0.00</td>
              <td>0000. 00. 00</td>
            </tr>
          </tbody>
        </table> */}
      </div>

      <div className="row j-center" style={{ margin: "50px 0" }}>
        {/* <button style={{ fontWeight: 400 }} className="primary-btn">
          {translatedText("Setup")}
        </button> */}
      </div>
    </div>
  );
}

Report.propTypes = {
  user: PropTypes.object,
};
