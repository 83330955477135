import React, { createContext, useContext, useEffect, useState } from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import useActions from "../hooks/useActions";
import { login } from "./http/authentication";
import { getOnline } from "./http/internet";
import { getUserInfo } from "./http/userAPI";

// "start": "set HTTPS=true&&set SSL_CRT_FILE=./.cert/cert.pem&&set SSL_KEY_FILE=./.cert/key.pem&&set PORT=9443&&react-scripts start",
export const PrivateRoute = () => {
  const auth = useAuth();

  const [actions, state] = useActions();
  const location = useLocation();

  useEffect(() => {
    if (
      location.pathname === "/login" ||
      (auth.sid && auth.user?.login) ||
      state.user?.uid
    )
      return;
    async function fetchData() {
      const res = await getUserInfo();
      actions.setUser(res);
    }
    fetchData();
  }, []);

  return auth.sid && localStorage.getItem("sid") ? (
    <Outlet />
  ) : (
    <Navigate to="/login" />
  );
};

export function AuthRoute({ children }) {
  const auth = useAuth();
  return !auth.sid || !localStorage.getItem("sid") ? (
    children
  ) : (
    <Navigate to="/" />
  );
}

const userAuth = {
  isAuthenticated: !!localStorage.getItem("sid"),
  signin: async (_login, _pass, cb = null) => {
    try {
      // if (localStorage.getItem("sid")) return;
      const data = await login(_login, _pass);
      if (data?.error && data?.error != 0) {
        if (cb) cb(false, null);
        return;
      }
      localStorage.setItem("sid", data.sid);
      localStorage.setItem("uid", data.uid);
      userAuth.isAuthenticated = true;
      if (cb) cb(data);
    } catch (error) {
      if (cb)
        cb(false, error?.code === "ERR_NETWORK" ? "ERR_NETWORK (cors)" : null);
      console.log(error);
    }
  },

  signout(cb) {
    localStorage.removeItem("sid");
    localStorage.removeItem("uid");
    userAuth.isAuthenticated = false;
    if (cb) cb();
  },
};

function useProvideAuth() {
  const [actions, state] = useActions();
  const [sid, setSid] = useState(localStorage.getItem("sid"));
  const [user, setUser] = useState(state.user);
  async function fetchData() {
    try {
      if (state.online?.length) return;
      const onlineData = await getOnline();
      if (!Array.isArray(onlineData) || !onlineData?.length) return;
      actions.setOnline(onlineData);
    } catch (error) {
      console.log(error);
    }
  }
  
  useEffect(() => {
    if (!sid) return;
    fetchData();
  }, [sid]);

  const signin = (username, password, cb, withPhone) => {
    return userAuth.signin(
      username,
      password,
      async (data = false, error = null) => {
        if (data.sid) {
          setSid(data.sid);
          actions.setTariff({});
          actions.setPi({});
          actions.setManager({});
          actions.setContacts({});
          actions.setServices({});
          actions.setTariffs({});
          actions.setOnline({});
        }
        if (cb) cb(data, error);
      },
      withPhone
    );
  };

  const signout = (cb) => {
    return userAuth.signout(() => {
      setUser(null);
      setSid(null);
      actions.setUser(null);
      cb();
    });
  };

  return {
    user,
    sid,
    signin,
    signout,
  };
}

const authContext = createContext();

export default function ProvideAuth({ children }) {
  const auth = useProvideAuth();

  // if (!auth.sid && location.pathname !== "/login")
  //   return <Navigate to="/login" state={{ from: location.pathname }} />; //<Loader fill center />;
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export function useAuth() {
  return useContext(authContext);
}
