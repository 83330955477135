import { t } from "i18next";
import React from "react";
import classes from "./ComnetTitle.module.scss";

export const ComnetTitle = () => {
  return (
    <div className={classes.comnetTitle}>
      <p>сomnet</p> <p className={classes.small}>-</p>{" "}
      <p>{t("Your caring provider")}</p>
      <img src={require("../../assets/images/hand1.png")} alt="" />
    </div>
  );
};
