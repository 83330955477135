import React from 'react'
import { translatedText } from '../../services/translatedText'
import { withTranslation } from 'react-i18next'
import { useState } from 'react'
import { useEffect } from 'react'
import { getCurrentTariff, getTariffs } from '../../services/http/tariffs'
import Loader from '../../components/Loader'
import useActions from '../../hooks/useActions'
import TariffCard from '../../components/TariffCard'
import { useNavigate } from 'react-router-dom'
import TariffModal from './TariffModal'
import { useCheckPhone } from '../../hooks/useCheckPhone'
import InternetAccordion from '../../components/InternetAccordion'

export default withTranslation()(function TariffsPlan() {
  const [allTariffs, setAllTariffs] = useState()
  const [currentTariff, setCurrentTariff] = useState()
  const [actions, { tariff: globalTariff, tariffs: globalTariffs }] = useActions()
  const [tariff, setTariff] = useState()
  const [showArchiveTariffs, setShowArchiveTariffs] = useState(false)

  const onClickTariff = (tariff) => {
    setTariff(tariff)
    setOpen(true)
  }

  const resetTariff = async () => {
    try {
      const tempCurrentTariff = (await getCurrentTariff())[0]

      let temp = await getTariffs()
      temp = temp.errno ? { error: 2 } : temp

      const result = !Array.isArray(temp)
        ? temp
        : temp.filter((t) => t.name.includes('+')).sort((a, b) => a.speed1 - b.speed1)

      const crTariff = result.find((r) => r.tpId === tempCurrentTariff.tpId) || {}

      actions.setTariff({ ...tempCurrentTariff, ...crTariff })
      setCurrentTariff({ ...tempCurrentTariff, ...crTariff })
      setAllTariffs(result)
      actions.setTariffs(result)
    } catch (e) {
      console.log(e)
    }
  }

  const [open, setOpen] = useState(false)
  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    if (globalTariffs?.length && globalTariff?.id) {
      const validTariffs = {
        2279: 2279,
        2280: 2280,
        2281: 2281,
      }

      const filtered = globalTariffs.filter((el) => validTariffs[el.id])

      setAllTariffs(filtered)

      setCurrentTariff(globalTariff)
      return
    }

    async function fetchData() {
      try {
        let temp = await getTariffs()

        temp = temp.errno ? { error: 2 } : temp

        const result = !Array.isArray(temp)
          ? temp
          : temp.filter((t) => t.name.includes('+')).sort((a, b) => a.speed1 - b.speed1)

        const sorted = result.reduce((acc, el, i) => {
          i === 1 ? acc.unshift(el) : acc.push(el)
          return acc
        }, [])

        const validTariffs = {
          2279: 2279,
          2280: 2280,
          2281: 2281,
        }

        const filtered = sorted.filter((el) => validTariffs[el.id])

        setAllTariffs(filtered)
        actions.setTariffs(sorted)

        if (!globalTariff?.id) {
          const tempCurrentTariff = (await getCurrentTariff())[0]
          actions.setTariff(tempCurrentTariff)
          setCurrentTariff(tempCurrentTariff)
        } else if (!currentTariff) {
          setCurrentTariff(globalTariff)
        }
      } catch (e) {
        console.log(e)
      }
    }
    fetchData()
  }, [])

  const isPhone = useCheckPhone()
  const history = useNavigate()
  return (
    <div className="container">
      <h1 style={{ margin: '25px 0 30px 0' }}>{translatedText('Your Tariff')}</h1>
      <TariffCard history={history} tariff={currentTariff} current hideChangeButton />
      {allTariffs?.error ? (
        <p style={{ margin: '50px 0' }} className="error-text">
          {translatedText(
            allTariffs.error === 4506 || (allTariffs?.message || '').includes('NOT_ALLOWED_TO_CHANGE_TP')
              ? 'Not allowed change tariff'
              : 'No data'
          )}
        </p>
      ) : (
        <React.Fragment>
          <h1 style={{ margin: '30px 0 30px 0' }}>{translatedText('Tariff plan')}</h1>
          {!allTariffs ? (
            <Loader center />
          ) : (
            <div className="col block">
              {allTariffs
                .filter((t) => t.tpId !== globalTariff.tpId)
                .map((tariff) => (
                  <TariffCard
                    recommended={(tariff?.tpName || tariff?.name || '').includes('Internet+TV 2')}
                    onClickConnect={() => onClickTariff(tariff)}
                    history={history}
                    key={tariff.id}
                    tariff={tariff}
                  />
                ))}
            </div>
          )}
          <p
            style={isPhone ? { margin: '20px 0 30px 0', fontSize: 11 } : { margin: '30px 0 100px 0', fontSize: 20 }}
            className="additional-frame red-text"
          >
            <span>* {translatedText('Speed-alert')}</span>
          </p>
        </React.Fragment>
      )}

      {/* OLD TARIFF LIST */}

      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'center',
        }}
      >
        <button
          onClick={() => setShowArchiveTariffs((el) => !el)}
          style={{
            textAlign: 'center',
            marginBottom: '90px',
            fontSize: '20px',
            padding: '15px 20px',
            borderRadius: '8px',
            border: 'none',
            color: '#5d5d5d',
          }}
        >
          {translatedText('archivedTariffs')}
        </button>
      </div>

      {showArchiveTariffs ? <InternetAccordion /> : null}
      {/* ---/--- OLD TARIFF LIST ---/--- */}

      <TariffModal
        globalTariff={currentTariff}
        open={open}
        tariff={tariff || {}}
        handleClose={handleClose}
        resetTariff={resetTariff}
      />
    </div>
  )
})
