import React, { useState } from "react";
import css from "./style.module.scss";
import PropTypes from "prop-types";
import Arrow from "../../assets/images/svg/Down.svg";
import { useRef } from "react";
import { motion } from "framer-motion";
import { useEffect } from "react";

export default function DropDown({
  text,
  primary = false,
  items = [],
  defaultItem,
  label,
  onChangeItem,
}) {
  const [current, setCurrent] = useState();
  const [defaultValue, setDefaultValue] = useState(text ?? items[0]);
  const [showItems, setShowItems] = useState(false);
  const parent = useRef();
  const target = useRef();
  const visibleItems = items;

  useEffect(() => {
    if (!items?.length) return;

    setDefaultValue(items[0]);
  }, [items]);
  useEffect(() => {
    if (!defaultItem) return;
    setCurrent(defaultItem);
  }, [defaultItem]);

  function evLis(e) {
    if (!parent.current) return;
    if (
      !parent.current.contains(e.target) &&
      !target.current.contains(e.target)
    ) {
      setShowItems(false);
    }
  }
  useEffect(() => {
    window.addEventListener("click", evLis);
    return () => {
      window.removeEventListener("click", evLis);
    };
  }, []);

  return (
    <label className={[css.wrapLabel, primary ? css.primary : ""].join(" ")}>
      {label && <span style={{ marginLeft: 25 }}>{label}</span>}
      <div ref={target} className={css.dropdown}>
        <button
          style={!label ? { margin: 0 } : {}}
          onClick={() => setShowItems(!showItems)}
          className={[css.button, primary ? css.primary : ""].join(" ")}
        >
          {current || defaultValue}
          <img src={Arrow} />
        </button>

        <motion.div
          ref={parent}
          initial={{
            height: 0,
          }}
          animate={{
            height: showItems ? "auto" : 0,
            overflow: showItems ? "visible" : "hidden",
            transition: {
              height: {
                duration: showItems ? 0.2 : 0.15,
              },
            },
          }}
          className={[css.wrap, primary ? css.primary : ""].join(" ")}
        >
          <button
            style={{ marginTop: 0 }}
            onClick={() => {
              setTimeout(() => {
                setShowItems(false);
              }, 10);
            }}
            className={css.button}
          >
            {current || defaultValue}
            <img src={Arrow} />
          </button>

          <motion.div
            initial={{
              height: 0,
              overflowY: "hidden",
            }}
            animate={{
              height: showItems ? "auto" : 0,
              overflowY: showItems ? "auto" : "hidden",
              transition: {
                overflowY: {
                  delay: showItems ? 0.3 : 0,
                },
                height: {
                  duration: showItems ? 0.2 : 0.15,
                  delay: 0.1,
                },
              },
            }}
            style={{ maxHeight: 400 }}
            className={css.itemsWrapper}
          >
            {visibleItems.map((item, i) => (
              <a
                onClick={() => {
                  setTimeout(() => {
                    setShowItems(false);
                  }, 100);
                  setCurrent(item);
                  if (onChangeItem) onChangeItem(item, items.indexOf(item));
                }}
                className={css.a + (item === current ? " red-text" : "")}
                key={i}
              >
                {item}
              </a>
            ))}
          </motion.div>
        </motion.div>
      </div>
    </label>
  );
}
DropDown.propTypes = {
  text: PropTypes.string,
  label: PropTypes.string,
  items: PropTypes.array,
  onChangeItem: PropTypes.func,
};
