import axios from "axios";

export async function getAlertsUser(login) {
  const { data } = await axios.get(
    "https://serv.comnet.uz/api/notification/" + login,
    {
      headers: {
        Authorization: "Bearer 3|YOz6gV9lvSlVao1VfTzboRMDSpipDNuSMeSLaNFo",
      },
    }
  );

  return data?.message || data;
}

export async function fetchAlertConstruction(){
  const {data} = await axios.get("https://serv.comnet.uz/api/under/construction",{
    headers: {
      Authorization: `Bearer 3|YOz6gV9lvSlVao1VfTzboRMDSpipDNuSMeSLaNFo`,
    },
  })

  return data;
}