import { $host } from '.'
import { encodeText } from '../../utils/crypt'

//"pon02an110k18" "9148978a"
export async function login(login, password) {
  const raw = { login, password }
  const { data } = await $host.post('/api.cgi/users/login', raw)
  const encoded = encodeText('comnetuz')(JSON.stringify(raw))
  sessionStorage.setItem('crypt', encoded)
  return data
}
