import StaticIpOne from '../assets/images/svg/staticExtraServices/StaticIpOne.svg'
import StaticIpTwo from '../assets/images/svg/staticExtraServices/StaticIpTwo.svg'
import ParentalControl from '../assets/images/svg/staticExtraServices/ParentalControl.svg'
import ParentalControlAdultSites from '../assets/images/svg/staticExtraServices/ParentalControlAdultSites.svg'
import ParentalControlAdultAds from '../assets/images/svg/staticExtraServices/ParentalControlAdultAds.svg'
import ParentalControlFamilySearch from '../assets/images/svg/staticExtraServices/ParentalControlFamilySearch.svg'
import ParentalControlProtectionDangerousSites from '../assets/images/svg/staticExtraServices/ParentalControlProtectionDangerousSites.svg'
import ParentalControlSpamProtection from '../assets/images/svg/staticExtraServices/ParentalControlSpamProtection.svg'
import ParentalControlBotProtection from '../assets/images/svg/staticExtraServices/ParentalControlBotProtection.svg'
import ParentalControlFast from '../assets/images/svg/staticExtraServices/ParentalControlFast.svg'
import ParentalControlSecure from '../assets/images/svg/staticExtraServices/ParentalControlSecure.svg'
import ParentalControlFree from '../assets/images/svg/staticExtraServices/ParentalControlFree.svg'
import FreezingOne from '../assets/images/svg/staticExtraServices/FreezingOne.svg'
import FreezingTwo from '../assets/images/svg/staticExtraServices/FreezingTwo.svg'
import CallExpert from '../assets/images/svg/staticExtraServices/CallExpert.svg'
import PostponementOne from '../assets/images/svg/staticExtraServices/PostponementOne.svg'
import PostponementTwo from '../assets/images/svg/staticExtraServices/PostponementTwo.svg'
import RentRouterOne from '../assets/images/svg/staticExtraServices/RentRouterOne.svg'
import RentRouterTwo from '../assets/images/svg/staticExtraServices/RentRouterTwo.svg'
import GiveBackRouterOne from '../assets/images/svg/staticExtraServices/GiveBackRouterOne.svg'
import GiveBackRouterTwo from '../assets/images/svg/staticExtraServices/GiveBackRouterTwo.svg'
import BuyDeviceOne from '../assets/images/svg/staticExtraServices/BuyDeviceOne.svg'
import BuyDeviceTwo from '../assets/images/svg/staticExtraServices/BuyDeviceTwo.svg'

const yandex_dns_mode = {
  settings: [
    { title: 'IPv4', items_ru: ['77.88.8.8', '77.88.8.1'], items_uz: ['77.88.8.8', '77.88.8.1'] },
    {
      title: 'IPv6',
      items_ru: ['2a02:6b8::feed:0ff', '2a02:6b8:0:1::feed:0ff'],
      items_uz: ['2a02:6b8::feed:0ff', '2a02:6b8:0:1::feed:0ff'],
    },
    { title: 'DoH', items_ru: ['77.88.8.8:443'], items_uz: ['77.88.8.8:443'] },
    {
      title: 'DoT',
      items_ru: ['77.88.8.8:853', 'common.dot.dns.yandex.net', 'Доменное имя сервера TLS', '(Private DNS для Android)'],
      items_uz: ['77.88.8.8:853', 'common.dot.dns.yandex.net', 'TLS server domen nomi', '(Android uchun Private DNS)'],
    },
  ],
  conditions: [
    {
      title_ru: 'Блокировка сайтов для взрослых',
      title_uz: 'Katta yoshdagilar kiradigan saytlarni bloklash',
      descr_ru: `Алгоритмы Яндекса способны автоматически определять эротический и порнографический контент. Если вы попробуете открыть такие сайты, вместо нежелательной информации вы увидите специальную 'заглушку'. Эта страница предоставляет безопасное и ненавязчивое решение, обеспечивая вам защиту от несанкционированного контента.`,
      descr_uz: `Yandex algoritmlari erotik va pornografik kontentni avtomatik ravishda aniqlashga qodir. Agar siz bunday saytlarni ochishga harakat qilsangiz, keraksiz ma'lumotlar o'rniga siz maxsus "qotib qolish" holatini ko'rasiz. Ushbu sahifa sizni ruxsatsiz kontentga kirishdan himoyalaydigan xavfsiz va sodda yechimni taqdim etadi.`,
      svgIcon: ParentalControlAdultSites,
    },
    {
      title_ru: 'Блокировка рекламы для взрослых',
      title_uz: 'Katta yoshdagilar uchun reklamalarni blokirovka qilish',
      descr_ru: `Яндекс проводит мониторинг рекламных сетей и выявляет те, которые содержат эротическую и порнографическую рекламу. Чтобы исключить показ такой нежелательной рекламы на всех сайтах, Яндекс блокирует доступ этой рекламной сети к вашему IP-адресу. Это обеспечивает более безопасное и комфортное пространство в Интернете для пользователей.`,
      descr_uz: `Yandex reklama tarmoqlarini kuzatib boradi va erotik hamda pornografik reklamalarni o'z ichiga olgan kontentlarni aniqlaydi. Bunday noo’rin reklamaning barcha saytlarda ko'rsatilishiga yo'l qo'ymaslik uchun Yandex ushbu reklama tarmog'i sizning IP manzilingizga kirishini bloklaydi. BU esa internet foydalanuvchi uchun xavfsizroq va qulayroq onlayn makonga aylanishini ta’minlaydi.`,
      svgIcon: ParentalControlAdultAds,
    },
    {
      title_ru: 'Семейный поиск Яндекса',
      title_uz: 'Yandexdan butun oila uchun izlash tizimi',
      descr_ru: `Контент для взрослых автоматически исключается из результатов поиска. Даже если вы введите явный запрос, например, 'порно', эротический и порнографический контент не будет отображаться в результатах поиска.`,
      descr_uz: `Voyaga yetganlar uchun kontent avtomatik ravishda qidiruv natijalaridan chiqarib tashlanadi. Agar siz "porno" kabi aniq so'rovni kiritsangiz ham, erotik va pornografik kontent qidiruv natijalarida ko'rinmaydi.`,
      svgIcon: ParentalControlFamilySearch,
    },
    {
      title_ru: 'Защита от опасных сайтов',
      title_uz: 'Xavfli saytlardan himoya tizimi',
      descr_ru: `Яндекс DNS заботится о вашей безопасности в сети, блокируя доступ к опасным веб-сайтам. База данных заражённых сайтов обновляется ежедневно, обеспечивая вам надежную защиту от вредоносных ресурсов в Интернете.`,
      descr_uz: `Yandex DNS xavfli veb-saytlarga kirishni bloklash orqali onlayn xavfsizligingiz borasida g'amxo'rlik qiladi. Zararlangan saytlarning ma'lumotlar bazasi har kuni yangilanadi, bu sizni Internetdagi zararli manbalardan ishonchli himoya qiladi.
      `,
      svgIcon: ParentalControlProtectionDangerousSites,
    },
    {
      title_ru: 'Защита от мошенников',
      title_uz: 'Firibgarlikdan himoya ',
      descr_ru: `Опасные веб-ресурсы могут представлять угрозу для вашей личной информации, такой как номер телефона и данные банковской карты. Они также могут пытаться навязать платную рассылку. Благодаря Яндекс DNS, вы можете быть уверены в том, что такие сайты будут заблокированы, обеспечивая надёжную защиту ваших личных данных и финансовых средств`,
      descr_uz: `Xavfli veb-resurslar telefon raqamingiz va bank kartangiz ma'lumotlari kabi shaxsiy ma'lumotlaringizga tahdid solishi mumkin. Shuningdek, ular sizni pullik elektron pochta xabarlarini yuborishga undashlari mumkin. Yandex DNS tufayli esa siz shaxsiy ma'lumotlaringiz va moliyaviy aktivlaringiz ishonchli himoyasi ta'minlangan holda bunday turdagi saytlar bloklanganiga amin bo'lishingiz mumkin.`,
      svgIcon: ParentalControlSpamProtection,
    },
    {
      title_ru: 'Защита от ботнетов',
      title_uz: 'Botnet himoyasi',
      descr_ru: `Боты могут представлять угрозу для вашего компьютера, крадя ваши данные или даже майнить криптовалюту, используя ваши ресурсы. Сервис Яндекс блокирует деятельность ботнетов, то есть компьютерных сетей, зараженных ботами, прерывая их связь с управляющим сервером. Эти данные поступают от наших партнеров, обеспечивая вашу безопасность в сети.`,
      descr_uz: `Botlar sizning ma'lumotlaringizni o'g'irlash yoki hatto resurslaringizdan foydalangan holda kriptovalyutani mayn qilish orqali kompyuteringizga tahdid solishi mumkin. Yandex xizmati botnetlar faoliyatini, ya'ni botlardan tomonidan zararlangan kompyuter tarmoqlarini bloklaydi, ularning boshqaruv serveri bilan ulanishini to'xtatadi. Ushbu maʼlumotlar bizga hamkorlarimiz tomonidan kelib tushadi hamda sizning onlayn xavfsizligingizni taʼminlaydi.`,
      svgIcon: ParentalControlBotProtection,
    },
    {
      title_ru: 'Быстрый',
      title_uz: 'Tezkor',
      descr_ru: `Ваш запрос автоматически направляется к одному из 100+ DNS-серверов Яндекса, что обеспечивает быструю загрузку веб-сайтов.`,
      descr_uz: `Sizning so'rovingiz avtomatik ravishda Yandex-ning 100+ DNS serverlaridan biriga yo'naltiriladi va veb-saytlarning tez yuklanishini ta'minlaydi.`,
      svgIcon: ParentalControlFast,
    },
    {
      title_ru: 'Надёжный',
      title_uz: 'Ishonchli',
      descr_ru: `Наши DNS-серверы всегда готовы к использованию, ваша личная информация остается в надежной защите.`,
      descr_uz: `Bizning DNS serverlarimiz har doim foydalanishga shay holatdadir, bu sizning shaxsiy ma'lumotlaringiz ishonchli tarzda himoyalanganligini ta'minlaydi.`,
      svgIcon: ParentalControlSecure,
    },
    {
      title_ru: 'Бесплатный',
      title_uz: 'Bepul',
      descr_ru: `Мы стремимся обеспечить удобный и безопасный доступ к интернету для наших пользователей.`,
      descr_uz: `Biz foydalanuvchilarimiz uchun qulay va xavfsiz Internetga kirishni ta'minlashga intilamiz.`,
      svgIcon: ParentalControlFree,
    },
  ],
  some_info_ru: `Даже если ваш DNS-сервер временно не отвечает, вы по-прежнему сможете открывать веб-сайты, так как ваш запрос будет автоматически перенаправлен на DNS-сервер Яндекса.`,
  some_info_uz: `Agar sizning DNS serveringiz vaqtincha javob bermay turgan bo’lsa ham, siz xuddi oldingidek veb-saytlarni ochish imkoniyatiga egasiz, chunki sizning so'rovingiz avtomatik ravishda Yandex DNS serveriga yo'naltiriladi.`,
  dns_is: [
    {
      title_ru: 'Что такое DNS?',
      title_uz: 'DNS nima?',
      description_ru: [
        'DNS - это как справочник интернета, где записаны числовые адреса для всех веб-сайтов. Например, доменное имя Яндекса (yandex.ru) переводится в числовой IP-адрес 5.255.255.70.',
        `При вводе URL веб-сайта ваш браузер осуществляет поиск его адреса в DNS. Скорость загрузки сайта зависит от скорости работы DNS-сервера, находящегося ближе к вам: чем он быстрее функционирует, тем быстрее откроется сайт.`,
      ],
      description_uz: [
        `DNS Internet katalogiga o'xshaydi, u yerda barcha veb-saytlar uchun raqamli manzillar qayd etiladi. Masalan, Yandex domen nomi (yandex.ru) 5.255.255.70 raqamli IP manziliga tarjima qilinadi.`,
        `Veb-sayt URL manzilini kiritganingizda, brauzeringiz DNS-da veb-sayt manzilini qidiradi. Saytni yuklash tezligi sizga yaqinroq joylashgan DNS server tezligiga bog'liq: u qanchalik tez ishlasa, sayt shunchalik tez ochiladi.`,
      ],
    },
    {
      title_ru: 'Где работает?',
      title_uz: 'Qayerda ishlaydi?',
      description_ru: [
        `После подключения услуги, через Яндекс DNS будут проходить все устройства подключенные к вашей сети.`,
      ],
      description_uz: [`Xizmatni ulaganingizdan so'ng, tarmoqqa ulangan barcha qurilmalar Yandex DNS orqali o'tadi.`],
    },
  ],
}

const parentalControl = {
  id: 15,
  route_id: 'parental-control',
  route: '/extra-services/parental-control',
  title_ru: 'Родительский контроль',
  title_uz: 'Ota-ona nazorati',
  descr_ru: `Защитите своих детей и устройства от вирусов и нежелательного контента. Надежный родительский контроль - ваш ключ к безопасному интернету.`,
  descr_uz: `Farzandlaringizni va qurilmalaringizni viruslar va nomaqbul kontentdan himoya qiling. Kuchli ota-ona nazorati – internetdan bexavotir foydalanishingiz kalitidir.`,
  svgIcon: ParentalControl,
  more_ru: 'Подробнее',
  more_uz: `Batafsil ma'lumot`,
  full: {
    title_ru: 'Родительский контроль',
    title_uz: 'Ota-ona nazorati',
    descr_ru: `Мы направляем ваши запросы к DNS-серверу от Яндекса, что обеспечивает мгновенное открытие веб-сайтов и
    максимальную безопасность. Яндекс DNS блокирует доступ к “взрослым” и опасным ресурсам. Услуга Яндекс
    DNS абсолютно бесплатна.`,
    descr_uz: `Biz sizning so'rovlaringizni Yandex-dan DNS serveriga yo'naltiramiz, bu veb-saytlarning bir zumda ochilishini va maksimal xavfsizlikni ta'minlaydi. Yandex DNS "katta yoshdagilar" kontenti va xavfli resurslarga kirishni bloklaydi. Yandex DNS xizmati mutlaqo bepul.`,
    price: '0',
    service_fee: '0',
    currency_ru: 'Бесплатно',
    currency_uz: 'Bepul',
    yandex_dns_mode,
  },
}

export default {
  'static-ip': {
    id: 13,
    route_id: 'static-ip',
    route: '/extra-services/static-ip',
    title_ru: 'Статический IP-адрес',
    title_uz: 'Statik IP-manzil',
    descr_ru:
      'Гарантированная безопасность и удобство управления вашим сервером с нашей услугой статического IP-адреса. Создайте надежную инфраструктуру с нами!',
    descr_uz: `Statik IP-manzillar xizmatimiz bilan kafolatlangan xavfsizlik va server boshqaruvida aqlbovar qilmas qulaylikka ega bo’ling. Biz bilan ishonchli infratuzilmangizni barpo eting!`,
    svgIcon: StaticIpOne,
    more_ru: 'Подробнее',
    more_uz: `Batafsil ma'lumot`,
    full: {
      title_ru: 'Статический публичный IP-адрес:',
      title_uz: 'Statik umumiy IP-manzil:',
      descr_ru:
        'Статический IP-адрес – это фиксированный уникальный адрес в сети Интернет. Статический IP-адрес отличается от динамического тем, что не меняется каждый раз, когда вы устанавливаете подключение.',
      descr_uz: `Statik IP-manzil Internetdagi o'zgarmas, yagona manzildir. Statik IP-manzil dinamik IP-manzildan farq qiladi, chunki u har gal ulangan vaqtda o'zgarmaydi.`,
      articles: [
        {
          title_ru: 'Что такое публичный IP?',
          title_uz: 'Umumiy IP nima?',
          descr_ru:
            'Публичный IP-адрес нигде не преобразуется и конечный узел получает обращения именно с него. Доступ к компьютеру с таким адресом можно получить из любой точки сети Интернет. Но публичный IP не всегда «ваш», он может меняться от подключения к подключению, такой IP адрес называется динамическим, если IP не изменяется и присвоен только Вам, то он называется статическим публичным IP-адресом (белым).',
          descr_uz: `Umumiy IP manzil hech qayerda o’zgarmaydi va oxirgi tugun aynan undan so'rovlarni oladi. Ushbu manzilga ega kompyuterga Internetning istalgan joyidan kirish mumkin. Ammo umumiy IP har doim ham "sizniki" emas, u ulanishdan ulanishga qarab o'zgarishi mumkin, bunday IP manzil dinamik deb ataladi, agar IP o'zgarmasa va faqat sizga tayinlangan bo'lsa, u statik umumiy IP manzil deb ataladi (oq).`,
        },
        {
          title_ru: 'Зачем нужен статический IP?',
          title_uz: 'Nima uchun statik IP kerak?',
          descr_ru: 'Для обмена данными с Web или FTP-серверами, для удаленного управления оборудованием.',
          descr_uz: `Veb yoki FTP serverlari bilan ma'lumotlarni almashish, uskunani masofadan boshqarish uchun.`,
        },
      ],
      warning_ru: `* При несвоевременной оплате услуги статический IP-адрес и Интернет будут приостановлены до момента пополнения счета. "Статический IP-адрес" аннулируется, если основной баланс абонента не пополняется в течение одного месяца после наступления абонентской платы.`,
      warning_uz: `* Xizmatlar uchun to'lov kechiktirilgan taqdirda, statik IP-manzil va Internet hisob to'ldirilguncha to'xtatib qo'yiladi. Agar abonentning asosiy balansi abonent to'lovi boshlanganidan keyin bir oy ichida to'ldirilmasa, "statik IP-manzil" bekor qilinadi.`,
      price: '50000',
      currency_ru: 'сум/мес',
      currency_uz: 'sum/oy',
      svgIcon: StaticIpTwo,
    },
  },
  'parental-control': parentalControl,
  freezing: {
    id: 2,
    route_id: 'freezing',
    route: '/extra-services/freezing',
    title_ru: 'Заморозка лицевого счета',
    title_uz: 'Shaxsiy hisob muzlatish',
    descr_ru: `Активируйте данную услугу, чтобы сохранить все накопленные скидки, а так же не переплачивать полную сумму за тарифный план в момент отъезда.`,
    descr_uz: `Barcha to'plangan chegirmalarni saqlab qolish, shuningdek, safarga chiqqan vaqtingizda tarif rejasi uchun to'liq summani ortiqcha to'lamaslik uchun ushbu xizmatni yoqing.`,
    svgIcon: FreezingOne,
    more_ru: 'Подробнее',
    more_uz: `Batafsil ma'lumot`,
    full: {
      title_ru: '',
      title_uz: '',
      descr_ru:
        'Данная услуга поможет вам заморозить средства на лицевом счете, сохранить все накопленные скидки, а так же не переплачивать полную сумму за тарифный план в момент отъезда',
      descr_uz: `
      Ushbu xizmat shaxsiy hisobingizdagi mablag'larni muzlatishga, barcha to'plangan chegirmalarni tejashga, shuningdek, jo'nash vaqtida tarif rejasi uchun to'liq miqdorni ortiqcha to'lamaslikka yordam beradi.`,
      articles: [
        {
          title_ru: 'Описание',
          title_uz: `Tavsif`,
          descr_ru:
            'Услуга поможет сохранить имеющееся интернет-соединение (подключение абонента к оборудованию Comnet) и не тратить время на переподключение к сети в момент длительного простоя.',
          descr_uz: `Xizmat mavjud Internet aloqasini (Comnet uskunasiga abonent ulanishi) saqlab turishga yordam beradi va uzoq vaqt ishlamay qolganda tarmoqqa qayta ulanish uchun vaqtingizni behuda sarflamasligingizni ta’minlaydi.`,
        },
        {
          title_ru: 'Стоимость и оплата',
          title_uz: `Xarajat va to'lov`,
          descr_ru:
            'Стоимость услуги составляет 15 000 сумов в месяц. Средства за подключенную услугу будут списываться с лицевого счета абонента',
          descr_uz: `Xizmat narxi oyiga 15 000 so‘m. Faollashtirilgan xizmat uchun mablag'lar abonentning shaxsiy hisobvarag'idan yechib olinadi`,
        },
        {
          title_ru: 'Срок действия',
          title_uz: 'Amal qilish muddati',
          descr_ru:
            'Услуга "Заморозка лицевого счета" работает по предоплате. Если на лицевом счету абонента недостаточно средств для оплаты этой услуги, то абонент автоматически будет переведен на услугу “Резерв порта”',
          descr_uz: `“Shaxsiy hisobni muzlatish” xizmati avans toʻlovi asosida ishlaydi. Agar ushbu xizmatni to'lash uchun abonentning shaxsiy hisobidagi mablag' yetarli bo'lmasa, abonent avtomatik ravishda "Port muzlatilishi" xizmatiga o'tadi.`,
        },
      ],
      warning_ru: '',
      warning_uz: '',
      price: '15000',
      currency_ru: 'сум/мес',
      currency_uz: 'sum/oy',
      svgIcon: FreezingTwo,
    },
  },
  'call-expert': {
    route_id: 'call-expert',
    route: 'https://comnet.uz/master',
    title_ru: 'Вызов мастера',
    title_uz: `Ustaga qo'ng'iroq qiling`,
    descr_ru: `Проблемы с подключением? Вы можете вызвать специалиста к себе домой. Мы сами найдем и исправим проблему.`,
    descr_uz: `Ulanishda muammo bormi? Uyingizga mutaxassisimizni chaqirishingiz mumkin. Muammoni o'zimiz topamiz va bartaraf qilamiz.`,
    svgIcon: CallExpert,
    more_ru: 'Подробнее',
    more_uz: `Batafsil ma'lumot`,
    full: {
      price: '0',
      currency_ru: 'см.в таблице',
      currency_uz: 'Jadvalga qarang',
    },
  },
  postponement: {
    id: 'postponement-1',
    route_id: 'postponement',
    route: '/extra-services/postponement',
    title_ru: 'Отсрочка платежа',
    title_uz: `To'lovni kechiktirish`,
    descr_ru: `Не успели оплатить вовремя? Не беда! Получите возможность использования услуги Интернет отсрочив дату платежа Сроком до 3-х дней`,
    descr_uz: `To’lovni o'z vaqtida amalga oshira olmadingizmi? Hammasi joyida! Sababi, sizda to'lov muddatini 3 kungacha kechiktirish orqali Internet xizmatidan foydalanish imkoniyati mavjud.`,
    svgIcon: PostponementOne,
    more_ru: 'Подробнее',
    more_uz: `Batafsil ma'lumot`,
    full: {
      title_ru: '',
      title_uz: ``,
      descr_ru: `Услуга “Отсрочка платежа” дает возможность использования услуги Интернет сроком на 3 дня в кредит. Подключение услуги возможно в личном кабинете.`,
      descr_uz: `“Kechiktirilgan to‘lov” xizmati Internet xizmatidan 3 kun muddatga kredit asosida foydalanish imkonini beradi. Xizmatni shaxsiy kabinetingizda faollashtirishingiz mumkin.`,
      articles: [
        {
          title_ru: '',
          title_uz: ``,
          descr_ru:
            'После активации услуги, баланс лицевого счета будет отображен в отрицательном значении согласно Вашему тарифному плану.',
          descr_uz: `Xizmatni faollashtirgandan so'ng, sizning shaxsiy hisobingiz balansi tarif rejangizga muvofiq salbiy qiymatda ko'rsatiladi.`,
        },
        {
          title_ru: 'Отсрочка платежа',
          title_uz: `To'lovni kechiktirish`,
          descr_ru: `Это не бесплатное предоставление услуг, а помощь тем, кто не может своевременно произвести оплату. Необходимо, при первой возможности погасить образовавшуюся задолженность в полном размере, за весь период использования услуг.
          `,
          descr_uz: `Bu xizmatlarni bepul ko'rsatish emas, balki o'z vaqtida to'lovlarni amalga oshira olmaydiganlar uchun o’ziga xos yordamdir. Birinchi imkoniyat paydo bo'lganda, xizmatlardan foydalanishning butun davri uchun yuzaga kelgan qarzni to'liq to'lab qo’yish kerak.`,
        },
        {
          title_ru: '',
          title_uz: ``,
          descr_ru: `Напоминаем, в случае смены статуса на "Слишком маленький депозит" при несвоевременной оплате услуг, система автоматически обнулит скидку по бонусной программе “Будь в плюсе” и отсчет пребывания в статусе «Активно» с целью накопления скидки начнется заново.`,
          descr_uz: `Sizga shuni eslatib o'tamizki, agar xizmatlar uchun to'lov kechiktirilganligi sababli "Depozit juda ham kichik" holatiga o'zgartirilsa, tizim avtomatik ravishda "Plyusda bo'ling" bonus dasturi bo'yicha chegirmani nolga tushuradi va "Faol" statusini ortga qaytara boshlaydi  hamda chegirmalarni to'plash jarayoni qaytadan boshlanadi.`,
        },
      ],
      warning_ru: '',
      warning_uz: '',
      price: '0',
      credit: {
        amount: '195000',
        currency_ru: 'сум/мес',
        currency_uz: 'sum/oy',
      },
      currency_ru: 'Бесплатно',
      currency_uz: 'Bepul',
      svgIcon: PostponementTwo,
    },
  },
  'rent-router': {
    id: 14,
    route_id: 'rent-router',
    route: '/extra-services/rent-router',
    title_ru: 'Wi-Fi роутер в аренду',
    title_uz: 'Wi-Fi routerni ijaraga oling',
    descr_ru:
      'Нет возможности приобрести Wi-Fi роутер?! Арендуйте мощный, 2х диапазонный, гигабитный роутер!',
    descr_uz: `Wi-Fi router sotib olishga imkoniyatingiz yo’qmi? Kuchli, 2 diapazonli, gigabitli routerni ijaraga oling!`,
    svgIcon: RentRouterOne,
    more_ru: 'Подробнее',
    more_uz: `Batafsil ma'lumot`,
    full: {
      before_title_text_ru: `Нет возможности приобрести Wi-Fi роутер?! Арендуйте всего за 24 990 сум в месяц!
      Высокая скорость и стабильность Wi-Fi роутера Mercusys MR30G, имеющего 4-х антенны и два диапазона (2.4 ГГц и 5 ГГц) позволит наслаждаться интернетом без ограничений!`,
      before_title_text_uz: `Wi-Fi router sotib olishga imkoniyatingiz yo’qmi? Oyiga atigi 24 990 so'm to’lab ijaraga oling! 4 ta antenna va ikkita diapazonga (2,4 GGs va 5 GGs) ega Mercusys MR30G Wi-Fi routerining yuqori tezligi va barqarorligi internetdan cheklovsiz bahramand bo‘lish imkonini beradi!`,
      title_ru: 'Характеристики:',
      title_uz: 'Xususiyatlari:',
      descr_ru:
        'Статический IP-адрес – это фиксированный уникальный адрес в сети Интернет. Статический IP-адрес отличается от динамического тем, что не меняется каждый раз, когда вы устанавливаете подключение.',
      descr_uz: '',
      rent_terms: {
        title_ru: 'Условия аренды Wi-Fi роутера',
        title_uz: `Wi-Fi routerni ijaraga olish shartlari`,
        terms_ru: [
          `Оборудование передается на период потребления (время использования) Абонентом услуг связи OOO “IPLUS” TM Comnet (далее Исполнитель).`,
          `Оборудование:
          подлежит использованию только для получения услуг связи OOO “IPLUS”;
          должно быть безусловно возвращено Исполнителю, с учетом его естественного износа за период эксплуатации, при прекращении (расторжении) договора на оказание услуг связи для физических лиц;
          в период пользования оборудование является собственностью Исполнителя, состоит на его балансе и не может быть отчуждено (передано) Абонентом третьим лицам.`,
          `Передаваемое оборудование находится в исправном состоянии и в надлежащем комплекте.`,
          `Абонент несет полную имущественную ответственность за обеспечение сохранности оборудования. Нанесенный оборудованию вред (ущерб) возмещается Абонентом в бесспорном порядке. 5. Не распространяется на партнерскую сеть.`,
        ],
        terms_uz: [
          `Uskuna "IPLUS" TM Comnet MChJ aloqa xizmatlari abonenti (keyingi o'rinlarda Bajaruvchi deb yuritiladi) tomonidan xizmatlardan foydalanish davriga topshiriladi.`,
          `Uskunalar: faqat "IPLUS" MChJ aloqa xizmatlaridan foydalanish uchun ishlatiladi; jismoniy shaxslar uchun aloqa xizmatlarini ko'rsatish shartnomasi bekor qilinganda (tugatilganda) foydalanish davrida uning tabiiy eskirishini hisobga olgan holda Bajaruvchiga so'zsiz qaytarilishi kerak; foydalanish davrida qurilma Bajaruvchining mulki hisoblanadi, uning balansida bo'ladi va Abonent tomonidan uchinchi shaxslarga foydalanishga topshirilishi (berilishi) mumkin emas.`,
          `Foydalanishga topshiriladigan uskuna yaxshi holatda va to’liq komplekt ko’rinishida.`,
          `Abonent uskunaning xavfsizligini ta'minlash uchun to'liq moliyaviy javobgarlikni o'z zimmasiga oladi. Uskunaga yetkazilgan har qanday zarar Abonent tomonidan shubhasiz tarzda qoplanadi.`,
        ],
      },
      articles: [
        {
          title_ru: 'Параметры Wi-Fi',
          title_uz: 'Wi-Fi sozlamalari',
          items_ru: [
            'Стандарт Wi-Fi: 5 (802.11ac), 4 (802.11n)',
            'Максимальная скорость по частоте 2.4 ГГц: 300 Мбит/с',
            'Максимальная скорость по частоте 5 ГГц: 867 Мбит/с',
            'Мощность передатчика: 20 dBm',
          ],
          items_uz: [
            `Wi-Fi standarti: 5 (802.11ac), 4 (802.11n)`,
            `2,4 gigagertsli chastotada maksimal tezlik: 300 Mbit / s`,
            `5 gigagertsli chastotada maksimal tezlik: 867 Mbit / s`,
            `Transmitter quvvati: 20 dBm`,
          ],
        },
        {
          title_ru: 'Порты',
          title_uz: 'Portlar',
          items_ru: [
            'Тип подключения: Ethernet',
            'Количество LAN портов: 2',
            'Скорость передачи по проводному подключению: 1000 Мбит/с',
          ],
          items_uz: [`Ulanish turi: Ethernet`, `LAN portlari soni: 2`, `Simli uzatish tezligi: 1000 Mbit / s`],
        },
        {
          title_ru: 'Габариты, Вес',
          title_uz: `O'lchamlari, vazni`,
          items_ru: ['Ширина: 159.7 мм', 'Длина/Глубина: 125.9 мм', 'Высота: 37.1 мм'],
          items_uz: [`Kengligi: 159,7 mm`, `Uzunlik/chuqurlik: 125,9 mm`, `Balandligi: 37,1 mm`],
        },
      ],
      price: '24990',
      currency_ru: 'сум/мес',
      currency_uz: 'sum/oy',
      notice_ru: `После отправки формы, менеджер абонентского отдела свяжется с вами для уточнения деталей.`,
      notice_uz: `Blankani topshirganingizdan so'ng, mijozlarga xizmat ko'rsatish bo’limi menejeri tafsilotlarni aniqlashtirish uchun siz bilan bog'lanadi.`,
      svgIcon: RentRouterTwo,
    },
  },
  'give-back-router': {
    route_id: 'give-back-router',
    route: '/extra-services/give-back-router',
    title_ru: 'Сдать оборудование',
    title_uz: 'Uskunani qaytarish',
    descr_ru:
      'Сдайте арендованное оборудование в пару кликов. Наш курьер сам приедет к вам в удобное для вас время и заберет оборудование.',
    descr_uz: `Ijaraga olgan uskunangizni bir necha tugma orqali topshiring. Bizning kuryerimiz sizga qulay vaqtda oldingizga tashrif buyuradi va jihozlarni olib ketadi.`,
    svgIcon: GiveBackRouterOne,
    more_ru: 'Подробнее',
    more_uz: `Batafsil ma'lumot`,
    full: {
      title_ru: 'Сдать оборудование',
      title_uz: 'Uskunani qaytarish',
      articles: [
        {
          descr_ru:
            'Уважаемый абонент. Напоминаем что оборудование выделяемое в аренду, является собственностью компании OOO “IPLUS” TM Comnet.',
          descr_uz: `Hurmatli abonent. Eslatib oʻtamiz, ijara uchun ajratilgan uskuna “IPLUS” TM Comnet MChJ mulki hisoblanadi.`,
        },
        {
          descr_ru:
            'Ниже необходимо указать контактные данные, по котором сотрудник компании свяжется с вами для уточнения деталей.',
          descr_uz: `Siz bilan kompaniya xodimi aloqaga chiqishi uchun hamda tafsilotlarni aniqlashtirib olishi uchun quyida kontaktlaringizni qoldiring.
          Iltimos, aloqa uchun telefon raqamingizni kiriting.`,
        },
      ],
      price: '0',
      currency_ru: 'Бесплатно',
      currency_uz: 'Bepul',
      svgIcon: GiveBackRouterTwo,
    },
  },
  'buy-device': {
    route_id: 'buy-device',
    route: '/extra-services/buy-device',
    title_ru: 'Выкупить оборудование',
    title_uz: 'Uskunani sotib oling',
    descr_ru:
      'Выкупите арендованное оборудование в пару кликов. Мы готовы предоставить вам возможность стать полноправным владельцем GPON терминала или WI-FI роутера.',
    descr_uz: `Ijaraga olingan uskunani bir necha tugmani bosish orqali sotib oling. Biz sizga GPON terminali yoki WI-FI routerining to'liq egasi bo'lish imkoniyatini taqdim etishga tayyormiz.`,
    svgIcon: BuyDeviceOne,
    more_ru: 'Подробнее',
    more_uz: `Batafsil ma'lumot`,
    full: {
      title_ru: '',
      title_uz: '',
      articles: [
        {
          descr_ru:
            'Если вы решили выкупить оборудование у нас, мы готовы предоставить вам возможность стать полноправным владельцем GPON терминала или WI-FI роутера.',
          descr_uz: `Agar siz bizdan uskuna sotib olishga qaror qilsangiz, biz sizga GPON terminali yoki WI-FI routerining to'liq egasi bo'lish imkoniyatini taqdim etishga tayyormiz.`,
        },
        {
          descr_ru:
            'Ниже необходимо указать контактные данные, по котором сотрудник компании свяжется с вами для уточнения деталей.',
          descr_uz: `Siz bilan kompaniya xodimi aloqaga chiqishi uchun hamda tafsilotlarni aniqlashtirib olishi uchun quyida kontaktlaringizni qoldiring.`,
        },
      ],
      price: '0',
      currency_ru: 'Бесплатно',
      currency_uz: 'Bepul',
      svgIcon: BuyDeviceTwo,
    },
  },
}
