import React, { useEffect, useState } from 'react'
import i18next from 'i18next'
import ConfirmationModal from '../ConfirmationModal'
import cl from './BuyDevice.module.css'
import extraServices from '../../../staticData/extraServices'
import { PatternFormat } from 'react-number-format'
import { getAndBackDevices, getUserAllInfo } from '../../../services/http/userAPI'
import useActions from '../../../hooks/useActions'
import { translatedText } from '../../../services/translatedText'
import Loader from '../../../components/Loader'

const BuyDevice = () => {
  const [actions, { user }] = useActions()

  const lang = i18next.language
  const [modalIsOpen, setModal] = useState(false)
  const [loadingRequest, setLoadingRequest] = useState(false)
  const service = extraServices['buy-device']

  const [phoneNum, setPhoneNum] = useState({})
  const [disabledSubmit, setDisabledSubmit] = useState(true)
  const [GPON, setGPON] = useState(true)
  const [WiFi, setWiFi] = useState(false)

  useEffect(() => {
    if (phoneNum.value?.length === 9 && (GPON || WiFi)) {
      setDisabledSubmit(false)
    } else {
      setDisabledSubmit(true)
    }
  }, [phoneNum, GPON, WiFi])

  const modalHandler = async () => {
    setModal((prev) => !prev)

    const devices = {
      GPON: 'Купить GPON',
      WiFi: 'Купить WiFi',
      Both: 'Купить GPON и WiFi',
    }

    if (!modalIsOpen) {
      setLoadingRequest(true)

      const userInfo = await getUserAllInfo()

      const params = {
        title: WiFi && GPON ? devices.Both : GPON ? devices.GPON : devices.WiFi,
        name: userInfo.fio,
        phone: phoneNum.formattedValue,
        login: user.login,
      }

      await getAndBackDevices(params)
      setLoadingRequest(false)
    }

    setPhoneNum({})
    setGPON(false)
    setWiFi(false)
  }

  return (
    <div
      className="container"
      style={{ marginBottom: '35px', marginTop: '30px', display: 'flex', flexDirection: 'column' }}
    >
      <ConfirmationModal
        loadingRequest={loadingRequest}
        service={service}
        isOpen={modalIsOpen}
        onClose={modalHandler}
        textMode={true}
      />

      <h1 style={{ textAlign: 'left' }}>{lang === 'ru' ? service.title_ru : service.title_uz}</h1>

      <div className={cl.service_wrap}>
        <div className={`${cl.sidebar} ${cl.left_side}`}>
          <div className={cl.left_content}>
            <div className={cl.svg_wrap}>
              <img src={service.full.svgIcon} alt="" />
            </div>
          </div>
        </div>

        <div className={`${cl.sidebar} ${cl.right_side}`}>
          <div className={cl.right_content}>
            <div>
              {service.full.articles.map((article, index) => (
                <div key={index} className={cl.about}>
                  <h3 className={cl.descr}>{lang === 'ru' ? article.descr_ru : article.descr_uz}</h3>
                </div>
              ))}
            </div>

            <div className={cl.form_wrap}>
              <p className={cl.info_text}>{translatedText('ContactPhoneNumber')}</p>
              <PatternFormat
                onValueChange={(value) => setPhoneNum(value)}
                format="+998 (##) ###-##-##"
                allowEmptyFormatting
                mask="_"
                className={phoneNum.value?.length ? `${cl.font_color} ${cl.number_field}` : cl.number_field}
              />

              <div className={cl.equipment_wrap}>
                <label className={cl.radio_container}>
                  {translatedText('GPON')}
                  <input type="radio" checked={GPON} name="GPON" onClick={() => setGPON((prev) => !prev)} />
                  <span className={cl.radio_checkmark}></span>
                </label>

                <label className={cl.radio_container}>
                  {translatedText('WIFI')}
                  <input type="radio" checked={WiFi} name="WI-FI" onClick={() => setWiFi((prev) => !prev)} />
                  <span className={cl.radio_checkmark}></span>
                </label>
              </div>

              <button
                className={cl.connect_btn}
                style={{ opacity: disabledSubmit ? 0.5 : 1, cursor: disabledSubmit ? 'not-allowed' : 'pointer' }}
                onClick={modalHandler}
                disabled={disabledSubmit}
              >
                {translatedText('Send')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BuyDevice
