import React, { useEffect, useState } from 'react'
import i18next from 'i18next'
import ConfirmationModal from '../ConfirmationModal'
import cl from './RentRouter.module.css'
import extraServices from '../../../staticData/extraServices'
import { PatternFormat } from 'react-number-format'
import { getAndBackDevices, getUserAllInfo, getUserExtraService } from '../../../services/http/userAPI'
import useActions from '../../../hooks/useActions'
import { translatedText } from '../../../services/translatedText'

const RentRouter = () => {
  const [actions, { user }] = useActions()

  const lang = i18next.language
  const [modalIsOpen, setModal] = useState(false)
  const service = extraServices['rent-router']
  const [loadingRequest, setLoadingRequest] = useState(false)

  const [isServiceActive, setIsServiceActive] = useState(false)
  const [phoneNum, setPhoneNum] = useState({})
  const [disabledSubmit, setDisabledSubmit] = useState(true)

  const getServiceAbout = async () => {
    const { activeService } = await getUserExtraService(service.id)
    setIsServiceActive(activeService ? true : false)
  }

  useEffect(() => {
    getServiceAbout()
    if (phoneNum.value?.length === 9) {
      setDisabledSubmit(false)
    } else {
      setDisabledSubmit(true)
    }
  }, [phoneNum])

  const modalHandler = async () => {
    setModal((prev) => !prev)

    if (!modalIsOpen) {
      setLoadingRequest(true)

      const userInfo = await getUserAllInfo()

      const params = {
        title: lang === 'ru' ? 'Аренда роутера' : 'Router ijarasi',
        name: userInfo.fio,
        phone: phoneNum.formattedValue,
        login: user.login,
      }

      await getAndBackDevices(params)
      setLoadingRequest(false)
    }
  }

  return (
    <div
      className="container"
      style={{ marginBottom: '35px', marginTop: '30px', display: 'flex', flexDirection: 'column' }}
    >
      <ConfirmationModal
        loadingRequest={loadingRequest}
        service={service}
        isOpen={modalIsOpen}
        onClose={modalHandler}
        textMode={true}
      />

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '15px',
          position: 'relative',
          flexWrap: 'wrap',
        }}
      >
        <h1 style={{ textAlign: 'left' }}>{lang === 'ru' ? service.title_ru : service.title_uz} </h1>
        {isServiceActive ? (
          <span
            style={{
              fontSize: '16px',
              background: '#cbffb2',
              borderRadius: '30px',
              padding: '8px 20px',
              color: '#43b70d',
              fontWeight: '500',
            }}
          >
            {translatedText('Connected')}
          </span>
        ) : null}
      </div>

      <div className={cl.service_wrap}>
        <div className={`${cl.sidebar} ${cl.left_side}`}>
          <div className={cl.left_content}>
            <div className={cl.svg_wrap}>
              <img src={service.full.svgIcon} alt="" />
            </div>
            <p className={cl.info_text}>{translatedText('ContactPhoneNumber')}</p>
            <PatternFormat
              onValueChange={(value) => setPhoneNum(value)}
              format="+998 (##) ###-##-##"
              allowEmptyFormatting
              mask="_"
              className={phoneNum.value?.length ? `${cl.font_color} ${cl.number_field}` : cl.number_field}
            />
          </div>

          <button
            className={cl.connect_btn}
            onClick={modalHandler}
            style={{
              opacity: phoneNum.value?.length ? 1 : 0.5,
              cursor: phoneNum.value?.length ? 'pointer' : 'not-allowed',
            }}
            disabled={disabledSubmit}
          >
            {translatedText('OrderRouter')}
          </button>
          <p className={cl.notice}>{lang === 'ru' ? service.full.notice_ru : service.full.notice_uz}</p>
        </div>

        <div className={`${cl.sidebar} ${cl.right_side}`}>
          <div className={cl.right_content}>
            <div>
              <div className={cl.descr}>
                {lang === 'ru' ? service.full.before_title_text_ru : service.full.before_title_text_uz}
              </div>
              <div className={cl.main_title}>{lang === 'ru' ? service.full.title_ru : service.full.title_uz}</div>
              <div>
                {service.full.articles.map((article, index) => (
                  <div key={index} className={cl.about}>
                    <h3 className={cl.title}>{lang === 'ru' ? article.title_ru : article.title_uz}</h3>
                    <div>
                      {article[lang === 'ru' ? 'items_ru' : 'items_uz'].map((row, index) => (
                        <p key={index} className={cl.descr}>
                          {row}
                        </p>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="">
        <h2 className={cl.title}>
          {lang === 'ru' ? service.full.rent_terms.title_ru : service.full.rent_terms.title_uz}
        </h2>

        <ol className={cl.term_list}>
          {lang === 'ru'
            ? service.full.rent_terms.terms_ru.map((term, index) => (
                <li key={index} className={cl.descr}>
                  {term}
                </li>
              ))
            : service.full.rent_terms.terms_uz.map((term, index) => (
                <li key={index} className={cl.descr}>
                  {term}
                </li>
              ))}
        </ol>

        <a className={cl.cover_zone} href="https://comnet.uz/home-users/cover-zone" rel="noreferrer" target="_blank">
          {translatedText('ComnetZone')}
        </a>
      </div>
    </div>
  )
}

export default RentRouter
