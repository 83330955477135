import React from 'react'
// import MainLayout from '../../components/MainLayout'

// import classes from './CallExpert.module.css'
// import MasterCall from '../../../components/MasterCall'
// import PriceTable from '../../../components/PriceTable'
// import {Datas} from "../../context"

const CallExpert = () => {
  // const {lang}=React.useContext(Datas)

  return (
    // <MainLayout
    //   title={lang==="ru"?"Проблемы с подключением? Вызовите мастера!":"Mutaxassis chaqirish"}
    //   description="Выезд специалиста на дом в Ташкенте, прокладка и монтаж кабеля, замена коннектора, сверление отверстий, настройка сети Wi-Fi роутера и ТВ-приставки"
    // >
    // <div className={classes.Master}>
    //   <MasterCall />
    //   <PriceTable />
    // </div>
    // </MainLayout>
    <div></div>
  )
}

export default CallExpert
