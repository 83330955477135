import React, { useState, useEffect } from "react";
import "../styles/header.scss";
import { useTranslation } from "react-i18next";
import { translatedText } from "../services/translatedText";
import { getUserAllInfo } from "../services/http/userAPI";
import useActions from "../hooks/useActions";

//? SVG
import logo from "../assets/images/svg/logo.svg";
import phone from "../assets/images/svg/phone.svg";
import massage from "../assets/images/svg/massage.svg";
import DownSvg from "../assets/images/svg/Down.svg";
import Profile from "../assets/images/svg/profileNew.svg";
import logout from "../assets/images/svg/logoutNew.svg";
import { useCheckPhone } from "../hooks/useCheckPhone";
import { useAuth } from "../services/authentication";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

export default function Header({ isLogin }) {
	const [pi, setPi] = useState({ fio: "" });
	const [actions, { pi: storePi }] = useActions();

	const langs = ["uz", "ru"];
	useEffect(() => {
		if (storePi?.fio) {
			setPi(storePi);
			return;
		}
		async function fetchData() {
			if (!auth.sid) return;
			const response = await getUserAllInfo();
			setPi(response);
			actions.setPi(response);
		}
		fetchData();
	}, [storePi]);
	let name = pi.fio;

	const { i18n, t } = useTranslation();
	const [currentLang, setLang] = useState(localStorage.getItem("lang") || "ru");
	const [islangOpen, setIsLangOpen] = useState(false);
	const auth = useAuth();
	const navigate = useNavigate();
	const clickLogout = async () => {
		await auth.signout(() => {
			navigate("/login");
		});
	};
	
	const button = document.querySelector(".b24-widget-button-icon-container");

	// const button = document.querySelector(".b24-widget-button-openline_livechat");
	const openChat = () => {
		if (button) {
			button.click();
		}
	};
	const handleLang = (language) => {
		setLang(language);
		i18n.changeLanguage(language);
		localStorage.setItem("lang", language);
	};

	const toggleLang = () => {
		setIsLangOpen((prevIsLangOpen) => !prevIsLangOpen);
	};
	const phoneNumber = {
		link: "tel:+998712058888",
		text: "71 205-88-88",
	};

	const Lang = () => (
		<div className={`lang ${islangOpen ? "open" : ""}`} onClick={() => toggleLang()}>
			<div className="lang__wrapper">
				<div className="lang__head">
					{t(`lang.${currentLang}`)}
					<div className="g">
						<img src={DownSvg} alt="" />
					</div>
				</div>
				<div className="lang__content">
					{langs.map((lang, index) => {
						return (
							<button
								key={index}
								className="lang__content_item"
								onClick={() => handleLang(lang)}
							>
								{t(`lang.${lang}`)}
							</button>
						);
					})}
					{/* <button
            className="lang__content_item"
            onClick={() => handleLang(currentLang === "uz" ? "ru" : "uz")}
          >
            {currentLang === "uz" ? "UZ" : "РУ"}
          </button>
          <button
            className="lang__content_item"
            onClick={() => handleLang(currentLang === "uz" ? "ru" : "uz")}
          >
            {currentLang === "uz" ? "РУ" : "UZ"}
          </button> */}
				</div>
			</div>
		</div>
	);
	const LeftSide = () => (
		<div className="leftSide">
			<div className="leftSide__logo">
				<img
					onClick={() => {
						navigate("/");
					}}
					id="logo"
					src={logo}
					alt="logo"
				/>
			</div>
			<div className="leftSide__number number">
				<a href={phoneNumber.link}>{phoneNumber.text}</a>
			</div>
			<Lang />
		</div>
	);
	const RightSide = () => (
		<div className="rightSide">
			<a href={phoneNumber.link} className="rightSide__phone">
				<div className="rightSide__phone_icon icon">
					<img src={phone} alt="" />
				</div>
				<div className="rightSide__phone_wrapper">
					<div className="rightSide__phone_number number">{phoneNumber.text}</div>
					<div className="rightSide__phone_text">
						{translatedText("Subscriber department")}
					</div>
				</div>
			</a>
			{!isLogin ? (
				<div className="massage" onClick={openChat}>
					<div className="icon">
						<img src={massage} alt="" />
					</div>
				</div>
			) : null}
			{!isLogin ? (
				<div className="profile" onClick={clickLogout}>
					<div className="profile__icon icon">
						<img src={logout} className="logout" alt="" />
					</div>
				</div>
			) : null}
			{isLogin?<>
				<a href="https://cabinet2.comnet.uz" rel="noreferrer" target="_blank" className="oldCabinet">
					<p dangerouslySetInnerHTML={{__html:translatedText("OldCabinet")}} className="oldCabinetText">
					</p>
				</a>
			</>:<></>}
		</div>
	);
	return (
		<header className="headerMain">
			<div className="container">
				<div className="headerMain__wrapper">
					<LeftSide />
					<RightSide />
				</div>
			</div>
		</header>
	);
}
