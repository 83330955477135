import React, { useCallback, useMemo } from 'react'
import { translatedText } from '../services/translatedText'
import { convertToCurrency, parsePhone } from '../utils/parser'
import Loader from './Loader'
import managers from '../data/managers.json'
import MainBlock from './MainBlock'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useEffect } from 'react'
import { getUserCredit, getUserExtraService, getUserPhones } from '../services/http/userAPI'
import {
  addToMonth,
  daysBetweenDates,
  getCurrentDate,
  getCurrentMonthDate,
  intervalBetweenDate,
  toStringDate,
} from '../utils/date'
import FormModal from './FormModal'
import Input from './Input'
import { useRef } from 'react'
import {
  addUserContacts,
  checkVerification,
  restManager,
  sendCodeVerification,
  updateUserContacts,
} from '../services/http/contacts'
import useActions from '../hooks/useActions'
import { useNavigate } from 'react-router-dom'
import Profile from './Profile'

import editIcon from '../assets/images/svg/edit.svg'
import errorIcon from '../assets/images/svg/error.svg'
import alertIcon from '../assets/images/svg/AlertIconWhite.svg'
import phoneIcon from '../assets/images/svg/phone.svg'
import CircularChart from './CircularChart'
import { getStorageURL } from '../utils/url'
import i18next from 'i18next'

import { useAutoAnimate } from '@formkit/auto-animate/react'
import areEqual from '../utils/areEqual'
import CreditForm from './CreditForm'
import { useCheckPhone } from '../hooks/useCheckPhone'
import extraServices from '../staticData/extraServices'

function UserInfo({ user: _user, tariff, reportBug }) {
  const [actions, { contacts, pi, manager: globalManager, online: globalOnline }] = useActions()
  const user = _user || {}
  const [phones, setPhones] = useState()
  const [manager, setManager] = useState()
  const [open, setOpen] = useState(false)
  const [showCreditForm, setShowCreditForm] = useState(false)

  const isLegal = user?.companyId > 0
  const [animationParent] = useAutoAnimate()

  async function fetchData(reset = true) {
    try {
      let phone = !reset ? contacts : await getUserPhones()
      actions.setContacts(phone)
      setPhones(Array.isArray(phone) ? phone.filter((ph) => ph.name.includes('PHONE')) : [])
    } catch (e) {
      console.log(e)
    }
  }
  useEffect(() => {
    fetchData(false)
  }, [contacts])

  useEffect(() => {
    if (!isLegal || !pi?.pmanagerId) return

    async function fetchManager() {
      
      let temp_manager = globalManager?.name ? globalManager : await restManager(pi?.pmanagerId)
      temp_manager = temp_manager?.manager_id ? temp_manager : managers.find((m) => m.id == pi?.pmanagerId)
      if (temp_manager) {
        temp_manager.desc = temp_manager.desc ? temp_manager.desc : 'менеджер по работе с корпоративными клиентами'
        temp_manager.desc_uz = temp_manager.desc_uz ? temp_manager.desc_uz : 'korporativ mijozlar menejeri'
        if (!temp_manager.img) temp_manager.img = 'https://serv.comnet.uz/images/comnet.png'
        else if (!(temp_manager.img || '').includes('http')) temp_manager.img = getStorageURL(temp_manager.img)
      }

      setManager(!temp_manager ? 'not-found' : temp_manager)
      if (!temp_manager) return
      actions.setManager(temp_manager)
    }

    fetchManager()
  }, [pi, _user])

  const lang = i18next.language
  const history = useNavigate()

  function getStatus(status, internetStatus) {
    const res = +status
    if (internetStatus != 0) return 'Disabled'
    switch (res) {
      case 0:
        return 'Actived'
      case 1:
        return 'Disabled'
      case 2:
        return 'Not activated'
      case 3:
        return 'Suspended'
      case 5:
        return 'SMD'
    }
  }
  const isPhone = useCheckPhone()

  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  const getTranslatedService = (id) => {
    switch (id) {
      case 13:
        return lang === 'ru' ? 'Статический IP адрес' : 'Statik IP manzil'

      case 14:
        return lang === 'ru' ? 'Аренда Роутера' : 'Router ijarasi'

      case 15:
        return lang === 'ru' ? 'Родительский контроль' : 'Ota-ona nazorati'

      default:
        return '?'
    }
  }

  // if (!user?.login) return null;
  const reduction = user?.reduction || 0
  const balance = user?.deposit ? parseInt(user.deposit) : 0
  let tariffPrice = tariff?.personalTp || tariff?.monthFee
  tariffPrice = tariffPrice ? parseInt(tariffPrice) - parseInt(tariffPrice) * (reduction / 100) : 0
  const credit = user?.credit ? parseInt(user.credit) : 0

  const nextDate =
    tariff?.internetActivate === '0000-00-00' ? getCurrentMonthDate(true, 1) : addToMonth(tariff?.internetActivate, 1)
  //

  const [enoughAmountForNextMonth, setEnoughAmountForNextMonth] = useState({
    enough: true,
    includingBalance: 0,
    withoutBlance: 0,
  })

  const [allConnectedTarifsAndServices, setallConnectedTarifsAndServices] = useState([])
  const [showNeedAmountForNextMonthModal, setShowNeedAmountForNextMonthModal] = useState(false)

  const getConnectedServices = async () => {
    const res = await getUserExtraService()

    const filtered = res.reduce((acc, el) => {
      if (el.activeService) {
        el.name = getTranslatedService(el.id)
        acc.push(el)
      }
      return acc
    }, [])

    const totalCredits = [{ name: `${translatedText('TarifPlan')} ${tariff.tpName}`, price: tariffPrice }, ...filtered]

    if (credit) {
      totalCredits.push({ name: translatedText('PaymentDeferment'), price: credit })
    }

    const totalAmountNeed = totalCredits.reduce((acc, el) => {
      acc += el.price
      return acc
    }, 0)

    setEnoughAmountForNextMonth({
      enough: !`${+balance - +totalAmountNeed}`.includes('-'),
      includingBalance: +balance - +totalAmountNeed,
      withoutBlance: totalAmountNeed,
    })

    setallConnectedTarifsAndServices(totalCredits)
  }

  useEffect(() => {
    if (balance > 0) getConnectedServices()
  }, [balance, lang])

  const BalanceInfo = () => (
    <React.Fragment>
      <div className="full-row">
        <div className="col g-1">
          <h3>{translatedText('Current status')}</h3>
          <p
            className="mini-t"
            dangerouslySetInnerHTML={{
              __html: translatedText('FromDays', {
                from: nextDate,
                days: daysBetweenDates(nextDate),
              }),
            }}
          ></p>
        </div>
        <p className={'badge' + (getStatus(user.disable, tariff?.internetStatus) !== 'Actived' ? ' red' : ' green')}>
          {translatedText(user?.deleted == 1 ? 'Deleted' : getStatus(user.disable, tariff?.internetStatus))}
        </p>
      </div>
      <div className={'frame space ' + (globalOnline?.length ? 'succ' : 'red')}>
        {translatedText('Status internet', {
          text: globalOnline?.length ? translatedText('Online text') : translatedText('Offline text'),
        })}
      </div>

      {!isLegal && !isPhone && (
        <div className="col end">
          <span style={{ color: '#757575', fontSize: 20 }}>{translatedText('Balance')}:</span>
          <span className="balance-text">
            {convertToCurrency(balance)} {translatedText('sum')}
          </span>
        </div>
      )}
    </React.Fragment>
  )

  const openCreditForm = () => {
    setShowCreditForm(true)
  }

  const handleCloseCreditForm = () => {
    setShowCreditForm(false)
  }

  const CreditInfo = () => (
    <React.Fragment>
      <CreditForm open={showCreditForm} handleClose={handleCloseCreditForm} discount={reduction} balance={balance} />

      <div
        style={isLegal && !isPhone ? { marginBottom: 15, position: 'relative' } : { position: 'relative' }}
        className="col g-3"
      >
        {isLegal && !isPhone && (
          <div style={{ margin: '20px 0 0 0' }} className="between-cab end">
            <p>{translatedText('Balance')}:</p>
            <span className="balance-text">
              {convertToCurrency(balance)} {translatedText('sum')}
            </span>
          </div>
        )}

        <div className="between-cab">
          <p>{translatedText('Date of next write-off')}:</p>
          <p>{nextDate}</p>
        </div>

        <div className="between-cab">
          {translatedText('Next write-offs')}:
          <div className={isLegal ? '' : 'bold'}>
            <div className="show-missing-amount">
              <div className="missing-amount-for-next-month">
                <div className="missing-amount-for-next-month_item">
                  {allConnectedTarifsAndServices.map((el, i) => (
                    <div key={i}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <span>{el.name}:</span>

                        <span style={{ fontWeight: 'bold', textAlign: 'end' }}>
                          {convertToCurrency(el.price)} {translatedText('sum')}
                        </span>
                      </div>
                      {allConnectedTarifsAndServices.length ? (
                        <div
                          style={{
                            width: '100%',
                            height: '1px',
                            backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23CDCCCCFF' stroke-width='4' stroke-dasharray='6%2c 18' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
                          }}
                        />
                      ) : null}
                    </div>
                  ))}
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      opacity: '0.3',
                    }}
                  >
                    <span>{translatedText('Total')}</span>
                    <span style={{ fontWeight: 'bold', textAlign: 'end' }}>
                      {convertToCurrency(enoughAmountForNextMonth.withoutBlance)} {translatedText('sum')}
                    </span>
                  </div>
                </div>

                <div className="missing-amount-for-next-month_item">
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <span>{translatedText('Balance')}</span>
                    <span style={{ fontWeight: 'bold', color: 'rgb(67 183 14)' }}>
                      {convertToCurrency(balance)} {translatedText('sum')}
                    </span>
                  </div>

                  {!enoughAmountForNextMonth.enough ? (
                    <div
                      style={{
                        width: '100%',
                        height: '1px',
                        backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23CDCCCCFF' stroke-width='4' stroke-dasharray='6%2c 18' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
                      }}
                    />
                  ) : null}

                  {!enoughAmountForNextMonth.enough ? (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        fontWeight: 'bold',
                      }}
                    >
                      <span>{translatedText('TotalWithBalance')}</span>
                      <span style={{ color: 'rgba(243, 84, 89, 1)', fontSize: '20px' }}>
                        {`${convertToCurrency(enoughAmountForNextMonth.includingBalance)}`.replace(/-/, '')}{' '}
                        {translatedText('sum')}
                      </span>
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="show-next-month-amount">
                <div className="svg"></div>
                <svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="14.0044" cy="14.5005" r="13.7153" fill="#D9D9D9" fillOpacity="0.5" />
                  <path
                    d="M14.9039 18.7604C15.0505 18.7604 15.1772 18.8137 15.2839 18.9204C15.3905 19.0271 15.4439 19.1537 15.4439 19.3004V21.0604C15.4439 21.2071 15.3905 21.3337 15.2839 21.4404C15.1772 21.5471 15.0505 21.6004 14.9039 21.6004H13.1839C13.0372 21.6004 12.9105 21.5471 12.8039 21.4404C12.6972 21.3337 12.6439 21.2071 12.6439 21.0604V19.3004C12.6439 19.1537 12.6972 19.0271 12.8039 18.9204C12.9105 18.8137 13.0372 18.7604 13.1839 18.7604H14.9039ZM14.1039 7.40039C14.7305 7.40039 15.3372 7.48706 15.9239 7.66039C16.5239 7.83372 17.0505 8.09372 17.5039 8.44039C17.9705 8.77372 18.3439 9.19372 18.6239 9.70039C18.9039 10.1937 19.0439 10.7671 19.0439 11.4204C19.0439 11.9537 18.9572 12.4204 18.7839 12.8204C18.6239 13.2071 18.4105 13.5604 18.1439 13.8804C17.8905 14.1871 17.6105 14.4737 17.3039 14.7404C16.9972 14.9937 16.7039 15.2404 16.4239 15.4804C16.1572 15.7071 15.9172 15.9471 15.7039 16.2004C15.5039 16.4537 15.3839 16.7271 15.3439 17.0204C15.3172 17.1671 15.2572 17.2937 15.1639 17.4004C15.0705 17.5071 14.9505 17.5604 14.8039 17.5604H13.2839C13.1372 17.5604 13.0039 17.5071 12.8839 17.4004C12.7772 17.2937 12.7305 17.1671 12.7439 17.0204C12.7705 16.5004 12.8772 16.0471 13.0639 15.6604C13.2639 15.2604 13.4972 14.9071 13.7639 14.6004C14.0439 14.2804 14.3372 13.9937 14.6439 13.7404C14.9639 13.4871 15.2572 13.2471 15.5239 13.0204C15.7905 12.7804 16.0105 12.5471 16.1839 12.3204C16.3572 12.0804 16.4439 11.8204 16.4439 11.5404C16.4439 11.0337 16.2305 10.6204 15.8039 10.3004C15.3772 9.96706 14.8105 9.80039 14.1039 9.80039C12.9705 9.80039 12.1372 10.3671 11.6039 11.5004C11.5372 11.6471 11.4639 11.7604 11.3839 11.8404C11.3172 11.9071 11.1972 11.9404 11.0239 11.9404H9.40387C9.28387 11.9404 9.1772 11.9004 9.08386 11.8204C9.00386 11.7404 8.96387 11.6337 8.96387 11.5004C8.9772 11.0337 9.1172 10.5604 9.38387 10.0804C9.66387 9.58706 10.0305 9.14706 10.4839 8.76039C10.9505 8.36039 11.4905 8.03372 12.1039 7.78039C12.7305 7.52706 13.3972 7.40039 14.1039 7.40039Z"
                    fill="#CDCDCD"
                  />
                </svg>

                <span style={{ opacity: showNeedAmountForNextMonthModal ? 0.5 : 1 }}>
                  {enoughAmountForNextMonth.enough ? (
                    translatedText('No')
                  ) : (
                    <>
                      {convertToCurrency(enoughAmountForNextMonth.includingBalance<0?-enoughAmountForNextMonth.includingBalance:enoughAmountForNextMonth.includingBalance)} {translatedText('sum')}
                    </>
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>

        {isLegal ? (
          <div className="between-cab">
            <p>{translatedText('Discount')}:</p>
            <p className="red-text bold">{reduction}%</p>
          </div>
        ) : (
          <div className="between-cab">
            <p>{translatedText('Credit')}:</p>
            <p>{convertToCurrency(credit)}</p>
          </div>
        )}
      </div>

      {!isLegal && (
        <div style={{ marginTop: isPhone ? 0 : 30 }} className="row-m j-center g-2">
          <button onClick={openCreditForm} className="secondary-btn light full">
            {translatedText('Set Credit')}
          </button>
          <button onClick={() => history('/replenishment')} className="primary-btn light full">
            {translatedText('Top up')}
          </button>
        </div>
      )}
    </React.Fragment>
  )

  const ManagerInfo = () => (
    <div style={{ height: '100%' }}>
      <div
        style={{ height: '85%', gap: isPhone ? 0 : '3em' }}
        className={'row-m ' + (manager?.img ? 'between' : 'center')}
      >
        {manager?.img && (
          <div className="col center between g-2">
            <img height={isPhone ? 155 : 190} src={manager.img} />
            {/* <a onClick={() => history("/chat")} className="point">
            <img src={messageIcon} alt="chat-icon" />
            {translatedText("Write message")}
          </a> */}
          </div>
        )}
        <div className={'col g-2' + (isPhone ? ' center' : '')}>
          <h3 style={{ fontSize: isPhone ? 17 : 24 }}>{manager?.name}</h3>
          <p className="unfocus-text2 big">{lang === 'uz' ? manager?.desc_uz : manager?.desc}</p>
          <a href={'mailto:' + manager?.email} className="email-text">
            {manager?.email}
          </a>
        </div>
      </div>
      <p style={{ marginTop: isPhone ? 20 : 10 }} className="j-center g-3">
        {(manager?.phone || '').split(',').map((p) => (
          <a href={'tel:' + p} className="phone-text" key={p}>
            <img src={phoneIcon} alt="phone-icon" /> {parsePhone(p)}
          </a>
        ))}
      </p>
    </div>
  )
  return (
    <React.Fragment>
      <h1>{translatedText('Card abon')}</h1>
      <div ref={animationParent} className="row block">
        {(!phones || !tariff) && <Loader fill center blur tp />}

        <MainBlock
          hasProfile
          containerStyle={{ flex: 5 }}
          isMobile={isPhone}
          style={{ position: 'relative', display: 'grid', height: '100%' }}
        >
          <Profile cabinet legal={isLegal} />
          <div className="col cab g-3">
            <div className="between-cab">
              <p>{translatedText('Num contract')}:</p>
              <p>№ {pi.contractId}</p>
            </div>
            <div className="between-cab">
              <p>{translatedText('Your login')}:</p>
              <p>{user.login}</p>
            </div>
            <div className="between-cab">
              <p>{translatedText('Phone number')}:</p>
              <p className="row">
                <span className="col g-1">
                  {phones?.length ? (
                    phones.map((phone) => <span key={phone.id}>{parsePhone(phone.value)}</span>)
                  ) : (
                    <span>-</span>
                  )}
                </span>
                <img onClick={handleOpen} className="edit-btn" src={editIcon} alt="edit" />
              </p>
            </div>
            {isLegal && (
              <div className="between-cab">
                <p>{translatedText('INN')}:</p>
                <p>{user?.companyVat || '-'}</p>
              </div>
            )}
            <div className="between-cab">
              <p>{translatedText('City')}:</p>
              <p>{pi?.city}</p>
            </div>
            <div className="between-cab">
              <p>{translatedText('Street')}:</p>
              <p>{pi?.addressStreet || '-'}</p>
            </div>
            <div className="between-cab">
              <p>{translatedText('Home')}:</p>
              <p>{pi?.addressBuild || '-'}</p>
            </div>
            <div className="between-cab">
              <p>{translatedText('Appartment')}:</p>
              <p>{pi?.addressFlat || '-'}</p>
            </div>
          </div>
          <div className="col a-center j-center">
            <button onClick={reportBug} className="primary-btn light icon full">
              {translatedText('Activate promo')}
            </button>
          </div>
        </MainBlock>
        <div style={{ width: '100%', flex: 7 }} className="col block">
          <div style={isLegal ? { height: '60%' } : { height: '45%' }} ref={animationParent} className="row block">
            <MainBlock containerStyle={{ width: '100%' }} mini>
              <BalanceInfo />
              {isLegal && !isPhone && <CreditInfo />}
            </MainBlock>
            {isPhone && (
              <div className="row g-2">
                <MainBlock containerStyle={{ width: isLegal ? '100%' : '50%' }} mini>
                  <div className={'g-1 full-h between' + (!isLegal ? ' col' : ' col mob')}>
                    <span className="unfocus-t">{translatedText('Balance')}:</span>
                    <span className="balance-text">
                      {convertToCurrency(balance)} {translatedText('sum')}
                    </span>
                  </div>
                </MainBlock>
                {!isLegal && (
                  <MainBlock mini containerStyle={{ width: '50%' }}>
                    <div className="row between">
                      <div className="col between">
                        <span className="unfocus-t">{translatedText('Discount')}:</span>
                        <button onClick={() => history('/bonus')} className="icon-primary-btn">
                          {translatedText('More')}
                        </button>
                      </div>
                      {phones && tariff && (
                        <CircularChart
                          percentage={reduction}
                          width={70}
                          height={55}
                          fontSize={11}
                          max={15}
                          colors={['#f5161e', '#FFE3E4']}
                        />
                      )}
                    </div>
                  </MainBlock>
                )}
              </div>
            )}

            {!isLegal && !isPhone && (
              <MainBlock containerStyle={{ width: '50%' }} mini>
                <h3>{translatedText('Discount')}:</h3>
                <div className="col between" style={{ height: '100%' }}>
                  {phones && tariff && (
                    <CircularChart
                      percentage={reduction}
                      width={165}
                      height={160}
                      fontSize={40}
                      max={15}
                      colors={['#f5161e', '#FFE3E4']}
                    />
                  )}
                  <button onClick={() => history('/bonus')} className="icon-primary-btn">
                    {translatedText('More')}
                  </button>
                </div>
              </MainBlock>
            )}
          </div>
          {isLegal && isPhone && (
            <MainBlock containerStyle={{ width: '100%' }} mini>
              <CreditInfo />
            </MainBlock>
          )}
          <MainBlock
            containerStyle={isLegal ? { height: '40%' } : { height: '55%' }}
            style={{
              padding: isPhone ? '20px 25px 20px 25px' : '30px 35px',
              height: '100%',
              justifyContent: 'space-between',
            }}
          >
            {!isLegal ? (
              <CreditInfo />
            ) : manager === 'not-found' ? (
              <p className="a-center j-center" style={{ height: '100%', gap: '0.5em' }}>
                <img src={errorIcon} alt="error-icon" />
                {translatedText('No data')}
              </p>
            ) : (
              manager && <ManagerInfo />
            )}
          </MainBlock>
        </div>
      </div>
      <PhoneChangingModal
        resetData={fetchData}
        open={open}
        username={user?.login}
        handleClose={handleClose}
        phones={phones}
      />
    </React.Fragment>
  )
}

// UserInfo.propTypes = {
//   user: PropTypes.any,
//   tariff: PropTypes.any,
// }

export default React.memo(UserInfo, areEqual)

const PhoneChangingModal = React.memo(function Modal({ handleClose, phones, resetData, username, open }) {
  const [msg, setMsg] = useState({
    msg: '',
    error: false,
  })
  const [loader, setLoader] = useState(false)
  const [code, setCode] = useState()
  const currentCode = useRef()
  const timeoutExpired = useRef()
  const cellPhone = Array.isArray(phones) && phones.find((phone) => phone.name === 'CELL_PHONE')
  const firstPhone = Array.isArray(phones) && phones.find((phone) => phone.name.includes('PHONE') && phone.value)

  const [phone, setPhone] = useState(cellPhone)
  const [changePhone, setChangePhone] = useState()
  const [expired, setExpired] = useState(false)
  const sendSms = async (phone) => {
    const resSms = await sendCodeVerification(phone, username)

    if (resSms?.data == 'Вы были забанены') {
      const dt = new Date(resSms?.time)
      dt.setMinutes(dt.getMinutes() + 10)
      dt.setHours(dt.getHours() - 2)
      setMsg({
        msg: translatedText('You have ban', {
          date: toStringDate(dt, true),
        }),
        error: 1,
      })
      clearTimeout(timeoutExpired.current)
      setExpired(false)
      setCode('')
    } else if (resSms?.error_code || resSms?.status_description == 'Error in sms')
      setMsg({
        msg: translatedText('Unknown error'),
        error: resSms?.error_code ?? 1,
      })
    else {
      setMsg({
        msg: translatedText('Sent code1', { actualPhone: phone }),
        code: 1,
      })
      setExpired(false)
      timeoutExpired.current = setTimeout(() => setExpired(true), 5 * 60 * 1000)
      setCode('wait')
    }
  }
  const apply = async () => {
    let phoneCurrent = firstPhone ? firstPhone?.value : phone
    let codeState = code
    if (code === 'wait' && !expired && currentCode.current) {
      const data = await checkVerification(currentCode.current, username)
      setCode(data?.code)
      codeState = data?.code
      setLoader(true)
    }

    if (phoneCurrent) {
      phoneCurrent = phoneCurrent + ''
    }
    if (phoneCurrent.length === 9) {
      phoneCurrent = '998' + phoneCurrent
    }

    if (expired) {
      await sendSms(phoneCurrent)
      return
    } else if (codeState) {
      if (codeState == 'error') {
        setLoader(false)
        setCode('wait')
        setMsg({ msg: translatedText('Code error'), code: 1, error: 1 })
        setTimeout(() => {
          setMsg({
            msg: translatedText('Sent code1', { actualPhone: phoneCurrent }),
            code: 1,
          })
        }, 4000)
        return
      }
    } else if (phoneCurrent?.length !== 12) {
      setLoader(false)
      return
    } else {
      await sendSms(phoneCurrent)
      return
    }

    let res1
    if (phone === 9) {
      phoneCurrent = '998' + phone
    } else {
      phoneCurrent = phone + ''
    }
    if (firstPhone?.id) res1 = await updateUserContacts(phoneCurrent, firstPhone.id, 1)
    else res1 = await addUserContacts(phoneCurrent, 1)

    if (!res1 || res1?.affected !== undefined || res1?.result === 'OK') {
      setMsg({ msg: translatedText('Successfully completed') })
    } else
      setMsg({
        msg: translatedText('Unknown error'),
        error: res1?.errno ?? 1,
      })

    clearTimeout(timeoutExpired.current)
    await resetData()
    setLoader(false)
  }

  const closeModal = () => {
    setMsg({ msg: '', error: false })
    clearTimeout(timeoutExpired.current)
    setExpired(false)
    setCode()
    setChangePhone()
    setPhone()
    setMsg({ msg: '' })
    handleClose()
  }

  const MASK_PHONE = '+998 (##) ### ## ##'
  return (
    <FormModal open={open} onClose={closeModal}>
      <div
        className={(msg.msg && !msg?.code) || loader ? 'frame p-1' : 'col g-2'}
        style={{
          marginTop: 10,
          // maxHeight: "70vh",
          // minHeight: loader || msg.msg ? 0 : "50vh",
          // transition: "all 0.5s ease",
          // overflowY: "auto",
          // gap: 10,
        }}
      >
        {loader ? (
          <Loader center />
        ) : msg.msg && !msg?.code ? (
          <h2 style={{ color: msg?.error ? 'var(--primary-color)' : null }} className="succes-text">
            {msg.msg}
          </h2>
        ) : (
          <React.Fragment>
            <Input
              mask={MASK_PHONE}
              onChange={(value) => setPhone(value)}
              defaultValue={
                cellPhone?.value ? cellPhone.value.toString() : firstPhone?.value ? firstPhone.value.toString() : ''
              }
              readOnly={!!code}
              // placeholder={'+998 (12) 345 67 89'}
              label={translatedText('Phone number')}
            />
            {!expired && code && (
              <Input
                offSelection
                onChange={(value) => (currentCode.current = value)}
                label={translatedText('Code') + ':'}
                placeholder="12345"
              />
            )}
          </React.Fragment>
        )}
      </div>
      {!loader && code && msg?.code && (
        <span
          style={{
            marginLeft: 10,
            marginBottom: 20,
            lineHeight: 0,
            color: msg?.error || expired ? 'var(--primary-color)' : '#646464',
          }}
        >
          {expired ? translatedText('Time expired') : msg.msg}
        </span>
      )}
      <button
        disabled={
          phone?.length !== 12 ||
          String(phone).slice(3) == cellPhone?.value ||
          String(phone).slice(3) == firstPhone?.value
        }
        style={{ width: '100%' }}
        className="primary-btn"
        onClick={msg.msg && !msg?.code ? closeModal : apply}
      >
        {expired
          ? translatedText('Send again')
          : msg.msg && !msg?.code
          ? translatedText('Close')
          : translatedText('Apply')}
      </button>
    </FormModal>
  )
}, areEqual)
PhoneChangingModal.propTypes = {
  handleClose: PropTypes.func,
  resetData: PropTypes.func,
  open: PropTypes.bool,
  phones: PropTypes.array,
}
