const ru = {
  Message: 'Сообщение',
  Theme: 'Тема',
  Chapter: 'Раздел',
  Priority: 'Приоритет',
  Attachment: 'Прикрепление',
  'Select File': 'Выбрать файл',
  'File not selected': 'Файл не выбран',
  'message-text-1': 'Если у вас возникли вопросы напишите нам',
  Send: 'Отправить',
  'qr-message': 'Отсканируйте QR код, чтобы связаться с абонентским отделом',
  'History message': 'История сообщении',
  'dropdown-message-1.1': 'Выполнена и закрыта',
  'dropdown-message-1.2': 'Не выполнена и закрыта',
  'dropdown-message-1.3': 'Открыта',
  'dropdown-message-2.1': 'Очень низкий',
  'dropdown-message-2.2': 'Низкий',
  'dropdown-message-2.3': 'Нормальный',
  'dropdown-message-2.4': 'Высокий',
  'dropdown-message-2.5': 'Очень высокий',

  'dropdown-message-3.1': 'Все',
  'dropdown-message-3.2': 'Открыта',
  'dropdown-message-3.3': 'Не выполнена и закрыта',
  'dropdown-message-3.4': 'Выполнена и закрыта',
  'dropdown-message-3.5': 'В обработке',
  'dropdown-message-3.6': 'Новое сообщение',
  'dropdown-message-3.7': 'Приостановление доступа',
  'dropdown-message-3.8': 'Ждём ответ от пользователя',
  'dropdown-message-3.9': 'Все кроме выполненных',
  'dropdown-message-3.10': 'Следить',

  Total: 'Всего',
  Unblock: 'Открыта',
  'Last Activity': 'Последняя активность',
  Search: 'Поиск',
  Added: 'Добавлено',

  'suspension-description': `Услуга приостанавливает использование услуги Интернет на срок до 3 месяцев без необходимости оплачивать абонентскую плату за Интернет
  <br/><br/>
  Стоимость услуги составляет 400 сумов в день. Таким образом Вам не нужно переплачивать за небольшой простой в использовании услуги Интернет. То есть, с Вас не будет списываться разовая сумма в месяц, вы платите только за время своего простоя. 
  <br/><br/>
  Срок действия услуги «Резерв порта» устанавливается до ее полного погашения абонентом и внесении на счет суммы, достаточной для активации тарифного плана. По истечении 3-х месяцев действия услуги «Резерв порта», компания ООО «IPLUS» имеет право в одностороннем порядке расторгнуть договор с абонентом и демонтировать оборудование`,
}
const uz = {
  Message: 'Xabar',
  Theme: 'Mavzu',
  Chapter: "Bo'lim",
  Priority: 'Ustuvorlik',
  Attachment: 'biriktirma',
  'Select File': 'Faylni tanlash',
  'File not selected': 'Fayl tanlanmagan',
  'message-text-1': "Agar sizda biron bir savol bo'lsa, bizga yozing",
  Send: 'Yuborish',
  'qr-message': "Mijozlarga xizmat ko'rsatish bilan bog'lanish uchun QR kodini skanerlang",
  'History message': 'Xabarlar tarixi',
  'dropdown-message-1.1': 'Выполнена и закрыта',
  'dropdown-message-1.2': 'Не выполнена и закрыта',
  'dropdown-message-1.3': 'Открыта',
  'dropdown-message-2.1': 'Очень низкий',
  'dropdown-message-2.2': 'Низкий',
  'dropdown-message-2.3': 'Нормальный',
  'dropdown-message-2.4': 'Высокий',
  'dropdown-message-2.5': 'Очень высокий',

  'dropdown-message-3.1': 'Все',
  'dropdown-message-3.2': 'Открыта',
  'dropdown-message-3.3': 'Не выполнена и закрыта',
  'dropdown-message-3.4': 'Выполнена и закрыта',
  'dropdown-message-3.5': 'В обработке',
  'dropdown-message-3.6': 'Новое сообщение',
  'dropdown-message-3.7': 'Приостановление доступа',
  'dropdown-message-3.8': 'Ждём ответ от пользователя',
  'dropdown-message-3.9': 'Все кроме выполненных',
  'dropdown-message-3.10': 'Следить',

  Total: 'Jami',
  Unblock: 'Ochiq',
  'Last Activity': 'Oxirgi faoliyat',
  Search: 'Qidirish',
  Added: "Qo'shilgan",

  'suspension-description': `Xizmat Internet uchun abonent to‘lovini to‘lamasdan Internet xizmatidan foydalanishni 3 oygacha to‘xtatib qo‘yadi.
  <br/><br/>
  Xizmat narxi kuniga 400 so‘m. Shunday qilib, siz foydalanish uchun qulay bo'lgan kichik Internet xizmati uchun ortiqcha to'lashingiz shart emas. Ya'ni, sizdan oyiga bir martalik to'lov olinmaydi, faqat ishlamay qolgan vaqtingiz uchun to'laysiz.
  <br/><br/>
  “Port zaxirasi” xizmatining amal qilish muddati abonent tomonidan to‘liq to‘langunga qadar va tarif rejasini faollashtirish uchun yetarli bo‘lgan mablag‘ hisob raqamiga kiritilgunga qadar belgilanadi. “Port Reserve” xizmatidan 3 oy o‘tgach, “IPLUS” MChJ kompaniyasi abonent bilan shartnomani bir tomonlama bekor qilish va uskunani demontaj qilish huquqiga ega.`,
}

export default { ru, uz }
