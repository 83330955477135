import React from 'react'

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion'

import '../styles/InternetAccordion.css'
import '../styles/AccordionItems.scss'

const InternetAccordion = () => {
  const accardion = [
    {
      headTitle: 'ECONOM+, COMFORT+, PRO+, TURBO+',
      headSubtitle: 'Универсальные тарифы, который подойдут каждому',
      titles: [
        'Тарифный план',
        'Абонентская плата, сумов',
        'Скорость подключения, Мбит/с',
        'Скорость подключения c 00:00 до 12:00 Мбит/с',
        'Скорость в сети TAS-IX, Мбит/с',
      ],
      tariffs1: ['ECONOM+', '119 000', '30', '200', '200'],
      tariffs2: ['COMFORT+', '159 000', '60', '200', '200'],
      tariffs3: ['PRO+', '199 000', '100', '200', '200'],
      tariffs4: ['TURBO+', '299 000', '200', '200', '200'],
    },
    {
      headTitle: 'ECONOM, COMFORT, PRO, TURBO',
      headSubtitle: 'Универсальные тарифы, который подойдут каждому',
      titles: [
        'Тарифный план',
        'Абонентская плата, сумов',
        'Скорость подключения, Мбит/с',
        'Скорость подключения c 00:00 до 12:00 Мбит/с',
        'Скорость в сети TAS-IX, Мбит/с',
      ],
      tariffs1: ['ECONOM', '120 000', '25', '200', '200'],
      tariffs2: ['COMFORT', '160 000', '50', '200', '200'],
      tariffs3: ['PRO', '200 000', '75', '200', '200'],
      tariffs4: ['TURBO', '240 000', '100', '200', '200'],
    },
    {
      headTitle: 'Скоростной',
      headSubtitle: 'Хороший интернет на максимальной скорости',
      titles: [
        'Тарифный план',
        'Абонентская плата, сумов',
        'Включенный объем трафика, МБ*',
        'Скорость подключения с 9:00 по 00:00 , Мбит/с',
        'Скорость подключения с 00:00 по 09:00 , до Мбит/с',
        'Скорость в сети TAS-IX до, Мбит/с',
      ],
      tariffs1: ['Скоростной S', '60 000', '20 000', '25', '50', '100'],
      tariffs2: ['Скоростной M', '80 000', '30 000', '25', '50', '100'],
      tariffs3: ['Скоростной L', '100 000', '50 000', '25', '50', '100'],
      tariffs4: ['Скоростной XL', '140 000', '90 000', '25', '50', '100'],
    },
    {
      headTitle: 'Ultra Скоростной',
      headSubtitle: 'Универсальные тарифы, который подойдут каждому',
      titles: [
        'Тарифный план',
        'Абонентская плата, сумов',
        'Скорость подключения, Мбит/с',
        'Скорость в сети TAS-IX, Мбит/с FTTx (г. Ташкент)',
        'Скорость в сети TAS-IX, Мбит/с FTTx (г. Фергана)',
        'Скорость в сети TAS-IX, Мбит/с ADSL',
        'Включенный объем трафика, МБ*',
        'Стоимость 1MБ сверхлимита, сумов',
      ],
      tariffs1: [
        ['Ultra Скоростной'],
        ['40 000', '60 000', '80 000', '100 000', '140 000', '200 000'],
        ['до 15', 'до 15', 'до 15', 'до 20', 'до 20', 'до 20'],
        ['до 100', 'до 100', 'до 100', 'до 100', 'до 100', 'до 100'],
        ['до 15', 'до 15', 'до 15', 'до 15', 'до 15', 'до 15'],
        ['до 4', 'до 4', 'до 4', 'до 4', 'до 4', 'до 4'],
        [
          '12 000',
          ['15 000', '19 000'],
          ['22 000', '26 000'],
          ['30 000', '35 000'],
          ['45 000', '55 000'],
          ['65 000', '85 000'],
        ],
        [
          ['12', '10'],
          ['12', '10'],
          ['12', '10'],
          ['12', '10'],
          ['12', '10'],
          ['12', '10'],
        ],
      ],
    },
    {
      headTitle: 'ДЕНЬ-НОЧЬ',
      headSubtitle: 'Универсальные тарифы,, который подойдут каждому',
      titles: [
        'Тарифный план',
        'Абонентская плата, сумов',
        'Скорость подключения, Мбит/с',
        'Скорость в сети TAS-IX, Мбит/с круглосуточно FTTx*',
        'Скорость в сети TAS-IX, Мбит/с круглосуточно ADSL',
        'Включённый объём трафика днём, МБ** с 9:00 до 0:00',
        'Включённый объём трафика днём, МБ** с 0:00 до 9:00',
      ],
      tariffs1: ['День-Ночь 1', '40 000', '1', 'до 10', '4', '6 000', 'без ограничений'],
      tariffs2: ['День-Ночь 2', '60 000', '1', 'до 10', '4', '9 000', 'без ограничений'],
      tariffs3: ['День-Ночь 3', '80 000', '1,5', 'до 15', '4', '14 000', 'без ограничений'],
      tariffs4: ['День-Ночь 4', '100 000', '1,5', 'до 15', '4', '19 000', 'без ограничений'],
      tariffs5: ['День-Ночь 5', '120 000', '2', 'до 20', '4', '25 000', 'без ограничений'],
      tariffs6: ['День-Ночь 6', '160 000', '2', 'до 20', '4', '40 000', 'без ограничений'],
      info: '** Стоимость 1 МБ сверх включенного в тарифный план лимита составляет 40 сумов. Доступ в Интернет доступен только при положительном балансе лицевого счета',
    },
    {
      headTitle: 'МИКС',
      headSubtitle: 'Универсальные тарифы,, который подойдут каждому',
      titles: [
        'Тарифный план',
        'Абонентская плата, сумов',
        'Скорость подключения, Мбит/с',
        'Скорость в сети TAS-IX, Мбит/с круглосуточно FTTx (г. Ташкент)',
        'Скорость в сети TAS-IX, Мбит/с круглосуточно FTTx (г. Фергана)',
        'Скорость в сети TAS-IX, Мбит/с круглосуточно ADSL',
        'Включённый объём трафика днём, МБ** с 9:00 до 21:00',
        'Включённый объём трафика днём, МБ** с 21:00 до 9:00',
      ],
      tariffs1: [
        ['Микс'],
        ['40 000', '60 000', '80 000', '100 000', '120 000', '160 000'],
        ['2', '2', '3', '3', '4', '4'],
        [
          ['до 10', '100'],
          ['до 10', '100'],
          ['до 20', '100'],
          ['до 20', '100'],
          ['до 30', '100'],
          ['до 30', '100'],
        ],
        ['до 4', 'до 4', 'до 6', 'до 6', 'до 8', 'до 8'],
        ['до 4', 'до 4', 'до 4', 'до 4', 'до 4', 'до 4'],
        [
          ['8 000', '10 000'],
          ['13 000', ' 16 000'],
          ['18 000', '22 000'],
          ['25 000', '30 000'],
          ['32 000', '40 000'],
          ['50 000', '60 000'],
        ],
        ['без ограничений'],
      ],
      info: '** Стоимость 1 МБ сверх включенного в тарифный план лимита составляет 10 сумов. Доступ в Интернет доступен только при положительном балансе лицевого счета.',
    },
    {
      headTitle: 'ПЯТЁРОЧКА',
      headSubtitle: 'Универсальные тарифы,, который подойдут каждому',
      titles: [
        'Тарифный план',
        'Абонентская плата, сумов',
        'Скорость подключения, Мбит/с',
        'Скорость в сети TAS-IX, Мбит/с',
        'Включённый объём трафика, МБ  с 9:00 до 0:00',
        'Включённый объём трафика, МБ  с 0:00 до 9:00',
        'Цена за 1 MБ сверх трафика, сумов с 9:00 до 0:00',
        'Цена за 1 MБ сверх трафика, сумов с 0:00 до 9:00',
      ],
      tariffs1: ['Пятёрочка*', '20 000', '0,5', '1', '500', '500', '80', '40'],
      info: '- Подключение ТП "Пятёрочка" осуществляется только по технологии FTTx.',
    },
    {
      headTitle: 'ДЕЛОВОЙ UNLIM',
      headSubtitle: 'Универсальные тарифы,, который подойдут каждому',
      titles: [
        'Тарифный план',
        'Абонентская плата, сумов',
        'Скорость подключения, Мбит/с',
        'Суточный лимит трафика (с 00:01 до 23:59), МБ ',
        'Скорость после преодоления суточного лимита трафика, Мбит/с',
        'Скорость в сети TAS-IX, Мбит/с FTTx*',
        'Скорость в сети TAS-IX, Мбит/с ADSL',
        'Доступ',
      ],
      tariffs1: ['Деловой Unlim mini', '100 000', '1', '1 200', '0,5', 'до 30', '4', 'круглосуточно'],
      tariffs2: ['Деловой Unlim 1', '160 000', '2', '2 000', '0,5', 'до 30', '4', 'круглосуточно'],
      tariffs3: ['Деловой Unlim 2', '260 000', '4', '3 000', '1', 'до 30', '4', 'круглосуточно'],
      info: '- На тарифных планах "Деловой Unlim" указанная скорость гарантируется до преодоления суточного лимита трафика, после чего понижается. После 00.00 счетчик обнуляется и в распоряжении абонента снова доступен суточный лимит на гарантированной скорости.',
    },
    {
      headTitle: 'НОЧНЫЕ БЕЗЛИМИТЫ',
      headSubtitle: 'Универсальные тарифы,, который подойдут каждому',
      titles: [
        'Тарифный план',
        'Абонентская плата, сумов',
        'Скорость подключения, Кбит/с',
        'Скорость в сети TAS-IX, Кбит/с (круглосуточно) FTTx*',
        'Скорость в сети TAS-IX, Кбит/с (круглосуточно) ADSL',
        'Включённый объём трафика днём, МБ** с 9:00 до 20:00',
        'Включённый объём трафика днём, МБ** с 20:00 до 9:00',
      ],
      tariffs1: ['Ночник', '40 000', '256', '2048', '1024', '-', 'неограниченно'],
      tariffs2: ['Полуночник', '120 000', '768', '4096', '2048', '5 000', 'неограниченно'],
      info: '** Стоимость 1 МБ сверх включенного в тарифный план Полуночник составляет 12 сумов. Данные тарифные планы активны только при положительном балансе. На данных тарифнах планах неиспользованный за месяц лимит трафика сгорает, не переходя на следующий месяц.',
      titles2: [
        'Тарифный план',
        'Абонентская плата, сумов',
        'Скорость подключения, Мбит/с',
        'Скорость в сети TAS-IX, Кбит/с (круглосуточно) FTTx*',
        'Скорость в сети TAS-IX, Кбит/с (круглосуточно) ADSL',
        'Включённый объём трафика днём, МБ** с 9:00 до 20:00',
        'Включённый объём трафика днём, МБ** с 20:00 до 9:00',
        'Стоимость 1 МБ сверхлимита,  сумов с 9:00 до 20:00',
      ],
      tariffs3: ['Полуночник Light', '60 000', '0,5', 'до 10', '4', '4 000', 'неограниченно', '40'],
      titles3: [
        'Тарифный план',
        'Абонентская плата, сумов',
        'В будни (понедельник - пятница) Время',
        'В будни (понедельник - пятница) Скорость подключения, Мбит/с',
        'Выходные (Суббота - Воскресенье) Время',
        'Выходные (Суббота - Воскресенье) Скорость подключения, Мбит/с',
        'Скорость в сети TAS-IX, Кбит/с (круглосуточно) FTTx*',
        'Скорость в сети TAS-IX, Кбит/с (круглосуточно) ADSL',
      ],
      tariffs4: [
        ['Хит'],
        ['120 000', '120 000'],
        ['09:00-18:00', '18:00-09:00'],
        ['0,5', '2'],
        ['(круглосуточно)', '(круглосуточно)'],
        ['2', '2'],
        ['до 15', 'до 15'],
        ['4', '4'],
      ],
      info2: '** После преодоления 50 000 Mb скорость интернета понижается до 0,5 Мбит/с.',
    },
    {
      headTitle: 'БЕЗЛИМИТНЫЙ',
      headSubtitle: 'Универсальные тарифы, который подойдут каждому',
      titles: [
        'Тарифный план',
        'Абонентская плата, сумов',
        'Скорость подключения, Мбит/с',
        'Скорость в сети TAS-IX, Мбит/с FTTx (г. Ташкент)',
        'Скорость в сети TAS-IX, Мбит/с FTTx (г. Фергана)',
        'Скорость в сети TAS-IX, Мбит/с ADSL',
        'Доступ',
      ],
      tariffs1: [
        ['Безлимитный'],
        ['70 000', '140 000', '200 000', '320 000', '360 000', '480 000', '600 000'],
        ['0,5', '1', '1,5', '2,5', '3', '4', '5'],
        ['до 20', 'до 20', 'до 20', 'до 30', 'до 30', 'до 30', 'до 30'],
        ['до 4', 'до 4', 'до 4', 'до 4', 'до 4', 'до 4', 'до 5'],
        ['до 4', 'до 4', 'до 4', 'до 4', 'до 4', 'до 4', 'до 5'],
        ['без ограничений'],
      ],
      info: '- Скорость доступа в сеть Интернет с использованием файлообменных протоколов P2P (BitTorrent, Direct Connect и т.д.) не гарантируется.',
    },
    {
      headTitle: 'ДОМАШНИЙ',
      headSubtitle: 'Универсальные тарифы, который подойдут каждому',
      titles: [
        'Тарифный план',
        'Абонентская плата, сумов',
        'Скорость подключения, Мбит/с',
        'Скорость в сети TAS-IX, Мбит/с FTTx (г. Ташкент)',
        'Скорость в сети TAS-IX, Мбит/с FTTx (г. Фергана)',
        'Скорость в сети TAS-IX, Мбит/с ADSL',
        'Доступ',
      ],
      tariffs1: [
        ['Домашний'],
        ['60 000', '90 000', '130 000', '170 000', '200 000', '250 000', '350 000', '480 000'],
        ['1', '2', '3', '4', '5', '7', '10', '15'],
        ['до 100'],
        ['до 4', 'до 4', 'до 4', 'до 4', 'до 5', 'до 7', 'до 10', 'до 15'],
        ['до 4', 'до 4', 'до 4', 'до 4', 'до 5', '- *', '- *', '- *'],
        ['без ограничений'],
      ],
      info: '- Скорость доступа в сеть Интернет с использованием файлообменных протоколов P2P (BitTorrent, Direct Connect и т.д.) не гарантируется. * - Подключение на данные ТП осуществляется только по технологии FTTx.',
    },
    {
      headTitle: 'БЕЗЛИМИТНЫЙ',
      headSubtitle: 'Универсальные тарифы, который подойдут каждому',
      titles: [
        'Тарифный план',
        'Абонентская плата, сумов',
        'Скорость подключения, Мбит/с',
        'Скорость в сети TAS-IX, Мбит/с',
      ],
      tariffs1: ['Безлимитный S New', '80 000', '2', '100'],
      tariffs2: ['Безлимитный M', '100 000', '6', '100'],
      tariffs3: ['Безлимитный L', '160 000', '20', '100'],
      tariffs4: ['Безлимитный XL', '220 000', '40', '100'],
      // info:
      //   '** Стоимость 1 МБ сверх включенного в тарифный план лимита составляет 40 сумов. Доступ в Интернет доступен только при положительном балансе лицевого счета',
    },
    {
      headTitle: 'БЕЗЛИМИТНЫЙ +',
      headSubtitle: 'Универсальные тарифы, который подойдут каждому',
      titles: [
        'Тарифный план',
        'Абонентская плата, сумов',
        'Скорость подключения, Мбит/с',
        'Скорость подключения c 00:00 до 12:00 Мбит/с',
        'Скорость в сети TAS-IX, Мбит/с',
      ],
      tariffs1: ['Безлимитный S+', '85 000', '2', '100', '100'],
      tariffs2: ['Безлимитный M+', '105 000', '7', '100', '100'],
      tariffs3: ['Безлимитный L mini', '135 000', '15', '100', '100'],
      tariffs4: ['Безлимитный L+', '160 000', '25', '100', '100'],
      tariffs5: ['Безлимитный XL+', '335 000', '60', '100', '100'],
    },
    {
      headTitle: 'БЕЗ ЛИМИТА',
      headSubtitle: 'Универсальные тарифы, который подойдут каждому',
      titles: [
        'Тарифный план',
        'Абонентская плата, сумов',
        'Скорость подключения, Мбит/с',
        'Скорость подключения c 00:00 до 12:00 Мбит/с',
        'Скорость в сети TAS-IX, Мбит/с',
      ],
      tariffs1: ['БЕЗ ЛИМИТА S', '110 000', '10', '100', '100'],
      tariffs2: ['БЕЗ ЛИМИТА M', '140 000', '20', '100', '100'],
      tariffs3: ['БЕЗ ЛИМИТА L', '210 000', '40', '100', '100'],
      tariffs4: ['БЕЗ ЛИМИТА XL', '380 000', '80', '100', '100'],
    },
  ]

  return (
    <div className="InternetAccordion">
      <Accordion allowZeroExpanded>
        {accardion.splice(0, 1).map((item, index) => (
          <AccordionItem key={index} className="accordion__item nine">
            <AccordionItemHeading>
              <AccordionItemButton>
                <div className="InternetAccordion__headTitle">{item.headTitle}</div>
                <div className="InternetAccordion__headSubtitle">{item.headSubtitle}</div>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <div className="InternetAccordion__panel">
                <div className="InternetAccordion__panelTitlesWr">
                  <div className="InternetAccordion__panelTitles">
                    {item.titles.map((title, index) => (
                      <div className="InternetAccordion__panelTitle" key={index}>
                        {title}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="InternetAccordion__panelTariffsWr gray">
                  <div className="InternetAccordion__panelTariffs">
                    {item.tariffs1.map((tariffs, index) =>
                      Array.isArray(tariffs) ? (
                        <div className="InternetAccordion__panelTariff array" key={index}>
                          {tariffs.map((item, index) =>
                            Array.isArray(item) ? (
                              <div className="border-style" key={index}>
                                {item.map((el, ind) => (
                                  <span key={ind} className={ind === 0 ? 'line-through' : null}>
                                    {el}
                                  </span>
                                ))}
                              </div>
                            ) : (
                              <div key={index} className="border-style">
                                {item}
                              </div>
                            )
                          )}
                        </div>
                      ) : (
                        <div className="InternetAccordion__panelTariff" key={index}>
                          {tariffs}
                        </div>
                      )
                    )}
                  </div>
                  <div className="InternetAccordion__panelTariffs">
                    {item.tariffs2.map((tariffs, index) =>
                      Array.isArray(tariffs) ? (
                        <div className="InternetAccordion__panelTariff array" key={index}>
                          {tariffs.map((item, index) =>
                            Array.isArray(item) ? (
                              <div className="border-style" key={index}>
                                {item.map((el, ind) => (
                                  <span key={ind} className={ind === 0 ? 'line-through' : null}>
                                    {el}
                                  </span>
                                ))}
                              </div>
                            ) : (
                              <div key={index} className="border-style">
                                {item}
                              </div>
                            )
                          )}
                        </div>
                      ) : (
                        <div className="InternetAccordion__panelTariff" key={index}>
                          {tariffs}
                        </div>
                      )
                    )}
                  </div>
                  <div className="InternetAccordion__panelTariffs">
                    {item.tariffs3.map((tariffs, index) =>
                      Array.isArray(tariffs) ? (
                        <div className="InternetAccordion__panelTariff array" key={index}>
                          {tariffs.map((item, index) =>
                            Array.isArray(item) ? (
                              <div className="border-style" key={index}>
                                {item.map((el, ind) => (
                                  <span key={ind} className={ind === 0 ? 'line-through' : null}>
                                    {el}
                                  </span>
                                ))}
                              </div>
                            ) : (
                              <div key={index} className="border-style">
                                {item}
                              </div>
                            )
                          )}
                        </div>
                      ) : (
                        <div className="InternetAccordion__panelTariff" key={index}>
                          {tariffs}
                        </div>
                      )
                    )}
                  </div>

                  <div className="InternetAccordion__panelTariffs">
                    {item.tariffs4.map((tariffs, index) =>
                      Array.isArray(tariffs) ? (
                        <div className="InternetAccordion__panelTariff array" key={index}>
                          {tariffs.map((item, index) =>
                            Array.isArray(item) ? (
                              <div className="border-style" key={index}>
                                {item.map((el, ind) => (
                                  <span key={ind} className={ind === 0 ? 'line-through' : null}>
                                    {el}
                                  </span>
                                ))}
                              </div>
                            ) : (
                              <div key={index} className="border-style">
                                {item}
                              </div>
                            )
                          )}
                        </div>
                      ) : (
                        <div className="InternetAccordion__panelTariff" key={index}>
                          {tariffs}
                        </div>
                      )
                    )}
                  </div>
                </div>
                <div className="InternetAccordion__panelInfo">{item.info}</div>
              </div>
            </AccordionItemPanel>
          </AccordionItem>
        ))}

        {accardion.splice(0, 1).map((item, index) => (
          <AccordionItem key={index} className="accordion__item nine">
            <AccordionItemHeading>
              <AccordionItemButton>
                <div className="InternetAccordion__headTitle">{item.headTitle}</div>
                <div className="InternetAccordion__headSubtitle">{item.headSubtitle}</div>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <div className="InternetAccordion__panel">
                <div className="InternetAccordion__panelTitlesWr">
                  <div className="InternetAccordion__panelTitles">
                    {item.titles.map((title, index) => (
                      <div className="InternetAccordion__panelTitle" key={index}>
                        {title}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="InternetAccordion__panelTariffsWr gray">
                  <div className="InternetAccordion__panelTariffs">
                    {item.tariffs1.map((tariffs, index) =>
                      Array.isArray(tariffs) ? (
                        <div className="InternetAccordion__panelTariff array" key={index}>
                          {tariffs.map((item, index) =>
                            Array.isArray(item) ? (
                              <div className="border-style" key={index}>
                                {item.map((el, ind) => (
                                  <span key={ind} className={ind === 0 ? 'line-through' : null}>
                                    {el}
                                  </span>
                                ))}
                              </div>
                            ) : (
                              <div key={index} className="border-style">
                                {item}
                              </div>
                            )
                          )}
                        </div>
                      ) : (
                        <div className="InternetAccordion__panelTariff" key={index}>
                          {tariffs}
                        </div>
                      )
                    )}
                  </div>
                  <div className="InternetAccordion__panelTariffs">
                    {item.tariffs2.map((tariffs, index) =>
                      Array.isArray(tariffs) ? (
                        <div className="InternetAccordion__panelTariff array" key={index}>
                          {tariffs.map((item, index) =>
                            Array.isArray(item) ? (
                              <div className="border-style" key={index}>
                                {item.map((el, ind) => (
                                  <span key={ind} className={ind === 0 ? 'line-through' : null}>
                                    {el}
                                  </span>
                                ))}
                              </div>
                            ) : (
                              <div key={index} className="border-style">
                                {item}
                              </div>
                            )
                          )}
                        </div>
                      ) : (
                        <div className="InternetAccordion__panelTariff" key={index}>
                          {tariffs}
                        </div>
                      )
                    )}
                  </div>
                  <div className="InternetAccordion__panelTariffs">
                    {item.tariffs3.map((tariffs, index) =>
                      Array.isArray(tariffs) ? (
                        <div className="InternetAccordion__panelTariff array" key={index}>
                          {tariffs.map((item, index) =>
                            Array.isArray(item) ? (
                              <div className="border-style" key={index}>
                                {item.map((el, ind) => (
                                  <span key={ind} className={ind === 0 ? 'line-through' : null}>
                                    {el}
                                  </span>
                                ))}
                              </div>
                            ) : (
                              <div key={index} className="border-style">
                                {item}
                              </div>
                            )
                          )}
                        </div>
                      ) : (
                        <div className="InternetAccordion__panelTariff" key={index}>
                          {tariffs}
                        </div>
                      )
                    )}
                  </div>

                  <div className="InternetAccordion__panelTariffs">
                    {item.tariffs4.map((tariffs, index) =>
                      Array.isArray(tariffs) ? (
                        <div className="InternetAccordion__panelTariff array" key={index}>
                          {tariffs.map((item, index) =>
                            Array.isArray(item) ? (
                              <div className="border-style" key={index}>
                                {item.map((el, ind) => (
                                  <span key={ind} className={ind === 0 ? 'line-through' : null}>
                                    {el}
                                  </span>
                                ))}
                              </div>
                            ) : (
                              <div key={index} className="border-style">
                                {item}
                              </div>
                            )
                          )}
                        </div>
                      ) : (
                        <div className="InternetAccordion__panelTariff" key={index}>
                          {tariffs}
                        </div>
                      )
                    )}
                  </div>
                </div>
                <div className="InternetAccordion__panelInfo">{item.info}</div>
              </div>
            </AccordionItemPanel>
          </AccordionItem>
        ))}

        {accardion.splice(0, 1).map((item, index) => (
          <AccordionItem key={index} className="accordion__item one">
            <AccordionItemHeading>
              <AccordionItemButton>
                <div className="InternetAccordion__headTitle">{item.headTitle}</div>
                <div className="InternetAccordion__headSubtitle">{item.headSubtitle}</div>
              </AccordionItemButton>
            </AccordionItemHeading>

            <AccordionItemPanel className="scroll">
              <div className="InternetAccordion__panel">
                <div className="InternetAccordion__panelTitlesWr gray">
                  <div className="InternetAccordion__panelTitles">
                    {item.titles.map((title, index) => (
                      <div className="InternetAccordion__panelTitle" key={index}>
                        {title}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="InternetAccordion__panelTariffsWr gray">
                  <div className="InternetAccordion__panelTariffs">
                    {item.tariffs1.map((tariffs, index) => (
                      <div className="InternetAccordion__panelTariff " key={index}>
                        {tariffs}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="InternetAccordion__panelTariffsWr gray">
                  <div className="InternetAccordion__panelTariffs">
                    {item.tariffs2.map((tariffs, index) => (
                      <div className="InternetAccordion__panelTariff" key={index}>
                        {tariffs}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="InternetAccordion__panelTariffsWr gray">
                  <div className="InternetAccordion__panelTariffs">
                    {item.tariffs3.map((tariffs, index) => (
                      <div className="InternetAccordion__panelTariff " key={index}>
                        {tariffs}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="InternetAccordion__panelTariffsWr gray">
                  <div className="InternetAccordion__panelTariffs">
                    {item.tariffs4.map((tariffs, index) => (
                      <div className="InternetAccordion__panelTariff" key={index}>
                        {tariffs}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </AccordionItemPanel>
          </AccordionItem>
        ))}

        {accardion.splice(0, 1).map((item, index) => (
          <AccordionItem key={index} className="accordion__item two">
            <AccordionItemHeading>
              <AccordionItemButton>
                <div className="InternetAccordion__headTitle">{item.headTitle}</div>
                <div className="InternetAccordion__headSubtitle">{item.headSubtitle}</div>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <div className="InternetAccordion__panel">
                <div className="InternetAccordion__panelTitlesWr">
                  <div className="InternetAccordion__panelTitles">
                    {item.titles.map((title, index) => (
                      <div className="InternetAccordion__panelTitle" key={index}>
                        {title}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="InternetAccordion__panelTariffsWr gray">
                  <div className="InternetAccordion__panelTariffs">
                    {item.tariffs1.map((tariffs, index) =>
                      Array.isArray(tariffs) ? (
                        <div className="InternetAccordion__panelTariff array" key={index}>
                          {tariffs.map((item, index) =>
                            Array.isArray(item) ? (
                              <div className="border-style" key={index}>
                                {item.map((el, ind) => (
                                  <span key={ind} className={ind === 0 ? 'line-through' : null}>
                                    {el}
                                  </span>
                                ))}
                              </div>
                            ) : (
                              <div key={index} className="border-style">
                                {item}
                              </div>
                            )
                          )}
                        </div>
                      ) : (
                        <div className="InternetAccordion__panelTariff" key={index}>
                          {tariffs}
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
            </AccordionItemPanel>
          </AccordionItem>
        ))}

        {accardion.splice(0, 1).map((item, index) => (
          <AccordionItem key={index} className="accordion__item three">
            <AccordionItemHeading>
              <AccordionItemButton>
                <div className="InternetAccordion__headTitle">{item.headTitle}</div>
                <div className="InternetAccordion__headSubtitle">{item.headSubtitle}</div>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <div className="InternetAccordion__panel">
                <div className="InternetAccordion__panelTitlesWr">
                  <div className="InternetAccordion__panelTitles">
                    {item.titles.map((title, index) => (
                      <div className="InternetAccordion__panelTitle" key={index}>
                        {title}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="InternetAccordion__panelTariffsWr gray">
                  <div className="InternetAccordion__panelTariffs">
                    {item.tariffs1.map((tariffs, index) =>
                      Array.isArray(tariffs) ? (
                        <div className="InternetAccordion__panelTariff array" key={index}>
                          {tariffs.map((item, i) =>
                            Array.isArray(item) ? (
                              <div key={i} className="border-style">
                                {item.map((el, ind) => (
                                  <span key={ind} className={ind === 0 ? 'line-through' : null}>
                                    {el}
                                  </span>
                                ))}
                              </div>
                            ) : (
                              <div key={i} className="border-style">
                                {item}
                              </div>
                            )
                          )}
                        </div>
                      ) : (
                        <div className="InternetAccordion__panelTariff" key={index}>
                          {tariffs}
                        </div>
                      )
                    )}
                  </div>
                </div>
                <div className="InternetAccordion__panelTariffsWr gray">
                  <div className="InternetAccordion__panelTariffs">
                    {item.tariffs2.map((tariffs, index) =>
                      Array.isArray(tariffs) ? (
                        <div className="InternetAccordion__panelTariff array" key={index}>
                          {tariffs.map((item, i) =>
                            Array.isArray(item) ? (
                              <div key={i} className="border-style">
                                {item.map((el, ind) => (
                                  <span key={ind} className={ind === 0 ? 'line-through' : null}>
                                    {el}
                                  </span>
                                ))}
                              </div>
                            ) : (
                              <div key={i} className="border-style">
                                {item}
                              </div>
                            )
                          )}
                        </div>
                      ) : (
                        <div className="InternetAccordion__panelTariff" key={index}>
                          {tariffs}
                        </div>
                      )
                    )}
                  </div>
                </div>
                <div className="InternetAccordion__panelTariffsWr gray">
                  <div className="InternetAccordion__panelTariffs">
                    {item.tariffs3.map((tariffs, index) =>
                      Array.isArray(tariffs) ? (
                        <div className="InternetAccordion__panelTariff array" key={index}>
                          {tariffs.map((item, i) =>
                            Array.isArray(item) ? (
                              <div key={i} className="border-style">
                                {item.map((el, ind) => (
                                  <span key={ind} className={ind === 0 ? 'line-through' : null}>
                                    {el}
                                  </span>
                                ))}
                              </div>
                            ) : (
                              <div key={i} className="border-style">
                                {item}
                              </div>
                            )
                          )}
                        </div>
                      ) : (
                        <div className="InternetAccordion__panelTariff" key={index}>
                          {tariffs}
                        </div>
                      )
                    )}
                  </div>
                </div>
                <div className="InternetAccordion__panelTariffsWr gray">
                  <div className="InternetAccordion__panelTariffs">
                    {item.tariffs4.map((tariffs, index) =>
                      Array.isArray(tariffs) ? (
                        <div className="InternetAccordion__panelTariff array" key={index}>
                          {tariffs.map((item, i) =>
                            Array.isArray(item) ? (
                              <div key={i} className="border-style">
                                {item.map((el, ind) => (
                                  <span key={ind} className={ind === 0 ? 'line-through' : null}>
                                    {el}
                                  </span>
                                ))}
                              </div>
                            ) : (
                              <div key={i} className="border-style">
                                {item}
                              </div>
                            )
                          )}
                        </div>
                      ) : (
                        <div className="InternetAccordion__panelTariff" key={index}>
                          {tariffs}
                        </div>
                      )
                    )}
                  </div>
                </div>
                <div className="InternetAccordion__panelTariffsWr gray">
                  <div className="InternetAccordion__panelTariffs">
                    {item.tariffs5.map((tariffs, index) =>
                      Array.isArray(tariffs) ? (
                        <div className="InternetAccordion__panelTariff array" key={index}>
                          {tariffs.map((item, i) =>
                            Array.isArray(item) ? (
                              <div key={i} className="border-style">
                                {item.map((el, ind) => (
                                  <span key={ind} className={ind === 0 ? 'line-through' : null}>
                                    {el}
                                  </span>
                                ))}
                              </div>
                            ) : (
                              <div key={i} className="border-style">
                                {item}
                              </div>
                            )
                          )}
                        </div>
                      ) : (
                        <div className="InternetAccordion__panelTariff" key={index}>
                          {tariffs}
                        </div>
                      )
                    )}
                  </div>
                </div>
                <div className="InternetAccordion__panelTariffsWr gray">
                  <div className="InternetAccordion__panelTariffs">
                    {item.tariffs6.map((tariffs, index) =>
                      Array.isArray(tariffs) ? (
                        <div className="InternetAccordion__panelTariff array" key={index}>
                          {tariffs.map((item, i) =>
                            Array.isArray(item) ? (
                              <div key={i} className="border-style">
                                {item.map((el, ind) => (
                                  <span key={ind} className={ind === 0 ? 'line-through' : null}>
                                    {el}
                                  </span>
                                ))}
                              </div>
                            ) : (
                              <div key={i} className="border-style">
                                {item}
                              </div>
                            )
                          )}
                        </div>
                      ) : (
                        <div className="InternetAccordion__panelTariff" key={index}>
                          {tariffs}
                        </div>
                      )
                    )}
                  </div>
                </div>
                <div className="InternetAccordion__panelInfo">{item.info}</div>
              </div>
            </AccordionItemPanel>
          </AccordionItem>
        ))}

        {accardion.splice(0, 1).map((item, index) => (
          <AccordionItem key={index} className="accordion__item four">
            <AccordionItemHeading>
              <AccordionItemButton>
                <div className="InternetAccordion__headTitle">{item.headTitle}</div>
                <div className="InternetAccordion__headSubtitle">{item.headSubtitle}</div>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <div className="InternetAccordion__panel">
                <div className="InternetAccordion__panelTitlesWr">
                  <div className="InternetAccordion__panelTitles">
                    {item.titles.map((title, index) => (
                      <div className="InternetAccordion__panelTitle" key={index}>
                        {title}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="InternetAccordion__panelTariffsWr gray">
                  <div className="InternetAccordion__panelTariffs">
                    {item.tariffs1.map((tariffs, index) =>
                      Array.isArray(tariffs) ? (
                        <div className="InternetAccordion__panelTariff array" key={index}>
                          {tariffs.map((item, index) =>
                            Array.isArray(item) ? (
                              <div className="border-style" key={index}>
                                {item.map((el, ind) => (
                                  <span key={ind} className={ind === 0 ? 'line-through' : null}>
                                    {el}
                                  </span>
                                ))}
                              </div>
                            ) : (
                              <div key={index} className="border-style">
                                {item}
                              </div>
                            )
                          )}
                        </div>
                      ) : (
                        <div className="InternetAccordion__panelTariff" key={index}>
                          {tariffs}
                        </div>
                      )
                    )}
                  </div>
                </div>
                <div className="InternetAccordion__panelInfo">{item.info}</div>
              </div>
            </AccordionItemPanel>
          </AccordionItem>
        ))}

        {accardion.splice(0, 1).map((item, index) => (
          <AccordionItem key={index} className="accordion__item five">
            <AccordionItemHeading>
              <AccordionItemButton>
                <div className="InternetAccordion__headTitle">{item.headTitle}</div>
                <div className="InternetAccordion__headSubtitle">{item.headSubtitle}</div>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <div className="InternetAccordion__panel">
                <div className="InternetAccordion__panelTitlesWr">
                  <div className="InternetAccordion__panelTitles">
                    {item.titles.map((title, index) => (
                      <div className="InternetAccordion__panelTitle" key={index}>
                        {title}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="InternetAccordion__panelTariffsWr gray">
                  <div className="InternetAccordion__panelTariffs">
                    {item.tariffs1.map((tariffs, index) =>
                      Array.isArray(tariffs) ? (
                        <div className="InternetAccordion__panelTariff array" key={index}>
                          {tariffs.map((item, i) =>
                            Array.isArray(item) ? (
                              <div key={i} className="border-style">
                                {item.map((el, ind) => (
                                  <span key={ind} className={ind === 0 ? 'line-through' : null}>
                                    {el}
                                  </span>
                                ))}
                              </div>
                            ) : (
                              <div key={i} className="border-style">
                                {item}
                              </div>
                            )
                          )}
                        </div>
                      ) : (
                        <div className="InternetAccordion__panelTariff" key={index}>
                          {tariffs}
                        </div>
                      )
                    )}
                  </div>
                </div>
                <div className="InternetAccordion__panelInfo">{item.info}</div>
              </div>
            </AccordionItemPanel>
          </AccordionItem>
        ))}

        {accardion.splice(0, 1).map((item, index) => (
          <AccordionItem key={index} className="accordion__item six">
            <AccordionItemHeading>
              <AccordionItemButton>
                <div className="InternetAccordion__headTitle">{item.headTitle}</div>
                <div className="InternetAccordion__headSubtitle">{item.headSubtitle}</div>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <div className="InternetAccordion__panel">
                <div className="InternetAccordion__panelTitlesWr">
                  <div className="InternetAccordion__panelTitles">
                    {item.titles.map((title, index) => (
                      <div className="InternetAccordion__panelTitle" key={index}>
                        {title}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="InternetAccordion__panelTariffsWr gray">
                  <div className="InternetAccordion__panelTariffs">
                    {item.tariffs1.map((tariffs, index) =>
                      Array.isArray(tariffs) ? (
                        <div className="InternetAccordion__panelTariff array" key={index}>
                          {tariffs.map((item, index) =>
                            Array.isArray(item) ? (
                              <div className="border-style" key={index}>
                                {item.map((el, ind) => (
                                  <span key={ind} className={ind === 0 ? 'line-through' : null}>
                                    {el}
                                  </span>
                                ))}
                              </div>
                            ) : (
                              <div key={index} className="border-style">
                                {item}
                              </div>
                            )
                          )}
                        </div>
                      ) : (
                        <div className="InternetAccordion__panelTariff" key={index}>
                          {tariffs}
                        </div>
                      )
                    )}
                  </div>
                </div>

                <div className="InternetAccordion__panelInfo">{item.info}</div>
              </div>
            </AccordionItemPanel>
          </AccordionItem>
        ))}

        {accardion.splice(0, 1).map((item, index) => (
          <AccordionItem key={index} className="accordion__item seven">
            <AccordionItemHeading>
              <AccordionItemButton>
                <div className="InternetAccordion__headTitle">{item.headTitle}</div>
                <div className="InternetAccordion__headSubtitle">{item.headSubtitle}</div>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <div className="InternetAccordion__panel">
                <div className="InternetAccordion__panelTitlesWr">
                  <div className="InternetAccordion__panelTitles">
                    {item.titles.map((title, index) => (
                      <div className="InternetAccordion__panelTitle" key={index}>
                        {title}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="InternetAccordion__panelTariffsWr gray">
                  <div className="InternetAccordion__panelTariffs">
                    {item.tariffs1.map((tariffs, index) =>
                      Array.isArray(tariffs) ? (
                        <div className="InternetAccordion__panelTariff array" key={index}>
                          {tariffs.map((item, i) =>
                            Array.isArray(item) ? (
                              <div key={i} className="border-style">
                                {item.map((el, ind) => (
                                  <span key={ind} className={ind === 0 ? 'line-through' : null}>
                                    {el}
                                  </span>
                                ))}
                              </div>
                            ) : (
                              <div key={i} className="border-style">
                                {item}
                              </div>
                            )
                          )}
                        </div>
                      ) : (
                        <div className="InternetAccordion__panelTariff" key={index}>
                          {tariffs}
                        </div>
                      )
                    )}
                  </div>
                </div>
                <div className="InternetAccordion__panelTariffsWr gray">
                  <div className="InternetAccordion__panelTariffs">
                    {item.tariffs2.map((tariffs, index) =>
                      Array.isArray(tariffs) ? (
                        <div className="InternetAccordion__panelTariff array" key={index}>
                          {tariffs.map((item, i) =>
                            Array.isArray(item) ? (
                              <div key={i} className="border-style">
                                {item.map((el, ind) => (
                                  <span key={ind} className={ind === 0 ? 'line-through' : null}>
                                    {el}
                                  </span>
                                ))}
                              </div>
                            ) : (
                              <div key={i} className="border-style">
                                {item}
                              </div>
                            )
                          )}
                        </div>
                      ) : (
                        <div className="InternetAccordion__panelTariff" key={index}>
                          {tariffs}
                        </div>
                      )
                    )}
                  </div>
                </div>
                <div className="InternetAccordion__panelInfo">{item.info}</div>
                <div className="InternetAccordion__panelTitlesWr margin-top">
                  <div className="InternetAccordion__panelTitles">
                    {item.titles2.map((title, index) => (
                      <div className="InternetAccordion__panelTitle" key={index}>
                        {title}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="InternetAccordion__panelTariffsWr gray">
                  <div className="InternetAccordion__panelTariffs">
                    {item.tariffs3.map((tariffs, index) =>
                      Array.isArray(tariffs) ? (
                        <div className="InternetAccordion__panelTariff array" key={index}>
                          {tariffs.map((item, i) =>
                            Array.isArray(item) ? (
                              <div key={i} className="border-style">
                                {item.map((el, ind) => (
                                  <span key={ind} className={ind === 0 ? 'line-through' : null}>
                                    {el}
                                  </span>
                                ))}
                              </div>
                            ) : (
                              <div key={i} className="border-style">
                                {item}
                              </div>
                            )
                          )}
                        </div>
                      ) : (
                        <div className="InternetAccordion__panelTariff" key={index}>
                          {tariffs}
                        </div>
                      )
                    )}
                  </div>
                </div>
                <div className="InternetAccordion__panelTitlesWr margin-top">
                  <div className="InternetAccordion__panelTitles">
                    {item.titles3.map((title, index) => (
                      <div className="InternetAccordion__panelTitle" key={index}>
                        {title}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="InternetAccordion__panelTariffsWr gray">
                  <div className="InternetAccordion__panelTariffs">
                    {item.tariffs4.map((tariffs, index) =>
                      Array.isArray(tariffs) ? (
                        <div className="InternetAccordion__panelTariff array" key={index}>
                          {tariffs.map((item, index) =>
                            Array.isArray(item) ? (
                              <div className="border-style" key={index}>
                                {item.map((el, ind) => (
                                  <span key={ind} className={ind === 0 ? 'line-through' : null}>
                                    {el}
                                  </span>
                                ))}
                              </div>
                            ) : (
                              <div key={index} className="border-style">
                                {item}
                              </div>
                            )
                          )}
                        </div>
                      ) : (
                        <div className="InternetAccordion__panelTariff" key={index}>
                          {tariffs}
                        </div>
                      )
                    )}
                  </div>
                </div>
                <div className="InternetAccordion__panelInfo">{item.info2}</div>
              </div>
            </AccordionItemPanel>
          </AccordionItem>
        ))}

        {accardion.splice(0, 1).map((item, index) => (
          <AccordionItem key={index} className="accordion__item eight">
            <AccordionItemHeading>
              <AccordionItemButton>
                <div className="InternetAccordion__headTitle">{item.headTitle}</div>
                <div className="InternetAccordion__headSubtitle">{item.headSubtitle}</div>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <div className="InternetAccordion__panel">
                <div className="InternetAccordion__panelTitlesWr">
                  <div className="InternetAccordion__panelTitles">
                    {item.titles.map((title, index) => (
                      <div className="InternetAccordion__panelTitle" key={index}>
                        {title}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="InternetAccordion__panelTariffsWr gray">
                  <div className="InternetAccordion__panelTariffs">
                    {item.tariffs1.map((tariffs, index) =>
                      Array.isArray(tariffs) ? (
                        <div className="InternetAccordion__panelTariff array" key={index}>
                          {tariffs.map((item, index) =>
                            Array.isArray(item) ? (
                              <div className="border-style" key={index}>
                                {item.map((el, ind) => (
                                  <span key={ind} className={ind === 0 ? 'line-through' : null}>
                                    {el}
                                  </span>
                                ))}
                              </div>
                            ) : (
                              <div key={index} className="border-style">
                                {item}
                              </div>
                            )
                          )}
                        </div>
                      ) : (
                        <div className="InternetAccordion__panelTariff" key={index}>
                          {tariffs}
                        </div>
                      )
                    )}
                  </div>
                </div>
                <div className="InternetAccordion__panelInfo">{item.info}</div>
              </div>
            </AccordionItemPanel>
          </AccordionItem>
        ))}

        {accardion.splice(0, 1).map((item, index) => (
          <AccordionItem key={index} className="accordion__item nine">
            <AccordionItemHeading>
              <AccordionItemButton>
                <div className="InternetAccordion__headTitle">{item.headTitle}</div>
                <div className="InternetAccordion__headSubtitle">{item.headSubtitle}</div>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <div className="InternetAccordion__panel">
                <div className="InternetAccordion__panelTitlesWr">
                  <div className="InternetAccordion__panelTitles">
                    {item.titles.map((title, index) => (
                      <div className="InternetAccordion__panelTitle" key={index}>
                        {title}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="InternetAccordion__panelTariffsWr gray">
                  <div className="InternetAccordion__panelTariffs">
                    {item.tariffs1.map((tariffs, index) =>
                      Array.isArray(tariffs) ? (
                        <div className="InternetAccordion__panelTariff array" key={index}>
                          {tariffs.map((item, index) =>
                            Array.isArray(item) ? (
                              <div className="border-style" key={index}>
                                {item.map((el, ind) => (
                                  <span key={ind} className={ind === 0 ? 'line-through' : null}>
                                    {el}
                                  </span>
                                ))}
                              </div>
                            ) : (
                              <div key={index} className="border-style">
                                {item}
                              </div>
                            )
                          )}
                        </div>
                      ) : (
                        <div className="InternetAccordion__panelTariff" key={index}>
                          {tariffs}
                        </div>
                      )
                    )}
                  </div>
                </div>
                <div className="InternetAccordion__panelInfo">{item.info}</div>
              </div>
            </AccordionItemPanel>
          </AccordionItem>
        ))}

        {accardion.splice(0, 1).map((item, index) => (
          <AccordionItem key={index} className="accordion__item nine">
            <AccordionItemHeading>
              <AccordionItemButton>
                <div className="InternetAccordion__headTitle">{item.headTitle}</div>
                <div className="InternetAccordion__headSubtitle">{item.headSubtitle}</div>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <div className="InternetAccordion__panel">
                <div className="InternetAccordion__panelTitlesWr">
                  <div className="InternetAccordion__panelTitles">
                    {item.titles.map((title, index) => (
                      <div className="InternetAccordion__panelTitle" key={index}>
                        {title}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="InternetAccordion__panelTariffsWr gray">
                  <div className="InternetAccordion__panelTariffs">
                    {item.tariffs1.map((tariffs, index) =>
                      Array.isArray(tariffs) ? (
                        <div className="InternetAccordion__panelTariff array" key={index}>
                          {tariffs.map((item, index) =>
                            Array.isArray(item) ? (
                              <div className="border-style" key={index}>
                                {item.map((el, ind) => (
                                  <span key={ind} className={ind === 0 ? 'line-through' : null}>
                                    {el}
                                  </span>
                                ))}
                              </div>
                            ) : (
                              <div key={index} className="border-style">
                                {item}
                              </div>
                            )
                          )}
                        </div>
                      ) : (
                        <div className="InternetAccordion__panelTariff" key={index}>
                          {tariffs}
                        </div>
                      )
                    )}
                  </div>
                  <div className="InternetAccordion__panelTariffs">
                    {item.tariffs2.map((tariffs, index) =>
                      Array.isArray(tariffs) ? (
                        <div className="InternetAccordion__panelTariff array" key={index}>
                          {tariffs.map((item, index) =>
                            Array.isArray(item) ? (
                              <div className="border-style" key={index}>
                                {item.map((el, ind) => (
                                  <span key={ind} className={ind === 0 ? 'line-through' : null}>
                                    {el}
                                  </span>
                                ))}
                              </div>
                            ) : (
                              <div key={index} className="border-style">
                                {item}
                              </div>
                            )
                          )}
                        </div>
                      ) : (
                        <div className="InternetAccordion__panelTariff" key={index}>
                          {tariffs}
                        </div>
                      )
                    )}
                  </div>
                  <div className="InternetAccordion__panelTariffs">
                    {item.tariffs3.map((tariffs, index) =>
                      Array.isArray(tariffs) ? (
                        <div className="InternetAccordion__panelTariff array" key={index}>
                          {tariffs.map((item, index) =>
                            Array.isArray(item) ? (
                              <div className="border-style" key={index}>
                                {item.map((el, ind) => (
                                  <span key={ind} className={ind === 0 ? 'line-through' : null}>
                                    {el}
                                  </span>
                                ))}
                              </div>
                            ) : (
                              <div key={index} className="border-style">
                                {item}
                              </div>
                            )
                          )}
                        </div>
                      ) : (
                        <div className="InternetAccordion__panelTariff" key={index}>
                          {tariffs}
                        </div>
                      )
                    )}
                  </div>
                  <div className="InternetAccordion__panelTariffs">
                    {item.tariffs4.map((tariffs, index) =>
                      Array.isArray(tariffs) ? (
                        <div className="InternetAccordion__panelTariff array" key={index}>
                          {tariffs.map((item, index) =>
                            Array.isArray(item) ? (
                              <div className="border-style" key={index}>
                                {item.map((el, ind) => (
                                  <span key={ind} className={ind === 0 ? 'line-through' : null}>
                                    {el}
                                  </span>
                                ))}
                              </div>
                            ) : (
                              <div key={index} className="border-style">
                                {item}
                              </div>
                            )
                          )}
                        </div>
                      ) : (
                        <div className="InternetAccordion__panelTariff" key={index}>
                          {tariffs}
                        </div>
                      )
                    )}
                  </div>
                </div>
                <div className="InternetAccordion__panelInfo">{item.info}</div>
              </div>
            </AccordionItemPanel>
          </AccordionItem>
        ))}

        {accardion.splice(0, 1).map((item, index) => (
          <AccordionItem key={index} className="accordion__item nine">
            <AccordionItemHeading>
              <AccordionItemButton>
                <div className="InternetAccordion__headTitle">{item.headTitle}</div>
                <div className="InternetAccordion__headSubtitle">{item.headSubtitle}</div>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <div className="InternetAccordion__panel">
                <div className="InternetAccordion__panelTitlesWr">
                  <div className="InternetAccordion__panelTitles">
                    {item.titles.map((title, index) => (
                      <div className="InternetAccordion__panelTitle" key={index}>
                        {title}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="InternetAccordion__panelTariffsWr gray">
                  <div className="InternetAccordion__panelTariffs">
                    {item.tariffs1.map((tariffs, index) =>
                      Array.isArray(tariffs) ? (
                        <div className="InternetAccordion__panelTariff array" key={index}>
                          {tariffs.map((item, index) =>
                            Array.isArray(item) ? (
                              <div className="border-style" key={index}>
                                {item.map((el, ind) => (
                                  <span key={ind} className={ind === 0 ? 'line-through' : null}>
                                    {el}
                                  </span>
                                ))}
                              </div>
                            ) : (
                              <div key={index} className="border-style">
                                {item}
                              </div>
                            )
                          )}
                        </div>
                      ) : (
                        <div className="InternetAccordion__panelTariff" key={index}>
                          {tariffs}
                        </div>
                      )
                    )}
                  </div>
                  <div className="InternetAccordion__panelTariffs">
                    {item.tariffs2.map((tariffs, index) =>
                      Array.isArray(tariffs) ? (
                        <div className="InternetAccordion__panelTariff array" key={index}>
                          {tariffs.map((item, index) =>
                            Array.isArray(item) ? (
                              <div className="border-style" key={index}>
                                {item.map((el, ind) => (
                                  <span key={ind} className={ind === 0 ? 'line-through' : null}>
                                    {el}
                                  </span>
                                ))}
                              </div>
                            ) : (
                              <div key={index} className="border-style">
                                {item}
                              </div>
                            )
                          )}
                        </div>
                      ) : (
                        <div className="InternetAccordion__panelTariff" key={index}>
                          {tariffs}
                        </div>
                      )
                    )}
                  </div>
                  <div className="InternetAccordion__panelTariffs">
                    {item.tariffs3.map((tariffs, index) =>
                      Array.isArray(tariffs) ? (
                        <div className="InternetAccordion__panelTariff array" key={index}>
                          {tariffs.map((item, index) =>
                            Array.isArray(item) ? (
                              <div className="border-style" key={index}>
                                {item.map((el, ind) => (
                                  <span key={ind} className={ind === 0 ? 'line-through' : null}>
                                    {el}
                                  </span>
                                ))}
                              </div>
                            ) : (
                              <div key={index} className="border-style">
                                {item}
                              </div>
                            )
                          )}
                        </div>
                      ) : (
                        <div className="InternetAccordion__panelTariff" key={index}>
                          {tariffs}
                        </div>
                      )
                    )}
                  </div>
                  <div className="InternetAccordion__panelTariffs">
                    {item.tariffs4.map((tariffs, index) =>
                      Array.isArray(tariffs) ? (
                        <div className="InternetAccordion__panelTariff array" key={index}>
                          {tariffs.map((item, index) =>
                            Array.isArray(item) ? (
                              <div className="border-style" key={index}>
                                {item.map((el, ind) => (
                                  <span key={ind} className={ind === 0 ? 'line-through' : null}>
                                    {el}
                                  </span>
                                ))}
                              </div>
                            ) : (
                              <div key={index} className="border-style">
                                {item}
                              </div>
                            )
                          )}
                        </div>
                      ) : (
                        <div className="InternetAccordion__panelTariff" key={index}>
                          {tariffs}
                        </div>
                      )
                    )}
                  </div>
                </div>
                <div className="InternetAccordion__panelInfo">{item.info}</div>
              </div>
            </AccordionItemPanel>
          </AccordionItem>
        ))}

        {accardion.splice(0, 1).map((item, index) => (
          <AccordionItem key={index} className="accordion__item nine">
            <AccordionItemHeading>
              <AccordionItemButton>
                <div className="InternetAccordion__headTitle">{item.headTitle}</div>
                <div className="InternetAccordion__headSubtitle">{item.headSubtitle}</div>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <div className="InternetAccordion__panel">
                <div className="InternetAccordion__panelTitlesWr">
                  <div className="InternetAccordion__panelTitles">
                    {item.titles.map((title, index) => (
                      <div className="InternetAccordion__panelTitle" key={index}>
                        {title}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="InternetAccordion__panelTariffsWr gray">
                  <div className="InternetAccordion__panelTariffs">
                    {item.tariffs1.map((tariffs, index) =>
                      Array.isArray(tariffs) ? (
                        <div className="InternetAccordion__panelTariff array" key={index}>
                          {tariffs.map((item, index) =>
                            Array.isArray(item) ? (
                              <div className="border-style" key={index}>
                                {item.map((el, ind) => (
                                  <span key={ind} className={ind === 0 ? 'line-through' : null}>
                                    {el}
                                  </span>
                                ))}
                              </div>
                            ) : (
                              <div key={index} className="border-style">
                                {item}
                              </div>
                            )
                          )}
                        </div>
                      ) : (
                        <div className="InternetAccordion__panelTariff" key={index}>
                          {tariffs}
                        </div>
                      )
                    )}
                  </div>
                  <div className="InternetAccordion__panelTariffs">
                    {item.tariffs2.map((tariffs, index) =>
                      Array.isArray(tariffs) ? (
                        <div className="InternetAccordion__panelTariff array" key={index}>
                          {tariffs.map((item, index) =>
                            Array.isArray(item) ? (
                              <div className="border-style" key={index}>
                                {item.map((el, ind) => (
                                  <span key={ind} className={ind === 0 ? 'line-through' : null}>
                                    {el}
                                  </span>
                                ))}
                              </div>
                            ) : (
                              <div key={index} className="border-style">
                                {item}
                              </div>
                            )
                          )}
                        </div>
                      ) : (
                        <div className="InternetAccordion__panelTariff" key={index}>
                          {tariffs}
                        </div>
                      )
                    )}
                  </div>
                  <div className="InternetAccordion__panelTariffs">
                    {item.tariffs3.map((tariffs, index) =>
                      Array.isArray(tariffs) ? (
                        <div className="InternetAccordion__panelTariff array" key={index}>
                          {tariffs.map((item, index) =>
                            Array.isArray(item) ? (
                              <div className="border-style" key={index}>
                                {item.map((el, ind) => (
                                  <span key={ind} className={ind === 0 ? 'line-through' : null}>
                                    {el}
                                  </span>
                                ))}
                              </div>
                            ) : (
                              <div key={index} className="border-style">
                                {item}
                              </div>
                            )
                          )}
                        </div>
                      ) : (
                        <div className="InternetAccordion__panelTariff" key={index}>
                          {tariffs}
                        </div>
                      )
                    )}
                  </div>

                  <div className="InternetAccordion__panelTariffs">
                    {item.tariffs4.map((tariffs, index) =>
                      Array.isArray(tariffs) ? (
                        <div className="InternetAccordion__panelTariff array" key={index}>
                          {tariffs.map((item, index) =>
                            Array.isArray(item) ? (
                              <div className="border-style" key={index}>
                                {item.map((el, ind) => (
                                  <span key={ind} className={ind === 0 ? 'line-through' : null}>
                                    {el}
                                  </span>
                                ))}
                              </div>
                            ) : (
                              <div key={index} className="border-style">
                                {item}
                              </div>
                            )
                          )}
                        </div>
                      ) : (
                        <div className="InternetAccordion__panelTariff" key={index}>
                          {tariffs}
                        </div>
                      )
                    )}
                  </div>
                </div>
                <div className="InternetAccordion__panelInfo">{item.info}</div>
              </div>
            </AccordionItemPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </div>
  )
}

export default InternetAccordion
