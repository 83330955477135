import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { getSessions } from "../services/http/internet";
import { translatedText } from "../services/translatedText";
import { addDuration, formatDate } from "../utils/date";
import { formatBytes } from "../utils/parser";
import Filter from "./Filter";
import Table from "./Table";

function modifyData(t, isArray = false, count = 0) {
  if (Array.isArray(t) && t.length < 1) return [];
  if (Array.isArray(t)) {
    const times = t[0].split(":");
    const day = Math.floor(times[0] / 24);
    const time = `${times[0] - day * 24}:${times[1]}:${times[2]}`;
    return [
      count,
      `+ ${day} ${time}`,
      formatBytes(t[1]),
      formatBytes(t[2]),
      formatBytes(t[1] + t[2]),
      t[4],
    ];
  }

  delete t.cid;
  delete t.durationSec;
  delete t.end;
  delete t.acctSessionId;
  delete t.tpId;
  delete t.uid;
  delete t.ip;
  delete t.tpName;
  delete t.start;
  return isArray
    ? [t.duration, t.recv, t.sent, +t.recv + +t.sent, t.sum]
    : [
        `+ 0 ${t.duration}`,
        formatBytes(t.recv),
        formatBytes(t.sent),
        formatBytes(t.recv + t.sent),
        t.sum,
      ];
}

export default function SumTable({ handleFilter, response = [] }) {
  const [globalData, setGlobalData] = useState();

  useEffect(() => {
    async function fetchData() {
      if (response?.length) {
        const per = response.reduce(
          (prev, c) => {
            let all = prev[0];
            const allT = modifyData(c, true).map((c, i) => {
              if (i === 0) return addDuration(c, all[i]);
              return c + (all[i] ?? 0);
            });

            return [[...allT]];
          },
          [[]]
        );
        per[0] = modifyData(per[0], false, response.length);
        setGlobalData(per);
      } else {
        const empty = [0, "+ 0 00:00:00", "0 Bit", "0 Bit", "0 Bit", 0];
        setGlobalData([empty]);
      }
    }
    fetchData();
  }, [response]);

  const setFilterTable = async (filters) => {
    if (!filters?.from_date || !filters?.to_date) return;
    if (handleFilter) handleFilter(filters);
    setGlobalData(null);
    // eslint-disable-next-line no-undef
    // await new Promise((r) => setTimeout(r, 300)); //fake asleep

    // if (response?.length) {
    //   const per = response.reduce(
    //     (prev, c) => {
    //       let all = prev[0];
    //       const allT = modifyData(c, true).map((c, i) => {
    //         if (i === 0) return addDuration(c, all[i]);
    //         return c + (all[i] ?? 0);
    //       });

    //       return [[...allT]];
    //     },
    //     [[]]
    //   );
    //   per[0] = modifyData(per[0], false, response.length);
    //   setGlobalData(per);
    // } else {
    //   const empty = [0, "+ 0 00:00:00", "0 Bit", "0 Bit", "0 Bit", 0];
    //   setGlobalData([empty]);
    // }
  };

  return (
    <React.Fragment>
      <Filter setFilter={setFilterTable} />

      <h1 style={{ marginTop: 50 }}>{translatedText("Period")}</h1>

      <Table
        theadStyle={{ fontSize: 16 }}
        style={{ marginTop: 30 }}
        showPagination={false}
        headers={[
          translatedText("Session"),
          translatedText("Duration"),
          translatedText(["Traffic", "Received"]),
          translatedText(["Traffic", "Sent"]),
          translatedText(["Traffic", "Sum"]),
          translatedText("Sum"),
        ]}
        data={globalData}
        loading={!globalData}
      />
    </React.Fragment>
  );
}
