/* eslint-disable no-constant-condition */
import React, { useEffect } from 'react'
import { useState } from 'react'
import { useRef } from 'react'
import { useSelector } from 'react-redux'
import Input from '../components/Input'
import Loader from '../components/Loader'
import { checkVerification, sendCodeVerification } from '../services/http/contacts'
import { translatedText } from '../services/translatedText'
import resetIcon from '../assets/images/svg/resetWhite.svg'
import { toStringDate } from '../utils/date'
import Table from '../components/Table'
import { getBringFriends, sendBringFriendRequest } from '../services/http/bringFriend'
import { parsePhone } from '../utils/parser'
import { useCheckPhone } from '../hooks/useCheckPhone'
import sendIcon from '../assets/images/message/Telegram.svg'

export default function BringFriend() {
  const { user } = useSelector((state) => state)
  const currentCode = useRef()
  const timeoutExpired = useRef()
  const bringFr = useRef()
  const [loader, setLoader] = useState(false)
  const [loadingTable, setLoadingTable] = useState(false)
  const [msg, setMsg] = useState({
    msg: '',
  })
  const [timeout, setTime] = useState()
  const [confirmed, setConfirmed] = useState(false)
  const [expired, setExpired] = useState(false)
  const [code, setCode] = useState()
  const username = user?.login
  const [phone, setPhone] = useState()
  const nameFriend = useRef()
  const [friends, setFriends] = useState([])
  const [phones, setPhones] = useState([])
  const isPhone = useCheckPhone()

  useEffect(() => {
    if (!username) return
    async function fetchData() {
      setLoadingTable(true)
      try {
        const res = await getBringFriends(username)
        if (!Array.isArray(res)) {
          setLoadingTable(false)
          return
        }
        setPhones(res.map((r) => r.phone))
        setFriends(
          res.map((r) => {
            return [r.name, parsePhone(r.phone), r.stage]
          })
        )
      } catch (e) {
        console.log(e)
      } finally {
        setLoadingTable(false)
      }
    }
    fetchData()
  }, [username])

  const sendCode = async () => {
    let phoneCurrent = phone
    let codeState = code

    if (!phoneCurrent) {
      setMsg({ msg: translatedText('Valid data'), code: 1, error: 1 })
      return
    }

    if (!codeState) {
      if (phoneCurrent?.length !== 12) {
        setLoader(false)
        return
      } else {
        await apply(phoneCurrent)
        return
      }
    }

    clearTimeout(timeoutExpired.current)
    timeoutExpired.current = null
    setLoader(false)
  }
  const confirm = async () => {
    let codeState = code

    if (!nameFriend.current) {
      setMsg({
        ...msg,
        msg: translatedText('Valid data'),
        error: 3,
      })
      return
    }

    setLoader(true)

    if (msg.sent && !currentCode.current) {
      setMsg({
        ...msg,
        msg: translatedText('Code error'),
        code: 1,
        error: 1,
      })
      return
    }

    if (codeState === 'wait' && !expired && currentCode.current) {
      setLoader(true)
      const data = await checkVerification(currentCode.current, username)
      setCode(data?.code)
      codeState = data?.code
      if (codeState === 'success') {
        setMsg({ msg: '' })
        let currentPhone = phone?.length === 12 ? phone.slice(3) : phone
        if (phones.indexOf(currentPhone) >= 0) {
          setLoader(false)
          setConfirmed(true)
          setMsg({
            ...msg,
            msg: translatedText('bringfriend_phone_valid'),
            error: 1,
          })
          return
        }

        const response = await sendBringFriendRequest({
          phone: currentPhone,
          name: nameFriend.current,
          login: username,
        })
        if (response !== 'OK')
          setMsg({
            ...msg,
            msg: translatedText('Unknown error'),
            error: 1,
          })
        setConfirmed(true)
      } else {
        setLoader(false)
        setCode('wait')
        setMsg({
          ...msg,
          msg: translatedText('Code error'),
          code: 1,
          error: 1,
        })
        return
      }
    }

    setLoader(false)
  }

  function countdown(minutes) {
    let seconds = minutes * 60
    let timerId = setInterval(() => {
      if (!timeoutExpired.current) return
      seconds--
      if (seconds < 0) {
        clearInterval(timerId)
        return
      }
      let minutesLeft = `0${Math.floor(seconds / 60)}`.slice(-2)
      let secondsLeft = `0${seconds % 60}`.slice(-2)
      let timeLeft = `${minutesLeft}:${secondsLeft}`
      setTime(timeLeft)
    }, 1000)
  }

  const apply = async (phoneCurrent = null) => {
    try {
      var actualPhone = phoneCurrent || phone
      if (!actualPhone) {
        setMsg({
          msg: translatedText('Valid data'),
          error: 3,
        })
        return
      }

      setLoader(true)
      const resSms = await sendCodeVerification(actualPhone, username)

      if (resSms?.data == 'Вы были забанены') {
        const dt = new Date(resSms?.time)
        dt.setMinutes(dt.getMinutes() + 10)
        dt.setHours(dt.getHours() - 2)
        setMsg({
          msg: translatedText('You have ban', {
            date: toStringDate(dt, true),
          }),
          error: 3,
        })
        clearTimeout(timeoutExpired.current)
        timeoutExpired.current = null
        setExpired(false)
        setCode('')
      } else if (resSms?.error_code || resSms?.status_description == 'Error in sms')
        setMsg({
          msg: translatedText('Unknown error'),
          error: resSms?.error_code ?? 1,
        })
      else {
        setMsg({ msg: translatedText('Sent code'), sent: true, code: 1 })
        setExpired(false)
        countdown(2)
        timeoutExpired.current = setTimeout(() => {
          setTime(
            <div style={{ display: 'flex', alignItems: 'center', padding: 0 }} onClick={() => apply()}>
              <img width="16" src={resetIcon} alt="reset" />
            </div>
          )
          timeoutExpired.current = null
          setExpired(true)
        }, 2 * 60 * 1000)
        setCode('wait')
      }
    } catch (error) {
      if (error.code === 'ERR_BAD_RESPONSE')
        setMsg({
          msg: translatedText('Unknown error'),
          error: 1,
        })
    } finally {
      setLoader(false)
    }
  }

  return (
    <div>
      <div style={{ backgroundColor: '#FAFAFA' }}>
        <div className="bring-container container-min " style={{ padding: '0' }}>
          <div className="col g-3">
            <h1>{translatedText('Bring a friend')}</h1>
            <span>{translatedText('bringfriend_description')}</span>
            <button
              onClick={() =>
                bringFr.current.scrollIntoView({
                  behavior: 'smooth',
                  block: 'center',
                })
              }
              style={{
                borderRadius: 50,
                padding: '15px 30px',
                width: 'fit-content',
                fontSize: 16,
                fontWeight: 'normal',
              }}
              className="primary-btn"
            >
              {translatedText('bringfriend_button')}
            </button>
          </div>
          <img
            style={isPhone ? { position: 'absolute', bottom: 0, right: 0, opacity: 0.6 } : {}}
            width={isPhone ? 200 : 500}
            src={require('../assets/images/Bring a friend.png')}
          />
        </div>
      </div>
      <div className="container">
        <div
          style={{ lineHeight: '30px', margin: '20px 0' }}
          dangerouslySetInnerHTML={{
            __html: translatedText('bringfriend_rules'),
          }}
        ></div>

        <div
          className="col g-2 additional-frame"
          dangerouslySetInnerHTML={{
            __html: translatedText('bringfriend_notes'),
          }}
        ></div>

        <p
          style={{
            paddingLeft: isPhone ? 0 : 50,
            color: '#757575',
            margin: '20px 0',
          }}
        >
          {translatedText('bringfriend_note')}
        </p>

        <h1 ref={bringFr} style={isPhone ? { marginTop: 40, marginBottom: 30 } : { marginTop: 70, marginBottom: 50 }}>
          {translatedText('bringfriend_button')}:
        </h1>

        {confirmed ? (
          <div className={'frame br center' + (msg?.error ? ' additional-frame' : '')}>
            <span>{msg?.error ? msg.msg : translatedText('bringfriend_success')}</span>
            {!msg?.error && <div className="checkmark"></div>}
          </div>
        ) : (
          <React.Fragment>
            <div className="bring-friend-row">
              <Input
                marginLeftSpan={5}
                offSelection
                label={translatedText('bringfriend_name')}
                mini
                onChange={(value) => (nameFriend.current = value)}
              />
              <div style={{ height: 'fit-content' }} className="row g-1">
                <Input
                  marginLeftSpan={5}
                  mini
                  className="bring-friend_input"
                  mask="+998 (##) ### ## ##"
                  label={translatedText('bringfriend_phone_friend')}
                  onChange={(value) => setPhone(value)}
                />
                <div style={{ justifyContent: 'flex-end' }} className="col">
                  <p>{' '}</p>
                  <button
                    disabled={loader || (code === 'wait' && !expired)}
                    onClick={() => sendCode(!!timeout)}
                    className="primary-btn mini full"
                    style={{ padding: '10px 15px' }}
                  >
                    {timeout || <img height="20" src={sendIcon} />}
                  </button>
                </div>
              </div>
              <div style={{ width: 'min-content' }} className="col">
                <Input
                  marginLeftSpan={5}
                  label={translatedText('bringfriend_phone_code')}
                  onChange={(value) => (currentCode.current = value)}
                  mini
                  disabled={!msg.msg}
                  offSelection
                />
                <div
                  style={{
                    fontSize: 13,
                    color: '#A5A5A5',
                    fontWeight: 100,
                    maxWidth: 340,
                    padding: 10,
                  }}
                >
                  {translatedText('bringfriend_phone_note')}
                </div>
              </div>
            </div>

            <div className="col a-center">
              {msg.msg && (
                <p
                  className={'mini-t s13' + (msg?.error ? ' red-text' : '')}
                  style={{ margin: '0 0 10px 0', maxWidth: 300 }}
                >
                  {msg?.msg}
                </p>
              )}
              <button disabled={loader || !msg.sent} onClick={confirm} className="primary-btn">
                {loader ? <Loader center min /> : translatedText('Confirm')}
              </button>
            </div>
          </React.Fragment>
        )}

        <h1 style={isPhone ? { marginTop: 80, marginBottom: 30 } : { marginTop: 100, marginBottom: 50 }}>
          {translatedText('bringfriend_history_added_friends')}
        </h1>

        <Table
          style={{ marginBottom: 70 }}
          headers={[translatedText('FirstName') + ':', translatedText('Contact phone') + ':', translatedText('Status')]}
          data={friends}
          loading={loadingTable}
        >
          {(item, _, column) =>{
            let result = item.toLowerCase()
            if(column==2){
              if(result=="ОПЛАЧЕНО".toLowerCase()||"Архив оплаченных".toLowerCase()==result){
                item="Выполнено"
              }else
              if(result=='НЕ УДАЛОСЬ'.toLowerCase()||result=="ОТКАЗ".toLowerCase()||result=="ВНЕ ЗОНЫ".toLowerCase()||result=="ЗАСЧИТАНО".toLowerCase()||result=="Комментарий недозвон".toLowerCase()||result=="Техническая заявка".toLowerCase()||result=="Внутренний диалог".toLowerCase()||result=="Ошибочное обращение".toLowerCase()||result=="Сопровождение".toLowerCase()||result=="Сменить отдел".toLowerCase()){
                item = "Не удалось"
              }else{
                item = "Обрабатывается"
              }
            }
            
            return column > 1 ? (
              <div style={{ color: item == 'Actived' ? '#43B70D' : '#E5474C' }} className="table-item">
                {translatedText(item)}
              </div>
            ) : (
              item
            )
          }
            
          }
        </Table>
      </div>
    </div>
  )
}
