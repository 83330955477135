import React from 'react'
import { useState } from 'react'
import { useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Input from '../components/Input'
import Loader from '../components/Loader'
import MainBlock from '../components/MainBlock'
import { useAuth } from '../services/authentication'
import { translatedText } from '../services/translatedText'
import '../styles/login.scss'

export default function Login() {
  const form = useRef({})
  const auth = useAuth()
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const history = useNavigate()
  const { state } = useLocation()
  const timeout = useRef()

  async function Sign(e) {
    e.preventDefault()
    setLoading(true)
    await auth.signin(form.current.login, form.current.pass, (data, error) => {
      if (!data?.sid || error) {
        setError(translatedText(error || 'User not found'))
        clearTimeout(timeout.current)
        timeout.current = setTimeout(() => {
          setError(false)
        }, 4000)
      } else {
        history(state?.from || '/')
      }
      setLoading(false)
    })
  }

  return (
    <div className="background-login">
      <MainBlock hideTitle className="main-block-login">
        <form className="container-login">
          <h1>{translatedText('Login1')}</h1>
          <Input onChange={(value) => (form.current.login = value)} label={translatedText('Login')} />
          <Input onChange={(value) => (form.current.pass = value)} type="password" label={translatedText('Password')} />
          {error && (
            <p className="red-text t-center" style={{ lineHeight: 0 }}>
              {error}
            </p>
          )}
          <button type="submit" disabled={loading} onClick={Sign} className="primary-btn">
            {loading ? <Loader min center /> : translatedText('Login1')}
          </button>
          {/* <span className="row g-2">
            <img src={require('../assets/images/playMarket.png')} />
            <img src={require('../assets/images/appStore.png')} />
          </span> */}
        </form>
      </MainBlock>
    </div>
  )
}
