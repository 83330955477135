import React from "react";
import { translatedText } from "../services/translatedText";
import DateInput from "./DateInput";
import useWindowSize from "../hooks/useWindowSize";
import PropTypes from "prop-types";
import { useState } from "react";

export default function Filter({ setFilter }) {
  const [filters, setFilters] = useState({ rows: 25 });
  const [type, setType] = useState();
  const isMobile = useWindowSize().width < 1170;

  return (
    <div style={{ alignItems: "center" }} className="col">
      <h1 style={{ textAlign: "left", width: "100%", marginTop: 50 }}>
        {translatedText("Filter")}
      </h1>
      <div
        style={{
          marginTop: 30,
          width: "100%",
        }}
        className={isMobile ? "col g-2" : "row g-2"}
      >
        <DateInput
          onSelectType={setType}
          onChange={(date) =>
            setFilters({
              ...filters,
              from_date: date
                ? date.replaceAll(" ", "").replaceAll(".", "-")
                : "",
            })
          }
          type={{ id: type, second: false }}
          label={translatedText("Date from") + ":"}
        />
        <DateInput
          type={{ id: type, second: true }}
          onSelectType={setType}
          onChange={(date) =>
            setFilters({
              ...filters,
              to_date: date
                ? date.replaceAll(" ", "").replaceAll(".", "-")
                : "",
            })
          }
          label={translatedText("Date to") + ":"}
        />
        {/* <DropDown
          onChangeItem={(item) => setFilters({ ...filters, speed: item })}
          label={translatedText("Speed") + ":"}
          items={["Kb", "Mb", "Gb"]}
        /> */}
        <button
          onClick={() => setFilter && setFilter(filters)}
          style={{ marginTop: 30, fontSize: 18 }}
          className={"primary-btn fullButton"}
        >
          {translatedText("Show")}
        </button>
      </div>
    </div>
  );
}
Filter.propTypes = {
  setFilter: PropTypes.func,
};
