import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { translatedText } from '../services/translatedText'
import MainBlock from '../components/MainBlock'
import CircularChart from '../components/CircularChart'
import DiscountChart from '../components/DiscountChart'
import { addToMonth, getCurrentMonthDate, modifyDate } from '../utils/date'
import useActions from '../hooks/useActions'
import { getCurrentTariff } from '../services/http/tariffs'
import { useCheckPhone } from '../hooks/useCheckPhone'

function Bonus() {
  const [paymentDate, setPaymentDate] = useState()
  const [actions, { tariff, user }] = useActions()

  useEffect(() => {
    if (!tariff?.id) return
    const date =
      tariff?.internetActivate === '0000-00-00' ? getCurrentMonthDate() : modifyDate(tariff?.internetActivate)

    setPaymentDate(date)
  }, [tariff])
  useEffect(() => {
    async function fetchData() {
      if (!tariff?.id) {
        const temp = await getCurrentTariff()
        actions.setTariff(temp[0])
      }
    }
    fetchData()
  }, [])

  const isPhone = useCheckPhone()
  const REDUCTION = user?.reduction

  const nextDate =
    tariff?.internetActivate === '0000-00-00' ? getCurrentMonthDate(true, 1) : addToMonth(tariff?.internetActivate, 1)

  return (
    <div className="container">
      <div style={{ alignItems: 'center', marginTop: 10 }} className="between">
        <h1 style={{ margin: '30px 0' }}>{translatedText('Discounts')}</h1>
        <div className="danger-alert">
          {translatedText('Day-payment', {
            date: nextDate,
          })}
        </div>
      </div>

      <MainBlock style={isPhone ? { padding: '0 20px 20px 20px' } : { padding: '30px 70px 30px 10px' }}>
        <div className="row-m g-2">
          <div className="a-center col">
            <CircularChart
              width={isPhone ? 250 : undefined}
              fontSize={isPhone ? 60 : undefined}
              height={isPhone ? 200 : undefined}
              percentage={REDUCTION}
            />
            <h3 className="unfocus-text2">
              {translatedText('Your-discounts', {
                percentage: REDUCTION,
              })}
            </h3>
          </div>
          <div style={{ width: '100%' }} className="col">
            {REDUCTION >= 0 && (
              <DiscountChart isPhone={isPhone} reductionDate={user?.reductionDate} reduction={REDUCTION} date={paymentDate} />
            )}
            <p
              style={{
                fontSize: isPhone ? 11 : 16,
                marginTop: isPhone ? 20 : 60,
              }}
              className="unfocus-text2"
            >
              {translatedText('Discounts-info')}
            </p>
          </div>
        </div>
      </MainBlock>

      <h1 style={{ margin: '30px 0 30px 0' }}>{translatedText('Terms-discounts')}</h1>
      <p style={{ fontSize: 16 }} dangerouslySetInnerHTML={{ __html: translatedText('Terms-bonus1') }}></p>
      {/* <div style={{ margin: "50px 0" }} className="row j-center">
        <div>
          <p style={{ fontSize: 16 }} className="between">
            <b>{translatedText("Status-bonus-info")}</b>
            <b>{translatedText("Discount%")}</b>
          </p>
          <br />
          <div className="col g-1">
            <InfoBonus
              text1={translatedText("From-to-months", { from: 3, to: 6 })}
              text2="3%"
            />
            <InfoBonus
              text1={translatedText("From-to-months", { from: 6, to: 9 })}
              text2="5%"
              odd
            />
            <InfoBonus
              text1={translatedText("From-to-months", { from: 9, to: 12 })}
              text2="10%"
            />
            <InfoBonus
              text1={translatedText("From-to-months", { from: 12, to: 15 })}
              text2="12%"
              odd
            />
            <InfoBonus
              text1={translatedText("From-to-more-months", { from: 15 })}
              text2="15%"
            />
          </div>
        </div>
      </div> */}
      <p
        style={{ fontSize: 16, marginBottom: 100 }}
        dangerouslySetInnerHTML={{ __html: translatedText('Terms-bonus2') }}
      ></p>
    </div>
  )
}
export default withTranslation()(Bonus)

// eslint-disable-next-line react/prop-types
const InfoBonus = ({ text1, text2, odd }) => {
  return (
    <div
      className="info-bonus"
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        padding: '15px 25px',
        background: !odd ? '#F4F4F4' : '#EBEBEB',
        fontSize: 16,
        color: '#4C4C4C',
        borderRadius: '14px',
        minWidth: 500,
      }}
    >
      <span>{text1}</span>
      <span>{text2}</span>
    </div>
  )
}
