import React from "react";
import classes from "./style.module.css";
import PartnersItem from "../PartnersItem";
import { useState, useEffect } from "react";
import i18next from "i18next";

export default function PartnersGroup({ content, data }) {
  const lang = i18next.language;
  const [partners, setPartners] = useState();
  useEffect(() => {
    const partners = content.filter((item) => item.type === data.type);
    setPartners(partners);
  }, [content, data]);

  return (
    data.title !== "Все" && (
      <div className={classes.content}>
        <h2 className={classes.title}>
          {lang === "ru" ? data.title : data.titleuz}
        </h2>
        <div className={classes.group}>
          {partners &&
            partners.map((item) => <PartnersItem data={item} key={item.id} />)}
        </div>
      </div>
    )
  );
}
