import React from "react";
import Table from "../../components/Table";
import { translatedText } from "../../services/translatedText";
import "../../styles/internet.scss";
import { useEffect } from "react";
import {
  getDetail,
  getOnline,
  getSessions,
} from "../../services/http/internet";
import { useState } from "react";
import { formatBytes } from "../../utils/parser";
import { useSelector } from "react-redux";
import SumTable from "../../components/SumTable";
import HighchartsComponent from "../../components/Highcharts";
import reset from "../../assets/images/svg/reset.svg";
import ChartsData from "../../components/Chart/mock";
import {
  addDuration,
  checkDateInThisWeek,
  checkTodayDate,
  displayChartDate,
  formatDate,
  isInCurrentWeek,
  toDate,
} from "../../utils/date";
import i18next from "i18next";
import DropDown from "../../components/DropDown";

function calculateSpeed(startTime, endTime, loadedBits) {
  var range = endTime.getTime() - startTime.getTime();
  let timeElapsedInSeconds = range <= 0 ? 1000 : range / 1000;
  let speed = loadedBits / timeElapsedInSeconds;
  return speed;
}

export default function Internet() {
  const [sessions, setSessions] = useState();
  const [online, setOnline] = useState();
  const [chartData, setChartData] = useState();
  const [chartType, setChartType] = useState(0);
  const [sessionResponse, setSessionResponse] = useState(0);

  const { user, online: globalOnline } = useSelector((state) => state);

  async function fetchData(_f, _s) {
    var firstMonthDate = _f || null;
    var secondMonthDate = _s || null;

    let temp = await getSessions(firstMonthDate, secondMonthDate);
    temp = Array.isArray(temp) ? temp : [];
    setSessionResponse(temp);

    const modified = temp.map((t) => {
      delete t.cid;
      delete t.durationSec;
      delete t.end;
      delete t.acctSessionId;
      delete t.tpId;
      delete t.uid;
      const times = t.duration.split(":");
      const day = Math.floor(times[0] / 24);
      const time = `${times[0] - day * 24}:${times[1]}:${times[2]}`;

      return [
        t.start,
        `+ ${day} ${time}`,
        formatBytes(t.sent),
        formatBytes(t.recv),
        t.ip,
        t.tpName,
        t.sum,
      ];
    });
    modified.reverse();
    setSessions(modified);

    const tempOnline = globalOnline?.length ? globalOnline : await getOnline();
    setOnline(
      tempOnline.map((t) => {
        var res = t;
        if (typeof t === "object") {
          delete t.acctSessionId;
          delete t.clientIpNum;
          delete t.nasId;
          delete t.uid;
          delete t.userName;

          const times = t.duration.split(":");
          const day = Math.floor(times[0] / 24);
          const time = `${times[0] - day * 24}:${times[1]}:${times[2]}`;
          const received = t.acctInputOctets;
          // (t.acctInputOctets * 8) / 300 / 1024 / 1024;
          const sent = t.acctOutputOctets;
          // (t.acctOutputOctets * 8) / 300 / 1024 / 1024;

          res = [
            t.clientIp,
            `+ ${day} ${time}`,
            formatBytes(received),
            formatBytes(sent),
          ];
        }
        return typeof res === "object"
          ? Object.values(res)
          : Array.isArray(res)
          ? res
          : [res];
      })
    );
  }
  useEffect(() => {
    fetchData();
  }, []);
  function handleFilter(filters) {
    fetchData(filters?.from_date, filters?.to_date);
  }
  async function fetchChartDetail() {
    try {
      var response = await getDetail();
    } catch (error) {
      //error catch
    }
    let data = response || [];
    if (!Array.isArray(data)) {
      setChartData({ data: {} });
      return;
    }

    switch (chartType) {
      case 0:
        data = data.filter((d) => {
          return checkTodayDate(new Date(d[0]));
        });
        data = data.reverse();
        data = data || [];
        break;
      case 1:
        data = data.filter((d) => isInCurrentWeek(new Date(d[0])));
        data = data.reverse();
        break;
      default:
        data = data.reverse();
        break;
    }
    data = data.map((d, i, arrayData) => {
      const modifIndex = arrayData.length === i + 1 ? i : i + 1;
      const temp = arrayData[modifIndex];

      const intervalDate =
        // 300 ??
        i >= data.length - 1
          ? 300 // 5 minutes
          : (new Date(temp[0]) - new Date(arrayData[modifIndex - 1][0])) / 1000;

      let speedIn = (temp[3] - arrayData[modifIndex - 1][3]) / intervalDate;
      let speedOut = (temp[4] - arrayData[modifIndex - 1][4]) / intervalDate;

      speedIn = speedIn <= 0 ? 0 : (speedIn * 8) / 1024 / 1024;
      speedOut = speedOut <= 0 ? 0 : (speedOut * 8) / 1024 / 1024;

      return [d[0], d[1], d[2], speedIn, speedOut];
    });

    setChartData({ data });
  }

  useEffect(() => {
    setChartData(null);
    fetchChartDetail();
  }, [chartType, i18next.language]);

  return (
    <div className="container">
      <div className="internet-body">
        <h1>{translatedText("Detailing1")}</h1>
        <div className="online-row">
          <div
            className={
              !online || !online?.length ? "offline-icon" : "online-icon"
            }
          >
            <div></div>
          </div>
          <p className="online-text">
            {!online || !online?.length ? "Offline" : "Online"}
          </p>
        </div>
        <Table
          style={{ marginTop: 30 }}
          headers={[
            "IP",
            translatedText("Duration"),
            translatedText("Received"),
            translatedText("Sent"),
          ]}
          data={online}
          loading={!online}
          showPagination={false}
        >
          {(item, _, column) =>
            column > 1 ? (
              <div className="table-item">
                <img
                  src={require(`../../assets/images/svg/${
                    column == 2 ? "ArrowDown.svg" : "ArrowUp.svg"
                  }`)}
                />
                {item}
              </div>
            ) : (
              item
            )
          }
        </Table>

        <h1 style={{ marginTop: 30 }}>{translatedText("Statistics speed")}</h1>
        <div style={{ marginTop: 30 }} className="col g-2">
          <div className="row between g-2">
            <DropDown
              onChangeItem={(item, i) => setChartType(i)}
              items={[
                translatedText("Day"),
                translatedText("Week"),
                translatedText("All"),
              ]}
            />
            <button
              onClick={async () => {
                setChartData({ data: [] });
                fetchChartDetail();
              }}
              style={{
                padding: "10px 40px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 10,
                width: "auto",
              }}
              className="secondary-btn"
            >
              <img src={reset} alt="icon" />
              {translatedText("Reset")}
            </button>
          </div>
          <HighchartsComponent data={chartData?.data} />
        </div>

        <SumTable response={sessionResponse} handleFilter={handleFilter} />

        <h1 style={{ marginTop: 50 }}>{translatedText("Detail statistics")}</h1>
        <Table
          style={{ marginTop: 30, overflow: "auto" }}
          theadStyle={{ fontSize: 16 }}
          limit={100}
          headers={[
            translatedText("Session start"),
            translatedText("Duration"),
            translatedText("Sent"),
            translatedText("Received"),
            translatedText("IP"),
            translatedText("Tariff plan"),
            translatedText("Sum"),
          ]}
          data={sessions}
          loading={!sessions}
        />
      </div>
    </div>
  );
}
