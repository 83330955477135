import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import React, { useEffect, useRef, useState } from "react";
import { translatedText } from "../services/translatedText";
import { displayChartDate } from "../utils/date";
import { formatBytes } from "../utils/parser";

// function calculateSpeed(startTime, endTime, loadedBits) {
//   var range = endTime.getTime() - startTime.getTime();
//   let timeElapsedInSeconds = range <= 0 ? 1000 : range / 1000;
//   let speed = loadedBits / timeElapsedInSeconds;
//   return speed;
// }
export default function HighchartsComponent({ data }) {
  const [chartOptions, setChartOptions] = useState();
  const chartComponent = useRef();

  const resetZoom = () => {
    if (chartComponent.current.chart) {
      chartComponent.current.chart.xAxis[0].setExtremes();
      chartComponent.current.chart.yAxis[0].setExtremes();
    }
  };

  useEffect(() => {
    if (!Array.isArray(data)) return;
    resetZoom();

    const chartOP = {
      chart: {
        zoomType: "x",
        events: {
          render: function () {
            if (!this.resetZoomButton) return;
            this.resetZoomButton.hide();
            //this.resetZoomButton.destroy();
          },
        },
      },
      tooltip: {
        formatter: function () {
          var dt = new Date(this.x);
          const speed = this.y;

          let resultSpeed = speed * 1024 * 1024;
          return `${displayChartDate(
            dt.toLocaleString("ru")
          )}<br><br><b>${translatedText("Speed")}:</b> ${formatBytes(
            resultSpeed,
            2,
            false,
            true
          )} `;
        },
      },
      title: {
        text: "",
      },
      xAxis: {
        type: "datetime",
        labels: {
          format: "{value:%Y-%m-%d}",
        },
        title: {
          text: "",
        },
      },
      yAxis: {
        title: {
          text: translatedText("Speed mbit/s"),
        },
      },
      series: [
        {
          color: "#f35459",
          fillColor: "#f354597d",
          type: "area",
          name: translatedText("Received"),
          data: data.map((item) => [new Date(item[0]).getTime(), item[4]]),
        },
        {
          color: "#43b70d",
          fillColor: "#abff83b3",
          type: "area",
          name: translatedText("Sent"),
          data: data.map((item) => [new Date(item[0]).getTime(), item[3]]),
        },
      ],
    };

    setChartOptions(chartOP);
  }, [data]);

  // useEffect(() => {
  //   Highcharts.setOptions({
  //     lang: {
  //       resetZoom: "ZOOM OUT",
  //     },
  //   });
  // }, []);

  return (
    <HighchartsReact
      ref={chartComponent}
      highcharts={Highcharts}
      options={chartOptions}
    />
  );
}
