const setUser = (value) => {
  return {
    type: 'SET_USER',
    payload: value,
  }
}

const setPi = (value) => {
  return {
    type: 'SET_PI',
    payload: value,
  }
}
const setServices = (value) => {
  return {
    type: 'SET_SERVICES',
    payload: value,
  }
}

const setTariff = (value) => {
  return {
    type: 'SET_TARIFF',
    payload: value,
  }
}

const setTariffs = (value) => {
  return {
    type: 'SET_TARIFFS',
    payload: value,
  }
}

const setManager = (value) => {
  return {
    type: 'SET_MANAGER',
    payload: value,
  }
}

const setContacts = (value) => {
  return {
    type: 'SET_CONTACTS',
    payload: value,
  }
}

const setOnline = (value) => {
  return {
    type: 'SET_ONLINE',
    payload: value,
  }
}

const setExtraServices = (value) => {
  return {
    type: 'SET_EXTRA_SERVICES',
    payload: value,
  }
}
export default {
  setUser,
  setPi,
  setTariff,
  setTariffs,
  setContacts,
  setServices,
  setManager,
  setOnline,
  setExtraServices,
}
