import extraServices from '../../staticData/extraServices'

const states = {}

const userReducer = (state = states, action) => {
  switch (action.type) {
    case 'SET_USER':
      return (state = action.payload)
    default:
      return state
  }
}
const piReducer = (state = {}, action) => {
  switch (action.type) {
    case 'SET_PI':
      return (state = action.payload)
    default:
      return state
  }
}

const servicesReducer = (state = {}, action) => {
  switch (action.type) {
    case 'SET_SERVICES':
      return (state = action.payload)
    default:
      return state
  }
}

const tariffReducer = (state = {}, action) => {
  switch (action.type) {
    case 'SET_TARIFF':
      return (state = action.payload)
    default:
      return state
  }
}
const tariffsReducer = (state = {}, action) => {
  switch (action.type) {
    case 'SET_TARIFFS':
      return (state = action.payload)
    default:
      return state
  }
}

const managerReducer = (state = {}, action) => {
  switch (action.type) {
    case 'SET_MANAGER':
      return (state = action.payload)
    default:
      return state
  }
}

const contactsReducer = (state = {}, action) => {
  switch (action.type) {
    case 'SET_CONTACTS':
      return (state = action.payload)
    default:
      return state
  }
}

const onlineReducer = (state = {}, action) => {
  switch (action.type) {
    case 'SET_ONLINE':
      return (state = action.payload)
    default:
      return state
  }
}

const extraServiceState = {
  items: Object.values(extraServices),
}

const extraServicesReducer = (state = extraServiceState, action) => {
  switch (action.type) {
    case 'SET_EXTRA_SERVICES':
      state.items = [...action.payload]
      return state

    default:
      return state
  }
}

export {
  userReducer,
  piReducer,
  tariffReducer,
  contactsReducer,
  servicesReducer,
  tariffsReducer,
  managerReducer,
  onlineReducer,
  extraServicesReducer,
}
