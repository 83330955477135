import { t } from "i18next";

export const translatedText = (text, params = null) => {
  if (Array.isArray(text)) {
    let result = "";
    for (let i = 0; i < text.length; i++) {
      const temp = text[i];
      result += t(temp);
      result += " ";
    }
    return result;
  }

  return t(text, params);
};
