import React from 'react'
import { useState } from 'react'
import FormModal from '../../components/FormModal'
import Loader from '../../components/Loader'
import { changeTariff } from '../../services/http/tariffs'
import { translatedText } from '../../services/translatedText'
import errorIcon from '../../assets/images/svg/error.svg'
import CheckboxGroup from '../../components/CheckboxGroup'
import { addToMonth, getCurrentMonthDate } from '../../utils/date'
import { useNavigate } from 'react-router'
import useWindowSize from '../../hooks/useWindowSize'
import i18n from '../../services/i18n/i18n'

const FreezingServiceModal = ({ handleClose, open, tariff, globalTariff, resetTariff, service }) => {
  const navigate = useNavigate()
  const [period, setPeriod] = useState(null)
  const lang = i18n.language
  const [msg, setMsg] = useState({
    msg: '',
  })

  const [loader, setLoader] = useState(false)
  const { width } = useWindowSize()
  const currentTariff = tariff

  const apply = async () => {
    if (!currentTariff) return
    setLoader(true)
    // eslint-disable-next-line no-undef
    await new Promise((resolve) => setTimeout(resolve, 300))
    const res = await changeTariff(611, period, globalTariff.id)
    if (res?.success == 1) {
      setMsg({ msg: translatedText('Successfully completed') })
      if (period === 0 && resetTariff) {
        resetTariff()
      }
    } else {
      const msg = res?.message || ''
      if (res.error == 15 || msg.includes('ERR_SMALL_DEPOSIT'))
        setMsg({
          msg: translatedText('ERR_SMALL_DEPOSIT'),
          error: res?.error,
        })
      else if (res.error == 140 || msg.includes('NOT_ALLOWED_TO_CHANGE_TP'))
        setMsg({
          msg: translatedText('Not allowed change tariff'),
          error: res?.error,
        })
      else
        setMsg({
          msg: translatedText('Unknown error'),
          error: res?.error,
        })
    }

    setLoader(false)
  }

  const closeModal = () => {
    setPeriod(null)
    setMsg({ msg: '' })
    handleClose()
  }

  const isMSG = msg.msg || loader

  return (
    <FormModal
      open={open}
      onClose={closeModal}
      style={isMSG ? {} : { gap: '10px', margin: '0 20px' }}
      modalStyle={{ maxWidth: '480px', width: '100%' }}
    >
      {globalTariff.tpId === 611 ? (
        <div className={'col g-2 a-center' + (msg.msg ? ' frame p-1' : '')}>
          {width > 450 ? (
            <svg width="77" height="77" viewBox="0 0 77 77" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M71.5 38.5C71.5 56.7254 56.7254 71.5 38.5 71.5C20.2746 71.5 5.5 56.7254 5.5 38.5C5.5 20.2746 20.2746 5.5 38.5 5.5C56.7254 5.5 71.5 20.2746 71.5 38.5ZM77 38.5C77 59.763 59.763 77 38.5 77C17.237 77 0 59.763 0 38.5C0 17.237 17.237 0 38.5 0C59.763 0 77 17.237 77 38.5ZM41.072 49.7859C41.5575 49.4339 41.8003 49.0159 41.8003 48.5319V17.1819C41.8003 16.6979 41.5575 16.2799 41.072 15.9279C40.5864 15.5759 40.0098 15.3999 39.3421 15.3999H37.6594C36.9917 15.3999 36.4151 15.5759 35.9295 15.9279C35.4439 16.2799 35.2011 16.6979 35.2011 17.1819V48.5319C35.2011 49.0159 35.4439 49.4339 35.9295 49.7859C36.4151 50.1379 36.9917 50.3139 37.6594 50.3139H39.3421C40.0098 50.3139 40.5864 50.1379 41.072 49.7859ZM41.072 61.0719C41.5575 60.7199 41.8003 60.3019 41.8003 59.8179V56.0559C41.8003 55.5719 41.5575 55.1539 41.072 54.8019C40.5864 54.4499 40.0098 54.2739 39.3421 54.2739H37.6586C36.9909 54.2739 36.4143 54.4499 35.9287 54.8019C35.4431 55.1539 35.2003 55.5719 35.2003 56.0559V59.8179C35.2003 60.3019 35.4431 60.7199 35.9287 61.0719C36.4143 61.4239 36.9909 61.5999 37.6586 61.5999H39.3421C40.0098 61.5999 40.5864 61.4239 41.072 61.0719Z"
                fill="#F35459"
              />
            </svg>
          ) : null}

          <p style={{ textAlign: 'center', fonstFamily: 'Rubik', fontSize: '16px', lineHeight: '24px' }}>
            {translatedText('ForDeactivateTarif.first')}
            <span style={{ color: '#F35459', fontWeight: 'bold' }}>
              “{lang === 'ru' ? service.title_ru : service.title_uz}”
            </span>
            {translatedText('ForDeactivateTarif.second')}
            {lang === 'ru' ? (
              <span style={{ color: '#F35459', fontWeight: 'bold' }}>
                “{lang === 'ru' ? service.title_ru : service.title_uz}”
              </span>
            ) : null}
            <br />
          </p>
        </div>
      ) : (
        <>
          <div className={'col g-2 a-center' + (msg.msg ? ' frame p-1' : '')}>
            {msg.msg ? (
              <h2 style={{ color: msg?.error ? 'var(--primary-color)' : null }} className="succes-text">
                {msg.msg}
              </h2>
            ) : loader ? (
              <Loader center />
            ) : (
              <React.Fragment>
                {width > 450 ? (
                  <svg width="77" height="77" viewBox="0 0 77 77" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M71.5 38.5C71.5 56.7254 56.7254 71.5 38.5 71.5C20.2746 71.5 5.5 56.7254 5.5 38.5C5.5 20.2746 20.2746 5.5 38.5 5.5C56.7254 5.5 71.5 20.2746 71.5 38.5ZM77 38.5C77 59.763 59.763 77 38.5 77C17.237 77 0 59.763 0 38.5C0 17.237 17.237 0 38.5 0C59.763 0 77 17.237 77 38.5ZM41.072 49.7859C41.5575 49.4339 41.8003 49.0159 41.8003 48.5319V17.1819C41.8003 16.6979 41.5575 16.2799 41.072 15.9279C40.5864 15.5759 40.0098 15.3999 39.3421 15.3999H37.6594C36.9917 15.3999 36.4151 15.5759 35.9295 15.9279C35.4439 16.2799 35.2011 16.6979 35.2011 17.1819V48.5319C35.2011 49.0159 35.4439 49.4339 35.9295 49.7859C36.4151 50.1379 36.9917 50.3139 37.6594 50.3139H39.3421C40.0098 50.3139 40.5864 50.1379 41.072 49.7859ZM41.072 61.0719C41.5575 60.7199 41.8003 60.3019 41.8003 59.8179V56.0559C41.8003 55.5719 41.5575 55.1539 41.072 54.8019C40.5864 54.4499 40.0098 54.2739 39.3421 54.2739H37.6586C36.9909 54.2739 36.4143 54.4499 35.9287 54.8019C35.4431 55.1539 35.2003 55.5719 35.2003 56.0559V59.8179C35.2003 60.3019 35.4431 60.7199 35.9287 61.0719C36.4143 61.4239 36.9909 61.5999 37.6586 61.5999H39.3421C40.0098 61.5999 40.5864 61.4239 41.072 61.0719Z"
                      fill="#F35459"
                    />
                  </svg>
                ) : null}

                <div style={{ display: 'flex', flexDirection: 'column', gap: '13px' }}>
                  <p style={{ textAlign: 'center', fontFamily: 'Rubik', fontSize: '18px', lineHeight: '24px' }}>
                    {translatedText('ConfirmServiceActivation')}
                    <br />
                    <span style={{ color: '#F35459', fontWeight: 'bold' }}>
                      “{lang === 'ru' ? service.title_ru : service.title_uz}”
                    </span>
                  </p>

                  <p
                    style={{
                      textAlign: 'center',
                      fontFamily: 'Rubik',
                      fontSize: '18px',
                      lineHeight: '24px',
                      color: '#878787',
                    }}
                  >
                    {translatedText('AccountWillBeDebitedMonthly')}
                  </p>

                  <div
                    style={{
                      fontFamily: 'Rubik',
                      fontSize: '26px',
                      textAlign: 'center',
                      fontWeight: '500',
                    }}
                  >
                    {new Intl.NumberFormat('ru-RU').format(service.full.price)}{' '}
                    {lang === 'ru' ? service.full?.currency_ru : service.full?.currency_uz}
                  </div>
                </div>

                <CheckboxGroup
                  onChange={(data) => {
                    setPeriod(data[0] ? 0 : data[1] ? 1 : null)
                  }}
                  data={[
                    translatedText('Immediately'),
                    translatedText('Next accounting period', {
                      date:
                        globalTariff?.internetActivate === '0000-00-00'
                          ? getCurrentMonthDate(true, 1)
                          : addToMonth(globalTariff?.internetActivate, -1),
                    }),
                  ]}
                ></CheckboxGroup>
              </React.Fragment>
            )}
          </div>
        </>
      )}

      {globalTariff.tpId === 611 ? (
        <button
          style={{ width: '100%', marginTop: isMSG ? 0 : 20, minWidth: 300 }}
          className="primary-btn light"
          onClick={() => navigate('/tariffs-plan')}
        >
          {translatedText('ChooseTarif')}
        </button>
      ) : (
        <button
          disabled={loader || (!msg.msg && period === null)}
          style={{ width: '100%', marginTop: isMSG ? 0 : 20, minWidth: 300 }}
          className="primary-btn light"
          onClick={msg.msg ? closeModal : apply}
        >
          {msg.msg ? translatedText('Close') : translatedText('Connect')}
        </button>
      )}
      {!msg.msg && !loader && (
        <button style={{ width: '100%' }} className="secondary-btn light" onClick={closeModal}>
          {translatedText('Close')}
        </button>
      )}
    </FormModal>
  )
}

export default FreezingServiceModal
