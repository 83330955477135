import React from "react";
import css from "./style.module.css";
import PropTypes from "prop-types";
import { useState, useEffect } from "react";

export default function Radio({
  text,
  onChange,
  defaultValue = false,
  currentValue,
  isOff = false,
  disabled = false,
  keyValue,
  style,
}) {
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue, disabled]);

  useEffect(() => {
    if (currentValue === undefined) return;
    setValue(currentValue);
  }, [currentValue]);

  return (
    <div
      style={{
        pointerEvents: disabled ? "none" : "auto",
        color: disabled ? "#A5A5A5" : "black",
        ...style,
      }}
      onClick={() => {
        if (!isOff && value) return;
        if (onChange) onChange({ text, value: !value, keyValue });
        setValue(!value);
      }}
      className={css.wrap}
    >
      <div
        className={[
          css.radio,
          disabled ? css.disabled : "",
          value ? css.selected : "",
        ].join(" ")}
      ></div>
      {text}
    </div>
  );
}
Radio.propTypes = {
  text: PropTypes.any,
  onChange: PropTypes.func,
  defaultValue: PropTypes.bool,
  currentValue: PropTypes.bool,
  disabled: PropTypes.bool,
  isOff: PropTypes.bool,
  style: PropTypes.object,
};
