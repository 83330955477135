import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { useCheckPhone } from '../../hooks/useCheckPhone'
import { translatedText } from '../../services/translatedText'
import areEqual from '../../utils/areEqual'
import { addToMonth, getCurrentDate } from '../../utils/date'
import { convertToCurrency } from '../../utils/parser'
import CircularChart2 from '../CircularChart2'
import MainBlock from '../MainBlock'
import css from './style.module.scss'
import FreezingOne from '../../assets/images/svg/staticExtraServices/FreezingOne.svg'
import TariffSpeed40 from '../../assets/images/svg/TariffSpeed40.svg'
import TariffSpeed60 from '../../assets/images/svg/TariffSpeed60.svg'
import TariffSpeed100 from '../../assets/images/svg/TariffSpeed100.svg'
import TariffSpeed200 from '../../assets/images/svg/TariffSpeed200.svg'
import TariffBonus from '../../assets/images/svg/TariffBonus.svg'
import AmediatekaCinema from '../../assets/images/svg/Amediateka.svg'

function splitComments(plan, nameTariff) {
  function getReg(r, overlimit = false, multiSpeed = false, multiTraffic = false, multiLimit = false) {
    let res = plan.match(r) || [null, null, null, null]
    if (multiSpeed) {
      const its = res
      res = []
      for (let i = 0; i < its.length; i++) {
        const it = its[i]
        if (!it) continue
        const result = it.match(/СкоростьINTERNET(\S+)-(\S+)-(\d+)(\S+)\/с/)
        res.push([result[1], result[2], result[3], result[4] === 'Мбит' ? 'Mbps' : 'Kbps'])
      }
    }
    if (multiTraffic) {
      if (!res[0]) return []
      const its = res[0].split('|')
      res = []
      for (let i = 0; i < its.length; i++) {
        const it = its[i]
        if (!it) continue
        let result = it.match(/Оплаченныйтрафикс(\S+)до(\S+):(\d+)М/)
        if (!result) {
          result = it.match(/Оплаченныйтрафикс(\S+)до(\S+):(\S+)/)
          if (result) result['3'] = 'unlim'
        }

        if (result) res.push([result[1], result[2], result[3]])
      }
    }
    if (multiLimit) {
      const its = res
      res = []
      for (let i = 0; i < its.length; i++) {
        const it = its[i]
        if (!it) continue
        const result = it.match(/1МБсверхлимита(\S+?)-(\S+?):(\d+)сум/)
        res.push([result[1], result[2], result[3]])
      }
    }

    return overlimit
      ? [parseInt(res[1] || 0), parseInt(res[2] || 0)]
      : multiLimit || multiSpeed || multiTraffic
      ? res
      : parseInt((res[1] || '').split('|')[0].replace(/\D/g, '') || 0)
  }

  function findUnlim(str, _plan = null) {
    return (_plan || '').toLowerCase().includes(str)
  }

  const regexSum = /Абонентскаяплата:(\d+)сум/
  const regexTimeSpeed = /СкоростьINTERNET(\S+?)-(\S+?)-(\d+)(\S+?)\/с/g

  const regexPaidMBTime = /Оплаченныйтрафикс(\S+?)до(\S+?):(\S+)\|/g

  const regexTasIX = /СкоростьTas-IX:(\S+)Мбит\/с/
  // const regexTasIX = /СкоростьTas-IX:до10Мбит\/с/;
  const regexPaidMB = /Оплаченныйтрафик:(\d+)М/
  const regexOverlimit = /(\d+)МБсверхлимита:(\d+)сум/
  const regexOverlimitWithTimes = /1МБсверхлимита(\S+?)-(\S+?):(\d+)сум/g

  const isSpeedUnlim = findUnlim('скоростьinternet00:00-24:00-unlim')
  const isTasIxUnlim = findUnlim('скоростьtas-ix:unlim')

  const isTasIx = findUnlim('tas-ix', nameTariff)

  let matches = [
    getReg(regexSum),
    getReg(regexTimeSpeed, false, true),
    getReg(regexPaidMBTime, false, false, true),
    getReg(regexPaidMB),
    isTasIxUnlim ? 'Unlim' : getReg(regexTasIX),
    getReg(regexOverlimit, true),
    getReg(regexOverlimitWithTimes, false, false, false, true),
  ]

  if (matches) {
    const [sum, timeSpeed, paidMBTimes, paidMB, tasIX, overlimit, overlimitTimes] = matches
    return {
      sum,
      tasIX,
      timeSpeed: isSpeedUnlim ? [['00:00', '24:00', 'Unlim', 'no']] : isTasIx ? [] : timeSpeed,
      paidMBTimes,
      paidMB,
      overlimitTimes: overlimit[0] ? [[overlimit[0], null, overlimit[1]]] : overlimitTimes,
    }
  } else {
    return null
  }
}

// company_id

function TariffCard({ user, hideChangeButton = false, tariff, history, current, page, recommended, onClickConnect }) {
  const noData = translatedText('No data')
  const isPhone = useCheckPhone()
  const [data, setData] = useState()
  const [tariffAbout, setTariffAbout] = useState({
    speed: 0,
    img: '',
    tvChannels: 0,
  })

  const isLegal = user?.companyId > 0 || user?.gid === 4

  const validTariffs = {
    632: 632,
    633: 633,
    634: 634,
  }

  useEffect(() => {
    if (!tariff?.tpComments && !tariff?.comments) return

    const tariffComment = (tariff?.tpComments || tariff.comments || '').replaceAll(' ', '')

    const result = splitComments(tariffComment, tariff.name)

    setData({
      price: tariff?.personalTp || tariff?.monthFee || result.sum || 0,
      ...result,
    })

    const speedImg = {
      40: TariffSpeed40,
      100: TariffSpeed100,
      200: TariffSpeed200,
    }

    const tariffChanels = {
      40: 90,
      100: 180,
      200: 180,
    }

    setTariffAbout({
      speed: result.timeSpeed[0][2],
      img: speedImg[result.timeSpeed[0][2]],
      tvChannels: tariffChanels[result.timeSpeed[0][2]],
    })
  }, [tariff])

  const Content = () => (
    <div className="col g-3 full">
      {data?.timeSpeed?.length
        ? data.timeSpeed.map((tspeed, i) => (
            <div className="between-cab" key={i}>
              <p>
                {tspeed[0] === '00:00' && tspeed[1] === '24:00'
                  ? translatedText('Speed to the world')
                  : translatedText('Speed to the world from time to time', {
                      time1: tspeed[0],
                      time2: tspeed[1],
                    })}
              </p>
              {tspeed[2] === 'Unlim' ? (
                <p>{translatedText('Unlimited')}</p>
              ) : (
                <p>
                  {tspeed[2]} {translatedText(tspeed[3])}*
                </p>
              )}
            </div>
          ))
        : ''}
      <div className="between-cab">
        <p>{translatedText('Speed in TAS-IX: Around the clock')}:</p>
        {data ? (
          data.tasIX === 'Unlim' ? (
            <p>{translatedText('Unlimited')}</p>
          ) : (
            <p>
              {data.tasIX} {translatedText('Mbps')}*
            </p>
          )
        ) : (
          <p>{noData}</p>
        )}
      </div>

      {data?.paidMBTimes?.length ? (
        data.paidMBTimes.map((mbTime, i) => (
          <div className="between-cab" key={i}>
            <p>
              {translatedText('Traffic from time to time', {
                time1: mbTime[0],
                time2: mbTime[1],
              })}
            </p>
            <p>
              {mbTime[2] === 'unlim'
                ? translatedText('Unlimited')
                : convertToCurrency(mbTime[2]) + ' ' + translatedText('MB')}
            </p>
          </div>
        ))
      ) : (
        <div className="between-cab">
          <p>{translatedText('Traffic')}</p>
          <p>{data?.paidMB ? data.paidMB + ' ' + translatedText('MB') : translatedText('Unlimited')}</p>
        </div>
      )}

      {!!data?.overlimitTimes?.length &&
        data.overlimitTimes.map((limit, i) => (
          <div className="between-cab" key={i}>
            <p>
              {limit[1]
                ? translatedText('Overlimit times', {
                    time1: limit[0],
                    time2: limit[1],
                  })
                : translatedText('Overlimit', { amount: limit[0] })}
            </p>
            <p>
              {limit[2]} {translatedText('sum')}
            </p>
          </div>
        ))}

      <div style={page ? { marginTop: 0, marginBottom: 30 } : {}} className="between-cab">
        <p>{translatedText('Subscription fee:')}</p>
        <p style={page ? { fontSize: isPhone ? 30 : 40 } : {}} className={'red-text' + (page ? '' : ' bold')}>
          {convertToCurrency(data?.price)} {translatedText('sum')}
        </p>
      </div>
      {current && (
        <div className="between-cab">
          <p>{translatedText('Date of next write-off')}:</p>
          <p>
            {tariff?.internetActivate === '0000-00-00' ? getCurrentDate(true, 1) : addToMonth(tariff?.internetActivate)}
          </p>
        </div>
      )}
    </div>
  )

  const Controls = () => (
    <div style={{ marginTop: isPhone ? 10 : 30 }} className="row-m-r j-center g-2">
      <button
        onClick={() => history && history('/tariffs-plan/' + tariff.tpId, { state: tariff })}
        className="secondary-btn light full"
      >
        {translatedText('More')}
      </button>

      {!hideChangeButton && (
        <button
          onClick={() => {
            if (!current) {
              return onClickConnect && onClickConnect()
            }

            return history && history('/tariffs-plan')
          }}
          className="primary-btn light full"
        >
          {translatedText(current ? 'Change' : 'Connect')}
        </button>
      )}
    </div>
  )
  return (
    <div className="row block">
      {validTariffs[tariff?.tpId] ? (
        <MainBlock
          containerStyle={{ flex: 4 }}
          style={{
            height: '100%',
            flexDirection: page && !isPhone ? 'row' : 'column',
            justifyContent: 'space-between',
            paddingTop: isPhone ? 20 : 40,
            position: 'relative',
          }}
        >
          {!page && <h3>{tariff?.tpName || tariff?.name}</h3>}

          {recommended && (
            <div className={css.recommended}>
              <span className={css.text}>{translatedText('MostSaled')}</span>
            </div>
          )}

          <div
            style={{
              marginRight: page ? 30 : 0,
              height: '100%',
            }}
            className="j-center a-center"
          >
            {tariff?.tpName === 'Заморозка' ? (
              <img src={FreezingOne} alt="" style={{ width: '100%', maxWidth: '150px' }} />
            ) : (
              <>
                {data ? (
                  <div className={css.tariff_left_block_wrap}>
                    <div className={css.tatiff_block_top}>
                      <img src={tariffAbout.img} alt="" />
                      <div className={css.tatiff_block_info}>
                        <h2>{tariffAbout.speed} Мбит/с</h2>
                        <span>Интернет</span>
                      </div>
                    </div>

                    <div className={css.tariff_divider} />

                    <div className={css.tatiff_block_bottom}>
                      <img src={TariffBonus} alt="" />
                      <div className={css.tatiff_block_info}>
                        <h2>{tariffAbout.tvChannels}</h2>

                        {tariff.id === 2281 ? (
                          <div>
                            Интерактивных ТВ каналов, Фильмы и сериалы от
                            <img style={{ marginTop: '5px' }} src={AmediatekaCinema} alt="" />
                          </div>
                        ) : (
                          <span>Интерактивных ТВ каналов </span>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <p style={{ fontSize: 20 }}>{noData}</p>
                )}
              </>
            )}
          </div>

          {(page || isPhone) && <Content />}
          {isPhone && !page && <Controls />}
        </MainBlock>
      ) : (
        <MainBlock
          containerStyle={{ flex: 4 }}
          style={{
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {!page && <h3 style={{ fontSize: '21px',wordBreak: "break-word" }}>{tariff?.tpName || tariff?.name}</h3>}

          {(page || isPhone) && <Content />}
          {isPhone && !page && <Controls />}
        </MainBlock>
      )}

      {!page && !isPhone && (
        <MainBlock
          containerStyle={{ width: '100%', flex: 7 }}
          style={{
            height: '100%',
            justifyContent: 'space-between',
            paddingTop: 40,
            paddingBottom: 40,
          }}
        >
          <Content />
          {!isLegal && <Controls />}
        </MainBlock>
      )}
    </div>
  )
}

export default React.memo(withTranslation()(TariffCard), areEqual)
