import React from "react";
import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useRef } from "react";
import css from "./style.module.scss";
import { useCheckPhone } from "../../hooks/useCheckPhone";
import { CSSTransition } from "react-transition-group";

export default function DropDownBtn({ label, children }) {
  const [showPicker, setShowPicker] = useState(false);

  return (
    <div className={css.wrap}>
      <button
        onClick={(e) => {
          e.stopPropagation();
          setShowPicker(true);
        }}
        className="primary-btn light"
      >
        {label}
      </button>

      <AnimatePresence>
        {showPicker && (
          <DropDownContent
            showPicker={showPicker}
            onHide={() => setShowPicker(false)}
          >
            {children(() => setShowPicker(false))}
          </DropDownContent>
        )}
      </AnimatePresence>
    </div>
  );
}

const DropDownContent = React.memo(function DatePicker({
  showPicker,
  onHide,
  children,
}) {
  const parent = useRef();
  const triangle = useRef();
  const isPhone = useCheckPhone();

  function hide(e) {
    e.preventDefault();
    onHide();
  }

  return (
    <React.Fragment>
      {showPicker && <div onClick={hide} className={css.bg}></div>}
      {!isPhone ? (
        <motion.div
          style={{ position: "absolute", right: 0 }}
          initial={{
            scale: 0,
            transition: {
              type: "spring",
              bounce: 0,
              duration: 0.3,
            },
          }}
          exit={{
            scale: 0,
            transition: {
              type: "spring",
              bounce: 0,
              duration: 0.3,
            },
          }}
          animate={{
            scale: 1,
            zIndex: 2,
            transition: {
              type: "spring",
              bounce: 0.3,
              duration: 0.5,
              // delayChildren: 0.3,
              // staggerChildren: 0.05,
            },
          }}
        >
          <div ref={parent} className={css.content}>
            <div ref={triangle} className={css.triangle}></div>
            {children}
          </div>
        </motion.div>
      ) : (
        <CSSTransition in appear timeout={450} classNames="toUpDropDown">
          <div ref={parent} className={css.content}>
            {children}
          </div>
        </CSSTransition>
      )}
    </React.Fragment>
  );
});
