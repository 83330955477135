import PropTypes from "prop-types";
import React from "react";
import { translatedText } from "../../services/translatedText";
import exit from "../../assets/images/svg/exit.svg";
import profile from "../../assets/images/svg/profile.svg";
import legalProfile from "../../assets/images/svg/LegalProfile.svg";
import { useState, useEffect } from "react";
import { getUserAllInfo } from "../../services/http/userAPI";
import classes from "./style.module.css";
import useActions from "../../hooks/useActions";
import { useAuth } from "../../services/authentication";
import { useNavigate } from "react-router-dom";
import { withTranslation } from "react-i18next";

const Profile = ({ isMobile, cabinet, legal }) => {
  const [pi, setPi] = useState({ fio: "" });
  const [actions, { pi: storePi }] = useActions();
  const auth = useAuth();
  const history = useNavigate();

  useEffect(() => {
    if (storePi?.fio) {
      setPi(storePi);
      return;
    }
    if (cabinet) return;
    async function fetchData() {
      if (!auth.sid) return;
      const response = await getUserAllInfo();
      setPi(response);
      actions.setPi(response);
    }
    fetchData();
  }, [storePi]);

  // useEffect(() => {
  //   if (!pi.uid || cabinet) return;
  //   async function fetchData() {
  //     const response = await getUserAllInfo();
  //     setPi(response);
  //     actions.setPi(response);
  //   }
  //   fetchData();
  // }, [user]);

  let name = pi.fio;

  const clickLogout = async () => {
    await auth.signout(() => {
      history("/login");
    });
  };
  return (
    <div
      className={[
        isMobile ? classes.profile : "profile",
        cabinet ? classes.cabinet : "",
      ].join(" ")}
    >
      {isMobile ? (
        <div style={{ width: "auto" }} className="col">
          <div onClick={() => history("/")} className={classes.primary}>
            <img src={legal ? legalProfile : profile} alt="profile" />
          </div>
          <h2 className="regular">{name}</h2>
          {!cabinet && (
            <button onClick={clickLogout} className="logout-btn">
              <img height={15} src={exit} alt="exit" />
              {translatedText("Exit")}
            </button>
          )}
        </div>
      ) : (
        <React.Fragment>
          <div style={{ width: "auto" }} className="col">
            {pi.fio && (
              <h2
                className="regular"
                style={cabinet ? {} : { fontSize: "100%" }}
              >
                {name}
              </h2>
            )}
            {!cabinet && (
              <button onClick={clickLogout} className="logout-btn">
                <img height={15} src={exit} alt="exit" />
                {translatedText("Exit")}
              </button>
            )}
          </div>
          {legal ? (
            <img className={classes.legal} src={legalProfile} alt="profile" />
          ) : (
            <div onClick={() => history("/")} className="primary">
              <img src={profile} alt="profile" />
            </div>
          )}
        </React.Fragment>
      )}
      {!cabinet && (
        <a
          style={{
            fontSize: 12,
            alignSelf: "center",
          }}
          target="_blank"
          rel="noopener noreferrer"
          href="https://cabinet2.comnet.uz/"
        >
          {translatedText("Old cabinet")}
        </a>
      )}
    </div>
  );
};
Profile.propTypes = {
  isMobile: PropTypes.bool,
};

export default React.memo(withTranslation()(Profile));
