import { useState, useEffect } from 'react';

export function useCheckPhone() {
  const [isLess, setIsLess] = useState(window.innerWidth <= 850);

  useEffect(() => {
    function handleResize() {
        setIsLess(window.innerWidth < 850);
    }

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isLess;
}
