import React from "react";
import CheckboxGroup from "../../../../components/CheckboxGroup";
import { translatedText } from "../../../../services/translatedText";

export default function Step2({ onPossible, onImpossible }) {
  const checkBoxData = () => [
    translatedText("High price tariffs"),
    translatedText("Private technical failures"),
    translatedText("Bad service"),
    translatedText("Moving"),
    translatedText("Other1"),
  ];

  return (
    <div className="step">
      <h1 className="step-title">
        {translatedText("Reason for disconnection")}
      </h1>
      <CheckboxGroup
        lowPriority
        style={{ maxHeight: 150 }}
        gap={25}
        onChangeWithData={(data) => {
          var result = data.filter((d) => d?.value === true)[0]?.data;
          if (result) onPossible && onPossible(result);
          else onImpossible && onImpossible();
        }}
        data={checkBoxData()}
      />
    </div>
  );
}
