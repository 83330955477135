import { Backdrop, makeStyles, Modal } from '@material-ui/core'
import React from 'react'
import PropTypes from 'prop-types'
import closeIcon from '../../assets/images/svg/close.svg'
import MainBlock from '../MainBlock'
import { motion } from 'framer-motion'
import { containerMotion } from '../../utils/motion'

const useStyles = makeStyles({
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
})

export default function FormModal({ windowWidth, onClose, open, type, modalStyle = {}, style = {}, children }) {
  const classes = useStyles()

  return (
    <Modal
      open={open}
      onClose={onClose}
      className={[classes.modal, type].join(' ')}
      closeAfterTransition
      BackdropComponent={Backdrop}
      style={{ zIndex: 100000 }}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <React.Fragment>
        {windowWidth > 450 ? (
          <div
            className="close-btn"
            style={{
              position: 'absolute',
              top: 20,
              right: 20,
              cursor: 'pointer',
            }}
            onClick={onClose}
          >
            <img src={closeIcon} alt="close-icon" style={{ width: 40 }} />
          </div>
        ) : null}

        <motion.div
          className="modal-div"
          variants={containerMotion}
          initial="hidden"
          animate="visible"
          style={modalStyle}
        >
          <MainBlock style={{ padding: 20, ...style }}>{children}</MainBlock>
        </motion.div>
      </React.Fragment>
    </Modal>
  )
}
FormModal.propTypes = {
  children: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  type: PropTypes.string,
}
