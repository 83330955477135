import React from "react";
import css from "./style.module.css";
import PropTypes from "prop-types";
import document from "../../assets/images/svg/document.svg";
import download from "../../assets/images/svg/download.svg";

export default function FileCard({ file, title }) {
  return (
    <div className={css.wrap}>
      <div className="row between">
        <img src={document} alt="document" />
        <div className={css.button}>
          <span>11,5 Mb</span>
          <img className={css.download} src={download} alt="download" />
        </div>
      </div>
      <p
        style={title && title.length > 50 ? { fontSize: 16 } : {}}
        className={css.title}
      >
        {title}
      </p>
    </div>
  );
}
FileCard.propTypes = {
  file: PropTypes.any,
  title: PropTypes.string,
};
