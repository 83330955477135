import React from "react";
import css from "./style.module.css";
import PropTypes from "prop-types";
import { useState } from "react";
import { useEffect } from "react";

export default function Checkmark({
  checked = false,
  onChange,
  name,
  children,
  readOnly,
  lowPriority,
  ...props
}) {
  const [inChecked, setInChecked] = useState(checked);
  const handeCheck = (e) => {
    onChange(e);
    if (readOnly) return;
    setInChecked(!inChecked);
  };
  useEffect(() => {
    setInChecked(checked);
  }, [checked]);

  return (
    <label className={css.wrap + (lowPriority ? " " + css.low : "")}>
      <input
        name={name}
        onClick={handeCheck}
        checked={inChecked}
        readOnly
        className={css.input}
        type="checkbox"
        {...props}
      />
      <span className={css.checkmark}></span>
      {children}
    </label>
  );
}
Checkmark.propTypes = {
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  name: PropTypes.string,
  children: PropTypes.any,
  readOnly: PropTypes.bool,
};
