import React, { useRef } from 'react'
import { useEffect } from 'react'
import { getMonthNames, toDate } from '../../utils/date'
import { translatedText } from '../../services/translatedText'
import css from './style.module.scss'
import { useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { GradientPath } from 'gradient-path'
import Loader from '../Loader'

export default function DiscountChart({ reduction, date, reductionDate,isPhone }) {
  const HEIGHT_SVG = 247
  const WIDTH_COUNT = 16
  const HEIGHT_COUNT = 6
  const [WIDTH_CELL, setWidthCell] = useState(44)
  const svgRef = useRef()
  const svgWrapper = useRef()
  const [months, setMonths] = useState([])
  const [paths, setPaths] = useState({})
  const [rendered, setRendered] = useState(false)
  const path = useRef()

  function getIndexReduction(reduction) {
    if (reduction > 15) return 5
    switch (reduction) {
      case 15:
        return 5
      case 12:
        return 4
      case 10:
        return 3
      case 5:
        return 2
      case 3:
        return 1
      default:
        return 0
    }
  }



  const getColor = (num) => {
    switch (num) {
      case 0:
        return '#FDE063'
      case 1:
        return '#F18E34'
      case 2:
        return '#F35459'
      case 3:
        return '#DA3E40'
      default:
        return '#F35459'
    }
  }

  var timeOut = null

  useEffect(() => {
    if (!svgRef.current) return

    function resized() {
      clearTimeout(timeOut)

      timeOut = setTimeout(() => {
        try {
          const size = svgRef.current.getBoundingClientRect()
          setWidthCell(size.width / WIDTH_COUNT)
        } catch (e) {
          //error catch
        }
      }, 10)
    }
    resized()

    window.addEventListener('resize', resized)
  }, [svgRef])

  useEffect(() => {
    if (reduction === 0) setRendered(true)
    if (!path.current || !date || !reductionDate) return

    const lineOptions = {
      smoothing: 0.3,
      flattening: 1,
    }

    let tempHeight = HEIGHT_COUNT
    const heightCell = HEIGHT_SVG / HEIGHT_COUNT
    const points = [
      [0, HEIGHT_SVG],
      ...[...Array(WIDTH_COUNT).keys()]
        .filter((w) => (w + 1) % 3 === 0)
        .map((w) => {
          tempHeight--
          return [(w + 1) * WIDTH_CELL, tempHeight * heightCell]
        }),
    ]

    const line = (pointA, pointB) => {
      const lengthX = pointB[0] - pointA[0]
      const lengthY = pointB[1] - pointA[1]
      return {
        length: Math.sqrt(Math.pow(lengthX, 2) + Math.pow(lengthY, 2)),
        angle: Math.atan2(lengthY, lengthX),
      }
    }

    const lib = {
      map(value, inMin, inMax, outMin, outMax) {
        return ((value - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin
      },
      range(start, end, tick) {
        const s = Math.round(start / tick) * tick
        return Array.from(
          {
            length: Math.floor((end - start) / tick),
          },
          (v, k) => {
            return k * tick + s
          }
        )
      },
    }

    const controlPoint = (current, previous, next, reverse) => {
      const p = previous || current
      const n = next || current
      const o = line(p, n)
      // work in progress…
      const flat = lib.map(Math.cos(o.angle) * lineOptions.flattening, 0, 1, 1, 0)
      const angle = o.angle * flat + (reverse ? Math.PI : 0)
      const length = o.length * lineOptions.smoothing
      const x = current[0] + Math.cos(angle) * length
      const y = current[1] + Math.sin(angle) * length
      return [x, y]
    }

    const bezierCommand = (point, i, a) => {
      // start control point
      const cps = controlPoint(a[i - 1], a[i - 2], point)

      // end control point
      const cpe = controlPoint(point, a[i - 1], a[i + 1], true)
      return `C ${cps[0]},${cps[1]} ${cpe[0]},${cpe[1]} ${point[0]},${point[1]}`
    }

    const svgPath = (points, command, optionCirlce = { r: 4.2, fill: '#AEAEAE' }) => {
      // build the d attributes by looping over the points
      const id = getIndexReduction(+reduction)
      const circles = [],
        circles2 = []
      const d = points
        .filter((p, i) => i >= id)
        .reduce((acc, point, i, a) => {
          circles.push({ cx: point[0], cy: point[1], ...optionCirlce })
          return i === 0 ? `M ${point[0]},${point[1]}` : `${acc} ${command(point, i, a)}`
        }, '')

      const d2 = points
        .filter((p, i) => i <= id)
        .reduce((acc, point, i, a) => {
          circles2.push({
            cx: point[0],
            cy: point[1],
            ...{ r: 5, fill: getColor(i) },
          })
          return i === 0 ? `M ${point[0]},${point[1]}` : `${acc} ${command(point, i, a)}`
        }, '')

      return { d, d2, circles, circles2 }
    }

    const res = svgPath(points, bezierCommand)

    setPaths({
      d1: res.d,
      circles1: res.circles,
      d2: res.d2,
      circles2: res.circles2,
    })
  }, [date, reduction, reductionDate, WIDTH_CELL])

  useEffect(() => {
    if (reduction === 0) setRendered(true)
    if (!paths?.d2 || rendered || reduction === 0) return

    const gp = new GradientPath({
      path: path.current,
      segments: 30,
      samples: 3,
      precision: 2, // Optional
    })

    const id = getIndexReduction(+reduction)
    const colors = []
    for (let i = id; i > 0; i--) {
      colors.push({
        color: getColor(id - i),
        pos: i === id ? 0 : i === 1 ? 1 : Math.max(1 / i, 0),
      })
    }
    if (colors.length < 2) {
      colors[1] = colors[0]
      colors[1].pos = 1
    }
    gp.render({
      type: 'path',
      fill: colors,
      stroke: colors,
      strokeWidth: 0.5,
      width: 4,
    })
    setRendered(true)
  }, [paths, reduction, WIDTH_CELL])

  const transition = { duration: 1.5, ease: 'easeInOut' }
  const [key,setKey] = useState(0)
  useEffect(()=>{
    setTimeout(() => {
      if(isPhone){
        setKey(123)
      }
    }, 100);
  },[])

  return (
    <div
      key={key}
      style={{
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
      }}
    >
      {!rendered && (
        <Loader
          fill
          center
          blur
          style={{
            zIndex: 1,
            height: '130%',
            width: '110%',
            transform: 'translateX(-20px)',
          }}
        />
      )}
      <div className={css.wrapper1}>
        <div className={[css.discounts, 'unfocus-text2'].join(' ')}>
          <span className={reduction === 15 ? css.current : ''}>15%</span>
          <span className={reduction === 12 ? css.current : ''}>12%</span>
          <span className={reduction === 10 ? css.current : ''}>10%</span>
          <span className={reduction === 5 ? css.current : ''}>5%</span>
          <span className={reduction === 3 ? css.current : ''}>3%</span>
        </div>
        <div ref={svgWrapper} className={css.svgWrapper}>
          <div className={css.bgSvg}>
            {[...Array(WIDTH_COUNT).keys()].map((w) => (
              <span
                key={w}
                style={{ backgroundSize: `40px calc(100% / ${HEIGHT_COUNT})` }}
                className={[css.bgBlock, w % 2 === 1 ? css.odd : '', months[w]?.mark ? css.marked : ''].join(' ')}
              ></span>
            ))}
          </div>
          <span className={css.effect}></span>
          <motion.svg
            className={css.svg}
            // viewBox={`0 0 200 100`}
            version="1.1"
            ref={svgRef}
            xmlns="http://www.w3.org/2000/svg"
          >
            <motion.path
              d={paths?.d1}
              fill="none"
              stroke="#AEAEAE"
              strokeWidth="2px"
              initial={{ pathLength: 0 }}
              animate={{ pathLength: 1 }}
              transition={{ ...transition, delay: 1 }}
            ></motion.path>
            <motion.path
              d={paths?.d2}
              ref={path}
              id="discount-path"
              fill="none"
              stroke="#FDE063"
              strokeWidth="4px"
              initial={{ pathLength: 0 }}
              animate={{ pathLength: 1 }}
              transition={transition}
            ></motion.path>
            <AnimatePresence>
              {Array.isArray(paths?.circles1) &&
                paths.circles1.map((circle, i) => (
                  <motion.circle
                    initial={{ scale: 0 }}
                    animate={{ scale: 1 }}
                    transition={{
                      duration: 0.5,
                      type: 'spring',
                    }}
                    key={i + 'd2'}
                    {...circle}
                  ></motion.circle>
                ))}

              {rendered &&
                Array.isArray(paths?.circles2) &&
                paths.circles2.map((circle, i) => (
                  <motion.circle
                    initial={{ scale: 0 }}
                    animate={{ scale: 1 }}
                    transition={{
                      duration: 0.5,
                      type: 'spring',
                    }}
                    className={css.shadow}
                    key={i}
                    {...circle}
                    r={i === getIndexReduction(+reduction) ? 6 : 5}
                    strokeWidth={i === getIndexReduction(+reduction) ? 4 : 0}
                    stroke="#fff"
                  ></motion.circle>
                ))}
            </AnimatePresence>
          </motion.svg>
        </div>
      </div>
    </div>
  )
}
