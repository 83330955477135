import axios from 'axios'

const $host = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'text/plain',
  },
})

const $host2 = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'text/plain',
  },
})

const $authHost = axios.create({
  baseURL: process.env.REACT_APP_API_URL + '/api.cgi/user/',
  headers: {
    'Content-Type': 'text/plain',
  },
})

const $authHost2 = axios.create({
  baseURL: process.env.REACT_APP_API_URL + '/api.cgi/users/',
  headers: {
    'Content-Type': 'text/plain',
  },
})

const authInterceptor = (config) => {
  config.baseURL += localStorage.getItem('uid')
  config.headers.USERSID = localStorage.getItem('sid')
  return config
}

$authHost.interceptors.response.use(
  (response) => {
    if (response.data?.errstr === 'No such route') {
      response.status = 401
      response.statusText = 'Unathorized'
      response.data = 'Unathorized'
      localStorage.removeItem('sid')
      localStorage.removeItem('uid')
      // window.location.href = "/#/login";
      return response
    }
    return response
  },
  (error) => {
    console.log(`error ${error}`)
    if (error.toString().includes('401') || error.toString().includes('403')) {
      localStorage.removeItem('sid')
      localStorage.removeItem('uid')
      // window.location.href = "/#/login";
    }
    // eslint-disable-next-line no-undef
    return Promise.reject(error)
  }
)

$authHost.interceptors.request.use(authInterceptor)

$host2.interceptors.request.use((config) => {
  config.headers.USERSID = localStorage.getItem('sid')
  return config
})
$authHost2.interceptors.request.use(authInterceptor)

export { $host, $authHost, $authHost2, $host2 }
