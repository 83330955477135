import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./services/i18n/i18n";
import "./styles/index.css";
import "./styles/global.scss";
import "./styles/partners/partners.scss";
import "./styles/partners/partnersTab.scss";
import "./styles/partners/partnersItem.scss";

import { BrowserRouter, HashRouter } from "react-router-dom";
import ProvideAuth from "./services/authentication";
import { Provider } from "react-redux";
import { store } from "./store";
import "./utils/prototypes";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <HashRouter>
      <ProvideAuth>
        <App />
      </ProvideAuth>
    </HashRouter>
  </Provider>
);
