import React from "react";
import { useRef } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import Input from "../../../../components/Input";
import Loader from "../../../../components/Loader";
import {
  checkVerification,
  sendCodeVerification,
} from "../../../../services/http/contacts";
import { translatedText } from "../../../../services/translatedText";
import { toStringDate } from "../../../../utils/date";
import resetIcon from "../../../../assets/images/svg/reset.svg";
import { useAutoAnimate } from "@formkit/auto-animate/react";

export default function Step3({ onPossible, onImpossible }) {
  const { user, pi, contacts } = useSelector((state) => state);
  const currentCode = useRef();
  const timeoutExpired = useRef();
  const [loader, setLoader] = useState(false);
  const [phone, setPhone] = useState();
  const [applied, setApply] = useState(false);
  const [timeout, setTime] = useState("");
  const [expired, setExpired] = useState(false);
  const [msg, setMsg] = useState({
    msg: "",
  });
  const [code, setCode] = useState();
  const username = user?.login;
  const values = useRef({});
  const [animationParent] = useAutoAnimate();
  var actualPhone = (contacts?.length ? contacts : []).find((t) =>
    t.name.toLowerCase().includes("phone")
  )?.value;

  const apply = async (phoneCurrent = null) => {
    try {
      actualPhone = actualPhone || phoneCurrent || phone;
      if (!actualPhone) {
        setMsg({
          msg: translatedText("Valid data"),
          error: 3,
        });
        return;
      }
      setLoader(true);
      const resSms = await sendCodeVerification(actualPhone, username);

      if (resSms?.data == "Вы были забанены") {
        const dt = new Date(resSms?.time);
        dt.setMinutes(dt.getMinutes() + 10);
        dt.setHours(dt.getHours() - 2);
        setMsg({
          msg: translatedText("You have ban", {
            date: toStringDate(dt, true),
          }),
          error: 3,
        });
        setApply(false);
        clearTimeout(timeoutExpired.current);
        timeoutExpired.current = null;
        setExpired(false);
        setCode("");
      } else if (
        resSms?.error_code ||
        resSms?.status_description == "Error in sms"
      )
        setMsg({
          msg: translatedText("Unknown error"),
          error: resSms?.error_code ?? 1,
        });
      else {
        setApply(true);
        setMsg({ msg: translatedText("Sent code"), code: 1 });
        setExpired(false);
        countdown(2);
        timeoutExpired.current = setTimeout(() => {
          setTime(
            <button
              style={{ display: "flex", alignItems: "center", padding: 0 }}
              onClick={() => apply()}
            >
              <img src={resetIcon} alt="reset" />
            </button>
          );
          timeoutExpired.current = null;
          setExpired(true);
        }, 2 * 60 * 1000);
        setCode("wait");
      }
    } catch (error) {
      if (error.code === "ERR_BAD_RESPONSE")
        setMsg({
          msg: translatedText("Unknown error"),
          error: 1,
        });
    } finally {
      setLoader(false);
    }
  };
  const confirm = async () => {
    let phoneCurrent = phone;
    let codeState = code;
    if (code === "wait" && !expired && currentCode.current) {
      const data = await checkVerification(currentCode.current, username);
      setCode(data?.code);
      codeState = data?.code;
      setLoader(true);
    }

    if (codeState === "success") {
      setMsg({ success: 1 });
      checkIsPossible();
    } else if (codeState) {
      if (codeState == "error") {
        setLoader(false);
        setCode("wait");
        setMsg({ msg: translatedText("Code error"), code: 1, error: 1 });
        setTimeout(() => {
          setMsg({ msg: translatedText("Sent code"), code: 1 });
        }, 4000);
        return;
      }
    } else if (phoneCurrent?.length !== 12) {
      setLoader(false);
      return;
    } else {
      await apply(phoneCurrent);
      return;
    }

    clearTimeout(timeoutExpired.current);
    timeoutExpired.current = null;
    setLoader(false);
  };

  function countdown(minutes) {
    let seconds = minutes * 60;
    let timerId = setInterval(() => {
      if (!timeoutExpired.current) return;
      seconds--;
      if (seconds < 0) {
        clearInterval(timerId);
        return;
      }
      let minutesLeft = `0${Math.floor(seconds / 60)}`.slice(-2);
      let secondsLeft = `0${seconds % 60}`.slice(-2);
      let timeLeft = `${minutesLeft}:${secondsLeft}`;
      setTime(timeLeft);
    }, 1000);
  }

  function onChangeInputs(value, key) {
    if (!key) return;
    values.current[key] = value + "";
    if (!msg?.success) return;
    checkIsPossible();
  }
  function checkIsPossible() {
    const keys = Object.keys(values.current);
    const haveValues =
      Object.values(values.current).filter((v, i) =>
        keys[i] === "pinfl" ? v?.length === 14 : v
      ).length >= 3;
    if (haveValues && onPossible) onPossible(values.current);
    else if (onImpossible) onImpossible();
  }

  return (
    <div className="step">
      <h1 className="step-title">{translatedText("Personal data")}</h1>
      <div className="step-grid">
        <Input
          marginLeftSpan={10}
          defaultValue={pi?.fio}
          onChange={(val) => onChangeInputs(val, "fio")}
          mini
          label={translatedText("FIO")}
          init
        />
        <Input
          marginLeftSpan={10}
          disabled
          onChange={(val) => onChangeInputs(val, "login")}
          defaultValue={username}
          mini
          label={translatedText("Login")}
          init
        />
        <Input
          marginLeftSpan={10}
          defaultValue={pi?.pinfl}
          onChange={(val) => onChangeInputs(val, "pinfl")}
          mini
          label={translatedText("PINFL")}
          maxLength={14}
          init
        />
        <Input
          marginLeftSpan={10}
          onChange={(value) => setPhone(value)}
          mini
          readOnly={!!code || actualPhone}
          defaultValue={actualPhone ? actualPhone + "" : ""}
          label={translatedText("Contact phone")}
          mask="+998 (##) ### ## ##"
        />
        {msg?.success ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div className="step-checkmark"></div>
            {translatedText("Successfully confirmed")}
          </div>
        ) : (
          applied && (
            <div ref={animationParent}>
              <Input
                marginLeftSpan={10}
                onChange={(value) => (currentCode.current = value)}
                mini
                label={translatedText("Write code")}
                rightSuffix={timeout}
              />
              <p
                className={"mini-t s13" + (msg?.error ? " red-text" : "")}
                style={{ maxWidth: 350, margin: "10px 0" }}
              >
                {msg?.msg || translatedText("Send code to phone")}
              </p>
              <button
                disabled={loader}
                onClick={confirm}
                className="primary-btn mini full"
              >
                {loader ? <Loader min center /> : translatedText("Confirm")}
              </button>
            </div>
          )
        )}
        {!applied && (
          <div ref={animationParent}>
            {msg?.error === 3 && (
              <p className={"mini-t s13 red-text"} style={{ maxWidth: 350 }}>
                {msg?.msg}
              </p>
            )}
            <button
              onClick={() => apply()}
              style={{ marginTop: msg?.error === 3 ? 10 : 20 }}
              className="primary-btn mini full"
            >
              {translatedText("Apply")}
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
