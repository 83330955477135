const ru = {
  "Bonus-be-in-the-black": "Будь в плюсе",
  "Card user": "Карточка пользователя",
  "Balance account": "Баланс счёта",
  "Service Alerts": "Сервисные оповещения",
  "Shares and bonuses": "Акции и бонусы",
  "Tariff plan": "Тарифный план",
  Detailing: "Детализация",
  Detailing1: "Аналитика в реальном времени",
  "Support Chat": "Чат с поддержкой",
  "Suspension of services": "Приостановление услуг",
  "Termination of an agreement": "Расторжение договора",
  "Discounts from partners": "Cкидки от партнеров",
};
const uz = {
  "Bonus-be-in-the-black": "\"Будь в плюсе\" aksiyasi",
  "Card user": "Foydalanuvchi kartasi",
  "Balance account": "Hisob balansi",
  "Service Alerts": "Ogohlantirishlar",
  "Shares and bonuses": "Aksiya va bonuslar",
  "Tariff plan": "Tarif rejasi",
  Detailing: "Tafsilotlar",
  Detailing1: "Hozirgi vaqtdagi tafsilot",
  "Support Chat": "Yordam chat",
  "Suspension of services": "Xizmatlarni to'xtatib turish",
  "Termination of an agreement": "Shartnomani bekor qilish",
  "Discounts from partners": "Hamkorlardan chegirmalar",
};

export default { ru, uz };
