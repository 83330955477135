const prefix = "bringfriend_";

const bringFriend_ru = {
  "Bring a friend": "Приведи друга",
	[prefix + "description"]: `Уважаемые абоненты, теперь у вас есть потрясающая возможность ПОЛУЧИТЬ БОНУСЫ за подключение к услугам «Comnet» друзей и просто знакомых!
	Примите участие в акции «Приведи друга» и получите скидку в 50% сроком на 1 месяц на свой лицевой счет и на счет друга.`,
	[prefix + "button"]: "Привести друга",
	[prefix + "rules"]: `<span style="font-size: 24px">Правила акции:</span><br>За каждого привлеченного клиента в сегменте физических лиц действующий абонент компании получает единоразовый бонус в виде начисления на свой лицевой счет суммы в размере 50% от своей абонентской платы, как и на счет абонента, которого он привлёк. Для участия в акции Действующему абоненту необходимо в личном кабинете заполнить форму, где необходимо указать «имя», «контактный номер» привлекаемого клиента и пройти подтверждение указанного номера кодом, который придёт в виде SMS сообщения`,
	[prefix + "notes"]: `Данная акция не суммируется с другими акциями компании<br>
	<span class="note-sym">Привлеченным клиентом считается абонент заключивший договор и оплативший за первый месяц предоставления услуг.</span> 
	<span class="note-sym"> Действующим абонентом является физическое лицо, имеющее активный договор с компанией на момент участия в акции.</span>
	<span class="note-sym"> Начисленные в рамках акции «Приведи друга» бонусные денежные средства могут быть израсходованы только на услуги Компании и в случае расторжения договора не подлежат возврату.</span>
	`,
	[prefix + "note"]: `Компания оставляет за собой право вносить изменения и дополнения в условия Акции.
	Редакция №2 от 24.10.2022 г.`,
	[prefix + "name"]: "Имя друга:",
	[prefix + "phone_friend"]: "Контактный номер телефона друга:",
	[prefix + "phone_code"]: "Проверочный код:",
	[prefix + "phone_note"]: "На указанный номер друга поступит СМС с кодом в течении 2х минут.",
	[prefix + "success"]: "Успешно добавлено",
	[prefix + "history_added_friends"]: "История приглашенных друзей:",
	[prefix + "not_actived"]: "Не активирован",
	[prefix + "phone_valid"]: "Этот номер телефона уже использовался ранее",
	
	
};

const bringFriend_uz = {
	"Bring a friend": "Do'stingni olib kel",
	[prefix + "description"]: `Hurmatli obunachilar,
	endi siz do'stlaringiz va shunchaki tanishlaringizning "Comnet" xizmatlariga ulanishi orqali BONUSLARGA EGA BO'LISH imkoniyatiga egasiz!
"Приведи друга" aksiyasida ishtirok eting, do'stingiz va o'zingizning shaxsiy hisobingizga 1 oy muddat uchun 50% chegirmaga ega bo'ling!`,
	[prefix + "button"]: "Do'stimni taklif qilish",
	[prefix + "rules"]: `<span style="font-size: 24px">Aksiya qoidalari:</span><br>Jismoniy shaxslar sifatida jalb qilingan har bir mijoz uchun, kompaniyaning amaldagi abonenti hamda, u jalb qilgan yangi abonent hisob raqamlariga bir oylik to'lov hajmining 50% chegirmasi, bir marttalik bonus sifatida taqdim qilinadi. Amaldagi abonent aksiyada ishtirok etish uchun, shaxsiy kabinetida ariza to'ldirishi kerak. Arizada jalb qilinayotgan yangi abonentning "Ismi" va "Kontakt raqamini" ko'rsatib, ko'rsatilgan raqamga SMS xabar shaklida yuborilgan kodni kiritib tasdiqlanishi kerak.`,
	[prefix + "notes"]: `Ushbu aksiyani kompaniyaning boshqa aksiyalari bilan birlashtirish mumkin emas<br>
	<span class="note-sym">Jalb qilingan mijoz shartnoma imzolagan va xizmatlarning birinchi oylik haqini to‘lagan abonent hisoblanadi.</span>
	<span class="note-sym"> Faol obunachi - aksiyada ishtirok etish vaqtida kompaniya bilan faol shartnoma tuzgan jismoniy shaxs.</span>
	<span class="note-sym"> “Do'stingni olib kel” aksiyasi doirasida to‘plangan bonus mablag‘lari faqat Kompaniya xizmatlariga sarflanishi mumkin va shartnoma bekor qilingan taqdirda qaytarib berilmaydi.</span>
	`,
	[prefix + "note"]: `Kompaniya Aksiya shartlariga o'zgartirish va qo'shimchalar kiritish huquqini o'zida saqlab qoladi. 24.10.2022 № 2-nashr`,
	[prefix + "name"]: "Sizning ismingiz:",
	[prefix + "phone_friend"]: "Do'stimning telefon raqami:",
	[prefix + "phone_code"]: "Tasdiq kodi:",
	[prefix + "phone_note"]: "2 daqiqa ichida ko'rsatilgan telefon raqamiga kodli SMS yuboriladi.",
	[prefix + "success"]: "Muvaffaqiyatli qo'shildi",
	[prefix + "history_added_friends"]: "Taklif etilgan do'stlar tarixi:",
	[prefix + "not_actived"]: "Faol emas",
	[prefix + "phone_valid"]: "Bu telefon raqami allaqachon ishlatilgan",
};

export default { bringFriend_ru, bringFriend_uz };
