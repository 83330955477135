import { $authHost } from '.'

export async function getCurrentTariff() {
  const { data } = await $authHost.get('/internet')
  return data
}

export async function getTariffs() {
  const { data } = await $authHost.get('/internet/tariffs/all')
  return data
}

export async function changeTariff(tp_id, period, id) {
  const { data } = await $authHost.put('/internet/' + id, { tp_id, period })
  let result = data
  if (typeof data !== 'object') {
    if (Array.isArray(data)) result = data[0]
    else result = JSON.parse('{' + data.split('{')[1])
  }
  return result
}
