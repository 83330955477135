import React from 'react'
import { useState } from 'react'
import css from './style.module.css'
import PropTypes from 'prop-types'

const paymentJS = [
  { name: 'uzum', src: require('../../assets/images/payments/uzum2.png') },
  { name: 'payme', src: require('../../assets/images/payments/payme.png') },
  { name: 'click', src: require('../../assets/images/payments/click.png') },
  { name: 'paynet', src: require('../../assets/images/payments/paynet.png') },
]

export default function PaymentsCarousel({ limit = 6, onClick, styles }) {
  const [currentPage, setCurrentPage] = useState(1)
  const payments = paymentJS.filter((_, i) => i >= (currentPage - 1) * limit && i < (currentPage - 1) * limit + limit)
  return (
    <div className={css.col}>
      <div className={css.block}>
        <button disabled={currentPage <= 1} onClick={() => setCurrentPage((p) => p - 1)} className={css.arrow}>
          {'<'}
        </button>
        <div className={css.wrapper} style={styles}>
          {payments.map((payment, i) => (
            <button onClick={() => onClick(payment.name)} key={i} className={css.payment}>
              <img
                style={payment.name === 'uzum' ? { height: '35%', width: 'auto' } : {}}
                src={payment.src}
                alt="payment"
              />
            </button>
          ))}
        </div>
        <button
          disabled={currentPage >= Math.ceil(paymentJS.length / limit)}
          onClick={() => setCurrentPage((p) => p + 1)}
          className={css.arrow}
        >
          {'>'}
        </button>
      </div>
    </div>
  )
}
PaymentsCarousel.propTypes = {
  limit: PropTypes.number,
}
