import React from 'react'
import css from './style.module.css'
import PropTypes from 'prop-types'
import Checkmark from '../Checkmark'
import { useState } from 'react'
import { useEffect } from 'react'
import { translatedText } from '../../services/translatedText'

function uuidv4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      // eslint-disable-next-line no-undef
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  )
}

export default function CheckboxGroup({
  defaultOff = false,
  lowPriority,
  onChange,
  onChangeWithData,
  gap = 10,
  columnGap = null,
  data = [],
  style = {},
}) {
  // data.map((d, i) => (!defaultOff && i === 0 ? true : false))
  const [checkedInputs, setCheckedInputs] = useState(data.map((d, i) => (!defaultOff && i === 0 ? true : false)))
  const [windowSize, setWindowSize] = useState(0)
  const uuid = uuidv4()

  const getWindowSize = () => {
    setWindowSize(document.body.clientWidth)
  }

  useEffect(() => {
    setWindowSize(document.body.clientWidth)
    window.addEventListener('resize', getWindowSize)

    if (defaultOff) return

    let mod = data.map((_, index) => (index === 0 ? true : false))
    setCheckedInputs(mod)
    if (onChangeWithData)
      onChangeWithData(
        mod.map((m, i) => {
          return { value: m, data: data[i] }
        })
      )
    if (onChange) onChange(mod)

    return () => {
      removeEventListener('resize', getWindowSize)
    }
  }, [])

  return (
    <div style={{ columnGap, gap, ...style }} className={css.wrap}>
      {data.map((text, i) => (
        <div key={i}>
          <Checkmark
            readOnly
            lowPriority={lowPriority}
            onChange={(e) => {
              if (!defaultOff && checkedInputs[i]) {
                return
              }
              let mod = data.map((_, index) => (index === i && e.target.checked ? true : false))
              setCheckedInputs(mod)
              if (onChangeWithData)
                onChangeWithData(
                  mod.map((m, i) => {
                    return { value: m, data: data[i] }
                  })
                )
              if (onChange) onChange(mod)
            }}
            checked={checkedInputs[i]}
            name={uuid}
          >
            {text}
          </Checkmark>
          {/* <div
            className={css.arrow_extra_info}
            style={{
              opacity: checkedInputs[i] ? 1 : 0,
            }}
          ></div> */}
          {/* <div
            className={`${css.extra_info}`}
            style={{
              display: checkedInputs[i] ? 'block' : 'none',
              opacity: checkedInputs[i] ? 1 : 0,
              width: windowSize > 850 ? `calc(${windowSize}px / 5)` : `100%`,
              maxWidth: '300px',
              overflowY: 'scroll',
            }}
          >
            <p>{i === 0 ? translatedText('AboutImmediately') : translatedText('NextAccountingPeriod')}</p>
          </div> */}
        </div>
      ))}
    </div>
  )
}

CheckboxGroup.propTypes = {
  gap: PropTypes.number,
  data: PropTypes.array,
  onChange: PropTypes.func,
  onChangeWithData: PropTypes.func,
  style: PropTypes.object,
  defaultOff: PropTypes.bool,
}
