import React from "react";
import { useState } from "react";

import categories from "../../assets/images/partners/categories.svg";
import joy from "../../assets/images/partners/joy.svg";
import beauty from "../../assets/images/partners/beauty.svg";
import education from "../../assets/images/partners/education.svg";
import style from "../../assets/images/partners/style.svg";
import food from "../../assets/images/partners/food.svg";
import other from "../../assets/images/partners/other-services.svg";
import categoriesActive from "../../assets/images/partners/categories-active.svg";
import joyActive from "../../assets/images/partners/joy-active.svg";
import beautyActive from "../../assets/images/partners/beauty-active.svg";
import educationActive from "../../assets/images/partners/education-active.svg";
import styleActive from "../../assets/images/partners/style-active.svg";
import foodActive from "../../assets/images/partners/food-active.svg";
import otherActive from "../../assets/images/partners/other-services-active.svg";
import PartnersGroup from "../PartnersGroup";
import CarouselPartnerCategory from "../CaruselPartnerCategory";
import i18next from "i18next";

const group = [
  {
    title: "Все",
    titleuz: "Hammasi",
    img: categories,
    imgActive: categoriesActive,
    type: "all",
  },
  {
    title: "Еда и напитки",
    titleuz: `Oziq-ovqat va ichimliklar`,
    img: food,
    imgActive: foodActive,
    type: "food",
  },
  {
    title: "Мода и стиль",
    titleuz: `Moda va uslub`,
    img: style,
    imgActive: styleActive,
    type: "style",
  },
  {
    title: "Развлечения",
    titleuz: `Ko'ngil ochish`,
    img: joy,
    imgActive: joyActive,
    type: "joy",
  },
  {
    title: "Красота и здоровье",
    titleuz: `Go'zallik va so'glik`,
    img: beauty,
    imgActive: beautyActive,
    type: "beauty",
  },
  {
    title: "Образование",
    titleuz: `Ta'lim`,
    img: education,
    imgActive: educationActive,
    type: "education",
  },
  {
    title: "Прочие Услуги",
    titleuz: `Boshqa xizmatlar`,
    img: other,
    imgActive: otherActive,
    type: "other",
  },
];

export default function PartnersTab({ data }) {
  const lang = i18next.language;
  const [currentType, setType] = useState("all");
  const [innerWidth, setWidth] = useState(1000);

  const chose = (type) => {
    setType(type);
  };

  React.useEffect(() => {
    if (window) {
      setWidth(Number(window.innerWidth));
    }
  }, []);

  return (
    <div>
      <div className="partners-tabs">
        {innerWidth < 768 ? (
          <CarouselPartnerCategory
            chose={chose}
            currentType={currentType}
            data={group}
          />
        ) : (
          group.map((item, index) => (
            <div
              onClick={() => {
                chose(item.type);
              }}
              className={` partners-tabs__list-tab  ${
                item.type === currentType ? "react-tabs__tab--selected" : ""
              }`}
              key={index.toString()}
            >
              <div
                className={
                  "partners-tabs__list-tab-wrapperImage" +
                  " " +
                  (item.type === currentType
                    ? "partners-tabs__list-tab-wrapperImageActive"
                    : "")
                }
              >
                <img
                  src={item.type !== currentType ? item.imgActive : item.img}
                  alt=""
                />
              </div>
              <p>{lang === "ru" ? item.title : item.titleuz}</p>
            </div>
          ))
        )}
      </div>

      {currentType === "all" &&
        group.map((item) => {
          return <PartnersGroup data={item} key={item.title} content={data} />;
        })}
      {currentType !== "all" &&
        group
          .filter((i) => i.type == currentType)
          .map((item) => {
            return (
              <PartnersGroup data={item} key={item.title} content={data} />
            );
          })}
    </div>
  );
}
