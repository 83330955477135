import React from "react";
import { useRef } from "react";
import { useEffect } from "react";
import gauge from "../../utils/gauge";

// eslint-disable-next-line react/prop-types
export default function CircularChart({
  percentage = 0,
  width = 350,
  height = 300,
  fontSize = 96,
  max = 15,
  colors,
}) {
  const parent = useRef();

  useEffect(() => {
    if (!parent.current) return;
    const knob = gauge.createKnob(width, height);
    // Set properties.
    knob.setProperty("angleStart", -0.75 * Math.PI);
    knob.setProperty("angleEnd", 0.75 * Math.PI);
    knob.setProperty("colorFG", "#88ff88");
    knob.setProperty("colorBG", "rgba(235, 235, 235, 1)");
    knob.setProperty("readonly", true);
    knob.setProperty("trackWidth", 0.15);
    knob.setProperty("valMin", 0);
    knob.setProperty("valMax", Math.max(max, percentage));
    knob.setProperty("label", "%");
    knob.setProperty("fontSize", fontSize);
    knob.setProperty(
      "colors",
      colors || [
        "rgba(218, 62, 64, 1)",
        "rgba(243, 84, 89, 1)",
        "rgba(241, 142, 52, 1)",
        "rgba(253, 224, 99, 1)",
      ]
    );
    knob.setValue(percentage);
    const node = knob.node();

    parent.current.innerHTML = "";
    parent.current.appendChild(node);
  }, [parent, percentage]);

  return <div ref={parent}></div>;
}
