import React, { useEffect, useMemo, useState } from 'react'
import { translatedText } from '../../services/translatedText'
import classes from './style.module.css'
import i18next from 'i18next'
import { Link } from 'react-router-dom'
import extraServices from '../../staticData/extraServices'
import useActions from '../../hooks/useActions'

export default function AdditionalServices({ services }) {
  const lang = i18next.language

  const [isFreezing, setIsFreezing] = useState(false)
  const [serv, setServ] = useState([])

  useEffect(() => {
    const values = Object.values(extraServices)
    const listServ = services.map((item) => {
      const currentService = values.filter((i) => i.id == item.id)

      if (currentService.length) {
        item.full = currentService[0]
      }
      return item
    })

    setServ(listServ)
  }, [services])

  return (
    serv?.length > 0 && (
      <div className={classes.wrapper}>
        <h1>{translatedText('ConnectedServices')}</h1>

        <div className={'AdditionalServices__items ' + classes.cards}>
          {serv.map((item, i) => (
            <div className="AdditionalServices__col" key={i}>
              <div className="AdditionalServices__item">
                <div
                  className={`AdditionalServices__top ${
                    item.activeService || (item.id === 2 && isFreezing) ? 'cl.active_tag' : ''
                  }`}
                >
                  <div className="AdditionalServices__icon">
                    <img src={item.full?.svgIcon} alt="" />
                  </div>
                  <div className="AdditionalServices__title">
                    {lang === 'ru' ? item.full?.title_ru : item.full?.title_uz}
                  </div>
                  <div className="AdditionalServices__text">
                    {lang === 'ru' ? item.full?.descr_ru : item.full?.descr_uz}
                  </div>
                </div>

                <div className="AdditionalServices__bot">
                  <div className="AdditionalServices__free">
                    {item.full?.full.price === '0'
                      ? lang === 'ru'
                        ? item.full?.full.currency_ru
                        : item.full?.full.currency_uz
                      : `${new Intl.NumberFormat('ru-RU').format(item.price)} ${
                          lang === 'ru' ? item.full?.full.currency_ru : item.full?.full.currency_uz
                        }`}
                  </div>

                  {item.id === 14 ? (
                    <Link to="/extra-services/give-back-router" className="AdditionalServices__more">
                      {translatedText('GiveBackDevice')}
                    </Link>
                  ) : (
                    <Link to={item.full?.route} className="AdditionalServices__more">
                      {translatedText('Disable')}
                    </Link>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  )
}
