import axios from 'axios'
import { $authHost } from '.'

export async function getUserInfo() {
  const { data } = await $authHost.get('')
  return data
}

export async function getUserAllInfo() {
  const { data } = await $authHost.get('/pi')
  return data
}

export async function getAndBackDevices(params) {
  const { title, name, phone, login } = params

  const { data } = await axios({
    url: 'https://serv.comnet.uz/api/bitrix_crm?pass=@j9@LKLKK29782LLL)',
    method: 'POST',
    params: {
      title,
      name,
      phone,
      login,
    },
  })

  return data
}

export async function getUserExtraService(serviceId) {
  const { data } = await $authHost.get(`/abon`)

  if (serviceId) {
    const service = data.find((el) => el.id === serviceId)
    return service
  }

  return data
}

export async function setUserExtraService(serviceId) {
  const { data } = await $authHost.post(`/abon/${serviceId}`)
  return data
}

export async function removeUserExtraService(serviceId) {
  const { data } = await $authHost.delete(`/abon/${serviceId}`)
  return data
}

export async function getUserTags() {
  const { data } = await $authHost.get('/tags')
  return data
}

export async function setUserNotification({ Sms, Mail, Off }) {
  const { data } = await axios.post(
    `https://cabinet-backend.comnet.uz/api/shpion/second?key=fdsf32mof23&sms=${Off ? '0' : Sms ? '1' : '0'}&email=${
      Off ? '0' : Mail ? '1' : '0'
    }&login=${localStorage.getItem('uid')}`,
    {}
  )
  return data
}

export async function getUserPhones() {
  const { data } = await $authHost.get('/contacts')
  return data
}

export async function getUserServices() {
  const { data } = await $authHost.get('/abon')
  return data
}

export async function getUserCredit() {
  const { data } = await $authHost.get('/credit')
  return data
}

export async function setUserCredit() {
  const { data } = await $authHost.post('/credit', {})
  return data
}

export async function resetPassword(password) {
  const { data } = await $authHost.post('/reset/password', {
    password,
  })
  return data
}

export async function sentPromoCode(login, promo_code) {
  const { data } = await axios.post(
    'https://loginator.comnet.uz/api/promo_codes/activate',
    {
      login,
      promo_code
    },
    
  )

  return data
}

export async function sendRequestToBitrix(title, phone, message, login) {
  const { data } = await axios.post(
    'https://serv.comnet.uz/api/bitrix1',
    {
      title,
      description: title,
      phone,
      name: login,
      comment: message,
      uf_crm_business: 'Нет',
      pass: '@j9@LKLKK29782LLL)',
    },
    {
      headers: {
        Authorization: 'Bearer 3|YOz6gV9lvSlVao1VfTzboRMDSpipDNuSMeSLaNFo',
      },
    }
  )

  return data
}

export async function sendRequestToBitrix2(title, phone, login, requestData) {
  const { data } = await axios.post('https://api.comnet.uz:4444/terminate_contrack', {
    title,
    phone,
    login,
    data: requestData,
  })

  return data
}
