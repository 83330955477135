import React from "react";
import PropTypes from "prop-types";

export default function InfoText({ children, aCenter }) {
  return (
    <div
      style={{
        color: "rgb(139 139 139)",
        margin: "10px 0",
        alignItems: aCenter ? "center" : "initial",
      }}
      className="row g-2"
    >
      {children}
    </div>
  );
}
InfoText.propTypes = {
  children: PropTypes.any,
  aCenter: PropTypes.bool,
};
