const ru = {
  Login1: "Войти",
  Password: "Пароль",
  "User not found": "Пользователь или пароль не найден",
};
const uz = {
  Login1: "Kirish",
  Password: "Parol",
  "User not found": "Foydalanuvchi yoki parol topilmadi",
};

export default { ru, uz };
