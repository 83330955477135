import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import useWindowSize from "../../hooks/useWindowSize";
import { getPromotions } from "../../services/http/promotions";
import Prom from "./Prom";
import classes from "./style.module.css";
import { getStorageURL } from "../../utils/url";
import { convertToPlain } from "../../utils/parser";
import { useNavigate } from "react-router-dom";
import i18next, { t } from "i18next";
import Slider from "../Slider";

export default function Proms() {
  const [data, setData] = useState([]);
  const history = useNavigate();

  useEffect(() => {
    async function fetchData() {
      const news = data.length > 0 ? data : await getPromotions();

      let filtered = news.filter(
        (item) =>
          (item.type === "Акции" && item.name.includes("fiz")) ||
          item.name.includes("both") ||
          item.name.includes("$")
      );

      filtered = filtered.filter(
        (el) => !el.name.includes("$") && el.id !== 72
      );
      setData(filtered);
    }
    fetchData();
  }, [i18next.language]);

  return (
    <span className={classes.proms}>
      {data && data.length > 0 && (
        <h1 className={classes.title}>{t("Promotions and bonuses")}</h1>
      )}
      <div className={classes.first_bg}></div>
      <div className={classes.second_bg}></div>
      {data && data.length > 0 && (
        <Slider>
          {data.map((d) => (
            <Prom
              onClick={() =>
                history("/prom/" + d.id, { state: { proms: data } })
              }
              key={d.id}
              src={getStorageURL(d.pic)}
              title={convertToPlain(d["title_" + i18next.language])}
              description={convertToPlain(d["description_" + i18next.language])}
            />
          ))}
        </Slider>
      )}
      {/* <div className={classes.row}>
        <div
          onClick={() => setCurrentId(currentId - 1)}
          style={{ left: 10 }}
          className={[
            classes.arrow,
            currentId <= 0 ? classes.disabled : "",
          ].join(" ")}
        >
          {"<"}
        </div>
        {visibleData.map((v) => v)}
        <div
          onClick={() => setCurrentId(currentId + 1)}
          style={{ right: 10 }}
          className={[
            classes.arrow,
            currentId >= LIMIT_ID ? classes.disabled : "",
          ].join(" ")}
        >
          {">"}
        </div>
      </div> */}
    </span>
  );
}
