import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { translatedText } from '../../services/translatedText'
import { getMonthNames } from '../../utils/date'
import Loader from '../Loader'
import css from './style.module.scss'
import { useAutoAnimate } from '@formkit/auto-animate/react'
import { useCheckPhone } from '../../hooks/useCheckPhone'

export default function TableBlock({ loading, data, headers, labels, sort }) {
  const [modData, setModData] = useState([])
  const [animationParent] = useAutoAnimate()
  const isPhone = useCheckPhone()

  useEffect(() => {
    if (loading) return
    if (!sort) {
      setModData(data)
      return
    }
    let result = data.reduce((current, next, i) => {
      const result = next.reduce((acc, item) => {
        const currDate = item[0].split(' ')[0].split('.')
        const dt = new Date(currDate[0], currDate[1] - 1, currDate[2])
        const key = `${getMonthNames(dt.getMonth() + 1, true)}.${dt.getFullYear()}`
        const _r = {
          name: key,
          date: item[0],
          value: [{ gridColumn: i + 1, data: item }],
        }

        return [...acc, _r]
      }, [])

      return [...current, ...result]
    }, [])

    const output = []

    result.forEach(function (item) {
      var existing = output.filter(function (v, i) {
        return v.name == item.name
      })
      if (existing.length) {
        var existingIndex = output.indexOf(existing[0])
        output[existingIndex].value = output[existingIndex].value.concat(item.value)
      } else {
        if (typeof item.value == 'string') item.value = [item.value]
        output.push(item)
      }
    })

    setModData(output.sort((a, b) => new Date(b.date) - new Date(a.date)))
  }, [sort, loading])

  const Text = ({ label, text, light, dark }) => (
    <div className="col g-1">
      <p style={{ fontSize: isPhone ? 14 : 16 }} className="unfocus-text2">
        {label}
      </p>
      <p
        style={{
          fontSize: isPhone ? 16 : 20,
          fontWeight: light ? '500' : 'normal',
          color: dark ? '#6c1414' : light ? 'rgb(48 98 24)' : 'black',
        }}
        dangerouslySetInnerHTML={{ __html: text }}
      ></p>
    </div>
  )

  return (
    <div ref={animationParent} className={css.container}>
      {loading ? (
        <Loader center />
      ) : !modData.length ? (
        <div className="a-center j-center" style={{ height: '200px' }}>
          <h2 style={{ textAlign: 'center' }}>{translatedText('No data')}</h2>
        </div>
      ) : (
        <React.Fragment>
          <div className={css.labels}>
            {labels.map((label) => (
              <h1 key={label}>{label}</h1>
            ))}
          </div>
          <div className={css.list}>
            {modData.map((data, i) => {
              let prev

              return data.value.map((value, key) => {
                let item = value.data
                const month = data.name.split('.')[0]
                const index = value.gridColumn - 1
                let isHeader = false

                if (prev !== month) {
                  isHeader = true
                  prev = month
                }

                return (
                  <React.Fragment key={key}>
                    {isHeader && (
                      <div
                        key={key + '_header'}
                        style={{
                          marginTop: isPhone ? 20 : 30,
                          gridColumn: '1 / 3',
                          height: 'min-content',
                        }}
                      >
                        <h2
                          style={{
                            fontSize: isPhone ? 18 : 24,
                            color: '#A5A5A5',
                            fontWeight: '500',
                            textAlign: 'center',
                          }}
                        >
                          {translatedText(month)}
                        </h2>
                      </div>
                    )}

                    <div
                      style={{
                        gridColumn: index + 1,
                        background: index + 1 === 1 ? 'rgb(67 183 13 / 22%)' : 'rgb(243 84 89 / 22%)',
                      }}
                      className={css.block}
                      key={key}
                    >
                      <div className="col between">
                        <Text label={headers[index][0]} text={item[0]} style={{ background: 'red' }} />
                        <Text label={headers[index][1]} danger text={item[1]} />
                      </div>
                      <div className="col between g-2">
                        <Text label={headers[index][2]} text={item[2]} />
                        <Text light label={headers[index][4]} text={item[4]} />
                        <Text light={index === 0} dark={index === 1} label={headers[index][3]} text={item[3]} />
                      </div>
                    </div>
                  </React.Fragment>
                )
              })
            })}
          </div>
        </React.Fragment>
      )}
    </div>
  )
}
