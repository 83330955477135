const ru = {
  "Terminate contract": "Расторгнуть договора",
  "Disruptions lack of internet": "Срывы отсутствие интернета",
  "Low speed": "Низкая скорость",
  Moving: "Переезд",
  "Bad service": "Плохой сервис",
  "Lack of required service": "Неактуальность услуг",
  PINFL: "ПИНФЛ",
  Termination_reason: "Расторжение договора в связи с нижеуказанными причинами",
  "Shutdown date": "Дата отключения",
  "Return Form": "Форма возврата",
  Refund_balance: "Прошу вернуть мне остаток средств",
  FIO: "Ф.И.О.",
  Name_bank: "Название Банка",
  Num_card: "Номер карты",
  ACC_bank: "Расчетный счет банка",
  INN_bank: "ИНН банка",
  MFO_bank: "МФО банка",
  Form_return_terminal: "Форму по возврату абонентам терминала компании",
  Type_return_terminal1: "обязуюсь вернуть терминал",
  Type_return_terminal2: "обязуюсь вернуть деньги за терминал",
  Return_contract_num: "Контактный номер для возврата",
  "Access denied": "В доступе отказано",

  "i want terminate": "Хочу расторгнуть договор",
  Back: "Назад",
  Next: "Далее",
  "High price tariffs": "Не устраивает стоимость тарифных планов",
  "Services disable": "Какие услуги вы хотите отключить",
  "Home internet": "Домашний интернет",
  TV: "Телевидение",
  "Additional service": "Дополнительние услуги",
  "Reason for disconnection": "Причина отлкючения",
  "Do not use": "Не пользуюсь",
  "Private technical failures": "Частые проблемы с интернетом",
  Step: "Шаг",
  "Personal data": "Личные данные",
  "Contact phone": "Контактный Телефон",
  "Send code to phone":
    "Вам отправилено код по смс на ваш телефонный номер. Смс будут приходить в течении:",
  "Write code": "Напишите код",
  "Successfully confirmed": "Успешно подтверждено",

  Tashkent: "Ташкент",
  Fergana: "Фергана",
  Kokand: "Коканд",
  Axangaran: "Ахангаран",
  "Shutdown address": "Адрес отключения",
  "Home address": "Домашний Адрес",
  Kuril: "Курил",
  Rented: "Брал в аренду",
  Router: "Роутер",
  Modem: "Модем",
  "Connection address": "Адрес подключения",
  Area: "Район",
  Home: "Дом",
  Appartment: "Квартира",
  "Terminate contract text":
    "Прошу Вас расторгнуть договор № {{contractId}} от {{date}}",

  "Which ISP switch":
    "К какому интернет провайдеру вы перешли (собираетесь перейти)?",
  "Stay in pacc": "Оставить на лицевом счете",
  "Return through cash": "Вернуть через кассу в филиалах компании",

  "IPLUS LLC and lose":
    "Я понимаю, что, расторгая договор, отключаюсь от услуг OOO «IPLUS» и лишаюсь",
  "High internet": "Высокоскоростного интернета",
  "Current tariff no": "Текущего тарифного плана на интернет",
  "Cumulative discount": "Накопительной скидки",
  "Caring technical support": "Заботливой технической поддержки",
  "Participation promotions": "Участие в конкурсах и акциях",
  "Im familiar conditions":
    "Я ознакомлен с условиями других интернет-провайдеров, их ценами и качествами предоставляемых ими услуг",

  "Funds on account": "Средства, находящиеся на балансе лицевого счета",
};

const uz = {
  "Terminate contract": "Shartnomani bekor qilish",
  "Disruptions lack of internet": "Internet yetishmasligi",
  "Low speed": "Past tezlik",
  Moving: "Ko'chish",
  "Bad service": "Yomon xizmat",
  "Lack of required service": "Xizmatlarning ahamiyatsizligi",
  PINFL: "PINFL",
  Termination_reason: "Shartnomani quyidagi sabablarga ko'ra bekor qilish",
  "Shutdown date": "O'chirish sanasi",
  "Return Form": "Qaytarib berish formasi",
  Refund_balance: "Iltimos, balansimni qaytaring",
  FIO: "F.I.SH.",
  Name_bank: "Bank nomi",
  Num_card: "Karta raqami",
  ACC_bank: "Bank hisob raqami",
  INN_bank: "Bank INN",
  MFO_bank: "Bank MFO",
  Form_return_terminal:
    "Kompaniyaga abonentlar terminalni qaytarish uchun forma",
  Type_return_terminal1: "terminalni qaytarib berishga majburman",
  Type_return_terminal2: "terminal uchun pulni qaytarishga majburman",
  Return_contract_num: "Qaytarish uchun telefon raqami",
  "Access denied": "Ruxsat berilmadi",

  "i want terminate": "Men shartnomani bekor qilmoqchiman",
  Back: "Ortga",
  Next: "Keyingi",
  "High price tariffs": "Tarif rejalari narxidan norozi",
  "Services disable": "Qaysi xizmatlarni o'chirib qo'ymoqchisiz",
  "Home internet": "Uy Interneti",
  TV: "Televideniya",
  "Additional service": "Qo'shimcha xizmatlar",
  "Reason for disconnection": "O'chirish sababi",
  "Do not use": "Foydalanmayman",
  "Private technical failures": "Tez-tez internet bilan bog'liq muammolar",
  Step: "Qadam",
  "Personal data": "Shaxsiy ma'lumotlar",
  "Contact phone": "Aloqa uchun telefon raqami",
  "Send code to phone":
    "Telefon raqamingizga SMS orqali kod yuborildi. SMS quyidagi muddatlarda qabul qilinadi:",
  "Write code": "Kod yozing",
  "Successfully confirmed": "Muvaffaqiyatli tasdiqlandi",

  Tashkent: "Toshkent",
  Fergana: "Farg'ona",
  Kokand: "Qo'qon",
  Axangaran: "Oxangaron",
  "Shutdown address": "O'chirish manzili",
  "Home address": "Uy adresi",
  Kuril: "Курил",
  Rented: "Ijaraga olingan",
  Router: "Router",
  Modem: "Modem",
  "Connection address": "Ulanish manzili",
  Area: "Hudud",
  Home: "Uy",
  Appartment: "Kvartira",
  "Terminate contract text":
    "{{date}} sanasidagi {{contractId}} - sonli shartnomani bekor qilishingizni so‘rayman.",

  "Which ISP switch": "Qaysi provayderga o'tdingiz (o'tmoqchimisiz)?",
  "Stay in pacc": "Shaxsiy hisobda qoldirish",
  "Return through cash": "Kompaniya filiallaridagi kassa orqali qaytaring",

  "IPLUS LLC and lose":
    "Men shartnomani bekor qilish orqali \"IPLUS\" MChJ xizmatlaridan uzib qo'yishimni va yo'qotishimni tushunaman",
  "High internet": "Yuqori tezlikdagi internet",
  "Current tariff no": "Internet uchun joriy tarif rejasi",
  "Cumulative discount": "To'plangan chegirmalar",
  "Caring technical support": "Texnik yordam",
  "Participation promotions": "Tanlovlar va aksiyalarda ishtirok etish",
  "Im familiar conditions":
    "Boshqa internet-provayderlarning shartlari, narxlari va xizmatlari sifati bilan tanishdim",

  "Funds on account": "Shaxsiy hisobdagi mablag'lar",
};

export default { ru, uz };
