import React from 'react'
import PropTypes from 'prop-types'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useRef } from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { useState } from 'react'
import { useCheckPhone } from '../hooks/useCheckPhone'
import extraServices from '../staticData/extraServices'

const CabinetDropDown = ({ id, Image, text, items = [], isImg = false, to, onClick }) => {
  var isCurrent = false
  const isBtn = !items.length
  const location = useLocation()
  const history = useNavigate()

  if (isBtn ? to == location.pathname : items.find((item) => item.to == location.pathname)) {
    isCurrent = true
  }

  // Object.values(extraServices).find((item) => item.route === location.pathname)

  const thisR = useRef()
  const [visible, setVisible] = useState(false)

  const isPhone = useCheckPhone()

  const onShowMenu = () => {
    if (isBtn) {
      if (onClick) onClick(null, null, null, true)
      history(to)
      return
    }
    if (!isPhone) return
    const { left } = thisR.current.getBoundingClientRect()
    if (onClick) onClick(items, id, left + 10)
  }

  const DropwDownContent = () => (
    <div
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
      onClick={() => {
        if (!isBtn) return
        history(to)
      }}
      className={'dropdown-content' + (isBtn ? ' btn' : '')}
    >
      <TransitionGroup className="content" appear>
        {items.map((item, i) => {
          const isThis = item.to == location.pathname
          return (
            <CSSTransition in appear key={i} timeout={50 * (i + 1)} classNames="dropdown-link">
              <Link className={'dropdown-link' + (isThis ? ' red-text' : '')} to={item.to ?? '/'} key={i}>
                <p className="dropdown-link-text">
                  {item.image && <item.image color={isThis ? 'var(--primary-color)' : undefined} />}
                  {item.text}
                </p>
              </Link>
            </CSSTransition>
          )
        })}
      </TransitionGroup>
    </div>
  )

  return (
    <div
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
      ref={thisR}
      className={['dropdown-wrapper', !isImg && isBtn ? ' special' : '', isCurrent ? 'red-image' : ''].join(' ')}
    >
      {!isPhone ? (
        <button className="cabinet-dropdown" onClick={onShowMenu}>
          {/* {(isImg || !isBtn) && image} */}
          <span>{text}</span>
        </button>
      ) : (
        <div onClick={onShowMenu}>
          <button className="cabinet-dropdown">{Image && <Image />}</button>
        </div>
      )}

      {!isPhone && visible && <DropwDownContent />}
    </div>
  )
}

CabinetDropDown.propTypes = {
  id: PropTypes.number,
  image: PropTypes.node,
  text: PropTypes.string,
  to: PropTypes.string,
  items: PropTypes.array,
  onClick: PropTypes.func,
  isBtn: PropTypes.bool,
}

export default CabinetDropDown
