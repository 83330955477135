function GaugeChart() {
  this.createKnob = function (width, height, val = 50) {
    const heightString = height.toString();
    const widthString = width.toString();
    const smaller = width < height ? width : height;
    const fontSize = 0.2 * smaller;
    const fontSizeString = fontSize.toString();
    const canvas = document.createElement("canvas");
    const div = document.createElement("div");

    div.style.display = "inline-block";
    div.style.height = heightString + "px";
    div.style.position = "relative";
    div.style.textAlign = "center";
    div.style.width = widthString + "px";
    div.appendChild(canvas);

    var endPercent = val;
    var curPerc = 0;

    var requestAnimationFrame =
      window.requestAnimationFrame ||
      window.mozRequestAnimationFrame ||
      window.webkitRequestAnimationFrame ||
      window.msRequestAnimationFrame;
    window.requestAnimationFrame = requestAnimationFrame;

    function bounce(_timeFraction) {
      const x = _timeFraction / 100;
      let result = timing(x);
      return result;
    }
    function timing(x) {
      return Math.sqrt(1 - Math.pow(x - 1, 2));
    }
    /*
     * The knob object.
     */
    const knob = {
      _canvas: canvas,
      _div: div,
      _height: height,
      _listeners: [],
      _mousebutton: false,
      _previousVal: 0,
      _timeout: null,
      _timeoutDoubleTap: null,
      _touchCount: 0,
      _width: width,

      /*
       * Notify listeners about value changes.
       */
      _notifyUpdate: function () {
        const properties = this._properties;
        const value = properties.val;
        const listeners = this._listeners;
        const numListeners = listeners.length;

        /*
         * Call all listeners.
         */
        for (let i = 0; i < numListeners; i++) {
          const listener = listeners[i];

          /*
           * Call listener, if it exists.
           */
          if (listener !== null) {
            listener(this, value);
          }
        }
      },

      /*
       * Properties of this knob.
       */
      _properties: {
        angleEnd: 2.0 * Math.PI,
        angleOffset: -0.5 * Math.PI,
        angleStart: 0,
        colorBG: "#181818",
        colorFG: "#ff8800",
        colorLabel: "#ffffff",
        fnStringToValue: function (string) {
          return parseInt(string);
        },
        fnValueToString: function (value) {
          return value.toString();
        },
        label: null,
        needle: false,
        readonly: false,
        textScale: 1.0,
        trackWidth: 0.4,
        valMin: 0,
        valMax: 100,
        val: 0,
        fontSize: 96,
        labelSize: null,
        placeholderWidth: null,
        cirlceTrack: false,
        colors: [
          "rgba(218, 62, 64, 1)",
          "rgba(243, 84, 89, 1)",
          "rgba(241, 142, 52, 1)",
          "rgba(253, 224, 99, 1)",
        ],
      },

      /*
       * Abort value change, restoring the previous value.
       */
      abort: function () {
        const previousValue = this._previousVal;
        const properties = this._properties;
        properties.val = previousValue;
        this.redraw();
      },

      /*
       * Adds an event listener.
       */
      addListener: function (listener) {
        const listeners = this._listeners;
        listeners.push(listener);
      },

      /*
       * Commit value, indicating that it is no longer temporary.
       */
      commit: function () {
        const properties = this._properties;
        const value = properties.val;
        this._previousVal = value;
        this.redraw();
        this._notifyUpdate();
      },

      /*
       * Returns the value of a property of this knob.
       */
      getProperty: function (key) {
        const properties = this._properties;
        const value = properties[key];
        return value;
      },

      /*
       * Returns the current value of the knob.
       */
      getValue: function () {
        const properties = this._properties;
        const value = properties.val;
        return value;
      },

      /*
       * Return the DOM node representing this knob.
       */
      node: function () {
        const div = this._div;
        return div;
      },

      /*
       * Redraw the knob on the canvas.
       */
      calculateTrianglePoints(angle, width) {
        let r = width / Math.sqrt(3);
        let firstPoint = [r * Math.cos(angle), r * Math.sin(angle)];

        let secondPoint = [
          r * Math.cos(angle + (2 * Math.PI) / 3),
          r * Math.sin(angle + (2 * Math.PI) / 3),
        ];

        let thirdPoint = [
          r * Math.cos(angle + (4 * Math.PI) / 3),
          r * Math.sin(angle + (4 * Math.PI) / 3),
        ];

        return [firstPoint, secondPoint, thirdPoint];
      },
      drawTriangle(ctx, _radius, rAngle, cx, cy) {
        const radius = _radius * 1.15;
        var PI = Math.PI;
        var triLength1 = 10 * (this._properties.fontSize / 96);
        var triLength2 = 10 * (this._properties.fontSize / 96);
        // calc triangle tip point (on circle's circumference);
        var x0 = cx + radius * Math.cos(rAngle);
        var y0 = cy + radius * Math.sin(rAngle);

        // calc outer side of triangle
        var tricx = cx + (radius + triLength1) * Math.cos(rAngle);
        var tricy = cy + (radius + triLength1) * Math.sin(rAngle);

        // calc remaining 2 triangle points
        var x1 = tricx + triLength2 * Math.cos(rAngle - PI / 2);
        var y1 = tricy + triLength2 * Math.sin(rAngle - PI / 2);
        var x2 = tricx + triLength2 * Math.cos(rAngle + PI / 2);
        var y2 = tricy + triLength2 * Math.sin(rAngle + PI / 2);

        ctx.beginPath();
        ctx.moveTo(x0, y0);
        ctx.lineTo(x1, y1);
        ctx.lineTo(x2, y2);
        ctx.fillStyle = "#BFBFBF";
        ctx.fill();
      },
      drawCirlce(ctx, _radius, rAngle, cx, cy) {
        const radius = _radius;
        var PI = Math.PI;

        var x0 = cx + radius * Math.cos(rAngle);
        var y0 = cy + radius * Math.sin(rAngle);

        ctx.save();
        ctx.beginPath();

        ctx.shadowColor = "rgba(0, 0, 0, 0.12)";
        ctx.shadowOffsetX = 0;
        ctx.shadowOffsetY = 4;
        ctx.shadowBlur = 11;

        ctx.arc(x0, y0, 11, 0, 2 * PI, false);
        ctx.fillStyle = "#D9D9D9";
        ctx.fill();
        ctx.lineWidth = 6;
        ctx.strokeStyle = "#fff";
        ctx.stroke();
        ctx.restore();
      },
      animate: function () {
        if (curPerc >= endPercent) {
          return;
        }
        const properties = this._properties;
        const needle = properties.needle;
        const angleStart = properties.angleStart;
        const angleOffset = properties.angleOffset;
        const angleEnd = properties.angleEnd;
        const actualStart = angleStart + angleOffset;
        const actualEnd = angleEnd + angleOffset;
        const label = properties.label;
        const _value = properties.val;
        const value = _value * bounce(curPerc);
        const valueToString = properties.fnValueToString;
        const valueStr =
          _value >= 100000 ? "Unlim" : valueToString(Math.ceil(value));
        const valMin = properties.valMin;
        const valMax = properties.valMax;
        const relValue = (value - valMin) / (valMax - valMin);
        const relAngle = relValue * (angleEnd - angleStart);
        const angleVal = actualStart + relAngle;
        const colorTrack = properties.colorBG;
        const trackWidth = properties.trackWidth;
        const height = this._height;
        const width = this._width;
        const smaller = width < height ? width : height;
        const centerX = 0.5 * width;
        const centerY = 0.55 * height;
        const radius = 0.4 * smaller;
        const labelY = centerY + radius - this._properties.fontSize / 2;
        const lineWidth = Math.round(trackWidth * radius);
        const lineWidthTrack = Math.round(
          this._properties.placeholderWidth * radius
        );
        const canvas = this._canvas;
        const ctx = canvas.getContext("2d");
        endPercent = 100;

        ctx.clearRect(0, 0, width, height);
        ctx.beginPath();
        ctx.arc(centerX, centerY, radius, actualStart, actualEnd);
        ctx.lineCap = "round";
        ctx.lineWidth = lineWidthTrack;
        ctx.strokeStyle = colorTrack;
        ctx.stroke();
        ctx.beginPath();
        if (needle) {
          ctx.arc(centerX, centerY, radius, angleVal - 0.1, angleVal + 0.1);
        } else {
          ctx.arc(centerX, centerY, radius, actualStart, angleVal);
        }
        var grad = ctx.createLinearGradient(326.8, 150, 8.6, 150);

        const colorsGR = this._properties.colors;
        if (colorsGR.length === 4) {
          grad.addColorStop(0, colorsGR[0]);
          grad.addColorStop(1 / 4, colorsGR[1]);
          grad.addColorStop(2 / 4, colorsGR[2]);
          grad.addColorStop(1.0, colorsGR[3]);
        } else {
          let cnt = colorsGR.length - 1;
          for (let i = 0; i < colorsGR.length; i++) {
            const cl = colorsGR[i];
            grad.addColorStop(1 / (cnt + 1), cl);
            cnt--;
          }
        }
        ctx.lineCap = "round";
        ctx.lineWidth = lineWidth;
        ctx.strokeStyle = grad;
        ctx.stroke();
        ctx.font =
          valueStr === "Unlim"
            ? `400 65px Rubik`
            : `300 ${this._properties.fontSize}px Rubik`;
        ctx.fillStyle = "black";
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";

        if (this._properties.cirlceTrack) var margin = 10;
        else margin = 0;
        ctx.fillText(valueStr, centerX, centerY - margin);
        if (label !== null) {
          if (margin > 0) margin += 10;
          const size = this._properties.labelSize;
          ctx.font = `600 ${Math.ceil(
            size || this._properties.fontSize / 2.5
          )}px Rubik`;
          ctx.fillStyle = "black";
          ctx.textAlign = "center";
          ctx.textBaseline = "middle";
          ctx.fillText(label, centerX, labelY - margin);
        }

        if (this._properties.cirlceTrack)
          this.drawCirlce(ctx, radius, angleVal, centerX, centerY);
        else this.drawTriangle(ctx, radius, angleVal, centerX, centerY);

        curPerc += 0.1;
        requestAnimationFrame(() => {
          this.animate();
        });
      },

      redraw: function () {
        this.resize();
        this.animate();
      },

      /*
       * This is called as the canvas or the surrounding DIV is resized.
       */
      resize: function () {
        const canvas = this._canvas;
        const ctx = canvas.getContext("2d");
        const scale = window.devicePixelRatio;
        canvas.style.height = this._height + "px";
        canvas.style.width = this._width + "px";
        canvas.height = Math.floor(this._height * scale);
        canvas.width = Math.floor(this._width * scale);
        ctx.scale(scale, scale);
      },

      /*
       * Sets the value of a property of this knob.
       */
      setProperty: function (key, value) {
        this._properties[key] = value;
        this.redraw();
      },

      /*
       * Sets the value of this knob.
       */
      setValue: function (value) {
        this.setValueFloating(value);
        this.commit();
      },

      /*
       * Sets floating (temporary) value of this knob.
       */
      setValueFloating: function (value) {
        const properties = this._properties;
        const valMin = properties.valMin;
        const valMax = properties.valMax;

        /*
         * Clamp the actual value into the [valMin; valMax] range.
         */
        if (value < valMin) {
          value = valMin;
        } else if (value > valMax) {
          value = valMax;
        }

        value = Math.round(value);
        this.setProperty("val", value);
      },
    };

    const resizeListener = function (e) {
      knob.redraw();
    };

    const updatePixelRatio = function () {
      const pixelRatio = window.devicePixelRatio;
      knob.redraw();
      const pixelRatioString = pixelRatio.toString();
      const matcher = "(resolution:" + pixelRatioString + "dppx)";

      const params = {
        once: true,
      };

      // window
      //   .matchMedia(matcher)
      //   .addEventListener("change", updatePixelRatio, params);
    };

    canvas.addEventListener("resize", resizeListener);
    updatePixelRatio();
    return knob;
  };
}

export default new GaugeChart();
