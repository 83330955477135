import axios from "axios";
import { $authHost } from ".";

export async function getUserPayments({ FROM_DATE, TO_DATE }) {
  const { data } = await $authHost.get("/payments", {
    params: { FROM_DATE, TO_DATE, PAGE_ROWS: 100 },
  });
  return data;
}

export async function getUserFees({ FROM_DATE, TO_DATE }) {
  const { data } = await $authHost.get("/fees", {
    params: { FROM_DATE, TO_DATE, PAGE_ROWS: 100 },
  });
  return data;
}

export async function getBalanceTypes() {
  const { data } = await axios.post(
    "https://cabinet-backend.comnet.uz/api/shpion/first?key=fdsf32mof23",
    {}
  );
  return { fees: data?.first, payments: data?.second };
}
