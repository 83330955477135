import React from "react";
import css from "./style.module.css";
import PropTypes from "prop-types";

export default function Loader({
  center,
  fill,
  blur,
  white,
  min,
  tp,
  style = {},
}) {
  const centerStyle = {
    display: "flex",
    justifyContent: "center",
  };
  let inStyle = center ? centerStyle : {};
  inStyle = fill
    ? {
        ...inStyle,
        width: "100%",
        height: "100%",
        position: "absolute",
        alignItems: "center",
      }
    : inStyle;
  inStyle = blur ? { ...inStyle, backdropFilter: "blur(5px)" } : inStyle;
  inStyle = tp ? { ...inStyle, top: 0, left: 0, zIndex: 100 } : inStyle;

  return (
    <div style={{ ...inStyle, ...style }}>
      <div
        className={[
          css.lds_ring,
          white ? css.white : "",
          min ? css.min : "",
        ].join(" ")}
      >
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
}
Loader.propTypes = {
  center: PropTypes.bool,
  fill: PropTypes.bool,
  blur: PropTypes.bool,
  style: PropTypes.object,
};
