import React from 'react'
import { useState } from 'react'
import FormModal from '../../components/FormModal'
import Loader from '../../components/Loader'
import { changeTariff } from '../../services/http/tariffs'
import { translatedText } from '../../services/translatedText'
import errorIcon from '../../assets/images/svg/error.svg'
import CheckboxGroup from '../../components/CheckboxGroup'
import { addToMonth, getCurrentMonthDate } from '../../utils/date'
import PropTypes from 'prop-types'
import useWindowSize from '../../hooks/useWindowSize'

export default function TariffModal({ handleClose, open, tariff, globalTariff, resetTariff }) {
  const [period, setPeriod] = useState(null)
  const [msg, setMsg] = useState({
    msg: '',
  })
  const [loader, setLoader] = useState(false)
  const currentTariff = tariff
  const { width } = useWindowSize()

  const apply = async () => {
    if (!currentTariff) return
    setLoader(true)
    // eslint-disable-next-line no-undef
    await new Promise((resolve) => setTimeout(resolve, 300))
    const res = await changeTariff(currentTariff.tpId, period, globalTariff.id)
    if (res?.success == 1) {
      setMsg({ msg: translatedText('Successfully completed') })
      if (period === 0 && resetTariff) {
        resetTariff()
      }
    } else {
      const msg = res?.message || ''
      if (res.error == 15 || msg.includes('ERR_SMALL_DEPOSIT'))
        setMsg({
          msg: translatedText('ERR_SMALL_DEPOSIT'),
          error: res?.error,
        })
      else if (res.error == 140 || msg.includes('NOT_ALLOWED_TO_CHANGE_TP'))
        setMsg({
          msg: translatedText('Not allowed change tariff'),
          error: res?.error,
        })
      else
        setMsg({
          msg: translatedText('Unknown error'),
          error: res?.error,
        })
    }

    setLoader(false)
  }

  const closeModal = () => {
    setPeriod(null)
    setMsg({ msg: '' })
    handleClose()
  }

  const isMSG = msg.msg || loader
  return (
    <FormModal
      open={open}
      onClose={closeModal}
      style={isMSG ? {} : { gap: '10px', margin: '0 20px' }}
      modalStyle={{ width: 'fit-content' }}
      windowWidth={width}
    >
      <div className={'col g-2 a-center' + (msg.msg ? ' frame p-1' : '')}>
        {msg.msg ? (
          <h2 style={{ color: msg?.error ? 'var(--primary-color)' : null }} className="succes-text">
            {msg.msg}
          </h2>
        ) : loader ? (
          <Loader center />
        ) : (
          <React.Fragment>
            {width > 450 ? <img width={77} height={77} src={errorIcon} alt="error-icon" /> : null}

            <h3
              style={{
                fontWeight: 400,
                fontSize: 20,
                margin: '10px 0 10px 0',
                maxWidth: 400,
                textAlign: 'center',
              }}
              dangerouslySetInnerHTML={{
                __html: translatedText('r-u-sure-change-tariff', {
                  tariff: currentTariff.name,
                }),
              }}
            ></h3>

            <CheckboxGroup
              onChange={(data) => {
                setPeriod(data[0] ? 0 : data[1] ? 1 : null)
              }}
              data={[
                translatedText('Immediately'),
                translatedText('Next accounting period', {
                  date:
                    globalTariff?.internetActivate === '0000-00-00'
                      ? getCurrentMonthDate(true, 1)
                      : addToMonth(globalTariff?.internetActivate, -1),
                }),
              ]}
            ></CheckboxGroup>
          </React.Fragment>
        )}
      </div>

      <button
        disabled={loader || (!msg.msg && period === null)}
        style={{ width: '100%', marginTop: isMSG ? 0 : 20, minWidth: 300 }}
        className="primary-btn light"
        onClick={msg.msg ? closeModal : apply}
      >
        {msg.msg ? translatedText('Close') : translatedText('Connect')}
      </button>

      {!msg.msg && !loader && (
        <button style={{ width: '100%' }} className="secondary-btn light" onClick={closeModal}>
          {translatedText('Close')}
        </button>
      )}
    </FormModal>
  )
}
TariffModal.propTypes = {
  tariff: PropTypes.object,
  handleClose: PropTypes.func,
  resetTariff: PropTypes.func,
  globalTariff: PropTypes.object,
  open: PropTypes.bool,
}
