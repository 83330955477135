import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { getUserCredit, setUserCredit } from '../services/http/userAPI'
import { translatedText } from '../services/translatedText'
import { convertToCurrency } from '../utils/parser'
import FormModal from './FormModal'
import Loader from './Loader'

export default function CreditForm({ handleClose, open, discount, balance }) {
  const [creditInfo, setCreditInfo] = useState()
  const [loading, setLoading] = useState()
  const [msg, setMsg] = useState({
    msg: '',
  })

  const closeModal = () => {
    handleClose()
  }

  useEffect(() => {
    if (!open) return

    async function fetchData() {
      setLoading(true)
      const creditInfo = await getUserCredit()
      const err = creditInfo?.errstr
      if (err === 'ERR_CREDIT_CHANGE_LIMIT_REACH') setMsg({ msg: translatedText('Credit not support') })
      else if (err === 'ERR_NO_CREDIT_CHANGE_ACCESS') setMsg({ msg: translatedText('Credit not access') })
      const creditWithDiscount = (creditInfo.creditSum * discount) / 100 - balance
      setCreditInfo({ ...creditInfo, creditWithDiscount })
      setLoading(false)
    }
    fetchData()
  }, [open])

  const onConfirm = async () => {
    try {
      setLoading(true)
      const data = await setUserCredit()
      if (data?.errstr === 'ERR_CREDIT_CHANGE_LIMIT_REACH') setMsg({ msg: translatedText('Credit not support') })
      else {
        setMsg({ msg: translatedText('Credit set'), success: true })
      }
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <FormModal open={open} onClose={closeModal}>
      {loading ? (
        <Loader center />
      ) : msg.msg ? (
        <div className={msg?.success ? 'frame space' : 'additional-frame big'}>{msg.msg}</div>
      ) : (
        <h3 style={{ fontWeight: 'normal', display: 'block' }} className="frame space">
          {translatedText('Credit sum', {
            text: convertToCurrency(creditInfo?.creditWithDiscount || '0'),
          })}
          <br />
          {translatedText('Credit price', {
            text: convertToCurrency(creditInfo?.creditChgPrice || '0'),
          })}
          <br />
          <br />

          <span>{translatedText('AreYouReallyWantToGetLoan')}</span>
        </h3>
      )}
      <div style={{ marginTop: 10 }} className="row g-2">
        {!msg.msg && !loading && (
          <button onClick={onConfirm} className="primary-btn light full">
            {translatedText('Confirm')}
          </button>
        )}
        <button onClick={closeModal} className="secondary-btn light full">
          {translatedText('Close')}
        </button>
      </div>
    </FormModal>
  )
}
