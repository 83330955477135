import React from "react";
import { translatedText } from "../services/translatedText";
import { useState } from "react";
import areEqual from "../utils/areEqual";
import CrashIcon from "../assets/images/svg/Crash1.svg";
import CircularDiv from "./CircularDiv";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchAlertConstruction } from "../services/http/alerts";
import i18next from "i18next";

function TopInfo() {
  // const [ip, setIp] = useState("0.0.0.0");
  const [isCrashed, setCrashed] = useState(false);
  const { pi } = useSelector((s) => s);
  const lang = i18next.language;

  const closeClick = () => {
    setCrashed(false);
  };
  useEffect(() => {
    if (!pi.uid) return;
    async function fetchData() {
      const { data: allData } = await fetchAlertConstruction();
      (allData || []).forEach(function loop(data) {
        if (loop.stop && data.status !== "active") {
          return;
        }

        if (data?.district != pi.districtId) return;
        if (!data?.street) return;
        if (data.build) {
          if (pi.streetId != data.street) return;
          if (pi.locationId != data.build) return;
          if (!isValidDate(data.valid_until)) return;
          setCrashed(data);
          loop.stop = true;
        } else {
          if (pi.streetId != data.street) return;
          if (!isValidDate(data.valid_until)) return;
          setCrashed(data);
          loop.stop = true;
        }
      });
    }
    fetchData();
  }, [pi]);

  const isValidDate = (valid_date) => {
    const dt = new Date(valid_date);
    if (new Date().getTime() >= dt.getTime()) return false;
    return true;
  };

  return (
    isCrashed &&
    pi?.uid && (
      <CircularDiv
        onClickClose={closeClick}
        icon={CrashIcon}
        bg
        direction="top-right"
      >
        <p className="red-text bold">{translatedText("crash-text")}</p>
        <p>{lang === "uz" ? isCrashed.text_uz : isCrashed.text_ru}</p>
      </CircularDiv>
    )
  );
}

export default React.memo(TopInfo, areEqual);
