import React, { useCallback, useEffect, useState } from 'react'
import Banner from './Banner/Banner'
import cl from './ParentalControl.module.css'
import ParentalControlBg from '../../../assets/images/svg/staticExtraServices/ParentalControlBg.svg'
import extraServices from '../../../staticData/extraServices'
import { Button, Element } from 'react-scroll'
import ConfirmationModal from '../ConfirmationModal'
import { getUserExtraService, removeUserExtraService, setUserExtraService } from '../../../services/http/userAPI'
import { translatedText } from '../../../services/translatedText'
import i18next from 'i18next'

const ParentalControl = () => {
  const yandexDnsMode = extraServices['parental-control'].full.yandex_dns_mode
  const settings = extraServices['parental-control'].full.yandex_dns_mode.settings
  const conditions = extraServices['parental-control'].full.yandex_dns_mode.conditions
  const parentalControl = extraServices['parental-control']
  const lang = i18next.language

  const [modalIsOpen, setModal] = useState(false)
  const [windowWidth, setWindowWidth] = useState(document.body.clientWidth)
  const getWindowWidth = () => setWindowWidth(document.body.clientWidth)

  useEffect(() => {
    window.addEventListener('resize', getWindowWidth)

    return () => {
      removeEventListener('resize', getWindowWidth)
    }
  }, [windowWidth])

  const modalHandler = () => setModal((prev) => !prev)

  const service = extraServices['parental-control']
  const [isServiceActive, setIsServiceActive] = useState(false)

  const getServiceAbout = async () => {
    const { activeService } = await getUserExtraService(service.id)
    setIsServiceActive(activeService ? true : false)
  }

  const setExtraService = useCallback(async () => {
    const { affected } = await setUserExtraService(service.id)
    getServiceAbout()
  }, [])

  const removeExtraService = useCallback(async () => {
    const { affected } = await removeUserExtraService(service.id)
    getServiceAbout()
  }, [])

  useEffect(() => {
    getServiceAbout()
  }, [])

  return (
    <>
      <div className={cl.main_container}>
        {/* BANNER */}
        <div className={cl.main_banner_wrapper}>
          {windowWidth > 740 ? (
            <div className={cl.main_banner}>
              <div className={cl.left_side}>
                <h2>
                  {translatedText('ParentalControl')}{' '}
                  {isServiceActive ? (
                    <span
                      style={{
                        fontSize: '16px',
                        background: '#cbffb2',
                        borderRadius: '30px',
                        padding: '8px 20px',
                        color: '#43b70d',
                        fontWeight: '500',
                      }}
                    >
                      {translatedText('Connected')}
                    </span>
                  ) : null}
                </h2>
                <p>{lang === 'ru' ? parentalControl.full.descr_ru : parentalControl.full.descr_uz}</p>

                <div className={cl.anchor_btns}>
                  <Button activeClass="active" to="about-service" spy={true} smooth={true} duration={500}>
                    {translatedText('More')}
                  </Button>

                  <Button activeClass="active" to="dns-is" spy={true} smooth={true} duration={500}>
                    {translatedText('WhatDnsIs')}
                  </Button>
                </div>
              </div>

              <div className={cl.right_side}>
                <img src={ParentalControlBg} alt="" />
              </div>
            </div>
          ) : (
            <div className={cl.left_side}>
              <h2>
                {translatedText('ParentalControl')}{' '}
                {isServiceActive ? (
                  <span
                    style={{
                      fontSize: '16px',
                      background: '#cbffb2',
                      borderRadius: '30px',
                      padding: '8px 20px',
                      color: '#43b70d',
                      fontWeight: '500',
                    }}
                  >
                    {translatedText('Connected')}
                  </span>
                ) : null}
              </h2>

              <img src={ParentalControlBg} alt="" />

              <p>
                Мы направляем ваши запросы к DNS-серверу от Яндекса, что обеспечивает мгновенное открытие веб-сайтов и
                максимальную безопасность. Яндекс DNS блокирует доступ к “взрослым” и опасным ресурсам. Услуга Яндекс
                DNS абсолютно бесплатна.
              </p>

              <div className={cl.anchor_btns}>
                <Button activeClass="active" to="about-service" spy={true} smooth={true} duration={500}>
                  Подробнее
                </Button>

                <Button activeClass="active" to="dns-is" spy={true} smooth={true} duration={500}>
                  Что такое DNS?
                </Button>
              </div>
            </div>
          )}
        </div>

        {/* YANDEX DNS */}
        <Element name="about-service">
          <div className={cl.yandex_dns_wrap}>
            <h2>{translatedText('YandexDnsWorkModes')}</h2>

            <div className={cl.settings_wrap}>
              {settings.map((el, index) => (
                <div key={index} className={cl.setting_item}>
                 {el.title_ru||el.title_uz?<h3>{lang === 'ru' ? el.title_ru : el.title_uz}</h3>:<></>}
                  <div className={cl.setting_item_inside_wrap}>
                    {lang === 'ru'
                      ? el.items_ru.map((item, index) => <div key={index}>{item}</div>)
                      : el.items_uz.map((item, index) => <div key={index}>{item}</div>)}
                  </div>
                </div>
              ))}
            </div>

            <div className={cl.conditions_wrap}>
              {conditions.map((el, index) => (
                <div key={index} className={cl.condition_item}>
                  <img src={el.svgIcon} alt={el.title_ru} />
                  <h3>{lang === 'ru' ? el.title_ru : el.title_uz}</h3>
                  <p>{lang === 'ru' ? el.descr_ru : el.descr_uz}</p>
                </div>
              ))}
            </div>

            <div className={cl.connect_service_wrap}>
              <p>{lang === 'ru' ? yandexDnsMode.some_info_ru : yandexDnsMode.some_info_uz}</p>
              <button onClick={modalHandler}>
                {isServiceActive ? translatedText('Disable') : translatedText('Enable')}
              </button>
            </div>

            <Element name="dns-is">
              <div className={cl.about_dns_wrap}>
                {yandexDnsMode.dns_is.map((el, index) => (
                  <div key={index} className={cl.about_dns_item}>
                    <h3>{lang === 'ru' ? el.title_ru : el.title_uz}</h3>

                    <div className={cl.about_dns_descr_wrap}>
                      {lang === 'ru'
                        ? el.description_ru?.map((item, index) => <p key={index}>{item}</p>)
                        : el.description_uz?.map((item, index) => <p key={index}>{item}</p>)}
                    </div>
                  </div>
                ))}
              </div>
            </Element>
          </div>
        </Element>

        <ConfirmationModal
          setExtraService={setExtraService}
          removeExtraService={removeExtraService}
          service={{ ...extraServices['parental-control'], isServiceActive }}
          isOpen={modalIsOpen}
          onClose={modalHandler}
        />
      </div>
    </>
  )
}

export default ParentalControl
