import axios from "axios";

export async function sendBringFriendRequest({ phone, name, login }) {
  const { data } = await axios.get("https://api.comnet.uz:4444/bring_friend", {
    params: {
      name,
      num: phone,
      login,
    },
  });
  return data;
}
export async function getBringFriends(login) {
  const { data } = await axios.get(
    "https://api.comnet.uz:4444/bring_friend_status",
    {
      params: {
        login,
      },
    }
  );
  return data;
}
