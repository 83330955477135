import i18next from 'i18next'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import dopPic from '../../assets/icons/dopPic.svg'
import { getUserCredit, getUserExtraService, setUserCredit } from '../../services/http/userAPI'
import extraServices from '../../staticData/extraServices'
import '../../styles/ExtraServices.css'
import ConfirmationModal from './ConfirmationModal'
import cl from './ExtraService.module.css'
import { getCurrentTariff } from '../../services/http/tariffs'
import useActions from '../../hooks/useActions'
import { translatedText } from '../../services/translatedText'

const ExtraServices = () => {
  const API = {
    PRODUCTION: 'https://serv.comnet.uz',
    TEST: 'https://newserv.comnet.uz',
  }

  const navigate = useNavigate()

  const [actions, { user, tariff: globalTariff, tariffs }] = useActions()

  const [services, setServices] = useState(Object.values(extraServices))
  const [modalIsOpen, setModal] = useState(false)
  const [isFreezing, setIsFreezing] = useState(false)
  const [credit, setCredit] = useState(false)
  const lang = i18next.language

  function getWithExpiry(key) {
    const itemStr = localStorage.getItem(key)

    if (!itemStr) {
      return null
    }

    const item = JSON.parse(itemStr)
    const now = new Date()

    if (now.getTime() > item.expiry) {
      localStorage.removeItem(key)
      return null
    }
    return item.value
  }

  function setWithExpiry(key, value, minut) {
    const now = new Date()
    const item = {
      value: value,
      expiry: now.getTime() + minut * 60 * 1000,
    }
    localStorage.setItem(key, JSON.stringify(item))
  }

  const modalHandler = () => setModal((prev) => !prev)

  const getServiceAbout = async () => {
    let res = []

    if (user?.credit) res.push({ id: 'postponement-1', activeService: 1 })

    res.push(...(await getUserExtraService()))

    const newArr = res.reduce((acc, el) => {
      if (!acc[el.id]) acc[el.id] = el
      return acc
    }, {})

    const filteredExtraServices = services.reduce((acc, el) => {
      const currService = newArr[el.id]
      const newEl = {
        activeService: 0,
        ...el,
      }

      if (currService) {
        newEl.activeService = currService.activeService
        acc.push(newEl)
      } else {
        newEl.activeService = 0
        acc.push(newEl)
      }

      return acc
    }, [])

    setServices(filteredExtraServices)
  }

  const checkCredit = async () => {
    // const res = await getUserCredit()
    // const res = await setUserCredit()
    // console.log(res)
  }

  useEffect(() => {
    if (globalTariff.tpId) {
      getServiceAbout()
      if (globalTariff.tpId === 611) setIsFreezing(true)
    }
    // checkCredit()
  }, [globalTariff])

  return (
    <div style={{ marginTop: '30px' }} className="container">
      <div className="AdditionalServices">
        <div className="AdditionalServices__inner">
          <h1 className="AdditionalServices__headTitle">{lang === 'ru' ? `Дополнительные услуги` : `Qo'shimcha xizmatlar`}</h1>
          <div className="AdditionalServices__infoTop">
              <img src={dopPic} alt="" />
              {lang === 'ru'
                ? `Дополнительные инструменты для вашего интернета`
                : "Internetingiz uchun qo'shimcha qurilmalar"}
            </div>

          <div className="AdditionalServices__items">
            {services.map((item) => (
              <Link
                to={item.route}
                className="AdditionalServices__col"
                key={item.route}
                style={{
                  padding: '0 0 20px 20px',
                }}
              >
                <div
                  className={`AdditionalServices__item ${cl.hover_item}`}
                  style={{
                    transition: 'all 0.3s',
                  }}
                >
                  <div
                    className={`AdditionalServices__top ${
                      item.activeService || (item.id === 2 && isFreezing) ? cl.active_tag : ''
                    }`}
                    data-active-tag={translatedText('Connected')}
                  >
                    <div className="AdditionalServices__icon">
                      <img src={item.svgIcon} alt="" />
                    </div>
                    <div className="AdditionalServices__title">{lang === 'ru' ? item.title_ru : item.title_uz}</div>
                    <div className={`AdditionalServices__text ${cl.text_justify}`}>
                      {lang === 'ru' ? item.descr_ru : item.descr_uz}
                    </div>
                  </div>

                  <div className="AdditionalServices__bot">
                    <div className="AdditionalServices__free">
                      {item.full.price === '0'
                        ? lang === 'ru'
                          ? item.full?.currency_ru
                          : item.full?.currency_uz
                        : `${new Intl.NumberFormat('ru-RU').format(item.full.price)} ${
                            lang === 'ru' ? item.full?.currency_ru : item.full?.currency_uz
                          }`}
                    </div>

                    <div className="AdditionalServices__more">{lang === 'ru' ? item.more_ru : item.more_uz}</div>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ExtraServices

// const [action, state] = useActions()
// const [services, setServices] = useState(state.extraServices.items)

// const getServiceAbout = async () => {
//   const res = await getUserExtraService()

//   const newArr = res.reduce((acc, el) => {
//     if (!acc[el.id]) acc[el.id] = el
//     return acc
//   }, {})

//   const filteredExtraServices = services.reduce((acc, el) => {
//     const currService = newArr[el.id]
//     const newEl = {
//       activeService: 0,
//       ...el,
//     }

//     if (currService) {
//       newEl.activeService = currService.activeService
//       acc.push(newEl)
//     } else {
//       newEl.activeService = 0
//       acc.push(newEl)
//     }

//     return acc
//   }, [])

//   setServices(filteredExtraServices)
//   action.setExtraServices(filteredExtraServices)
// }

// useEffect(() => {
//   getServiceAbout()
// }, [])
