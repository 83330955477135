import React from "react";
import PropTypes from "prop-types";

import classes from "./Share.module.css";
import soc2 from "../../assets/images/svg/soc2.svg";
import soc3 from "../../assets/images/svg/soc3.svg";
import soc4 from "../../assets/images/svg/soc4.svg";
import soc5 from "../../assets/images/svg/soc5.svg";
import soc6 from "../../assets/images/svg/soc6.svg";
import { t } from "i18next";

const Share = ({ pic, staticPic }) => {
  const path = window.location.pathname;
  const url = `https://comnet.uz${path}`;
  const socials = [
    {
      icon: soc2,
      link: `https://www.facebook.com/sharer/sharer.php?u=${url}`,
      name: "Facebook",
    },
    {
      icon: soc3,
      link: `https://t.me/share/url?url=${url}`,
      name: "Telegram",
    },
    {
      icon: soc4,
      link: "https://www.instagram.com/comnet_uz/?hl=en",
      name: "Instagram",
    },
  ];

  return (
    <div className={classes.Share}>
      <div className={classes.Share__pic}>
        {(staticPic && (
          <img
            src={pic || require("../../assets/images/sharePic.png")}
            alt=""
          />
        )) || (
          <img
            src={
              `https://serv.comnet.uz/storage/${pic}` ||
              require("../../assets/images/sharePic.png")
            }
            alt=""
          />
        )}
      </div>
      <div className={classes.Share__socialsWr}>
        <h2 className={classes.Share__socialsTitle}>{t("Share")}</h2>
        <div className={classes.Share__socials}>
          {socials.map((soc, index) => (
            <a
              key={index}
              href={soc.link}
              title={soc.name}
              className={classes.Share__social}
            >
              <img src={soc.icon} alt={soc.name} />
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};
Share.propTypes = {
  pic: PropTypes.any,
  staticPic: PropTypes.any,
};

export default Share;
