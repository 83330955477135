import React from "react";
import classes from "./style.module.css";
import { useEffect } from "react";
import i18next from "i18next";
import { Link } from "react-router-dom";
import prize from "../../assets/images/svg/prize.svg";

export default function PartnersItem({ data }) {
  const lang = i18next.language;
  const state = data && {
    logo: data.logo,
    phone: data.contact_phone.split("$"),
    site: data.contact_site,
    location:
      lang === "ru"
        ? data.contact_location_ru.split("$")
        : data.contact_location_uz.split("$"),
    title: lang === "ru" ? data.title_ru.split("$") : data.title_uz,
    info: lang === "ru" ? data.info_ru : data.info_uz,
    promoCount: data.promo_count,
    promoDesc: lang === "ru" ? data.promo_info_ru : data.promo_info_uz,
    conditions: (data.condition || []).map((item) => item.name),
    partner_card: data.partner_card,
  };
  const ref = React.useRef();
  useEffect(() => {
    if (ref.current && data.partner_card) {
      ref.current.style.backgroundImage = `url("https://serv.comnet.uz${data.partner_card}")`;
    }
  }, [ref]);

  return (
    <Link
      to={{
        pathname: "/partners/" + data.id,
      }}
      // as={`/partners/${data.id}`}
    >
      <div className={classes.container}>
        <div
          className={classes.backImage}
          ref={ref}
          // style={{ backgroundImage: data.partner_card?`url(https://serv.comnet.uz${data.partner_card})`:'url(/img/zaglushka.png)' }}
        >
          <img
            width="100px"
            src={`https://serv.comnet.uz/storage/${state.logo}`}
            alt=""
          />
        </div>
        <p className={classes.title}>
          {lang === "ru" ? data.title_ru : data.title_uz}
        </p>
        <p className={classes.info}>
          {lang === "ru" ? data.info_ru : data.info_uz}
        </p>

        {data.promo_count != 0 && (
          <div className={classes.bonus}>
            <p>{data.promo_count}%</p>
          </div>
        )}
        {data.promo_count == 0 && (
          <div className={classes.prizeList}>
            <img src={prize} />
            <div className={classes.bonus2}>
              <p>{lang == "ru" ? "Подарок" : `Sovg'a`}</p>
            </div>
          </div>
        )}
      </div>
    </Link>
  );
}
