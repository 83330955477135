import React from "react";
import CheckboxGroup from "../../../../components/CheckboxGroup";
import { translatedText } from "../../../../services/translatedText";

export default function Step6({ onPossible, onImpossible }) {
  const checkBoxData = () => [
    translatedText("High internet"),
    translatedText("Current tariff no"),
    translatedText("Cumulative discount"),
    translatedText("Caring technical support"),
    translatedText("Participation promotions"),
    translatedText("Im familiar conditions"),
  ];

  return (
    <div className="step">
      <h1 className="step-title">{translatedText("IPLUS LLC and lose")}</h1>
      <CheckboxGroup
        lowPriority
        gap={25}
        onChangeWithData={(data) => {
          var result = data.filter((d) => d?.value === true)[0]?.data;
          if (result) onPossible && onPossible(result);
          else onImpossible && onImpossible();
        }}
        data={checkBoxData()}
      />
    </div>
  );
}
