import { useAutoAnimate } from "@formkit/auto-animate/react";
import React, { useEffect } from "react";
import { useState } from "react";
import GradualActionProcess from "../../../components/Steps";
import { translatedText } from "../../../services/translatedText";
import Step0 from "./Steps/Step0";
import Step1 from "./Steps/Step1";
import Step2 from "./Steps/Step2";
import Step3 from "./Steps/Step3";
import Step4 from "./Steps/Step4";
import Step5 from "./Steps/Step5";
import Step6 from "./Steps/Step6";
import "../../../styles/terminateContract.scss";
import { useSelector } from "react-redux";
import { getCurrentDate } from "../../../utils/date";
import { sendRequestToBitrix2 } from "../../../services/http/userAPI";
import Loader from "../../../components/Loader";
import CryptoJS from "crypto-js";
import { useSearchParams } from "react-router-dom";

function generateSecretKey() {
  const secretSeed = "comnet-terminate-contract";
  const now = Math.floor(Date.now() / (5 * 60 * 1000)); // Round to the nearest 5 minutes
  return `${secretSeed}-${now}`;
}

export default function TerminateContract() {
  const { pi, user, contacts } = useSelector((state) => state);
  const steps = [
    { id: 1, content: Step0 },
    { id: 2, content: Step3 },
    { id: 3, content: Step2 },
    { id: 4, content: Step1 },
    { id: 5, content: Step4 },
    { id: 6, content: Step5 },
    { id: 7, content: Step6 },
  ];
  const [animationParent] = useAutoAnimate();
  const [showContract, setShowContract] = useState(false);
  const [finished, setFinished] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchParams, setParams] = useSearchParams();

  const actualPhone = (
    Array.isArray(contacts)
      ? contacts.filter((ph) => ph.name.includes("PHONE"))[0]
      : []
  )?.value;

  const confirm = async () => {
    setLoading(true);
    let confirming = false;
    try {
      const contract_id = pi?.contractId;
      const date = getCurrentDate();
      const address = finished["1"];
      const personal_info = finished["2"];
      const reason_disconnection = finished["3"];
      const service_to_disable = finished["4"];
      const isp_changed = finished["5"];
      const funds_on_account = finished["6"];
      const agree_service = finished["7"];
      const data = {
        contract_id,
        date,
        address,
        personal_info,
        reason_disconnection,
        service_to_disable,
        isp_changed,
        funds_on_account,
        agree_service,
      };

      await sendRequestToBitrix2(
        "Расторжение договора",
        actualPhone || "998123456789",
        user?.login,
        data
      );

      // setConfirmed(true);
      confirming = { confirmed: true };
    } catch (e) {
      console.log(e);
      confirming = {
        confirmed: true,
        error: translatedText("Failed to send request"),
      };
    } finally {
      setLoading(false);
    }
    const secretKey = generateSecretKey();
    // Convert the plain object to a string
    const plainString = JSON.stringify(confirming);
    // Encrypt the string with the secret key
    const cipherString = CryptoJS.AES.encrypt(
      plainString,
      secretKey
    ).toString();

    // Append the base64-encoded cipher text as a query parameter to the current URL
    window.location.search = `data=${cipherString}`;
  };

  useEffect(() => {
    // Parse the query string to get the 'data' parameter
    const base64CipherText = searchParams.get("data");
    if (base64CipherText) {
      try {
        const secretKey = generateSecretKey();
        // Decode the base64-encoded cipher text
        const bytes = CryptoJS.AES.decrypt(base64CipherText, secretKey);
        const decryptedString = bytes.toString(CryptoJS.enc.Utf8);

        // Parse the decrypted string as JSON
        const plainObject = JSON.parse(decryptedString);

        setConfirmed(plainObject);
      } catch (e) {
        // error
      }
    }
  }, []);

  return (
    <div ref={animationParent} className="container terminate-contract">
      <h1 style={{ margin: "30px 0 0 0" }}>
        {translatedText("Termination of an agreement")}
        <p
          style={{
            margin: "20px 0 20px 0",
            color: "#757575",
            lineHeight: "40px",
          }}
          className="regular"
        >
          {translatedText("Terminate contract text", {
            contractId: pi?.contractId,
            date: getCurrentDate(),
          })}
        </p>
      </h1>
      <button
        disabled={showContract}
        style={{ marginBottom: 60 }}
        onClick={() => setShowContract(true)}
        className="secondary-btn light a-c"
      >
        {translatedText("i want terminate")}
      </button>
      {loading ? (
        <Loader center />
      ) : confirmed ? (
        <div className="frame br center">
          <span>
            {confirmed?.error ||
              translatedText("Application successfully sent")}
          </span>
          <div className={confirmed?.error ? "error-mark" : "checkmark"}></div>
        </div>
      ) : (
        showContract && (
          <React.Fragment>
            <GradualActionProcess
              onFinish={(data) => {
                setFinished(data);
              }}
              steps={steps}
            />
            <button
              disabled={!finished}
              style={{ marginBottom: 60 }}
              onClick={confirm}
              className="primary-btn light a-c"
            >
              {translatedText("Confirm")}
            </button>
          </React.Fragment>
        )
      )}
    </div>
  );
}
