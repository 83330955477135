import React from "react";
import css from "./style.module.css";
import PropTypes from "prop-types";
import { translatedText } from "../../services/translatedText";
import { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import Input from "../Input";
import { motion } from "framer-motion";
import areEqual from "../../utils/areEqual";

var isOutOfViewport = function (elem) {
  var bounding = elem.getBoundingClientRect();
  var out = {};
  out.top = bounding.top < 0;
  out.left = bounding.left < 0;
  out.bottom =
    bounding.top + (bounding.height || 460) >
    (window.innerHeight || document.documentElement.clientHeight);
  out.right =
    bounding.right >
    (window.innerWidth || document.documentElement.clientWidth);
  out.any = out.top || out.left || out.bottom || out.right;
  out.all = out.top && out.left && out.bottom && out.right;
  return out;
};

export default function DateInput({
  label,
  type,
  onChange,
  onSelectType,
  style = {},
}) {
  const [currentDate, setCurrentDate] = useState();
  const [showPicker, setShowPicker] = useState(false);

  return (
    <label style={style} className={css.wrap}>
      {label && <span style={{ marginLeft: 25 }}>{label}</span>}
      <input
        onClick={(e) => {
          e.stopPropagation();
          setShowPicker(true);
        }}
        className={css.input}
        type="text"
        readOnly
        value={currentDate || ""}
        placeholder="____.__.__"
      />
      <motion.div
        initial={false}
        animate={showPicker ? "open" : "closed"}
        // style={showPicker ? {} : { display: "none" }}
      >
        <DatePicker
          showPicker={showPicker}
          type={type}
          onSelectType={(type) => {
            if (onSelectType) onSelectType(type);
          }}
          onHide={() => setShowPicker(false)}
          onChange={(date) => {
            setCurrentDate(date);
            if (onChange) onChange(date);
          }}
        />
      </motion.div>
    </label>
  );
}
DateInput.propTypes = {
  label: PropTypes.string,
  type: PropTypes.any,
  onChange: PropTypes.func,
  onSelectType: PropTypes.func,
  style: PropTypes.object,
};

const DatePicker = React.memo(function DatePicker({
  onChange,
  showPicker,
  type,
  onSelectType,
  onHide,
}) {
  const types = [
    translatedText("Today"),
    translatedText("Yesterday"),
    translatedText("Last day", { day: 7 }),
    translatedText("Last day", { day: 30 }),
    translatedText("Month"),
    translatedText("Previous month"),
    translatedText("Other1"),
  ];
  const parent = useRef();
  const triangle = useRef();

  const [currentType, setType] = useState(type?.id ?? types.length - 1);
  const [date, setDate] = useState();
  useEffect(() => {
    setType(type?.id ?? types.length - 1);
  }, [type?.id]);

  function hide(e) {
    e.preventDefault();
    onHide();
  }

  const isViewpPort = () => {
    if (!parent.current) return;
    const p = parent.current;
    const tr = triangle.current;
    const isOut = isOutOfViewport(p);
    if (isOut.bottom) {
      p.style.top = 0;
      p.style.bottom = "auto";
      p.style.transform = "translateY(calc(-110% + 20px))";
      tr.style.bottom = "-15px";
      tr.style.top = "auto";
      tr.style.transform = "rotate(180deg)";
    } else if (isOut.top) {
      p.style.top = "auto";
      p.style.bottom = 0;
      p.style.transform = "translateY(calc(110% + 10px))";
      tr.style.top = "-15px";
      tr.style.bottom = "auto";
      tr.style.transform = "rotate(0deg)";
    }
  };
  useEffect(() => {
    isViewpPort();
    window.removeEventListener("scroll", isViewpPort, { passive: true });
    if (!showPicker) return;
    window.addEventListener("scroll", isViewpPort, { passive: true });
    return () => {
      window.removeEventListener("scroll", isViewpPort, { passive: true });
    };
  }, [showPicker]);

  return (
    <React.Fragment>
      {showPicker && <div onClick={hide} className={css.bg}></div>}
      <motion.div
        style={{ position: "absolute", top: "50%" }}
        variants={{
          open: {
            scale: 1,
            zIndex: 2,
            transition: {
              type: "spring",
              bounce: 0.3,
              duration: 0.5,
              // delayChildren: 0.3,
              // staggerChildren: 0.05,
            },
          },
          closed: {
            scale: 0,
            transition: {
              type: "spring",
              bounce: 0,
              duration: 0.3,
            },
          },
        }}
      >
        <div ref={parent} className={css.picker}>
          <div ref={triangle} className={css.triangle}></div>
          <div style={{ marginBottom: 20 }} className="row g-2">
            <div style={{ width: 190 }} className="col">
              {types.map((type, i) => (
                <button
                  onClick={() => {
                    setType(i);
                    onSelectType(i);
                  }}
                  className={[
                    css.typeBtn,
                    currentType === i ? css.selectedTypeBtn : "",
                  ].join(" ")}
                  key={i}
                >
                  {type}
                </button>
              ))}
            </div>
            <Calendar
              apply={(date) => onChange(date)}
              onChange={setDate}
              onSelectType={setType}
              type={{ id: currentType, second: type?.second }}
            />
          </div>
          <div className="row g-2">
            <button
              onClick={(e) => {
                onChange(date);
                hide(e);
              }}
              className="primary-btn"
            >
              {translatedText("Apply")}
            </button>
            {/* <button onClick={hide} className="secondary-btn">
            {translatedText("Reject")}
          </button> */}
          </div>
        </div>
      </motion.div>
    </React.Fragment>
  );
});
DatePicker.propTypes = {
  onChange: PropTypes.func,
  onSelectType: PropTypes.func,
  onHide: PropTypes.func,
  type: PropTypes.any,
  showPicker: PropTypes.bool,
};

const Calendar = React.memo(function Calendar({
  type,
  onSelectType,
  onChange,
  apply,
}) {
  const [visibleDates, setVisibleDates] = useState([]);
  const [calendar, setCalendar] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(
    calendar.toLocaleDateString("ru")
  );
  const [customDate, setCustomDate] = useState();
  const yearEl = useRef();

  function last7Day(second) {
    let result = second
      ? new Date().toLocaleDateString("ru")
      : new Date(
          calendar.getFullYear(),
          calendar.getMonth(),
          calendar.getDate() - 7
        ).toLocaleDateString("ru");

    setSelectedDate(result);
    setCalendar(new Date());
    return result.split(".").reverse().join(". ");
  }
  function last30Day(second) {
    let result = second
      ? new Date().toLocaleDateString("ru")
      : new Date(
          calendar.getFullYear(),
          calendar.getMonth(),
          calendar.getDate() - 30
        ).toLocaleDateString("ru");

    setSelectedDate(result);
    setCalendar(new Date());
    return result.split(".").reverse().join(". ");
  }
  function currentMonth(second) {
    let result = second
      ? new Date(
          calendar.getFullYear(),
          calendar.getMonth() + 1,
          0
        ).toLocaleDateString("ru")
      : new Date(
          calendar.getFullYear(),
          calendar.getMonth(),
          1
        ).toLocaleDateString("ru");

    setSelectedDate(result);
    setCalendar(new Date());
    return result.split(".").reverse().join(". ");
  }
  function previousMonth(second) {
    let result = second
      ? new Date(
          calendar.getFullYear(),
          calendar.getMonth(),
          0
        ).toLocaleDateString("ru")
      : new Date(
          calendar.getFullYear(),
          calendar.getMonth() - 1,
          1
        ).toLocaleDateString("ru");

    setSelectedDate(result);
    setCalendar(new Date());
    return result.split(".").reverse().join(". ");
  }
  const calendarControl = {
    localDate: new Date(),
    prevMonthLastDate: null,
    calWeekDays: [
      translatedText("Mon"),
      translatedText("Tue"),
      translatedText("Wed"),
      translatedText("Thu"),
      translatedText("Fri"),
      translatedText("Sat"),
      translatedText("Sun"),
    ],
    calMonthName: [
      translatedText("Jan"),
      translatedText("Feb"),
      translatedText("Mar"),
      translatedText("Apr"),
      translatedText("May"),
      translatedText("Jun"),
      translatedText("Jul"),
      translatedText("Aug"),
      translatedText("Sep"),
      translatedText("Oct"),
      translatedText("Nov"),
      translatedText("Dec"),
    ],
    daysInMonth: function (month, year) {
      return new Date(year, month, 0).getDate();
    },
    firstDay: function () {
      return new Date(calendar.getFullYear(), calendar.getMonth(), 1);
    },
    lastDay: function () {
      return new Date(calendar.getFullYear(), calendar.getMonth() + 1, 0);
    },
    firstDayNumber: function () {
      const temp = calendarControl.firstDay().getDay();
      return temp === 0 ? 7 : temp;
    },
    lastDayNumber: function () {
      const temp = calendarControl.lastDay().getDay();
      return temp === 0 ? 7 : temp;
    },
    getPreviousMonthLastDate: function () {
      let lastDate = new Date(
        calendar.getFullYear(),
        calendar.getMonth(),
        0
      ).getDate();
      return lastDate;
    },
    navigateToPreviousMonth: function () {
      const date = calendar.setMonth(calendar.getMonth() - 1);
      setCalendar(new Date(date));
      calendarControl.attachEventsOnNextPrev();
    },
    navigateToNextMonth: function () {
      const date = calendar.setMonth(calendar.getMonth() + 1);
      setCalendar(new Date(date));
      calendarControl.attachEventsOnNextPrev();
    },
    navigateToCustomDate: function (_customDate) {
      var date = (_customDate || customDate || selectedDate).split(".");
      if (date.length < 3) return;

      let currentYear = +date[2];
      let currentMonth = date[1] - 1;

      let temp = new Date(calendar.setMonth(currentMonth));
      temp = new Date(temp.setYear(currentYear));
      setCalendar(temp);
      calendarControl.attachEventsOnNextPrev();
    },
    navigateToCurrentMonth: function () {
      let currentMonth = calendarControl.localDate.getMonth();
      let currentYear = calendarControl.localDate.getFullYear();
      let temp = new Date(calendar.setMonth(currentMonth));
      temp = new Date(temp.setYear(currentYear));
      setCalendar(temp);
      calendarControl.attachEventsOnNextPrev();
    },
    displayYear: function () {
      const yearLabel = yearEl.current;
      yearLabel.innerHTML =
        calendarControl.calMonthName[calendar.getMonth()] +
        " " +
        calendar.getFullYear();
    },
    attachEventsOnNextPrev: function () {
      calendarControl.displayYear();
      // calendarControl.attachEvents();
    },
    selectDate: function (date) {
      setCustomDate("");
      setSelectedDate(date);
      onSelectType(6);
    },
    highlightToday: function (date) {
      if (selectedDate) return selectedDate === date;
      const dateParts = date.split(".");
      const fullDate = new Date(+dateParts[2], dateParts[0] - 1, +dateParts[1]);
      return calendar.getDate() === fullDate.getDate();
    },
  };
  // const currentMonthDates = calendarControl.daysInMonth(
  //   calendar.getMonth() + 1,
  //   calendar.getFullYear()
  // );
  useEffect(() => {
    let date;
    setCustomDate(null);
    switch (type.id) {
      case 0:
        setSelectedDate(new Date().toLocaleDateString("ru"));
        setCalendar(new Date());
        break;
      case 1:
        setSelectedDate(
          new Date(
            calendar.getFullYear(),
            calendar.getMonth(),
            calendar.getDate() - 1
          ).toLocaleDateString("ru")
        );
        setCalendar(new Date());
        break;
      case 2:
        date = last7Day(type.second);
        apply(date);
        break;
      case 3:
        date = last30Day(type.second);
        apply(date);
        break;
      case 4:
        date = currentMonth(type.second);
        apply(date);
        break;
      case 5:
        date = previousMonth(type.second);
        apply(date);
        break;
    }
  }, [type]);
  useEffect(() => {
    calendarControl.displayYear();
    const displayDates = [];
    let count = 1;
    let prevDateCount = 0;
    calendarControl.prevMonthLastDate =
      calendarControl.getPreviousMonthLastDate();
    let prevMonthDatesArray = [];
    let calendarDays = calendarControl.daysInMonth(
      calendar.getMonth() + 1,
      calendar.getFullYear()
    );
    for (let i = 1; i < calendarDays; i++) {
      if (i < calendarControl.firstDayNumber()) {
        prevDateCount += 1;
        prevMonthDatesArray.push({
          date: calendarControl.prevMonthLastDate,
          fullDate: new Date(
            calendar.getFullYear(),
            calendar.getMonth() - 1,
            calendarControl.prevMonthLastDate
          ).toLocaleDateString("ru"),
          isHightlight: false,
        });
        calendarControl.prevMonthLastDate--;
      } else {
        displayDates.push({
          date: count,
          fullDate: new Date(
            calendar.getFullYear(),
            calendar.getMonth(),
            count
          ).toLocaleDateString("ru"),
          isHightlight: true,
        });
        count++;
      }
    }
    for (let j = 0; j < prevDateCount + 1; j++) {
      displayDates.push({
        date: count,
        fullDate: new Date(
          calendar.getFullYear(),
          calendar.getMonth(),
          count
        ).toLocaleDateString("ru"),
        isHightlight: true,
      });
      count++;
    }

    displayDates.unshift(...prevMonthDatesArray.reverse());
    let limit = [];
    const result = [];
    for (let i = 0; i < displayDates.length; i++) {
      limit.push(displayDates[i]);
      if (
        limit.length >= 7 ||
        (i + 1 === displayDates.length && limit.length < 7)
      ) {
        if (limit.length < 7)
          limit.push(
            ...[...Array(7 - limit.length).keys()].map((k) => {
              return {
                date: +k + 1,
                fullDate: new Date(
                  calendar.getFullYear(),
                  calendar.getMonth() + 1,
                  +k + 1
                ).toLocaleDateString("ru"),
                isHightlight: false,
              };
            })
          );
        result.push(limit);
        limit = [];
      }
    }

    setVisibleDates(result);
  }, [calendar]);
  useEffect(() => {
    onChange(selectedDate.split(".").reverse().join(". "));
  }, [selectedDate]);

  function handleInput(_date) {
    try {
      setCustomDate(_date);
      var date = _date;
      // .replace(/\s/g, "");
      date = date.split(".");
      if (date.length < 3) return;
      date = new Date(date[0], date[1] - 1, date[2]);

      let result = date.toLocaleDateString("ru");
      setSelectedDate(result);
      calendarControl.navigateToCustomDate(result);
    } catch (e) {
      setSelectedDate(new Date().toLocaleDateString("ru"));
      console.log(e);
    }
  }

  return (
    <div style={{ justifyContent: "space-between" }} className="col g-1">
      <div style={{ marginTop: 15 }} className="row between">
        <div
          onClick={calendarControl.navigateToPreviousMonth}
          className={css.arrow}
        >
          {"<"}
        </div>
        <p ref={yearEl} className={css.p}></p>
        <div
          onClick={calendarControl.navigateToNextMonth}
          className={css.arrow}
        >
          {">"}
        </div>
      </div>
      <table className={css.table}>
        <thead>
          <tr>
            <th>{translatedText("Mon")}</th>
            <th>{translatedText("Tue")}</th>
            <th>{translatedText("Wed")}</th>
            <th>{translatedText("Thu")}</th>
            <th>{translatedText("Fri")}</th>
            <th>{translatedText("Sat")}</th>
            <th>{translatedText("Sun")}</th>
          </tr>
        </thead>
        <tbody>
          {visibleDates.map((dates, key) => (
            <tr key={key}>
              {dates.map(({ date, fullDate, isHightlight }) => {
                return (
                  <td
                    onClick={() =>
                      isHightlight && calendarControl.selectDate(fullDate)
                    }
                    className={
                      calendarControl.highlightToday(fullDate)
                        ? css.hightlight
                        : !isHightlight
                        ? css.unHighlight
                        : ""
                    }
                    key={date}
                  >
                    <span className={css.tableSpan}>{date}</span>
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
      <Input
        offSelection
        clear
        maxLength={12}
        onChange={handleInput}
        value={
          customDate ||
          (selectedDate ? selectedDate.split(".").reverse().join(". ") : "")
        }
        style={{
          margin: "0 0 3px 0",
          fontSize: 14,
          padding: 15,
          background: "#f2f2f2",
        }}
      />
    </div>
  );
},
areEqual);

Calendar.propTypes = {
  type: PropTypes.object,
  onSelectType: PropTypes.func,
  onChange: PropTypes.func,
  apply: PropTypes.func,
};
