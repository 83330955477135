import axios from "axios";

export async function getPromotions() {
  const { data } = await axios.get("https://serv.comnet.uz/api/news_new", {
    headers: {
      Authorization: "Bearer 3|YOz6gV9lvSlVao1VfTzboRMDSpipDNuSMeSLaNFo",
    },
  });
  const news = data.data;
  return news;
}

export async function getPromotionById(id) {
  const { data } = await axios.get(
    "https://serv.comnet.uz/api/news_new/" + id,
    {
      headers: {
        Authorization: "Bearer 3|YOz6gV9lvSlVao1VfTzboRMDSpipDNuSMeSLaNFo",
      },
    }
  );
  const news = data.data;
  return news;
}
