import React from "react";
import PartnersTab from "../../components/PartnersTab";
import axios from "axios";
import i18next from "i18next";

function DiscountsFromPartners() {
  const [partners, setPartners] = React.useState([]);
  const lang = i18next.language;

  React.useEffect(() => {
    axios({
      method: "POST",
      url: "https://serv.comnet.uz/api/partners/coll",
      headers: {
        Authorization: "Bearer 3|YOz6gV9lvSlVao1VfTzboRMDSpipDNuSMeSLaNFo",
      },
    })
      // .then(res => res.json())
      .then((response) => {
        setPartners(response.data.data.sort((a, b) => a.order - b.order));
      });
  }, []);

  return (
    <div className="partners container">
      <div className="partners__title">
        <h1>
          {lang === "ru"
            ? `Скидки от партнеров`
            : "Hamkorladan chegirma va bonuslar"}
        </h1>
        <div className="partners__title-info">
          {lang === "ru" ? (
            <React.Fragment>
              Уважаемые абоненты, мы вас ценим и любим! И специально для вас
              организовали эксклюзивные скидки от наших друзей и партнеров! С
              каждым днем количество партнеров растет! Узнавайте первыми о новых
              скидках на сайте <span className="red-text"> comnet.uz </span>и в
              социальных сетях. Быть абонентом Comnet выгодно!
            </React.Fragment>
          ) : (
            <React.Fragment>
              Aziz obunachilar, biz sizni qadrlaymiz va yaxshi ko&apos;ramiz! Va
              ayniqsa siz uchun biz do&apos;stlarimiz va sheriklarimizdan
              eksklyuziv chegirmalar tashkil qildik! Hamkorlar va takliflar soni
              kun sayin ortib bormoqda!{" "}
              <span className="red-text"> Comnet.uz </span>
              veb-sayti va Facebook, Instagram, VK, Odnoklassniki va Telegram
              ijtimoiy tarmoqlaridagi yangi chegirmalar to&apos;g&apos;risida
              birinchi bo&apos;lib xabardor bo&apos;ling, Comnet abonentlari
              bo&apos;lish foydalidir!
            </React.Fragment>
          )}
        </div>
      </div>
      <PartnersTab data={partners} />
    </div>
  );
}

export default DiscountsFromPartners;
