const ru = {
  BonusAlert:
    'Пользуйтесь услугами COMNET, заранее пополняйте свой баланс и получайте ежемесячно скидку на абонентскую плату и сверхлимитный трафик до 15%!',
  TariffNote: `<li>Все цены указаны в национальной валюте сум с учетом НДС по действующей ставке законодательства РУз</li>
	
	<li>Предел оплаты не ограничен. Денежный остаток на абонентском счету переходит на следующий месяц</li>
	
	<li>Подключение услуг при наличии технической возможности производится в течении 5 рабочих дней.</li>
	
	<li>Скорость выше 100 Мбит/с доступна по технологии GPON или при наличии технической возможности по FTTx, и только на оборудовании Comnet (не распространяется на партнёрскую сеть других провайдеров)</li> 
	
	<li>Скорость доступа к сети Интернет зависит не только от технических особенностей Услуги, но и от действий третьих сил, организаций и лиц, управляющих сегментами сети Интернет (или оказывающих на них влияние), не принадлежащих ООО «IPLUS». Скорость доступа к сети Интернет является величиной неопределенной и зависит от ряда параметров, в том числе, технических характеристик подключения, маршрута и текущей загрузки каналов, характеристик установленного абонентского оборудования (ПК, смартфон, Wi-Fi роутер), версии программного обеспечения или используемого стандарта, месторасположения запрашиваемого контента. Все параметры являются переменными и не гарантируются ООО «IPLUS» за пределами своей сети. Указанная в тарифных планах скорость является максимально возможной</li>
	`,
  'r-u-sure-change-tariff': "Вы уверены, что хотите сменить тарифный план на <span class='red-text'>{{tariff}}?</span>",
}
const uz = {
  BonusAlert:
    'COMNET xizmatlaridan foydalaning, balansingizni oldindan to‘ldiring va abonent to‘loviga oylik chegirmaga ega bo‘ling va trafikni 15% gacha oshirib yuboring!',
  TariffNote: `<li>Barcha narxlar milliy valyuta summasida, shu jumladan O‘zbekiston Respublikasi qonunchiligining amaldagi kursi bo‘yicha QQS bilan ko‘rsatilgan.</li>
	
	<li>To'lov limiti cheklanmagan. Abonent hisobidagi qoldiq keyingi oyga o'tkaziladi</li>
	
	<li>Texnik imkoniyatlar mavjud bo'lganda xizmatlarni ulash 5 ish kuni ichida amalga oshiriladi.</li>
	
	<li>100 Mbit/s dan yuqori tezliklar GPON texnologiyasi yoki, agar texnik jihatdan iloji bo'lsa, FTTx orqali va faqat Comnet uskunasida mavjud (boshqa provayderlarning hamkor tarmoqlariga taalluqli emas)</li> 
	
	<li>Internet tarmog‘iga kirish tezligi nafaqat Xizmatning texnik xususiyatlariga, balki “IPLUS” MChJga tegishli bo‘lmagan uchinchi shaxslar, tashkilotlar va Internet segmentlarini boshqaruvchi (yoki ularga ta’sir etuvchi) shaxslarning harakatlariga ham bog‘liq. Internetga kirish tezligi cheksiz qiymat bo'lib, bir qator parametrlarga, jumladan ulanishning texnik xususiyatlariga, yo'nalish va joriy kanal yukiga, o'rnatilgan abonent uskunasining xususiyatlariga (kompyuter, smartfon, Wi-Fi) bog'liq. router), dasturiy ta'minot versiyasi yoki ishlatiladigan standart, so'ralgan kontentning joylashuvi. Barcha parametrlar o'zgaruvchan va IPLUS MChJ tarmog'idan tashqarida kafolatlanmaydi. Tarif rejalarida ko'rsatilgan tezlik maksimal mumkin</li>
	`,
  'r-u-sure-change-tariff': "Tarif rejangizni <span class='red-text'>{{tariff}}</span> ga o'zgartirmoqchimisiz?",
}

export default { ru, uz }
