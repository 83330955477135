import i18next from 'i18next'
import React, { useCallback, useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import useActions from '../../hooks/useActions'
import { getCurrentTariff, getTariffs } from '../../services/http/tariffs'
import { getUserExtraService, removeUserExtraService, setUserExtraService } from '../../services/http/userAPI'
import extraServices from '../../staticData/extraServices'
import ConfirmationModal from './ConfirmationModal'
import cl from './ExtraService.module.css'
import FreezingServiceModal from './FreezingConfirmationModal'
import { getOnline } from '../../services/http/internet'
import CreditForm from '../../components/CreditForm'
import { translatedText } from '../../services/translatedText'

const ExtraService = () => {
  const tariffState = useLocation().state
  const [actions, { user, tariff: globalTariff, tariffs }] = useActions()

  const [tariff, setCurrentTariff] = useState(tariffState)

  const lang = i18next.language
  const { id } = useParams()

  const [modalIsOpen, setModal] = useState(false)
  const [freezingModal, setFreezingModal] = useState(false)
  const [showCreditForm, setShowCreditForm] = useState(false)

  const service = extraServices[id]
  const [isServiceActive, setIsServiceActive] = useState(false)
  const [ipAddress, setIpAdress] = useState('')

  const getServiceAbout = async () => {
    let res = {}

    if (service.id === 'postponement-1' && user?.credit) {
      res = { id: 'postponement-1', activeService: 1 }
    } else {
      res = await getUserExtraService(service.id)
    }

    setIsServiceActive(res?.activeService ? true : false)

    if (res?.id == 13) {
      const info = await getOnline()

      if (info.length) {
        setIpAdress(info[0]?.clientIp)
      } else {
        setIpAdress('')
      }
    } else {
      setIpAdress('')
    }
    return res
  }

  const setExtraService = useCallback(async () => {
    const currService = await getServiceAbout()

    if (user.deposit < currService.price) {
      return lang === 'ru' ? 'На вашем балансе недостаточно средств' : 'Balansingizda yetarli mablag‘ yo‘q'
    }

    const { affected } = await setUserExtraService(service.id)
    getServiceAbout()
  })

  const removeExtraService = useCallback(async () => {
    const { affected } = await removeUserExtraService(service.id)

    if (user?.credit) actions
    getServiceAbout()
  }, [user])

  const resetTariff = async () => {
    try {
      const tempCurrentTariff = (await getCurrentTariff())[0]

      let temp = Array.isArray(tariffs) ? tariffs : await getTariffs()
      temp = !Array.isArray(tariffs) ? [] : temp

      const result = !Array.isArray(temp)
        ? temp
        : temp.filter((t) => t.name.includes('+')).sort((a, b) => a.speed1 - b.speed1)

      const crTariff = result.find((r) => r.tpId === tempCurrentTariff.tpId) || {}
      actions.setTariff({ ...tempCurrentTariff, ...crTariff })
    } catch (e) {
      console.log(e)
    }
  }

  const modalHandler = () => {
    switch (id) {
      case 'freezing':
        setFreezingModal((prev) => !prev)
        break

      case 'postponement':
        setShowCreditForm((prev) => !prev)
        break

      default:
        setModal((prev) => !prev)
    }
  }

  useEffect(() => {
    getServiceAbout()
  }, [])

  return (
    <div
      className="container"
      style={{ marginBottom: '35px', marginTop: '30px', display: 'flex', flexDirection: 'column' }}
    >
      <CreditForm open={showCreditForm} handleClose={modalHandler} />

      <ConfirmationModal
        setExtraService={setExtraService}
        removeExtraService={removeExtraService}
        service={{ ...service, isServiceActive }}
        isOpen={modalIsOpen}
        onClose={modalHandler}
      />

      <FreezingServiceModal
        open={freezingModal}
        handleClose={modalHandler}
        globalTariff={globalTariff}
        tariff={tariff || {}}
        resetTariff={resetTariff}
        service={service}
      />

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '15px',
          position: 'relative',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '15px',
            position: 'relative',
            justifyContent: 'space-between',
          }}
        >
          <h1 style={{ textAlign: 'left' }}>{lang === 'ru' ? service.title_ru : service.title_uz} </h1>
          {isServiceActive || (id === 'freezing' && globalTariff.tpId === 611) ? (
            <span
              style={{
                fontSize: '16px',
                background: '#cbffb2',
                borderRadius: '30px',
                padding: '8px 20px',
                color: '#43b70d',
                fontWeight: '500',
              }}
            >
              {translatedText('Connected')}
            </span>
          ) : null}
        </div>
        {ipAddress && (
          <div style={{ display: 'flex', alignItems: 'center', gap: 10, right: 0 }}>
            <p>{translatedText('YourIP')}</p>
            <h3 style={{ color: '#f35459', fontSize: 22 }}>{ipAddress}</h3>
          </div>
        )}
      </div>

      <div className={cl.service_wrap}>
        <div className={`${cl.sidebar} ${cl.left_side}`}>
          <div className={cl.left_content}>
            <div className={cl.svg_wrap}>
              <img src={service.full.svgIcon} alt="" />
            </div>
            <p className={cl.info_text}>{lang === 'ru' ? service.full.descr_ru : service.full.descr_uz}</p>
            {+service.full.price ? (
              <div className={cl.service_price}>
                <span style={{ fontSize: 40 }}>{new Intl.NumberFormat('ru-RU').format(service.full.price)}</span>
                {lang === 'ru' ? service.full?.currency_ru : service.full?.currency_uz}
              </div>
            ) : null}
          </div>

          <button
            className={cl.connect_btn}
            onClick={modalHandler}
            disabled={isServiceActive && service.id === 'postponement-1'}
          >
            {isServiceActive || (id === 'freezing' && globalTariff.tpId === 611)
              ? translatedText('Disable')
              : translatedText('Enable')}
          </button>
        </div>

        <div className={`${cl.sidebar} ${cl.right_side}`}>
          <div className={cl.right_content}>
            <div>
              <div className={cl.main_title}>{lang === 'ru' ? service.full.title_ru : service.full.title_uz}</div>
              <div>
                {service.full.articles.map((article, index) => (
                  <div key={index} className={cl.about}>
                    <h3 className={cl.title}>{lang === 'ru' ? article.title_ru : article.title_uz}</h3>
                    <p className={cl.descr}>{lang === 'ru' ? article.descr_ru : article.descr_uz}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {service.full.warning_ru && (
        <div>
          <em className={cl.payment_warning}>{lang === 'ru' ? service.full.warning_ru : service.full.warning_uz}</em>
        </div>
      )}
    </div>
  )
}

export default ExtraService
