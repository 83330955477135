import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import FormModal from "../../components/FormModal";
import Input from "../../components/Input";
import Radio from "../../components/Radio";
import Report from "../../components/Report";
import useWindowSize from "../../hooks/useWindowSize";
import { translatedText } from "../../services/translatedText";
import { equalObjects } from "../../utils/prototypes";
import PropTypes from "prop-types";
import {
  getUserAllInfo,
  getUserPhones,
  setUserNotification,
} from "../../services/http/userAPI";
import Loader from "../../components/Loader";
import { useEffect } from "react";
import useActions from "../../hooks/useActions";
import { parsePhone } from "../../utils/parser";
import {
  addUserContacts,
  checkVerification,
  sendCodeVerification,
  updateUserContacts,
} from "../../services/http/contacts";
import { useRef } from "react";
import {
  sendEmailResponse,
  checkEmailCode,
} from "../../services/http/internet";
import InfoText from "../../components/InfoText";
import { AnimatePresence, motion } from "framer-motion";
import { toStringDate } from "../../utils/date";

export default function Alerts() {
  const { user, pi, contacts } = useSelector((state) => state);
  const isMobile = useWindowSize().width < 1170;
  const initialData = {
    Mail: !!pi.emailNotify,
    Sms: !!pi.smsNotify,
    Off: !pi.emailNotify && !pi.smsNotify,
  };
  const [currentData, setCurrentData] = useState(initialData);
  // console.log(initialData);
  useEffect(() => {
    setCurrentData(initialData);
  }, [pi]);

  const [openModal, setOpenModal] = useState({ open: false });
  const handleCloseModal = () => {
    setOpenModal({ open: false });
  };
  const handleOpen = (change = false, actualContact = null) => {
    setOpenModal({ open: true, change, actualContact });
  };

  const handeRadio = (val) => {
    const t = Object.keys(initialData).reduce((previous, currentValue) => {
      return { ...previous, [currentValue]: false };
    }, {});
    if (val.text === translatedText("SwitchedOff")) {
      setCurrentData({ ...t, Off: val.value });
      return;
    }
    setCurrentData({ ...t, [val.text]: val.value });
  };

  const actualContact =
    Array.isArray(contacts) &&
    contacts.find(
      (cont) =>
        cont.name ===
          (currentData.Mail
            ? "EMail"
            : currentData.Sms
            ? "ACTUAL_PHONE"
            : "") && cont.value
    );

  return (
    <div className="container">
      <h1 style={{ margin: "40px 0" }}>{translatedText("Alerts")}</h1>
      <div className={!isMobile ? "row g-3" : "col g-3"}>
        {/* <div style={isMobile ? {} : { width: "60%" }} className="col g-3">
          <Collapse
            label={translatedText("Tariff plan") + ":"}
            text={translatedText("Free set of alerts")}
          />
          <Collapse
            label={translatedText("Status") + ":"}
            text={translatedText("Active")}
          />
          <DateInput label={translatedText("Registration")} />
        </div> */}
        <div style={{ width: "100%" }} className="col">
          <span style={{ marginLeft: 25, marginBottom: 10 }}>
            {translatedText("Type") + ":"}
          </span>
          <AnimatePresence mode="popLayout">
            <div className="main-block">
              <div className="row-m g-2">
                <Radio
                  style={{ width: "100%" }}
                  currentValue={currentData.Mail}
                  onChange={handeRadio}
                  text="Mail"
                />
                <Radio
                  style={{ width: "100%" }}
                  currentValue={currentData.Sms}
                  onChange={handeRadio}
                  text="Sms"
                />
                <Radio
                  style={{ width: "100%" }}
                  currentValue={currentData.Off}
                  onChange={handeRadio}
                  // defaultValue
                  text={translatedText("SwitchedOff")}
                />
              </div>
              <motion.div layout>
                {actualContact && (
                  <div
                    style={{
                      marginLeft: 10,
                      marginTop: 10,
                    }}
                    className="col"
                  >
                    {currentData.Sms ? (
                      <InfoText aCenter>
                        {translatedText("Phone number")}:
                        <span style={{ width: "100%" }}>
                          {parsePhone(actualContact.value)}
                        </span>
                        {initialData.Sms && (
                          <button
                            onClick={() => handleOpen(true, actualContact)}
                            className="primary-small-btn"
                          >
                            {translatedText("Change")}
                          </button>
                        )}
                      </InfoText>
                    ) : (
                      <InfoText aCenter>
                        {translatedText("Email")}:
                        <span style={{ width: "100%" }}>
                          {actualContact.value}
                        </span>
                        {initialData.Mail && (
                          <button
                            onClick={() => handleOpen(true, actualContact)}
                            className="primary-small-btn"
                          >
                            {translatedText("Change")}
                          </button>
                        )}
                      </InfoText>
                    )}
                  </div>
                )}
              </motion.div>
            </div>
          </AnimatePresence>
        </div>
      </div>
      <div
        style={{
          marginTop: isMobile ? 30 : 40,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <button
          onClick={() => handleOpen()}
          disabled={equalObjects(currentData, initialData)}
          style={{ fontWeight: 400, width: 350 }}
          className="primary-btn"
        >
          {translatedText("Change")}
        </button>
      </div>

      <Report user={user} />
      {currentData.Mail ? (
        <ModalEmail openModal={openModal} handleCloseModal={handleCloseModal} />
      ) : (
        <Modal
          openModal={openModal}
          handleCloseModal={handleCloseModal}
          currentData={currentData}
          username={user?.login}
        />
      )}
    </div>
  );
}

const Modal = ({ openModal, handleCloseModal, currentData, username }) => {
  const [msg, setMsg] = useState({
    msg: "",
  });
  const [code, setCode] = useState();
  const currentCode = useRef();
  const timeoutExpired = useRef();

  const [loader, setLoader] = useState(false);
  const [phone, setPhone] = useState();
  const [changePhone, setChangePhone] = useState();
  const [expired, setExpired] = useState(false);
  const [actions, { contacts }] = useActions();
  const actualPhone =
    Array.isArray(contacts) &&
    contacts.find((phone) => phone.name === "ACTUAL_PHONE");

  const sendSms = async (phone) => {
    const resSms = await sendCodeVerification(phone, username);

    if (resSms?.data == "Вы были забанены") {
      const dt = new Date(resSms?.time);
      dt.setMinutes(dt.getMinutes() + 10);
      dt.setHours(dt.getHours() - 2);
      setMsg({
        msg: translatedText("You have ban", {
          date: toStringDate(dt, true),
        }),
        error: 1,
      });
      clearTimeout(timeoutExpired.current);
      setExpired(false);
      setCode("");
    } else if (
      resSms?.error_code ||
      resSms?.status_description == "Error in sms"
    )
      setMsg({
        msg: translatedText("Unknown error"),
        error: resSms?.error_code ?? 1,
      });
    else {
      setMsg({ msg: translatedText("Sent code"), code: 1 });
      setExpired(false);
      timeoutExpired.current = setTimeout(
        () => setExpired(true),
        5 * 60 * 1000
      );
      setCode("wait");
    }
  };
  const apply = async () => {
    let phoneCurrent = phone;
    let codeState = code;
    if (code === "wait" && !expired && currentCode.current) {
      const data = await checkVerification(currentCode.current, username);
      setCode(data?.code);
      codeState = data?.code;
      setLoader(true);
    }

    if (actualPhone && !changePhone) {
      phoneCurrent = actualPhone.value + "";
    }

    if (!currentData.Off) {
      if (expired) {
        await sendSms(phoneCurrent);
        return;
      } else if (codeState) {
        if (codeState == "error") {
          setLoader(false);
          setCode("wait");
          setMsg({ msg: translatedText("Code error"), code: 1, error: 1 });
          setTimeout(() => {
            setMsg({ msg: translatedText("Sent code"), code: 1 });
          }, 4000);
          return;
        }
      } else if (phoneCurrent?.length !== 12) {
        setLoader(false);
        return;
      } else {
        await sendSms(phoneCurrent);
        return;
      }
    }

    try {
      const res1 = await setUserNotification(currentData);
      const resData = res1?.data;
      if (
        res1?.status !== "bad" &&
        (!resData ||
          resData?.affected !== undefined ||
          resData?.result === "OK")
      ) {
        setMsg({ msg: translatedText("Successfully completed") });
      } else
        setMsg({
          msg: translatedText("Unknown error"),
          error: res1?.errno ?? 1,
        });

      clearTimeout(timeoutExpired.current);
      await resetData();
    } catch (e) {
      console.log(e);
      setMsg({
        msg: e?.response?.data?.message || translatedText("Unknown error"),
        error: 1,
      });
    } finally {
      setLoader(false);
    }
    // if (currentData.Mail) return;
  };

  const resetData = async () => {
    try {
      const response = await getUserAllInfo();
      const contacts = await getUserPhones();
      actions.setPi(response);
      actions.setContacts(contacts);
    } catch (error) {
      console.log(error);
    }
  };

  const closeModal = () => {
    clearTimeout(timeoutExpired.current);
    setExpired(false);
    setCode();
    setChangePhone();
    setPhone();
    setMsg({ msg: "" });
    handleCloseModal();
  };

  useEffect(() => {
    if (!openModal?.change) return;
    const act = openModal?.actualContact;
    if (!act) return;
    setPhone(act.value + "");
    actualPhone.value = act.value + "";
    setChangePhone(act);
  }, [openModal?.change]);

  const MASK_PHONE = "+998 (##) ### ## ##";
  return (
    <FormModal open={openModal.open} onClose={closeModal}>
      <div
        style={{ marginTop: 10 }}
        className={
          (msg.msg && !msg?.code) ||
          loader ||
          currentData.Off ||
          (!changePhone && actualPhone && !code && currentData?.Sms)
            ? "frame p-1"
            : ""
        }
      >
        {loader ? (
          <Loader center />
        ) : msg.msg && !msg?.code ? (
          <h2
            style={{ color: msg?.error ? "var(--primary-color)" : null }}
            className="succes-text"
          >
            {msg.msg}
          </h2>
        ) : (
          <React.Fragment>
            {currentData.Off ? (
              <h2 style={{ margin: "0px 10px" }} className="succes-text">
                {translatedText("Are you sure disable notification")}
              </h2>
            ) : !changePhone && actualPhone && !code && currentData?.Sms ? (
              <div className="between">
                <p className="succes-text">
                  {translatedText("Current phone number")}:
                </p>
                <p className="succes-text">{parsePhone(actualPhone.value)}</p>
              </div>
            ) : (
              <div className="col g-2">
                <Input
                  mask={currentData.Sms ? MASK_PHONE : null}
                  onChange={(value) => setPhone(value)}
                  defaultValue={
                    actualPhone && currentData?.Sms
                      ? actualPhone.value.toString().slice(3)
                      : ""
                  }
                  readOnly={!!code}
                  placeholder={"+998 (12) 345 67 89"}
                  label={translatedText("Phone number")}
                />
                {!expired && code && (
                  <Input
                    offSelection
                    onChange={(value) => (currentCode.current = value)}
                    label={translatedText("Code") + ":"}
                    placeholder="12345"
                  />
                )}
              </div>
            )}
          </React.Fragment>
        )}
      </div>
      {!loader && code && msg?.code && (
        <span
          style={{
            marginLeft: 10,
            marginTop: 10,
            color: msg?.error || expired ? "var(--primary-color)" : "#646464",
          }}
        >
          {expired ? translatedText("Time expired") : msg.msg}
        </span>
      )}
      <div className="row g-2" style={{ alignItems: "end" }}>
        {!changePhone &&
          currentData?.Sms &&
          actualPhone &&
          !code &&
          !msg.error && (
            <button
              onClick={() => {
                setPhone(actualPhone.value + "");
                actualPhone.value = actualPhone.value + "";
                setChangePhone(actualPhone);
              }}
              style={{ width: "100%", height: "100%", padding: 18 }}
              className="secondary-btn"
            >
              {translatedText("Change")}
            </button>
          )}
        <button
          disabled={
            (currentData.Sms &&
              (phone?.length !== 12 || phone === actualPhone?.value)) ||
            (openModal?.change && openModal?.actualContact?.value == phone)
          }
          onClick={msg.msg && !msg?.code ? closeModal : apply}
          style={{ width: "100%", marginTop: code ? 0 : 10 }}
          className="primary-btn"
        >
          {expired
            ? translatedText("Send again")
            : msg.msg && !msg?.code
            ? translatedText("Close")
            : currentData.Off
            ? translatedText("Disable")
            : translatedText("Apply")}
        </button>
      </div>
    </FormModal>
  );
};

Modal.propTypes = {
  openModal: PropTypes.object,
  handleCloseModal: PropTypes.func,
  currentData: PropTypes.object,
};

const ModalEmail = ({ openModal, handleCloseModal }) => {
  const validateEmail = (email) => {
    const em = email ? email.toString() : "";
    return em.match(
      // eslint-disable-next-line no-useless-escape
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };
  const [msg, setMsg] = useState({
    msg: "",
  });
  const [code, setCode] = useState();
  const currentCode = useRef();
  const timeoutExpired = useRef();

  const [loader, setLoader] = useState(false);
  const [email, setEmail] = useState();
  const [changeEmail, setChangeEmail] = useState();
  const [expired, setExpired] = useState(false);
  const [actions, { contacts }] = useActions();
  const actualEmail =
    Array.isArray(contacts) &&
    contacts.find((email) => email.name === "EMail" && email.value);

  const sendEmail = async (email) => {
    try {
      setLoader(true);
      
      let resSms = await sendEmailResponse(email);

      if (resSms?.data?.status != "OK")
        setMsg({
          msg: translatedText("Unknown error"),
          error: resSms?.error_code ?? 1,
        });
      else {
        setMsg({ msg: translatedText("Sent code email"), code: 1, });
        setExpired(false);
        setCode("sent");
        timeoutExpired.current = setTimeout(
          () => setExpired(true),
          5 * 60 * 1000
        );
      }
    } catch (e) {
      console.log(e);
      setMsg({
        msg: translatedText("Unknown error"),
        error: 1,
      });
    }
    finally{
      setLoader(false);
    }
  };

  const apply = async () => {
    let emailCurrent = email;
    if (code && !expired && currentCode.current && code == currentCode.current)
      setLoader(true);

    if (actualEmail && !changeEmail) {
      emailCurrent = actualEmail.value + "";
    }

    if (expired) {
      await sendEmail(emailCurrent);
      return;
    } else if (code ==="sent") {
      const responeCode = await checkEmailCode(emailCurrent, currentCode.current);
      if (responeCode.data.code === "error") {
        setMsg({ msg: translatedText("Code error"), error: 1, code: 1 });
        return;
      }
      if (changeEmail)
        await updateUserContacts(emailCurrent, changeEmail.id, 9);
      else if (!actualEmail) await addUserContacts(emailCurrent, 9);
    } else if (!validateEmail(emailCurrent)) {
      setLoader(false);
      return;
    } else {
      await sendEmail(emailCurrent);
      return;
    }

    try{
      const res1 = await setUserNotification({
        Sms: false,
        Mail: true,
        Off: false,
      });
      const resData = res1?.data;
      if (
        res1?.status !== "bad" &&
        (!resData ||
          resData?.affected !== undefined ||
          resData?.result === "OK")
      ) {
        setMsg({ msg: translatedText("Successfully completed") });
      } else
        setMsg({
          msg: translatedText("Unknown error"),
          error: res1?.errno ?? 1,
        });

      clearTimeout(timeoutExpired.current);
      await resetData();
    } catch (e) {
      console.log(e);
      setMsg({
        msg: e?.response?.data?.message || translatedText("Unknown error"),
        error: 1,
      });
    } finally {
      setLoader(false);
    }

  };

  const resetData = async () => {
    try {
      const response = await getUserAllInfo();
      const contacts = await getUserPhones();
      actions.setPi(response);
      actions.setContacts(contacts);
    } catch (error) {
      console.log(error);
    }
  };

  const closeModal = () => {
    clearTimeout(timeoutExpired.current);
    setExpired(false);
    setCode();
    setChangeEmail();
    setEmail();
    setMsg({ msg: "" });
    handleCloseModal();
  };

  useEffect(() => {
    if (!openModal?.change) return;
    const act = openModal.actualContact;
    setEmail(act.value + "");
    actualEmail.value = act.value + "";
    setChangeEmail(act);
  }, [openModal?.change]);

  return (
    <FormModal open={openModal.open} onClose={closeModal}>
      <div
        style={{ marginTop: 10 }}
        className={(msg.msg && !msg?.code) || loader ? "frame p-1" : ""}
      >
        {loader ? (
          <Loader center />
        ) : msg.msg && !msg?.code ? (
          <h2
            style={{ color: msg?.error ? "var(--primary-color)" : null }}
            className="succes-text"
          >
            {msg.msg}
          </h2>
        ) : (
          <React.Fragment>
            {!changeEmail && actualEmail && !code ? (
              <div className="between">
                <p className="succes-text">
                  {translatedText("Current email")}:
                </p>
                <p className="succes-text">{actualEmail.value}</p>
              </div>
            ) : (
              <div className="col g-2">
                <Input
                  onChange={(value) => setEmail(value)}
                  defaultValue={actualEmail ? actualEmail.value : ""}
                  readOnly={!!code}
                  placeholder={"info@mail.ru"}
                  label={translatedText("Email")}
                />
                {!expired && code && (
                  <Input
                    offSelection
                    onChange={(value) => (currentCode.current = value)}
                    label={translatedText("Code") + ":"}
                    placeholder="12345"
                  />
                )}
              </div>
            )}
          </React.Fragment>
        )}
      </div>
      {!loader && code && msg?.code && (
        <span
          style={{
            marginLeft: 10,
            marginTop: 10,
            color: msg?.error || expired ? "var(--primary-color)" : "#646464",
          }}
        >
          {expired ? translatedText("Time expired") : msg.msg}
        </span>
      )}
      <div className="row g-2" style={{ alignItems: "end" }}>
        {!changeEmail && actualEmail && !code && !msg.error && (
          <button
            onClick={() => {
              setEmail(actualEmail.value + "");
              actualEmail.value = actualEmail.value + "";
              setChangeEmail(actualEmail);
            }}
            style={{ width: "100%", height: "100%", padding: 18 }}
            className="secondary-btn"
          >
            {translatedText("Change")}
          </button>
        )}
        <button
          disabled={
            loader ||
            (!validateEmail(email) && (!actualEmail || changeEmail)) ||
            (openModal?.change && openModal.actualContact.value == email)
          }
          onClick={msg.msg && !msg?.code ? closeModal : apply}
          style={{ width: "100%", marginTop: code ? 0 : 10 }}
          className="primary-btn"
        >
          {expired
            ? translatedText("Send again")
            : msg.msg && !msg?.code
            ? translatedText("Close")
            : translatedText("Apply")}
        </button>
      </div>
    </FormModal>
  );
};

ModalEmail.propTypes = {
  openModal: PropTypes.object,
  handleCloseModal: PropTypes.func,
};
