import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import DropDownBtn from '../../components/DropDownBtn'
import TableBlock from '../../components/TableBlock'
import { getBalanceTypes, getUserFees, getUserPayments } from '../../services/http/payments'
import { translatedText } from '../../services/translatedText'
import FilterIcon from '../../assets/images/svg/Filter.svg'
import Radio from '../../components/Radio'
import { getMonthNames } from '../../utils/date'
import DropDown from '../../components/DropDown'
import resetIcon from '../../assets/images/svg/reset.svg'
import { useRef } from 'react'
import { useForceUpdate } from 'framer-motion'

function formatDateRange(str) {
  const regex = /(\d{4}-\d{2}-\d{2})-(\d{4}-\d{2}-\d{2})/
  const match = regex.exec(str)
  if (match) {
    const startDate = match[1]
    const endDate = match[2]
    const formattedStartDate = startDate.replace(/-/g, '.')
    const formattedEndDate = endDate.replace(/-/g, '.')
    const formattedDateRange = `${formattedStartDate} - ${formattedEndDate}`
    return str.replace(regex, formattedDateRange)
  } else {
    return ((str && str.toString()) || '').replaceAll('-', '.')
  }
}

export default function AccountBalance() {
  const [dataPayments, setDataPayments] = useState([])
  const allDataPayments = useRef()
  const [dataWriteOff, setDataWriteOff] = useState([])
  const allDataWriteOff = useRef()
  const [loading, setLoading] = useState(false)
  const [filter, setFilter] = useState({ years: [], months: [] })
  const [currentFilter, setCurrentFilter] = useState({ year: '', month: [] })
  const tempFilter = useRef()
  const [forceUpdate] = useForceUpdate()

  async function fetchTypes() {
    const { fees, payments } = await getBalanceTypes()

    fetchPayments(null, null, payments)
    fetchFees(null, null, fees)
  }

  async function fetchPayments(FROM_DATE = null, TO_DATE = null, types = []) {
    const response = await getUserPayments({ FROM_DATE, TO_DATE })

    const result = response
      .sort((a, b) => new Date(b.datetime) - new Date(a.datetime))
      .map((pay) => {
        // delete pay.extId;
        delete pay.id
        delete pay.innerDescribe
        delete pay.uid
        pay.dsc = pay.dsc == '' ? '-' : pay.dsc

        var type = pay.method
        type = types.find((typ) => typ.id === type)?.name
        type = !type ? '-' : type

        pay.datetime = pay.datetime.replaceAll('-', '.')
        return [pay.datetime, pay.dsc, type, pay.sum]
      })

    setDataPayments(result)
    allDataPayments.current = result
  }

  async function fetchFees(FROM_DATE = null, TO_DATE = null, types = []) {
    const response = await getUserFees({ FROM_DATE, TO_DATE })

    const result = response
      .sort((a, b) => new Date(b.datetime) - new Date(a.datetime))
      .map((pay) => {
        delete pay.extId
        delete pay.id

        let type = pay.method
        type = types.find((typ) => typ.id === type)?.name
        type = !type ? '-' : type

        delete pay.innerDescribe
        delete pay.uid
        pay.dsc = pay.dsc == '' ? '-' : pay.dsc
        if (pay.dsc && pay.dsc !== '-') {
          const res = formatDateRange(pay.dsc)
          pay.dsc = res
        }
        pay.datetime = pay.datetime.replaceAll('-', '.')
        return [pay.datetime, pay.dsc, type, pay.sum]
      })

    setDataWriteOff(result)
    allDataWriteOff.current = result
  }

  useEffect(() => {
    fetchTypes()
  }, [])

  useEffect(() => {
    if (!dataPayments || !dataWriteOff || [...filter.years].length) return

    const all = [...dataPayments, ...dataWriteOff].sort((a, b) => new Date(b[0]) - new Date(a[0]))
    const filterResult = all.reduce(
      (current, next) => {
        const dt = new Date(next[0])
        const key = `${dt.getMonth() + 1}.${dt.getFullYear()}`
        current.years.add(dt.getFullYear())
        current.months.add(key)
        return current
      },
      // eslint-disable-next-line no-undef
      { years: new Set(), months: new Set() }
    )
    setFilter(filterResult)
    if (currentFilter.year) return
    const _y = [...filterResult.years][0]
    const result = {
      month: [...filterResult.months].filter((m) => m.split('.')[1] == _y).map((m) => m.split('.')[0] - 1),
      year: _y,
    }
    setCurrentFilter(result)
    tempFilter.current = result
  }, [dataPayments, dataWriteOff])

  const handleMonthRadio = ({ value, keyValue }) => {
    let result = tempFilter.current.month.indexOf(keyValue - 1)
    if (result < 0 && value) result = [...tempFilter.current.month, keyValue - 1]
    else if (!value && result >= 0) result = tempFilter.current.month.filter((m) => m !== keyValue - 1)
    else return

    tempFilter.current = {
      ...tempFilter.current,
      month: result,
    }
  }

  const handleYear = (year) => {
    tempFilter.current = {
      month: [...filter.months].filter((m) => m.split('.')[1] == year).map((m) => m.split('.')[0] - 1),
      year: year,
    }
    forceUpdate()
  }

  const showFilter = () => {
    const currentFilter = tempFilter.current
    if (!currentFilter.month?.length) return
    setLoading(true)
    setCurrentFilter(currentFilter)
    setDataPayments(
      allDataPayments.current.filter((d) => {
        const date = new Date(d[0])

        return currentFilter.month.indexOf(date.getMonth()) >= 0 && currentFilter.year == date.getFullYear()
      })
    )
    setDataWriteOff(
      allDataWriteOff.current.filter((d) => {
        const date = new Date(d[0])

        return currentFilter.month.indexOf(date.getMonth()) >= 0 && currentFilter.year == date.getFullYear()
      })
    )
    setLoading(false)
  }

  const resetFilter = () => {
    setLoading(true)
    const _y = [...filter.years][0]
    const result = {
      month: [...filter.months].filter((m) => m.split('.')[1] == _y).map((m) => m.split('.')[0] - 1),
      year: _y,
    }
    setCurrentFilter(result)
    tempFilter.current = result
    setDataPayments(allDataPayments.current)
    setDataWriteOff(allDataWriteOff.current)
    setLoading(false)
  }

  return (
    <div style={{ paddingBottom: 100 }} className="container">
      <div className="row-m center between">
        <h1 style={{ margin: '30px 0' }}>{translatedText('Balance account')}</h1>
        <DropDownBtn
          label={
            <div className="j-center g-1">
              <img src={FilterIcon} alt="filter-icon" />
              {translatedText('Filter1')}
            </div>
          }
        >
          {(hide) => (
            <div className="col g-3 p-1">
              <div className="grid-3">
                {[...Array(12).keys()].map((_, i) => (
                  <Radio
                    onChange={handleMonthRadio}
                    defaultValue
                    style={{
                      boxShadow: 'none',
                      background: '#F9F9F9',
                      padding: '20px 15px',
                      gap: '15px',
                      flexDirection: 'row-reverse',
                      width: 'auto',
                    }}
                    disabled={
                      ![...filter.months].find((p) => {
                        const res = p.split('.')
                        return res[0] == i + 1 && res[1] == tempFilter.current.year
                      })
                    }
                    isOff
                    text={translatedText(getMonthNames(i + 1))}
                    keyValue={i + 1}
                    key={currentFilter.year + '' + i}
                  />
                ))}
              </div>
              <DropDown onChangeItem={handleYear} defaultItem={currentFilter.year + ''} items={[...filter.years]} />
              <div className="row g-1">
                <button
                  style={{ width: 180 }}
                  className="secondary-btn light icon"
                  onClick={() => {
                    resetFilter()
                    hide()
                  }}
                >
                  <img src={resetIcon} alt="reset-icon" />
                  {translatedText('Reset')}
                </button>
                <button
                  style={{ width: 180, height: 'auto' }}
                  className="primary-btn light"
                  onClick={() => {
                    showFilter()
                    hide()
                  }}
                >
                  {translatedText('Show')}
                </button>
              </div>
            </div>
          )}
        </DropDownBtn>
      </div>

      <TableBlock
        sort={(a, b) => b - a}
        loading={loading || !dataPayments || !dataWriteOff}
        labels={[translatedText('Payment'), translatedText('Write-offs')]}
        headers={[
          [
            translatedText('Date'),
            translatedText('Description'),
            translatedText('By using'),
            // translatedText("Sum"),
            translatedText('Deposit'),
          ],
          [
            translatedText('Date'),
            translatedText('Description'),
            translatedText('Withdrawal type'),
            translatedText('Sum'),
          ],
        ]}
        data={[dataPayments, dataWriteOff]}
      />
    </div>
  )
}
