import i18next from 'i18next'
import React, { useEffect, useState } from 'react'
import FormModal from '../../components/FormModal'
import Loader from '../../components/Loader'
import { translatedText } from '../../services/translatedText'
import useWindowSize from '../../hooks/useWindowSize'

const ConfirmationModal = ({
  isOpen,
  onClose,
  service,
  textMode,
  removeExtraService,
  setExtraService,
  loadingRequest,
}) => {
  const lang = i18next.language
  const [loading, setLoading] = useState(true)
  const [anotherModal, setAnoterModal] = useState(false)
  const { width } = useWindowSize()
  const [msg, setMsg] = useState({
    msg: '',
  })

  useEffect(() => {
    setLoading(false)
  }, [])

  const onConfirm = async () => {
    if (service.isServiceActive) {
      setLoading(true)
      await removeExtraService()
      setLoading(false)
      onClose()
    } else {
      setLoading(true)
      const res = await setExtraService()

      if (res) {
        setMsg({
          msg: res,
        })
      }

      setLoading(false)
      onClose()
      setAnoterModal(true)
    }

    // if (service?.route === 'postponement') {
    //   setUserCredit
    // }
  }

  if (textMode) {
    return (
      <FormModal
        open={isOpen}
        onClose={onClose}
        style={{ alignItems: 'center', padding: '35px' }}
        modalStyle={{ width: 'min-content' }}
      >
        {loadingRequest ? (
          <Loader />
        ) : (
          <>
            {width > 450 ? (
              <svg width="77" height="77" viewBox="0 0 77 77" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M71.5 38.5C71.5 56.7254 56.7254 71.5 38.5 71.5C20.2746 71.5 5.5 56.7254 5.5 38.5C5.5 20.2746 20.2746 5.5 38.5 5.5C56.7254 5.5 71.5 20.2746 71.5 38.5ZM77 38.5C77 59.763 59.763 77 38.5 77C17.237 77 0 59.763 0 38.5C0 17.237 17.237 0 38.5 0C59.763 0 77 17.237 77 38.5ZM41.072 49.7859C41.5575 49.4339 41.8003 49.0159 41.8003 48.5319V17.1819C41.8003 16.6979 41.5575 16.2799 41.072 15.9279C40.5864 15.5759 40.0098 15.3999 39.3421 15.3999H37.6594C36.9917 15.3999 36.4151 15.5759 35.9295 15.9279C35.4439 16.2799 35.2011 16.6979 35.2011 17.1819V48.5319C35.2011 49.0159 35.4439 49.4339 35.9295 49.7859C36.4151 50.1379 36.9917 50.3139 37.6594 50.3139H39.3421C40.0098 50.3139 40.5864 50.1379 41.072 49.7859ZM41.072 61.0719C41.5575 60.7199 41.8003 60.3019 41.8003 59.8179V56.0559C41.8003 55.5719 41.5575 55.1539 41.072 54.8019C40.5864 54.4499 40.0098 54.2739 39.3421 54.2739H37.6586C36.9909 54.2739 36.4143 54.4499 35.9287 54.8019C35.4431 55.1539 35.2003 55.5719 35.2003 56.0559V59.8179C35.2003 60.3019 35.4431 60.7199 35.9287 61.0719C36.4143 61.4239 36.9909 61.5999 37.6586 61.5999H39.3421C40.0098 61.5999 40.5864 61.4239 41.072 61.0719Z"
                  fill="#F35459"
                />
              </svg>
            ) : null}

            <p
              style={{
                textAlign: 'center',
                fontFamily: 'Rubik',
                fontSize: '18px',
                lineHeight: '24px',
                wordBreak: 'break-word',
              }}
            >
              {translatedText('YourRequestHasBeenAccepted')}
            </p>

            <button onClick={onClose} className="primary-btn light">
              {translatedText('Close')}
            </button>
          </>
        )}
      </FormModal>
    )
  }

  const isMSG = msg.msg || loading

  return (
    <>
      {anotherModal ? (
        <FormModal
          open={anotherModal}
          onClose={() => setAnoterModal(false)}
          style={{ gap: '10px', margin: '0 20px' }}
          modalStyle={{ width: 'fit-content' }}
        >
          <div className={'col g-2 a-center' + (msg.msg ? ' frame p-1' : '')}>
            {loading ? (
              <Loader />
            ) : (
              <>
                {msg.msg ? (
                  <h2
                    style={{ background: '#f6f6f6', color: '#f35459', padding: '1em', borderRadius: '10px' }}
                    className="succes-text"
                  >
                    {msg.msg}
                  </h2>
                ) : (
                  <p style={{ textAlign: 'center', fontFamily: 'Rubik', fontSize: '16px', lineHeight: '24px' }}>
                    {lang === 'ru' ? <>{translatedText('Tarif')} </> : null}
                    <span style={{ color: '#F35459', fontWeight: 'bold' }}>
                      “{lang === 'ru' ? service.title_ru : service.title_uz}”
                    </span>
                    <br />
                    {translatedText('SuccessfullyConnected')}
                  </p>
                )}

                <div style={{ display: 'flex', gap: '10px', width: '100%' }}>
                  <button onClick={() => setAnoterModal(false)} className="secondary-btn light full">
                    {translatedText('Close')}
                  </button>
                </div>
              </>
            )}
          </div>
        </FormModal>
      ) : (
        <FormModal
          open={isOpen}
          onClose={onClose}
          style={{ gap: '10px', margin: '0 20px' }}
          modalStyle={{ width: 'fit-content' }}
        >
          <div className={'col g-2 a-center' + (msg.msg ? ' frame p-1' : '')}>
            {loading ? (
              <Loader />
            ) : (
              <>
                {service?.route_id === 'postponement' ? (
                  <>
                    <svg width="77" height="77" viewBox="0 0 77 77" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M71.5 38.5C71.5 56.7254 56.7254 71.5 38.5 71.5C20.2746 71.5 5.5 56.7254 5.5 38.5C5.5 20.2746 20.2746 5.5 38.5 5.5C56.7254 5.5 71.5 20.2746 71.5 38.5ZM77 38.5C77 59.763 59.763 77 38.5 77C17.237 77 0 59.763 0 38.5C0 17.237 17.237 0 38.5 0C59.763 0 77 17.237 77 38.5ZM41.072 49.7859C41.5575 49.4339 41.8003 49.0159 41.8003 48.5319V17.1819C41.8003 16.6979 41.5575 16.2799 41.072 15.9279C40.5864 15.5759 40.0098 15.3999 39.3421 15.3999H37.6594C36.9917 15.3999 36.4151 15.5759 35.9295 15.9279C35.4439 16.2799 35.2011 16.6979 35.2011 17.1819V48.5319C35.2011 49.0159 35.4439 49.4339 35.9295 49.7859C36.4151 50.1379 36.9917 50.3139 37.6594 50.3139H39.3421C40.0098 50.3139 40.5864 50.1379 41.072 49.7859ZM41.072 61.0719C41.5575 60.7199 41.8003 60.3019 41.8003 59.8179V56.0559C41.8003 55.5719 41.5575 55.1539 41.072 54.8019C40.5864 54.4499 40.0098 54.2739 39.3421 54.2739H37.6586C36.9909 54.2739 36.4143 54.4499 35.9287 54.8019C35.4431 55.1539 35.2003 55.5719 35.2003 56.0559V59.8179C35.2003 60.3019 35.4431 60.7199 35.9287 61.0719C36.4143 61.4239 36.9909 61.5999 37.6586 61.5999H39.3421C40.0098 61.5999 40.5864 61.4239 41.072 61.0719Z"
                        fill="#F35459"
                      />
                    </svg>

                    <p style={{ textAlign: 'center', fontFamily: 'Rubik', fontSize: '16px', lineHeight: '24px' }}>
                      {translatedText('ConfirmServiceActivation')}
                      <br />
                      <span style={{ color: '#F35459', fontWeight: 'bold' }}>
                        “{lang === 'ru' ? service.title_ru : service.title_uz}”
                      </span>
                      <br />
                      {translatedText('CreditAmount')}
                    </p>

                    <div
                      style={{
                        fontFamily: 'Rubik',
                        fontSize: '26px',
                        textAlign: 'center',
                      }}
                    >
                      {service.full.credit &&
                        `${new Intl.NumberFormat('ru-RU').format(service.full.credit.amount)} ${
                          lang === 'ru' ? service.full?.credit?.currency_ru : service.full?.credit?.currency_uz
                        }`}
                    </div>

                    <div style={{ display: 'flex', gap: '10px', width: '100%' }}>
                      {!msg.msg && !loading && (
                        <button onClick={onConfirm} style={{ marginBottom: '15px' }} className="primary-btn light full">
                          {translatedText('Confirm')}
                        </button>
                      )}
                      <button onClick={onClose} className="secondary-btn light full">
                        {translatedText('Close')}
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <svg width="77" height="77" viewBox="0 0 77 77" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M71.5 38.5C71.5 56.7254 56.7254 71.5 38.5 71.5C20.2746 71.5 5.5 56.7254 5.5 38.5C5.5 20.2746 20.2746 5.5 38.5 5.5C56.7254 5.5 71.5 20.2746 71.5 38.5ZM77 38.5C77 59.763 59.763 77 38.5 77C17.237 77 0 59.763 0 38.5C0 17.237 17.237 0 38.5 0C59.763 0 77 17.237 77 38.5ZM41.072 49.7859C41.5575 49.4339 41.8003 49.0159 41.8003 48.5319V17.1819C41.8003 16.6979 41.5575 16.2799 41.072 15.9279C40.5864 15.5759 40.0098 15.3999 39.3421 15.3999H37.6594C36.9917 15.3999 36.4151 15.5759 35.9295 15.9279C35.4439 16.2799 35.2011 16.6979 35.2011 17.1819V48.5319C35.2011 49.0159 35.4439 49.4339 35.9295 49.7859C36.4151 50.1379 36.9917 50.3139 37.6594 50.3139H39.3421C40.0098 50.3139 40.5864 50.1379 41.072 49.7859ZM41.072 61.0719C41.5575 60.7199 41.8003 60.3019 41.8003 59.8179V56.0559C41.8003 55.5719 41.5575 55.1539 41.072 54.8019C40.5864 54.4499 40.0098 54.2739 39.3421 54.2739H37.6586C36.9909 54.2739 36.4143 54.4499 35.9287 54.8019C35.4431 55.1539 35.2003 55.5719 35.2003 56.0559V59.8179C35.2003 60.3019 35.4431 60.7199 35.9287 61.0719C36.4143 61.4239 36.9909 61.5999 37.6586 61.5999H39.3421C40.0098 61.5999 40.5864 61.4239 41.072 61.0719Z"
                        fill="#F35459"
                      />
                    </svg>

                    {service.isServiceActive ? (
                      <>
                        <p style={{ textAlign: 'center', fontFamily: 'Rubik', fontSize: '16px', lineHeight: '24px' }}>
                          {translatedText('ConfirmDeactivationService')}{' '}
                          <span style={{ color: '#F35459', fontWeight: 'bold' }}>
                            “{lang === 'ru' ? service.title_ru : service.title_uz}”
                          </span>
                          . <br />{' '}
                          {service.full.price === '0' ? '' : translatedText('ForUnusedPeriodsAreNotRefundable')}
                        </p>
                      </>
                    ) : (
                      <>
                        <p style={{ textAlign: 'center', fontFamily: 'Rubik', fontSize: '16px', lineHeight: '24px' }}>
                          {translatedText('ConfirmServiceActivation')}
                          <br />
                          <span style={{ color: '#F35459', fontWeight: 'bold' }}>
                            “{lang === 'ru' ? service.title_ru : service.title_uz}”
                          </span>
                          {service.full.price !== '0' ? <p>{translatedText('AccountWillBeDebitedMonthly')}</p> : null}
                        </p>

                        <div
                          style={{
                            fontFamily: 'Rubik',
                            fontSize: '26px',
                            textAlign: 'center',
                          }}
                        >
                          {service.full.price !== '0' ? (
                            <>
                              {new Intl.NumberFormat('ru-RU').format(service.full.price)}{' '}
                              {lang === 'ru' ? service.full?.currency_ru : service.full?.currency_uz}
                            </>
                          ) : (
                            <>{translatedText('TheServiceIsFree')}</>
                          )}
                        </div>
                      </>
                    )}

                    <div style={{ display: 'flex', gap: '10px', width: '100%' }}>
                      {!msg.msg ? (
                        <button onClick={onConfirm} style={{ marginBottom: '15px' }} className="primary-btn light full">
                          {translatedText('Confirm')}
                        </button>
                      ) : null}
                      <button onClick={onClose} className="secondary-btn light full">
                        {translatedText('Close')}
                      </button>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </FormModal>
      )}
    </>
  )
}

export default ConfirmationModal
