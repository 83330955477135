import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { Route, useLocation, useParams, useNavigate } from 'react-router-dom'
import TariffCard from '../../components/TariffCard'
import useActions from '../../hooks/useActions'
import { getCurrentTariff, getTariffs } from '../../services/http/tariffs'
import { translatedText } from '../../services/translatedText'
import { BonusIcon } from '../../utils/SVG'
import TariffModal from './TariffModal'
import { useCheckPhone } from '../../hooks/useCheckPhone'

export default function TID() {
  const navigate = useNavigate()

  const { tid } = useParams()

  const tariffState = useLocation().state
  const [actions, { tariff: globalTariff, tariffs }] = useActions()
  const [tariff, setCurrentTariff] = useState(tariffState)

  const isCurrent = globalTariff?.tpId === tariffState?.tpId
  const isPhone = useCheckPhone()

  const resetTariff = async () => {
    try {
      const tempCurrentTariff = (await getCurrentTariff())[0]

      let temp = Array.isArray(tariffs) ? tariffs : await getTariffs()
      temp = !Array.isArray(tariffs) ? [] : temp

      const result = !Array.isArray(temp)
        ? temp
        : temp.filter((t) => t.name.includes('+')).sort((a, b) => a.speed1 - b.speed1)

      const crTariff = result.find((r) => r.tpId === tempCurrentTariff.tpId) || {}
      actions.setTariff({ ...tempCurrentTariff, ...crTariff })
    } catch (e) {
      console.log(e)
    }
  }

  const [open, setOpen] = useState(false)
  const handleClose = () => {
    setOpen(false)
  }
  const handleOpen = () => {
    setOpen(true)
  }

  useEffect(() => {
    if (window) window.scrollTo(0, 0)
    if ((Array.isArray(tariffs) && globalTariff?.id) || tid) {
      return
    }

    async function fetchData() {
      try {
        let temp = await getTariffs()
        temp = !Array.isArray(tariffs) ? [] : temp

        const result = !Array.isArray(temp)
          ? temp
          : temp.filter((t) => t.name.includes('+')).sort((a, b) => a.speed1 - b.speed1)

        actions.setTariffs(result)

        if (!globalTariff?.id || tid) {
          const tempCurrentTariff = (await getCurrentTariff())[0]
          const crTariff = result.find((r) => r.tpId === tempCurrentTariff.tpId) || {}
          actions.setTariff({ ...tempCurrentTariff, ...crTariff })
        }
      } catch (e) {
        console.log(e)
      }
    }

    fetchData()
  }, [])

  return (
    <div className="container">
      <h1 style={{ margin: '30px 0 30px 0' }}>{tariff?.tpName || tariff?.name}</h1>

      <TariffCard page tariff={tariff} />
      <p className="red-text" style={{ marginTop: 20, fontSize: isPhone ? 12 : 20 }}>
        <span>* {translatedText('Speed-alert')}</span>
      </p>

      {isCurrent ? (
        <div style={{ margin: '50px 0' }}></div>
      ) : (
        <div style={{ margin: '50px 0' }} className="j-center">
          <button onClick={handleOpen} style={{ maxWidth: 300 }} className="primary-btn light full">
            {translatedText('Connect')}
          </button>
        </div>
      )}

      <div className="additional-frame hover_me" onClick={() => navigate('/bonus')}>
        <div className="row g-3 red-text">
          {<BonusIcon color="var(--primary-color)" />}
          {translatedText('BonusAlert')}
        </div>
      </div>

      <ul
        className="list-note"
        dangerouslySetInnerHTML={{
          __html: translatedText('TariffNote'),
        }}
      ></ul>

      <TariffModal
        globalTariff={globalTariff}
        open={open}
        tariff={tariff || {}}
        handleClose={handleClose}
        resetTariff={resetTariff}
      />
    </div>
  )
}
