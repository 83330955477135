export function getCurrentDate() {
  const t = new Date()
  const month = t.getMonth() + 1 < 10 ? '0' + (t.getMonth() + 1) : t.getMonth() + 1
  const date = t.getDate() < 10 ? '0' + t.getDate() : t.getDate()
  return `${date}.${month}.${t.getFullYear()}`
}

export function getCurrentDate1() {
  const t = new Date()

  const month = t.getMonth() + 1 < 10 ? '0' + (t.getMonth() + 1) : t.getMonth() + 1
  const date = t.getDate() < 10 ? '0' + t.getDate() : t.getDate()
  t.return`${t.getFullYear()}-${month}-${date}`
}

export function getCurrentMonthDate(first = true, monthAdd = 0) {
  const date = new Date()

  if (first) {
    const firstDay = new Date(date.getFullYear(), date.getMonth() + monthAdd, 1)
    return toStringDate(firstDay)
  } else {
    const lastDay = new Date(date.getFullYear(), date.getMonth() + monthAdd, 0)
    return toStringDate(lastDay)
  }
}

export function getCurrentMonthToDate(first = true, monthAdd = 0) {
  const date = new Date()
  if (first) {
    const firstDay = new Date(date.getFullYear(), date.getMonth() + monthAdd, 1)
    return firstDay
  } else {
    const lastDay = new Date(date.getFullYear(), date.getMonth() + monthAdd, 0)
    return lastDay
  }
}

export function modifyDate(date) {
  if (!date) return
  return date.split('-').reverse().join('.')
}

export function daysBetweenDates(date) {
  const oneDay = 24 * 60 * 60 * 1000 // hours*minutes*seconds*milliseconds
  const today = new Date()
  const [day, month, year] = date.split('.')
  const givenDate = new Date(`${year}-${month}-${day}`)
  const daysLeft = Math.round(Math.abs((today - givenDate) / oneDay))

  return daysLeft
}

export function intervalBetweenDate(t1, t2) {
  if (!t1 || !t2) return {}

  const date1 = toDate(t1)
  const date2 = toDate(t2)
  const diffTime = Math.abs(date2 - date1)
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
  return { days: diffDays }
}

export function toDate(dateStr) {
  if (!dateStr) return

  const startDate = new Date(dateStr)
  const endDate = startDate.setDate(startDate.getDate() + 31)

  return endDate
}

export function toStringDate(t, time = false) {
  const res = t.toLocaleDateString('ru')

  t.setHours(t.getHours() + 2)

  if (time) return `${t.toLocaleTimeString('ru')} - ${t.toLocaleDateString('ru')}`

  return res
}

export function checkTodayDate(date) {
  var todaysDate = new Date()
  // console.log("0" + (date.getMonth() + 1));

  if (date.setHours(0, 0, 0, 0) == todaysDate.setHours(0, 0, 0, 0)) return true
  else return false
}

export function checkYesterdayDate(date) {
  var todaysDate = new Date()
  todaysDate.setDate(todaysDate.getDate() - 1)

  if (date.setHours(0, 0, 0, 0) == todaysDate.setHours(0, 0, 0, 0)) return true
  else return false
}

export function checkDateInThisWeek(date) {
  const todayObj = new Date()
  const todayDate = todayObj.getDate()
  const todayDay = todayObj.getDay()
  date.setHours(0, 0, 0, 0)
  todayObj.setHours(0, 0, 0, 0)

  const firstDayOfWeek = new Date(todayObj.setDate(todayDate - todayDay))

  firstDayOfWeek.setHours(0, 0, 0, 0)
  return date >= firstDayOfWeek && date <= todayObj
}

export function isInCurrentWeek(date) {
  const today = new Date()
  const firstDayOfWeek = new Date(today.setDate(today.getDate() - today.getDay()))
  const lastDayOfWeek = new Date(today.setDate(today.getDate() - today.getDay() + 6))

  return date >= firstDayOfWeek && date <= lastDayOfWeek
}

export function checkDateInThisMonth(date) {
  const todayObj = new Date()
  date.setHours(0, 0, 0, 0)
  todayObj.setHours(0, 0, 0, 0)
  // console.log(
  //   `${date.getFullYear()}.${date.getMonth() + 1}.${date.getDate()}`,
  //   `${todayObj.getFullYear()}.${todayObj.getMonth() + 1}.${todayObj.getDate()}`
  // );
  if (date.getFullYear() == todayObj.getFullYear() && date.getMonth() + 1 == todayObj.getMonth() + 1) return true
  else return false
}

export function addDuration(d1, d2) {
  let durs1 = d1.split(':')
  if (!d2) return d1
  let durs2 = d2 ? d2.split(':') : []
  durs1 = durs1[0] * 3600 + durs1[1] * 60 + +durs1[2]
  durs2 = durs2[0] * 3600 + durs2[1] * 60 + +durs2[2]
  const result = durs1 + durs2
  return toHHMMSS(result)
}

function toHHMMSS(sec) {
  var sec_num = parseInt(sec, 10)
  var hours = Math.floor(sec_num / 3600)
  var minutes = Math.floor((sec_num - hours * 3600) / 60)
  var seconds = sec_num - hours * 3600 - minutes * 60

  if (hours < 10) {
    hours = '0' + hours
  }
  if (minutes < 10) {
    minutes = '0' + minutes
  }
  if (seconds < 10) {
    seconds = '0' + seconds
  }
  return hours + ':' + minutes + ':' + seconds
}

export function addToMonth(date, month = 1) {
  if (!date) return ''

  const endDate = toDate(date)

  return toStringDate(new Date(endDate))
}

export function formatDate(date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear()

  if (month.length < 2) month = '0' + month
  if (day.length < 2) day = '0' + day

  return [year, month, day].join('-')
}

export function displayChartDate(date) {
  if (!date) return ''
  let res = date.split('-').join('.')
  res = res.split(' ').join('   ')
  return res
}

export function getMaxDate(data, objKey = '') {
  const max = Math.max(...data.map((e) => new Date(e[objKey])))
  if (max > 0) return new Date(max)
  else return 0
}

export function getMonthNames(monthNumber, full = false) {
  switch (monthNumber) {
    case 1:
      return full ? 'January' : 'Jan'
    case 2:
      return full ? 'February' : 'Feb'
    case 3:
      return full ? 'March' : 'Mar'
    case 4:
      return full ? 'April' : 'Apr'
    case 5:
      return full ? 'Mayf' : 'May'
    case 6:
      return full ? 'June' : 'Jun'
    case 7:
      return full ? 'Jule' : 'Jul'
    case 8:
      return full ? 'August' : 'Aug'
    case 9:
      return full ? 'September' : 'Sep'
    case 10:
      return full ? 'October' : 'Oct'
    case 11:
      return full ? 'November' : 'Nov'
    case 12:
      return full ? 'December' : 'Dec'
  }
}
