import axios from "axios";
import { $authHost } from ".";

export async function getSessions(FROM_DATE, TO_DATE, PAGE_ROWS = 10000) {
  const { data } = await $authHost.get("/sessions", {
    params: { FROM_DATE, TO_DATE, PAGE_ROWS },
  });
  return data;
}

export async function getOnline() {
  const { data } = await $authHost.get("/online");
  return data;
}

export async function getDetail(FROM_DATE, TO_DATE, PAGE_ROWS = 10000) {
  const { data } = await $authHost.get("/sessions/detail", {
    params: { FROM_DATE, TO_DATE, PAGE_ROWS },
  });
  return data;
}

export async function sendEmailResponse(email = null) {
  //test
  const { data } = await axios.post(
    "https://cabinet-backend.comnet.uz/api/check/email",
    {
      email,
      key: "fds2f23f3KFJSK0f32nkjfn23kjhf23@#(#*#"
    },
  );

  return data;
}
export async function checkEmailCode(email = null, code = null) {
  //test
  const { data } = await axios.post(
    "https://cabinet-backend.comnet.uz/api/check/email/" + email,
    {
      code,
      key: "fds2f23f3KFJSK0f32nkjfn23kjhf23@#(#*#"
    },
    {
      headers: {
        Authorization: "Bearer 7|yEiYtNKD5fFUYc2vs3NICnP5uRRbbFZPGIq6y1aY",
      },
    }
  );

  return data;
}
