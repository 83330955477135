import React from 'react'
import PropTypes from 'prop-types'
import css from './Input.module.css'
import { useState } from 'react'
import { useEffect } from 'react'
import { useRef } from 'react'
import { translatedText } from '../../services/translatedText'

const moneyMask = (value) => {
  value = value.replace(' ', '')
  const options = { minimumFractionDigits: 1 }
  const result = new Intl.NumberFormat('ru-RU', options).format(value).slice(0, -2)

  return result + ' ' + translatedText('sum')
}

export default function Input({
  validation,
  label,
  init = false,
  hideLabel = false,
  children,
  clear,
  suffix,
  typeInput,
  makeFocus = false,
  onChange: onChangeValue,
  defaultValue = '',
  marginLeftSpan = 25,
  mask,
  mini,
  textarea = false,
  resize = '',
  wrapStyle = {},
  className = '',
  rightSuffix,
  offSelection = false,
  inputStyle = {},
  ...props
}) {
  const [value, setValue] = useState(mask && defaultValue ? setMask(defaultValue) : defaultValue)
  const [error, setError] = useState(false)
  const inp = useRef()
  const modValue = useRef()
  function createSelection(field, start, end) {
    if (field.createTextRange) {
      var selRange = field.createTextRange()
      selRange.collapse(true)
      selRange.moveStart('character', start)
      selRange.moveEnd('character', end - start)
      selRange.select()
    } else if (field.setSelectionRange) {
      field.setSelectionRange(start, end)
    } else if (field.selectionStart) {
      field.selectionStart = start
      field.selectionEnd = end
    }
    field.focus()
  }

  useEffect(() => {
    if (!validation?.submitted) {
      setError(false)
      if (validation?.setValids) validation.setValids(false)
      return
    }
    if ((validation?.check && !validation.check(modValue.current)) || !modValue.current) {
      setError(true)
      validation.setValids(true)
    }
  }, [validation?.submitted])
  useEffect(() => {
    if (!makeFocus || !inp.current) return
    // inp.current.focus();
    createSelection(inp.current, value.length, value.length)
  }, [makeFocus])

  useEffect(() => {
    if ((!inp.current || value == defaultValue) && typeInput !== 'currency') return

    const t = typeInput === 'currency' ? value.length - translatedText('sum').length - 1 : value.length

    if (offSelection) return
    createSelection(inp.current, t, t)
  }, [value])
  useEffect(() => {
    if (init && onChangeValue) onChangeValue(defaultValue)
    if (value) return
    setValue(defaultValue)
  }, [defaultValue])

  function setMask(value) {
    let code = mask.split(' ')[0]
    let matrix = mask

    let i = 0,
      val = value.replace(code, '').replace(/\D/g, '')

    value = matrix.replace(/(?!\+)./g, function (a) {
      return /[#]/.test(a) && i < val.length ? val.charAt(i++) : i >= val.length ? '' : a
    })

    return value
  }

  const onInput = (e) => {
    if (!mask) return
    let val = e.target.value
    const t = val ? setMask(val) : val
    modValue.current = t ? t.replace(/\D/g, '') : t
    if (onChangeValue) onChangeValue(modValue.current)
    setValue(t)
  }

  const onChange = (e) => {
    if (mask) return
    // const data = e.nativeEvent.data;
    // const value2 = data ? value + data : value.slice(0, -1)
    let val = e.target.value
    if (typeInput === 'number') val = val.replace(/\D/g, '')
    else if (typeInput === 'currency') val = moneyMask(val.replace(/\D/g, '') ?? 0)

    modValue.current = typeInput === 'currency' ? val.replace(/\D/g, '') : val
    if (onChangeValue) onChangeValue(modValue.current)
    setValue(val)
  }

  const CustomTag = textarea ? 'textarea' : 'input'

  return clear ? (
    <CustomTag
      spellCheck="false"
      ref={inp}
      onInput={onInput}
      value={suffix ? value + ' ' + suffix : value}
      onChange={onChange}
      className={[css.input, mini ? css.miniInput : '', error ? css.errorInput : '', className].join(' ')}
      style={{ resize, border: '1px solid transparent', ...inputStyle }}
      type="text"
      {...props}
    />
  ) : (
    <label className={[css.label, error ? css.errorLabel : ''].join(' ')} style={wrapStyle}>
      {!hideLabel && <span style={{ marginLeft: marginLeftSpan }}>{label}</span>}
      <div className={css.wrap}>
        <CustomTag
          spellCheck="false"
          onInput={onInput}
          ref={inp}
          value={suffix ? value + ' ' + suffix : value}
          onChange={onChange}
          className={[css.input, mini ? css.miniInput : '', error ? css.errorInput : '', className].join(' ')}
          style={{ resize, border: '1px solid transparent', ...inputStyle }}
          type="text"
          {...props}
        />
        {children}
        {rightSuffix && <div className={css.rightSuffix}>{rightSuffix}</div>}
      </div>
    </label>
  )
}

Input.propTypes = {
  label: PropTypes.string,
  suffix: PropTypes.string,
  mask: PropTypes.string,
  children: PropTypes.any,
  clear: PropTypes.bool,
  mini: PropTypes.bool,
  makeFocus: PropTypes.bool,
  onChange: PropTypes.func,
  typeInput: PropTypes.string,
  placeholder: PropTypes.string,
  validation: PropTypes.object,
  inputStyle: PropTypes.object,
}
