import React, { useEffect } from 'react'
import { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { translatedText } from '../services/translatedText'
import {
  BonusIcon,
  BonusMobIcon,
  BringFriendIcon,
  DetailIcon,
  DiscountIcon,
  ExtraServicesImg,
  JingleIcon,
  PauseIcon,
  PersonMobIcon,
  ResetIcon,
  ServiceMobIcon,
  TariffsPlanIcon,
  TerminateConIcon,
  WalletMobIcon,
  WifiMobIcon,
} from '../utils/SVG'
import CabinetDropDown from './CabinetDropDown'
import Header from './Header'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { useCheckPhone } from '../hooks/useCheckPhone'
import extraServices from '../staticData/extraServices'
import useActions from '../hooks/useActions'

export default function Headers() {
  const [currentMenu, setCurrentMenu] = useState({ show: false })
  const location = useLocation()
  const locationHash = window.location.hash

  const [actions, { user }] = useActions()

  const isLegal = user?.companyId > 0 || user?.gid === 4

  const onClickMenuButton = (items, id, left, history = false) => {
    if (history || id === currentMenu.id || items.length < 1) setCurrentMenu({ show: false })
    else setCurrentMenu({ show: true, id, left, items })
  }

  const isLogin = location.pathname === '/login'
  const isPhone = useCheckPhone()

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [locationHash])

  return (
    <React.Fragment>
      {currentMenu.show && (
        <div onClick={() => setCurrentMenu({ show: false })} className="mobile-dropdown-content">
          <CSSTransition classNames="toUp" in appear timeout={500}>
            <div className="wrap">
              <TransitionGroup appear>
                {currentMenu.items.map((item, i) => {
                  const isThis = item.to == location.pathname
                  return (
                    <CSSTransition
                      in
                      appear
                      key={item.to}
                      timeout={50 * (i + 1)}
                      classNames="dropdown-link"
                    >
                      <div className="menu-item-mob">
                        <Link
                          onClick={() => setCurrentMenu({ show: false })}
                          to={item.to ?? '/'}
                          key={i}
                          className={isThis ? 'red-text menu-item-text' : 'menu-item-text'}
                        >
                          {item.image && <item.image color={isThis ? 'var(--primary-color)' : undefined} />}
                          {item.text}
                        </Link>
                      </div>
                    </CSSTransition>
                  )
                })}
              </TransitionGroup>
              </div>
          </CSSTransition>
        </div>
      )}
      <div>
        <Header isLogin={isLogin} />
        {!isLogin &&
          (!isPhone ? (
            <div className="cabinet-header">
              <div className="container">
                <CabinetDropDown id={0} onClick={onClickMenuButton} text={translatedText('Card user')} to="/" />

                <CabinetDropDown
                  id={1}
                  onClick={onClickMenuButton}
                  text={translatedText('Balance account')}
                  to="/account-balance"
                />
                <CabinetDropDown
                  id={2}
                  onClick={onClickMenuButton}
                  text={translatedText('Internet')}
                  items={
                    isLegal
                      ? [
                          {
                            to: '/statistics',
                            image: DetailIcon,
                            text: translatedText('Detailing'),
                            desc: 'Подробная информация о текущей сессии, обьеме траффика, статистика по полученным и отправленным пакетам',
                          },
                          {
                            to: '/reset-password',
                            image: ResetIcon,
                            text: translatedText('Reset password'),
                          },
                        ]
                      : [
                          {
                            to: '/tariffs-plan',
                            image: TariffsPlanIcon,
                            text: translatedText('Tariff plan'),
                            desc: 'Выгодные интернет тарифы для дома.',
                          },
                          {
                            to: '/statistics',
                            image: DetailIcon,
                            text: translatedText('Detailing'),
                            desc: 'Подробная информация о текущей сессии, обьеме траффика, статистика по полученным и отправленным пакетам',
                          },
                          {
                            to: '/reset-password',
                            image: ResetIcon,
                            text: translatedText('Reset password'),
                          },
                        ]
                  }
                />

                {!isLegal?<CabinetDropDown
                  id={3}
                  onClick={onClickMenuButton}
                  text={translatedText('ExtraServices')}
                  to="/extra-services"
                />:<></>}

                <CabinetDropDown
                  id={4}
                  onClick={onClickMenuButton}
                  text={translatedText('Shares and bonuses')}
                  items={[
                    {
                      to: '/bonus',
                      text: translatedText('Bonus-be-in-the-black'),
                      image: BonusIcon,
                      desc: 'Своевременно пополняйте баланс и получайте ежемесячно скидку на абонентскую плату до 15%',
                    },
                    {
                      to: '/discounts-from-partners',
                      text: translatedText('Discounts from partners'),
                      image: DiscountIcon,
                      desc: 'Эксклюзивные скидки от наших друзей и партнеров! Быть абонентом Comnet выгодно!',
                    },
                    {
                      to: '/bring-a-friend',
                      image: BringFriendIcon,
                      text: translatedText('Bring a friend'),
                    },
                  ]}
                />
                <CabinetDropDown
                  id={5}
                  onClick={onClickMenuButton}
                  text={translatedText('Help')}
                  items={[
                    {
                      to: '/alerts',
                      text: translatedText('Service Alerts'),
                      image: JingleIcon,
                      desc: 'Включение / отключение СМС информирования',
                    },
                    {
                      to: '/terminate-contract',
                      image: TerminateConIcon,
                      text: translatedText('Termination of an agreement'),
                      desc: 'Завершение договорных отношений между клиентом и компанией',
                    },
                  ]}
                />
              </div>
            </div>
          ) : (
            <div className="cabinet-header">
              <CabinetDropDown
                id={3}
                Image={BonusMobIcon}
                onClick={onClickMenuButton}
                items={[
                  {
                    to: '/bonus',
                    text: translatedText('Bonus-be-in-the-black'),
                    image: BonusIcon,
                    desc: 'Своевременно пополняйте баланс и получайте ежемесячно скидку на абонентскую плату до 15%',
                  },
                  {
                    to: '/discounts-from-partners',
                    text: translatedText('Discounts from partners'),
                    image: DiscountIcon,
                    desc: 'Эксклюзивные скидки от наших друзей и партнеров! Быть абонентом Comnet выгодно!',
                  },
                  {
                    to: '/bring-a-friend',
                    image: BringFriendIcon,
                    text: translatedText('Bring a friend'),
                  },
                ]}
              />
              <CabinetDropDown
                id={2}
                onClick={onClickMenuButton}
                Image={WifiMobIcon}
                items={
                  isLegal
                    ? [
                        {
                          to: '/statistics',
                          image: DetailIcon,
                          text: translatedText('Detailing'),
                          desc: 'Подробная информация о текущей сессии, обьеме траффика, статистика по полученным и отправленным пакетам',
                        },
                        {
                          to: '/reset-password',
                          image: ResetIcon,
                          text: translatedText('Reset password'),
                        },
                      ]
                    : [
                        {
                          to: '/tariffs-plan',
                          image: TariffsPlanIcon,
                          text: translatedText('Tariff plan'),
                          desc: 'Выгодные интернет тарифы для дома.',
                        },
                        {
                          to: '/statistics',
                          image: DetailIcon,
                          text: translatedText('Detailing'),
                          desc: 'Подробная информация о текущей сессии, обьеме траффика, статистика по полученным и отправленным пакетам',
                        },
                        {
                          to: '/reset-password',
                          image: ResetIcon,
                          text: translatedText('Reset password'),
                        },
                      ]
                }
              />
              <CabinetDropDown id={0} onClick={onClickMenuButton} Image={PersonMobIcon} to="/" />
              <CabinetDropDown id={1} onClick={onClickMenuButton} Image={WalletMobIcon} to="/account-balance" />
              <CabinetDropDown
                id={4}
                onClick={onClickMenuButton}
                Image={ServiceMobIcon}
                items={!isLegal? [
                  {
                    to: '/extra-services',
                    text: translatedText('ExtraServices'),
                    image: ExtraServicesImg,
                    desc: 'Включение / отключение СМС информирования',
                  },
                  {
                    to: '/alerts',
                    text: translatedText('Service Alerts'),
                    image: JingleIcon,
                    desc: 'Включение / отключение СМС информирования',
                  },
                  {
                    to: '/terminate-contract',
                    image: TerminateConIcon,
                    text: translatedText('Termination of an agreement'),
                    desc: 'Завершение договорных отношений между клиентом и компанией',
                  },
                ]:
              [
                  {
                    to: '/alerts',
                    text: translatedText('Service Alerts'),
                    image: JingleIcon,
                    desc: 'Включение / отключение СМС информирования',
                  },
                  {
                    to: '/terminate-contract',
                    image: TerminateConIcon,
                    text: translatedText('Termination of an agreement'),
                    desc: 'Завершение договорных отношений между клиентом и компанией',
                  },
                ]}
              />
            </div>
          ))}
      </div>
    </React.Fragment>
  )
}
