import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import { motion } from "framer-motion";

const PageTransition = () => {
  const pageVariants = {
    initial: {
      opacity: 0,
      transform: "translateY(15px)",
    },
    in: {
      opacity: 1,
      transform: "translateY(0px)",
      transition: {
        transform: {
          duration: 0.2,
        },
      },
    },
    out: {
      opacity: 0,
      transform: "translateY(15px)",
    },
  };

  const pageTransition = {
    type: "tween",
    ease: "linear",
    duration: 0.2,
  };

  const { pathname } = useLocation();
  return (
    <motion.div
      style={{ willChange: "transform, opacity" }}
      key={pathname}
      initial="initial"
      animate="in"
      variants={pageVariants}
      transition={pageTransition}
    >
      <Outlet />
    </motion.div>
  );
};

export default PageTransition;
