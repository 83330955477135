import { combineReducers } from 'redux'
import {
  userReducer,
  piReducer,
  tariffReducer,
  contactsReducer,
  servicesReducer,
  tariffsReducer,
  managerReducer,
  onlineReducer,
  extraServicesReducer,
} from './user'
import { bannerReducer } from './bannerReducer'

const rootReducers = combineReducers({
  user: userReducer,
  pi: piReducer,
  services: servicesReducer,
  extraServices: extraServicesReducer,
  tariff: tariffReducer,
  tariffs: tariffsReducer,
  manager: managerReducer,
  contacts: contactsReducer,
  online: onlineReducer,
  banner: bannerReducer
})

export default rootReducers
