import React, { useEffect, useState } from 'react'
import Cabinet from './pages/Cabinet'
import { Routes, Route, useLocation } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import ResetPassword from './pages/PersonalData/ResetPassword'
import Replenishment from './pages/Payment/Replenishment'
import Internet from './pages/Statistics/Internet'
import TariffsPlan from './pages/Tariffs/TariffsPlan'
import Alerts from './pages/Services/Alerts'
import FormsAndDocs from './pages/Services/FormsAndDocs'
import Headers from './components/Headers'
import SingleProm from './pages/SingleProm'
import Bonus from './pages/Bonus'
import Login from './pages/Login'
import { AuthRoute, PrivateRoute, useAuth } from './services/authentication'
import DiscountsFromPartners from './pages/Partners/DiscountsFromPartners'
import SinglePartner from './pages/Partners/pid'
import PageTransition from './components/PageTransition'
import TID from './pages/Tariffs/tid'
import AccountBalance from './pages/Payment/AccountBalance'
import TopInfo from './components/TopInfo'
import TerminateContract from './pages/Services/TerminateContract'
import useActions from './hooks/useActions'
import { getUserPhones } from './services/http/userAPI'
import BringFriend from './pages/BringFriend'
import ExtraServices from './pages/ExtraServices'
import ExtraService from './pages/ExtraServices/ExtraService'
import CallExpert from './pages/ExtraServices/CallExpert/CallExpert'
import RentRouter from './pages/ExtraServices/RentRouter/RentRouter'
import GiveBackRouter from './pages/ExtraServices/GiveBackRouter/GiveBackRouter'
import ParentalControl from './pages/ExtraServices/ParentalControl/ParentalControl'
import { getCurrentTariff, getTariffs } from './services/http/tariffs'
import ScrollToTop from 'react-scroll-to-top'
import BuyDevice from './pages/ExtraServices/BuyDevice/BuyDevice'

export default withTranslation()(function App() {
  const location = useLocation()
  const [actions, { contacts, tariff: globalTariff }] = useActions()
  const auth = useAuth()

  async function fetchData() {
    try {
      let temp = await getTariffs()

      temp = temp.errno ? { error: 2 } : temp

      const result = !Array.isArray(temp)
        ? temp
        : temp.filter((t) => t.name.includes('+')).sort((a, b) => a.speed1 - b.speed1)

      actions.setTariffs(result)

      if (!globalTariff?.id) {
        const tempCurrentTariff = (await getCurrentTariff())[0]
        actions.setTariff(tempCurrentTariff)
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    if (!auth.sid) return
    async function fetchData() {
      let phone = Array.isArray(contacts) ? contacts : await getUserPhones()
      actions.setContacts(phone)
    }
    fetchData()
  }, [auth.sid])

  useEffect(() => {
    const chat = document.querySelector('.bx-livechat-head')
    if (!chat) return
    chat.style.background = 'var(--primary-color)'
  }, [])

  useEffect(() => {
    if (location.pathname.includes('login')) document.body.classList.add('hide-chat')
    else document.body.classList.remove('hide-chat')
  }, [location])

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <div className="App">
      <ScrollToTop
        style={{
          background: '#f35459',
          borderRadius: '100%',
          cursor: 'pointer',
          width: '55px',
          height: '55px',
          transition: '0.5s all',
        }}
        className="rocket-scroll-to-top"
        top={740}
        smooth
        color="#fff"
        viewBox="0 0 32 54"
        svgPath="M24.6894 27.1188C24.7874 26.0537 24.8378 24.9753 24.8378 23.8854C24.8378 14.9174 21.4389 6.74356 15.8603 0.581055C10.2808 6.74356 6.88283 14.9174 6.88283 23.8854C6.88283 24.9753 6.93323 26.0547 7.03118 27.1188C3.42688 29.638 0.94856 33.657 0.501588 38.2703C0.483519 38.4596 0.561501 38.6317 0.717466 38.7411C0.87248 38.8514 1.06078 38.8685 1.23291 38.7877C3.72169 37.6303 6.37023 36.7601 9.13576 36.2171C9.39062 36.8885 9.66642 37.5504 9.96123 38.2009C11.8746 37.8519 13.8461 37.6693 15.8603 37.6693C17.8745 37.6693 19.846 37.8519 21.7594 38.2009C22.0542 37.5504 22.33 36.8885 22.5849 36.2171C25.3513 36.7601 27.9999 37.6303 30.4877 38.7877C30.6598 38.8675 30.8481 38.8504 31.0032 38.7411C31.1582 38.6307 31.2371 38.4596 31.219 38.2703C30.7721 33.657 28.2937 29.6371 24.6894 27.1188ZM15.8365 53.1383C0.86392 36.3274 30.8091 36.3274 15.8365 53.1383V53.1383ZM15.8365 47.5778C22.99 39.5466 10.16 38.9959 14.5688 45.9249L14.6934 44.0277L15.8042 47.5388C15.8156 47.5521 15.8261 47.5645 15.8375 47.5768L15.8365 47.5778ZM15.8774 24.3448C12.9379 24.3448 10.5566 21.9853 10.5566 19.041C10.5566 16.1119 12.9312 13.7372 15.8603 13.7372C18.7894 13.7372 21.1641 16.1119 21.1641 19.041C21.1641 21.9644 18.7989 24.3352 15.8784 24.3448H15.8774Z"
      />

      <Headers />
      <TopInfo />

      {/* <Chat /> */}

      <Routes>
        <Route
          path="/login"
          element={
            <AuthRoute>
              <Login />
            </AuthRoute>
          }
        />

        <Route exact path="/" element={<PrivateRoute />}>
          <Route exact path="/" element={<Cabinet />} />

          <Route element={<PageTransition />}>
            <Route exact path="/reset-password" element={<ResetPassword />} />
            <Route exact path="/bonus" element={<Bonus />} />
            <Route exact path="/account-balance" element={<AccountBalance />} />
            <Route exact path="/replenishment" element={<Replenishment />} />
            <Route exact path="/statistics" element={<Internet />} />
            <Route exact path="/tariffs-plan" element={<TariffsPlan />} />
            <Route exact path="/tariffs-plan/:tid" element={<TID />} />
            <Route exact path="/alerts" element={<Alerts />} />
            <Route exact path="/bring-a-friend" element={<BringFriend />} />
            <Route exact path="/extra-services" element={<ExtraServices />}></Route>
            <Route exact path="/extra-services/:id" element={<ExtraService />} />
            <Route exact path="/extra-services/parental-control" element={<ParentalControl />} />
            <Route exact path="/extra-services/call-expert" element={<CallExpert />} />
            <Route exact path="/extra-services/rent-router" element={<RentRouter />} />
            <Route exact path="/extra-services/give-back-router" element={<GiveBackRouter />} />
            <Route exact path="/extra-services/buy-device" element={<BuyDevice />} />
            <Route exact path="/terminate-contract" element={<TerminateContract />} />
            <Route exact path="/forms-and-documents" element={<FormsAndDocs />} />
            <Route exact path="/prom/:pid" element={<SingleProm />} />
            <Route exact path="/discounts-from-partners" element={<DiscountsFromPartners />} />
            <Route exact path="/partners/:pid" element={<SinglePartner />} />
            <Route path="/*" />
          </Route>
        </Route>
      </Routes>
    </div>
  )
})
