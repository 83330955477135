import React from "react";
import PropTypes from "prop-types";
import classes from "./style.module.css";
import useWindowSize from "../../hooks/useWindowSize";

export default function Prom({ onClick, src, title, description }) {
  const size = useWindowSize();

  return (
    <div className={classes.wrap_prom}>
      <div
        onClick={onClick}
        style={
          size.width < 1170
            ? { flexDirection: "column", width: 300, height: 440 }
            : {}
        }
        className={classes.prom}
      >
        <img
          style={size.width < 1170 ? { width: "100%", height: "auto" } : {}}
          src={src}
        />

        <div className={classes.prom_bottom}>
          <h3>{title}</h3>
          <div className={classes.desc}>{description}</div>
        </div>
      </div>
    </div>
  );
}

Prom.propTypes = {
  onClick: PropTypes.func,
  src: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
};
