import React, { useState } from "react";
import css from "./style.module.css";
import PropTypes from "prop-types";

export default function Tabs({ titles, children, ...props }) {
  const [value, setValue] = useState(0);

  return (
    <div className={css.tabs} {...props}>
      <div className={css.row}>
        {titles.map((title, i) => (
          <button
            onClick={() => setValue(i)}
            className={[css.button, value === i ? css.selected : ""].join(" ")}
            key={i}
          >
            {title}
          </button>
        ))}
      </div>
      <div className={css.wrapChildren}>{children && children(value)}</div>
    </div>
  );
}
Tabs.propTypes = {
  titles: PropTypes.array,
  children: PropTypes.func,
};
