import React from "react";
import css from "./style.module.scss";
import CloseIcon from "../../assets/images/svg/close.svg";
import ContentSVG from "../../assets/images/svg/ContentModal.svg";
import { motion } from "framer-motion";
import areEqual from "../../utils/areEqual";

function CircularDiv({
  direction = "bottom-right",
  bg = false,
  children,
  onClickClose,
  icon,
}) {
  let drStyle = { bottom: 50, right: 30 };
  switch (direction) {
    case "top-right":
      drStyle = { top: 50, right: 30 };
      break;
    case "top-left":
      drStyle = { top: 50, left: 30 };
      break;
    case "bottom-left":
      drStyle = { bottom: 50, left: 30 };
      break;
  }

  const handleClose = () => {
    if (onClickClose) onClickClose();
  };

  const Content = () => (
    <div
      // initial={{ transform: "translateY(30px)", opacity: 0 }}
      // animate={{ transform: "translateY(0px)", opacity: 1 }}
      // transition={{
      //   delay: 0.4,
      //   type: "spring",
      //   duration: 0.3,
      //   opacity: {
      //     ease: "linear",
      //   },
      // }}
      style={drStyle}
      className={css.wrapper}
    >
      <img
        onClick={handleClose}
        className={css.close}
        src={CloseIcon}
        alt="close-icon"
      />
      {icon && <img className={css.icon} src={icon} alt="icon-circular" />}
      <div className={css.content_wrap}>
        <img src={ContentSVG} alt="content" />
        <div className={css.content}>{children}</div>
      </div>
    </div>
  );
  return bg ? (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ type: "tween", duration: 0.5 }}
      className={css.background}
    >
      <Content />
    </motion.div>
  ) : (
    <Content />
  );
}

export default React.memo(CircularDiv, areEqual);
