/* eslint-disable no-constant-condition */
import React from "react";
import Input from "../../components/Input";
import { translatedText } from "../../services/translatedText";
import "../../styles/resetPassword.scss";
import { withTranslation } from "react-i18next";
import { useState } from "react";
import FormModal from "../../components/FormModal";
import Loader from "../../components/Loader";
import { useEffect } from "react";
import useActions from "../../hooks/useActions";
import { getUserPhones, resetPassword } from "../../services/http/userAPI";
import { useRef } from "react";
import {
  checkVerification,
  sendCodeVerification,
} from "../../services/http/contacts";
import { toStringDate } from "../../utils/date";
import { parsePhone } from "../../utils/parser";
import MainBlock from "../../components/MainBlock";

export default withTranslation()(function ResetPassword() {
  const [openModal, setOpenModal] = useState({ open: false });
  const [actions, { contacts, user, pi }] = useActions();
  const [phones, setPhones] = useState();
  const [error, setError] = useState();
  const [msg, setMsg] = useState();
  const [loading, setLoading] = useState(false);
  const currentPasswords = useRef({});

  useEffect(() => {
    const fetchData = async () => {
      let phone =
        Array.isArray(contacts) && contacts ? contacts : await getUserPhones();
      actions.setContacts(phone);
      setPhones(
        Array.isArray(phone)
          ? phone.filter((ph) => ph.name.includes("PHONE"))
          : []
      );
    };
    fetchData();
  }, []);

  const resetPass = async () => {
    setLoading(true);
    try {
      const response = await resetPassword(currentPasswords.current.password);
      if (response?.errno > 0) {
        setError(translatedText("Unknown error"));
        return;
      }

      setMsg(translatedText("Successfully changed password"));
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };
  const checkVerification = () => {
    const pass = currentPasswords.current.password || "";
    const ver = currentPasswords.current.verificationPass || "";
    if (pass?.length < 8) {
      setError(translatedText("Valid pass min"));
      return;
    }
    if (pass !== ver) {
      setError(translatedText("Valid verif pass"));
      return;
    }

    setError(null);
    setOpenModal({ open: true });
  };

  const handleCloseModal = () => {
    setOpenModal({ open: false });
  };

  return (
    <div className="container reset-page">
      <div className="reset-body">
        {msg ? (
          <div className="frame br center">
            <span>{msg}</span>
            <div className="checkmark"></div>
          </div>
        ) : loading ? (
          <Loader center />
        ) : (
          <React.Fragment>
            <MainBlock className="reset-note">
              <div
                dangerouslySetInnerHTML={{
                  __html: translatedText("Reset password info"),
                }}
              ></div>
            </MainBlock>
            <h1 style={{ marginTop: 70 }}>
              {translatedText("Reset password")}
            </h1>
            <Input
              typeInput="password"
              onChange={(pass) => (currentPasswords.current.password = pass)}
              label={translatedText("New password")}
            />
            <Input
              onChange={(pass) =>
                (currentPasswords.current.verificationPass = pass)
              }
              label={translatedText("Confirm password")}
            />

            {error && (
              <span style={{ lineHeight: 0 }} className="red-text">
                {error}
              </span>
            )}
            <Modal
              openModal={openModal}
              handleCloseModal={handleCloseModal}
              handleSubmit={resetPass}
              currentData={{ phones, user, pi }}
            />

            <div className="reset-button-wrap">
              <button
                onClick={checkVerification}
                style={{ marginTop: 40 }}
                className="primary-btn"
              >
                {translatedText("Save")}
              </button>
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  );
});

const Modal = ({ openModal, handleCloseModal, handleSubmit, currentData }) => {
  const [msg, setMsg] = useState({
    msg: "",
  });
  const [code, setCode] = useState();
  const currentCode = useRef();
  const timeoutExpired = useRef();

  const [loader, setLoader] = useState(false);
  const [phone, setPhone] = useState();
  const [changePhone, setChangePhone] = useState();
  const [expired, setExpired] = useState(false);
  const contacts = currentData.phones || [];
  const actualPhone = contacts[0];
  const username = currentData?.user?.login;

  const sendSms = async (phone) => {
    const resSms = await sendCodeVerification(phone, username);

    if (resSms?.data == "Вы были забанены") {
      const dt = new Date(resSms?.time);
      dt.setMinutes(dt.getMinutes() + 10);
      dt.setHours(dt.getHours() - 2);
      setMsg({
        msg: translatedText("You have ban", {
          date: toStringDate(dt, true),
        }),
        error: 1,
      });
      clearTimeout(timeoutExpired.current);
      setExpired(false);
      setCode("");
    } else if (
      resSms?.error_code ||
      resSms?.status_description == "Error in sms"
    )
      setMsg({
        msg: translatedText("Unknown error"),
        error: resSms?.error_code ?? 1,
      });
    else {
      setMsg({ msg: translatedText("Sent code"), code: 1 });
      setExpired(false);
      timeoutExpired.current = setTimeout(
        () => setExpired(true),
        5 * 60 * 1000
      );
      setCode("wait");
    }
  };
  const apply = async () => {
    let phoneCurrent = phone;
    let codeState = code;
    if (code === "wait" && !expired && currentCode.current) {
      const data = await checkVerification(currentCode.current, username);
      setCode(data?.code);
      codeState = data?.code;
      setLoader(true);
    }

    if (actualPhone && !changePhone) {
      phoneCurrent = actualPhone.value + "";
    }
    if (phoneCurrent.length === 9) {
      phoneCurrent = "998" + phoneCurrent;
    }

    if (expired) {
      await sendSms(phoneCurrent);
      return;
    } else if (codeState) {
      if (codeState == "error") {
        setLoader(false);
        setCode("wait");
        setMsg({ msg: translatedText("Code error"), code: 1, error: 1 });
        setTimeout(() => {
          setMsg({
            msg: translatedText("Sent code1", { actualPhone: phoneCurrent }),
            code: 1,
          });
        }, 4000);
        return;
      }
    } else if (phoneCurrent?.length !== 12) {
      setLoader(false);
      return;
    } else {
      await sendSms(phoneCurrent);
      return;
    }

    setMsg({ msg: translatedText("Successfully completed") });
    clearTimeout(timeoutExpired.current);
    setLoader(false);
    setTimeout(() => {
      handleSubmit();
      handleCloseModal();
    }, 500);
  };

  const closeModal = () => {
    clearTimeout(timeoutExpired.current);
    setExpired(false);
    setCode();
    setChangePhone();
    setPhone();
    setMsg({ msg: "" });
    handleCloseModal();
  };

  useEffect(() => {
    if (!openModal?.change) return;
    const act = openModal?.actualContact;
    if (!act) return;
    setPhone(act.value + "");
    actualPhone.value = act.value + "";
    setChangePhone(act);
  }, [openModal?.change]);

  const MASK_PHONE = "+998 (##) ### ## ##";
  return (
    <FormModal open={openModal.open} onClose={closeModal}>
      <div
        style={{ marginTop: 10 }}
        className={
          (msg.msg && !msg?.code) ||
          loader ||
          currentData.Off ||
          (!changePhone && actualPhone && !code && currentData?.Sms)
            ? "frame p-1"
            : ""
        }
      >
        {loader ? (
          <Loader center />
        ) : msg.msg && !msg?.code ? (
          <h2
            style={{ color: msg?.error ? "var(--primary-color)" : null }}
            className="succes-text"
          >
            {msg.msg}
          </h2>
        ) : (
          <React.Fragment>
            {currentData.Off ? (
              <h2 style={{ margin: "0px 10px" }} className="succes-text">
                {translatedText("Are you sure disable notification")}
              </h2>
            ) : !changePhone && actualPhone && !code && currentData?.Sms ? (
              <div className="between">
                <p className="succes-text">
                  {translatedText("Current phone number")}:
                </p>
                <p className="succes-text">{parsePhone(actualPhone.value)}</p>
              </div>
            ) : (
              <div className="col g-2">
                <Input
                  mask={MASK_PHONE}
                  onChange={(value) => setPhone(value)}
                  defaultValue={
                    actualPhone?.value ? actualPhone.value + "" : ""
                  }
                  readOnly={actualPhone?.value}
                  placeholder={"+998 (12) 345 67 89"}
                  label={translatedText("Phone number")}
                />
                {!expired && code && (
                  <Input
                    offSelection
                    onChange={(value) => (currentCode.current = value)}
                    label={translatedText("Code") + ":"}
                    placeholder="12345"
                  />
                )}
              </div>
            )}
          </React.Fragment>
        )}
      </div>
      {!loader && code && msg?.code && (
        <span
          style={{
            marginLeft: 10,
            marginTop: 10,
            color: msg?.error || expired ? "var(--primary-color)" : "#646464",
          }}
        >
          {expired ? translatedText("Time expired") : msg.msg}
        </span>
      )}
      <div className="row g-2" style={{ alignItems: "end" }}>
        {!changePhone &&
          currentData?.Sms &&
          actualPhone &&
          !code &&
          !msg.error && (
            <button
              onClick={() => {
                setPhone(actualPhone.value + "");
                actualPhone.value = actualPhone.value + "";
                setChangePhone(actualPhone);
              }}
              style={{ width: "100%", height: "100%", padding: 18 }}
              className="secondary-btn"
            >
              {translatedText("Change")}
            </button>
          )}
        <button
          disabled={
            (currentData.Sms &&
              (phone?.length !== 12 || phone === actualPhone?.value)) ||
            (openModal?.change && openModal?.actualContact?.value == phone)
          }
          onClick={msg.msg && !msg?.code ? closeModal : apply}
          style={{ width: "100%", marginTop: code ? 0 : 10 }}
          className="primary-btn"
        >
          {expired
            ? translatedText("Send again")
            : msg.msg && !msg?.code
            ? translatedText("Close")
            : currentData.Off
            ? translatedText("Disable")
            : translatedText("Apply")}
        </button>
      </div>
    </FormModal>
  );
};
