import React, { useRef } from 'react'
import classes from './paymodal.module.css'
import FormModal from '../FormModal'
import { useSelector } from 'react-redux'
import { translatedText } from '../../services/translatedText'
import { useQRCode } from 'next-qrcode'

export default function PayModal({ chosedPay, setChosedPay }) {
  const { user } = useSelector((state) => state)
  const id = useRef(user?.login)
  const login = id.current || user.login
  const balance = user?.deposit ? parseInt(user.deposit) : 0
  const { Canvas } = useQRCode()

  const paymentJS = [
    { name: 'uzum', src: require('../../assets/images/payments/uzum2.png') },
    { name: 'payme', src: require('../../assets/images/payments/payme.png') },
    { name: 'click', src: require('../../assets/images/payments/click.png') },
    { name: 'paynet', src: require('../../assets/images/payments/paynet.png') },
  ]
  return (
    <FormModal open={!!chosedPay.url} onClose={() => setChosedPay({ url: '', name: '' })}>
      {chosedPay.name !== 'paynet' ? (
        <div className={classes.modal}>
          <>
            {paymentJS
              .filter((i) => i.name == chosedPay.name)
              ?.map((item) => (
                <img src={item.src} className={classes.img} key={item.name} />
              ))}
            <div className={classes.infoItem}>
              <div className={classes.type}>{translatedText('Login')}:</div>
              <div className={classes.value}>{login}</div>
            </div>
            <div className={classes.infoItem}>
              <div className={classes.type}>{translatedText('Balance')}:</div>
              <div className={classes.value}>{Number(balance)?.toLocaleString()?.split(',')?.join(' ')}</div>
            </div>
            <div className={classes.infoItem}>
              <div className={classes.type}>{translatedText('PayMoney')}:</div>
              <div className={classes.valueGreen}>
                {Number(chosedPay.cost)?.toLocaleString()?.split(',')?.join(' ')}
              </div>
            </div>
            <div className={classes.scan_wrapper}>
              {/* <div className={classes.scan}>
                {translatedText('scan_pay', {
                  app: chosedPay.name,
                })}
                <span>или</span>
                <a href={chosedPay.url} target="_blank" rel="noreferrer">
                  Оплатить через сайт
                </a>
              </div> */}

              <div className={classes.scan}>
                {/* {translatedText('scan_pay', {
                  app: chosedPay.name,
                })} */}

                {translatedText('payWithQr')}
              </div>

              <a href={chosedPay.url} target="_blank" rel="noreferrer" className={classes.qr}>
                <Canvas
                  text={chosedPay.url}
                  options={{
                    type: 'image/jpeg',
                    errorCorrectionLevel: 'M',
                    margin: 0,
                    scale: 4,
                    width: 160,
                    color: {
                      dark: '#000',
                    },
                  }}
                />
              </a>

              <a href={chosedPay.url} target="_blank" rel="noreferrer" className={classes.pay_link_btn}>
                {translatedText('payWithWebSite')}
              </a>
            </div>
          </>
        </div>
      ) : (
        <div className={classes.modal2}>
          {paymentJS
            .filter((i) => i.name == chosedPay.name)
            ?.map((item) => (
              <img src={item.src} className={classes.img2} key={item.name} />
            ))}
          <div className={classes.paynet}>{translatedText('Paynet can not')}</div>
          <div onClick={() => setChosedPay({ url: '', name: '' })} className={classes.btn}>
            {translatedText('Close')}
          </div>
        </div>
      )}
    </FormModal>
  )
}
