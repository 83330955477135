import React, { useEffect, useRef, useState } from "react";
import { translatedText } from "../../services/translatedText";
import "../../styles/GradualActionProcess.scss";

const Step = ({
  step,
  onConfirm,
  isConfirmed,
  onBack,
  onSaveData,
  isCurrent,
  Content,
  isLast,
}) => {
  const parent = useRef();
  const data = useRef();
  const [isPossible, setPossible] = useState(false);
  useEffect(() => {
    if (!isCurrent || !parent.current) return;
    parent.current.scrollIntoView({ block: "center", behavior: "smooth" });
  }, [isCurrent]);

  return (
    <div className={`step-content step-${step}`}>
      <div className={"step-number" + (isLast ? " disable" : "")}>
        <div className="step-number-content">
          <div
            className={[
              "step-checkmark",
              isCurrent ? "current" : !isConfirmed ? "inactive" : "",
            ].join(" ")}
          ></div>
          {translatedText("Step")} {step}
        </div>
      </div>
      <div ref={parent} className="step-inside-content">
        <Content
          onPossible={(_data) => {
            data.current = _data;
            setPossible(true);
          }}
          onImpossible={() => setPossible(false)}
        />
        {isCurrent && (
          <div className="step-buttons">
            <button
              className="step-back"
              onClick={onBack}
              disabled={step === 1}
            >
              {translatedText("Back")}
            </button>
            <button
              disabled={!isPossible}
              className="step-confirm"
              onClick={() => {
                onSaveData(data.current);
                onConfirm();
              }}
            >
              {translatedText("Next")}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

const GradualActionProcess = ({ steps = [], onFinish }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [isFinished, setIsFinished] = useState(false);
  const data = useRef({});

  const handleBack = () => {
    setCurrentStep(currentStep - 1);
  };

  const saveData = (_data, index) => {
    data.current[index] = _data;
  };

  return (
    <div>
      <div className="steps-container">
        {steps.map((step, index) => (
          <div
            key={step.id}
            className={`step-container ${
              step.id === currentStep && !isFinished ? "active" : "inactive"
            }`}
          >
            {index !== 0 && (
              <div
                className={`line ${
                  step.id === currentStep ? "active" : "inactive"
                }`}
              ></div>
            )}
            <Step
              onSaveData={(data) => saveData(data, step.id)}
              step={step.id}
              onBack={handleBack}
              onConfirm={
                step.id === steps.length
                  ? () => {
                      setIsFinished(true);
                      onFinish(data.current);
                    }
                  : () => {
                      setCurrentStep(step.id + 1);
                    }
              }
              isCurrent={currentStep === step.id && !isFinished}
              isConfirmed={currentStep > step.id || isFinished}
              Content={step.content}
              isLast={index === steps.length - 1}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default GradualActionProcess;
