function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}

String.prototype.replaceAll = function (search, replacement) {
  var target = this;
  return target.replace(new RegExp(escapeRegExp(search), "g"), replacement);
};

export const equalObjects = (object, target) => {
  try {
    const o1 = JSON.stringify(object);
    const o2 = JSON.stringify(target);
    return o1 == o2;
  } catch (error) {
    return false;
  }
};
