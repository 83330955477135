import React, { useEffect, useState } from "react";

import classes from "./style.module.scss";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import axios from "axios";
import { Link } from "react-router-dom";
import useActions from "../../hooks/useActions";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const API = {
  PRODUCTION: "https://serv.comnet.uz",
};

function BannerHome({currentTariff}) {
  const [
    actions,
    {
      banner: { banners },
    },
  ] = useActions();
  const lang = localStorage.getItem("lang") || "ru";
  const [loading, setLoading] = useState(false);
  

  useEffect(() => {
    if(currentTariff){
      if (banners.length === 0) {
        setLoading(true);
      }
      axios({
        method: "GET",
        url: `${API.PRODUCTION}/api/slider_new`,
        headers: {
          Authorization: "Bearer 3|YOz6gV9lvSlVao1VfTzboRMDSpipDNuSMeSLaNFo",
        },
      })
        .then((response) => {
          if (response.data.data) {
            let listBanner = response.data.data
            if(currentTariff!=-1){
              console.log(currentTariff.tpId)
              listBanner = listBanner.filter(i=> !i.for_tariff||i.for_tariff==currentTariff.tpId)
            }else{
              listBanner = listBanner.filter(i=> !i.for_tariff)
            }
            actions.setBanners(listBanner);
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
    
  }, [currentTariff]);

  const CustomDot = ({ index, onClick, active }) => {
    return (
      <button
        onClick={(e) => {
          onClick();
          e.preventDefault();
        }}
        className={`${classes.customDot} ${
          active ? classes.customDotActive : ""
        }`}
      ></button>
    );
  };

  return (
    <>
      {loading ? (
        <div style={{ minHeight: 300 }}></div>
      ) : (
        <Carousel
          responsive={responsive}
          additionalTransfrom={0}
          arrows
          autoPlay={false}
          autoPlaySpeed={2500}
          centerMode={false}
          className=""
          containerClass={classes.TopSlider__carousel}
          itemclass="carousel-item-padding-40-px"
          focusOnSelect
          infinite
          keyBoardControl
          minimumTouchDrag={80}
          renderButtonGroupOutside={false}
          renderDotsOutside={false}
          showDots={true}
          slidesToSlide={1}
          swipeable
          draggable
          dotListClass={classes.customDotList}
          customDot={<CustomDot />}
        >
          {banners.map((slide) => (
            <div className={classes.TopSlider__item_wr} key={slide.id}>
              <div className={classes.TopSlider__item}>
                <div className={classes.TopSlider__left}>
                  {slide.title != "/" ? (
                    <h3 className={classes.TopSlider__title}>
                      {lang === "ru"
                        ? slide.title.split("$")[0]
                        : slide.title_uz}
                    </h3>
                  ) : (
                    <></>
                  )}
                  {slide.description != "/" ? (
                    <div className={classes.TopSlider__text}>
                      {lang === "ru"
                        ? slide.description.split("$")[0]
                        : slide.description_uz}
                    </div>
                  ) : (
                    <></>
                  )}
                  {slide.more != "/" ? (
                    <Link
                      to={slide.morelink}
                      className={classes.TopSlider__btn}
                      type="button"
                    >
                      {lang === "ru" ? slide.more.split("$")[0] : slide.btn_uz}
                    </Link>
                  ) : (
                    <></>
                  )}
                </div>
                <div className={classes.TopSlider__right}>
                  <h3 className={classes.small__title}>
                    {lang === "ru" ? slide.title.split("$")[0] : slide.title_uz}
                  </h3>

                  {/* <img src={slide.img} alt={slide.img} /> */}
                  <img
                    draggable={"false"}
                    className={classes.imgBanner}
                    style={{ width: "100%" }}
                    alt={slide.title}
                    src={`${API.PRODUCTION}/storage/${slide.photo}`}
                  />
                </div>
              </div>
            </div>
          ))}
        </Carousel>
      )}
    </>
  );
}

export default BannerHome;
