import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import classes from "./pid.module.css";
import {
  YMaps,
  Map,
  GeolocationControl,
  ZoomControl,
  Placemark,
} from "react-yandex-maps";
import axios from "axios";
import PartnersItem from "../../components/PartnersItem";
import { useState } from "react";
import i18next from "i18next";
import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import discount from "../../assets/images/partners/Discount.svg";

const phoneMask = (num) => {
  if (num.length < 12) {
    return num;
  }
  const res = num.replace("", "");
  return `${res.slice(3, 5)} ${res.slice(5, 8)}-${res.slice(8, 10)}-${res.slice(
    10,
    12
  )}`;
};

export default function SinglePartner() {
  const data = {};
  const [partners, setPartners] = useState();
  const [currentPartner, setCurrentPartner] = useState();
  const lang = i18next.language;
  const { pid } = useParams();
  const [showPromoCode, setShowPromo] = useState(false);

  const [mode, setMode] = useState(1);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (pid) {
      axios({
        method: "POST",
        url: "https://serv.comnet.uz/api/partners/coll",
        headers: {
          Authorization: "Bearer 3|YOz6gV9lvSlVao1VfTzboRMDSpipDNuSMeSLaNFo",
        },
      }).then((response) => {
        if (response && response.data && response.data.data) {
          let partners = response.data.data;
          partners = partners.map((item) => {
            let New = { ...item };
            if (New.images) {
              New.images = New.images.map((i) => i.replace("public", ""));
            }
            return New;
          });
          const currentPartner = partners.find((item) => item.id == pid);
          partners = partners.filter(
            (item) => item.id != pid && currentPartner?.type === item?.type
          );
          setPartners(partners);
          setCurrentPartner(currentPartner);
        }
      });
    }
  }, [pid]);

  const showPromo = () => {
    setShowPromo(true);
  };

  return (
    <div className={classes.pid + " container"}>
      <h2 className={classes.titleBold}>
        {lang == "ru"
          ? currentPartner && currentPartner.title_ru
          : currentPartner && currentPartner.title_uz}
      </h2>

      <div className={classes.top}>
        {currentPartner && currentPartner.images && (
          <Carousel
            className={classes.carousel}
            width="90%"
            showIndicators={false}
            showThumbs={false}
            showArrows={true}
            infiniteLoop={true}
            showStatus={false}
            renderArrowPrev={(onClickHandler, hasPrev, label) =>
              hasPrev && (
                <button
                  type="button"
                  onClick={onClickHandler}
                  className={classes.arrow}
                  style={{ left: -25 }}
                >
                  <svg
                    width="11"
                    height="18"
                    viewBox="0 0 11 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.2"
                      d="M0.91394 8.51406L8.58268 1.23117C8.83394 0.992689 9.24073 0.99309 9.49157 1.2324C9.74221 1.47168 9.74156 1.85929 9.49027 2.09795L2.278 8.94738L9.49053 15.7968C9.74179 16.0355 9.74244 16.4228 9.49183 16.6621C9.36609 16.7821 9.20136 16.8421 9.03663 16.8421C8.87232 16.8421 8.70823 16.7825 8.58272 16.6634L0.91394 9.38066C0.792926 9.266 0.725019 9.10996 0.725019 8.94738C0.725019 8.7848 0.79312 8.62894 0.91394 8.51406Z"
                      fill="black"
                      stroke="black"
                    />
                  </svg>
                </button>
              )
            }
            renderArrowNext={(onClickHandler, hasNext, label) =>
              hasNext && (
                <button
                  type="button"
                  onClick={onClickHandler}
                  className={classes.arrow}
                  style={{ right: -25 }}
                >
                  <svg
                    width="11"
                    height="18"
                    viewBox="0 0 11 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.2"
                      d="M10.0861 8.51406L2.41732 1.23117C2.16606 0.992689 1.75927 0.99309 1.50843 1.2324C1.25779 1.47168 1.25844 1.85929 1.50973 2.09795L8.722 8.94738L1.50947 15.7968C1.25821 16.0355 1.25756 16.4228 1.50817 16.6621C1.63391 16.7821 1.79864 16.8421 1.96337 16.8421C2.12768 16.8421 2.29177 16.7825 2.41728 16.6634L10.0861 9.38066C10.2071 9.266 10.275 9.10996 10.275 8.94738C10.275 8.7848 10.2069 8.62894 10.0861 8.51406Z"
                      fill="black"
                      stroke="black"
                    />
                  </svg>
                </button>
              )
            }
          >
            {currentPartner.images.map((item) => {
              return (
                <img
                  className={classes.imgCarusel}
                  src={"https://serv.comnet.uz/storage/" + item}
                  key={item}
                  alt="slide"
                />
              );
            })}
          </Carousel>
        )}
        <div className={classes.partnerWrap}>
          {currentPartner && !currentPartner.images && currentPartner.logo && (
            <div className={classes.image}>
              <img
                src={`https://serv.comnet.uz/storage/${currentPartner.logo}`}
              />
            </div>
          )}
          {currentPartner && (
            <div className={classes.col}>
              <div className={classes.topInfo}>
                <img src={discount} alt="discount" />
                <h3 className={classes.primaryText}>
                  {/* {currentPartner.promo_count != 0
                  ? `${currentPartner.promo_count}% скидка`
                  : ""} */}
                  {lang == "ru"
                    ? currentPartner.promo_info_ru
                    : currentPartner.promo_info_uz}
                </h3>

                {showPromoCode ? (
                  <p className="col g-2" style={{ width: "fit-content" }}>
                    {currentPartner?.partner_lk && (
                      <span style={{ lineHeight: "25px" }}>
                        <span
                          style={{
                            borderBottom: "1px dashed rgb(0 0 0)",
                          }}
                        >
                          {currentPartner?.partner_lk}
                        </span>{" "}
                        - {currentPartner["partner_lk_info_" + lang]}
                      </span>
                    )}
                    {currentPartner?.promo2 && (
                      <span style={{ lineHeight: "25px" }}>
                        <span
                          style={{
                            borderBottom: "1px dashed rgb(0 0 0)",
                          }}
                        >
                          {currentPartner?.promo2}
                        </span>{" "}
                        - {currentPartner["promo2_info_" + lang]}
                      </span>
                    )}
                  </p>
                ) : (
                  <button onClick={showPromo} className={classes.secondaryBtn}>
                    {lang == "ru" ? `Получить скидку` : `Chegirmani olish`}
                  </button>
                )}
              </div>
              <div className={classes.contacts}>
                <div className={classes.contact_buttons}>
                  {currentPartner.instagram && (
                    <a href="#" className={classes.socialItem}>
                      <img
                        src="data:image/svg+xml;base64,PHN2ZyBpZD0iQm9sZCIgZmlsbD0iI2ZmZiIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMjQgMjQiIGhlaWdodD0iNTEyIiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSI1MTIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0ibTEyLjAwNCA1LjgzOGMtMy40MDMgMC02LjE1OCAyLjc1OC02LjE1OCA2LjE1OCAwIDMuNDAzIDIuNzU4IDYuMTU4IDYuMTU4IDYuMTU4IDMuNDAzIDAgNi4xNTgtMi43NTggNi4xNTgtNi4xNTggMC0zLjQwMy0yLjc1OC02LjE1OC02LjE1OC02LjE1OHptMCAxMC4xNTVjLTIuMjA5IDAtMy45OTctMS43ODktMy45OTctMy45OTdzMS43ODktMy45OTcgMy45OTctMy45OTcgMy45OTcgMS43ODkgMy45OTcgMy45OTdjLjAwMSAyLjIwOC0xLjc4OCAzLjk5Ny0zLjk5NyAzLjk5N3oiLz48cGF0aCBkPSJtMTYuOTQ4LjA3NmMtMi4yMDgtLjEwMy03LjY3Ny0uMDk4LTkuODg3IDAtMS45NDIuMDkxLTMuNjU1LjU2LTUuMDM2IDEuOTQxLTIuMzA4IDIuMzA4LTIuMDEzIDUuNDE4LTIuMDEzIDkuOTc5IDAgNC42NjgtLjI2IDcuNzA2IDIuMDEzIDkuOTc5IDIuMzE3IDIuMzE2IDUuNDcyIDIuMDEzIDkuOTc5IDIuMDEzIDQuNjI0IDAgNi4yMi4wMDMgNy44NTUtLjYzIDIuMjIzLS44NjMgMy45MDEtMi44NSA0LjA2NS02LjQxOS4xMDQtMi4yMDkuMDk4LTcuNjc3IDAtOS44ODctLjE5OC00LjIxMy0yLjQ1OS02Ljc2OC02Ljk3Ni02Ljk3NnptMy40OTUgMjAuMzcyYy0xLjUxMyAxLjUxMy0zLjYxMiAxLjM3OC04LjQ2OCAxLjM3OC01IDAtNy4wMDUuMDc0LTguNDY4LTEuMzkzLTEuNjg1LTEuNjc3LTEuMzgtNC4zNy0xLjM4LTguNDUzIDAtNS41MjUtLjU2Ny05LjUwNCA0Ljk3OC05Ljc4OCAxLjI3NC0uMDQ1IDEuNjQ5LS4wNiA0Ljg1Ni0uMDZsLjA0NS4wM2M1LjMyOSAwIDkuNTEtLjU1OCA5Ljc2MSA0Ljk4Ni4wNTcgMS4yNjUuMDcgMS42NDUuMDcgNC44NDctLjAwMSA0Ljk0Mi4wOTMgNi45NTktMS4zOTQgOC40NTN6Ii8+PGNpcmNsZSBjeD0iMTguNDA2IiBjeT0iNS41OTUiIHI9IjEuNDM5Ii8+PC9zdmc+"
                        alt=""
                        className="lozad"
                        data-loaded="true"
                      />
                    </a>
                  )}
                  {currentPartner.facebook && (
                    <a href="#" className={classes.socialItem}>
                      <img
                        src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNyIgaGVpZ2h0PSIxNSIgdmlld0JveD0iMCAwIDcgMTUiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNNC40ODMzOCAxNC4xMDhWOC4wODU2Mkg2LjQyNzFMNi43MTgzMiA1LjczNDI5SDQuNDgzMzhWNC4yMzI5NEM0LjQ0OTg0IDQuMDc4MjEgNC40NTUzMyAzLjkxNzM0IDQuNDk5MzIgMy43NjUyNUM0LjU0MzMxIDMuNjEzMTYgNC42MjQ0NCAzLjQ3NDU0IDQuNzM1NCAzLjM2MTYxQzQuODQ2MzcgMy4yNDg2OCA0Ljk4MzY5IDMuMTY1MzYgNS4xMzQ5OSAzLjExODdDNS4yODYyOCAzLjA3MjA1IDUuNDQ2OCAzLjA2MzcxIDUuNjAyMSAzLjA5NDUzSDYuNzk5OTlWMC45OTA1NDNDNi4yMjE3NCAwLjkyOTczNiA1LjY0MDcgMC44OTk1NTcgNS4wNTkyNiAwLjg5OTkwNUMzLjMzNjY2IDAuODk5OTA1IDIuMTU2OSAxLjk5Njk2IDIuMTU2OSA0LjAwMTcxVjUuNzM0MjlIMC4yMDgyNTJWOC4wODU2MkgyLjE1NjlWMTQuMTA4SDQuNDg2NzFINC40ODMzOFoiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo="
                        alt=""
                        className="lozad"
                        data-loaded="true"
                      />
                    </a>
                  )}
                  {currentPartner.telegram && (
                    <a href="#" className={classes.socialItem}>
                      <img
                        src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxNSAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMy4xNDYzIDAuODMyMzk2TDAuNTg5ODA0IDUuNzk4OTFDLTAuMDk2NTk1NSA2LjAwNTE2IC0wLjA3NTE0NzUgNi43MTQ3MSAwLjQzMzA1MiA2Ljg3OTcxTDMuNTc5NiA3LjkxMDk2TDQuNzgzMjkgMTEuNzYzNkM0LjkyOTMxIDEyLjE4NDQgNS4wNDg5NCAxMi4zNDExIDUuMjk4OTEgMTIuMzQ5NEM1LjQyMzkxIDEyLjM0MiA1LjU0NTc2IDEyLjMwNzMgNS42NTU3MiAxMi4yNDc0QzUuNzY1NjggMTIuMTg3NSA1Ljg2MTA0IDEyLjEwNCA1LjkzNDk4IDEyLjAwMjlDNi4yNTQyNiAxMS42ODk0IDYuNzQxIDExLjE5NDcgNy41MDk5IDEwLjQxMDlMMTAuNzgxOSAxMi45MzUxQzExLjM4NDEgMTMuMjgxNiAxMS44MTg5IDEzLjEwODQgMTEuOTY5IDEyLjM0OTRMMTQuMDIwOCAxLjc1NjQ5QzE0LjI0MTEgMC44MzI0OTMgMTMuNzIzIDAuNTYwMTQ2IDEzLjE0NjMgMC44MzIzOTZaTTQuMDcxMzEgNy42NzE2OEwxMS4yMjQxIDIuOTg1OTRDMTEuNTc3MiAyLjczODQ0IDExLjY4NjkgMy4wMTA0OSAxMS41Mjg1IDMuMjAwMjRMNS41ODg1IDguNzc3MDVMNS4yODQwNiAxMS41NjU5TDQuMDcxMzEgNy42NzE2OFoiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo="
                        alt=""
                        className="lozad"
                        data-loaded="true"
                      />
                    </a>
                  )}
                </div>
                {currentPartner && (
                  <a href={currentPartner.contact_site}>
                    {lang == "ru" ? data.title_ru : data.title_uz}
                  </a>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      {/* // ! Description */}
      <div className={classes.description}>
        <div className={classes.titles}>
          <h1
            onClick={() => setMode(1)}
            className={mode === 1 ? classes.title : classes.title_unselected}
          >
            {lang == "ru" ? "Описание" : "Malumot"}
          </h1>
          <h1
            onClick={() => setMode(2)}
            className={mode === 2 ? classes.title : classes.title_unselected}
          >
            {lang == "ru" ? "Условия" : "Shartlar"}
          </h1>
        </div>

        {mode === 1 ? (
          <p>
            {currentPartner && (
              <>
                {lang === "ru"
                  ? currentPartner.info_ru
                  : currentPartner.info_uz}
              </>
            )}
          </p>
        ) : (
          <></>
        )}
        {mode === 2 && (
          <>
            <ul>
              {currentPartner.condition && currentPartner.condition.length ? (
                currentPartner.condition.map((item) => {
                  return <li key={item.id}>{item.name}</li>;
                })
              ) : (
                <>
                  <li>
                    {lang === "ru"
                      ? "Необходимо быть абонентом Comnet и получить промо-код на скидку в личном кабинете."
                      : "Chegirmali promo kodni olish uchun, COMNET internet provaydiriga obuna bo'lishingiz kerak."}
                  </li>
                </>
              )}
              <li>
                {lang === "ru"
                  ? "OOO IPLUS не несет ответственность за товары и услуги предоставляемые партнерами."
                  : "OOO IPLUS hamkorlarimiz ko'rsatayotgan xizmatlar va mahsulotlar sifatiga mas'uliyatni zimmasiga olmaydi."}
              </li>
            </ul>
          </>
        )}
      </div>

      {/* // ! Location */}
      <div className={classes.locations}>
        <h2 className={classes.titleBold}>
          {lang == "ru" ? "Локация и адрес" : "Manzil"}
        </h2>
        <div className={classes.info_location}>
          {currentPartner && currentPartner.contact_location_ru && (
            <>
              <p className={classes.infoName}>
                {" "}
                <span>{lang == "ru" ? "Адрес" : "Manzil"}:</span>{" "}
                <span
                  dangerouslySetInnerHTML={{
                    __html: currentPartner.contact_location_ru.split("$")[0],
                  }}
                ></span>
              </p>
            </>
          )}

          {currentPartner && currentPartner.contact_phone && (
            <>
              <p className={classes.infoName}>
                {" "}
                <span>{lang == "ru" ? "Телефон" : "Telefon"}:</span>{" "}
                <span
                  dangerouslySetInnerHTML={{
                    __html: `<strong>${phoneMask(
                      currentPartner.contact_phone.split("$")[0]
                    )}</strong>`,
                  }}
                ></span>{" "}
              </p>
            </>
          )}
        </div>
        <div className={classes.info_location}>
          {currentPartner && currentPartner.contact_site && (
            <>
              <p className={classes.infoName}>
                {" "}
                <span>{lang == "ru" ? "Сайт" : "Sayt"}:</span>{" "}
                <span
                  dangerouslySetInnerHTML={{
                    __html: `<a href="${currentPartner.contact_site}">${currentPartner.contact_site?.replace("https://",'')}</a> `,
                  }}
                ></span>
              </p>
            </>
          )}
        </div>
        {currentPartner && currentPartner.latitude && (
          <YMaps query={{ apikey: "01962eeb-9997-4b93-8e95-287c832dc6d1" }}>
            <Map
              width="100%"
              defaultState={{
                center: [
                  currentPartner.latitude.split("$")[0].split(",")[0],
                  currentPartner.latitude.split("$")[0].split(",")[1],
                ],
                zoom: 12,
              }}
            >
              {currentPartner.latitude.split("$").map((i, op) => {
                return <Placemark key={op} geometry={i.split(",")} />;
              })}
              {/* <Placemark geometry={currentPartners.latitude.split('&')} /> */}
              <GeolocationControl
                options={{ float: "right", maxWidth: "40px" }}
              />
              <ZoomControl />
            </Map>
          </YMaps>
        )}
      </div>
      {/* // ! Reviews */}
      {/* <div className="reviews">
          <h2 className={classes.titleBold}>Отзывы об услуге</h2>
          <div className={classes.reviews_block}>
            <div className="top">
              <div className="left-side">
                <div className={classes.avatar}>K</div>
              </div>
            </div>
          </div>
        </div> */}
      {/* // ! Recomended */}
      {partners && partners.length > 0 && (
        <div className={classes.rec}>
          <h1>
            {lang == "ru" ? "Вам может понравится" : "Sizga yoqishi mumkin"}
          </h1>
          <Carousel
            className={classes.carousel}
            centerMode={window.innerWidth > 830}
            thumbWidth={100}
            centerSlidePercentage={35}
            // width="100%"
            swipeable={true}
            swipeScrollTolerance={5}
            showArrows={true}
            showThumbs={false}
            showIndicators={false}
            showStatus={false}
            renderArrowPrev={(onClickHandler, hasPrev, label) =>
              hasPrev && (
                <button
                  type="button"
                  onClick={onClickHandler}
                  className={classes.arrow}
                  style={{ left: -25 }}
                >
                  <svg
                    width="11"
                    height="18"
                    viewBox="0 0 11 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.2"
                      d="M0.91394 8.51406L8.58268 1.23117C8.83394 0.992689 9.24073 0.99309 9.49157 1.2324C9.74221 1.47168 9.74156 1.85929 9.49027 2.09795L2.278 8.94738L9.49053 15.7968C9.74179 16.0355 9.74244 16.4228 9.49183 16.6621C9.36609 16.7821 9.20136 16.8421 9.03663 16.8421C8.87232 16.8421 8.70823 16.7825 8.58272 16.6634L0.91394 9.38066C0.792926 9.266 0.725019 9.10996 0.725019 8.94738C0.725019 8.7848 0.79312 8.62894 0.91394 8.51406Z"
                      fill="black"
                      stroke="black"
                    />
                  </svg>
                </button>
              )
            }
            renderArrowNext={(onClickHandler, hasNext, label) =>
              hasNext && (
                <button
                  type="button"
                  onClick={onClickHandler}
                  className={classes.arrow}
                  style={{ right: -25 }}
                >
                  <svg
                    width="11"
                    height="18"
                    viewBox="0 0 11 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.2"
                      d="M10.0861 8.51406L2.41732 1.23117C2.16606 0.992689 1.75927 0.99309 1.50843 1.2324C1.25779 1.47168 1.25844 1.85929 1.50973 2.09795L8.722 8.94738L1.50947 15.7968C1.25821 16.0355 1.25756 16.4228 1.50817 16.6621C1.63391 16.7821 1.79864 16.8421 1.96337 16.8421C2.12768 16.8421 2.29177 16.7825 2.41728 16.6634L10.0861 9.38066C10.2071 9.266 10.275 9.10996 10.275 8.94738C10.275 8.7848 10.2069 8.62894 10.0861 8.51406Z"
                      fill="black"
                      stroke="black"
                    />
                  </svg>
                </button>
              )
            }
          >
            {partners.map((partner) => (
              <PartnersItem key={partner.id} data={partner} />
            ))}
          </Carousel>
        </div>
      )}
      <div className={classes.bottomBtn}>
        <Link
          style={{ marginTop: 20 }}
          to={{
            pathname: "/discounts-from-partners",
          }}
        >
          <div className={classes.secondaryBtn}>
            {lang == "ru" ? "Вернуться назад" : "Orqaga qaytish"}
          </div>
        </Link>
      </div>
    </div>
  );
}
