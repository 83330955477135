import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import actions from "../store/actions";

export const useActions = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);

  return [bindActionCreators(actions, dispatch), state];
};

export default useActions;
