import React, { useEffect } from "react";
import css from "./slider.module.css";
import PropTypes from "prop-types";
import { useState } from "react";
import { useRef } from "react";
import useWindowSize from "../../hooks/useWindowSize";
import Carousel from "./carousel";

export default function Slider({ children, limitItems }) {
  const { width } = useWindowSize();
  const settings = {
    adaptiveHeight: true,
    centerMode: width < 700 ? false : true,
    slidesToShow: width < 700 ? 1 : 2,
  };

  if (!children?.length) return null;
  return (
    <Carousel {...settings} className={css.container}>
      {children}
    </Carousel>
  );
}
Slider.propTypes = {
  children: PropTypes.any,
  limitItems: PropTypes.number,
};
