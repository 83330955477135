import React from "react";
import PropTypes from "prop-types";
import css from "./table.module.css";
import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { translatedText } from "../../services/translatedText";
import Loader from "../Loader";
import { useAutoAnimate } from "@formkit/auto-animate/react";

function Table({
  className = "",
  headers,
  data,
  loading,
  limit = 15,
  showPagination = true,
  height,
  style = {},
  theadStyle = {},
  tbodyStyle = {},
  backgrounds,
  showIndex = false,
  children,
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentPage, setPage] = useState(1);
  const [animationParent] = useAutoAnimate();
  const visibleData =
    data &&
    (showPagination
      ? data.filter((_, i) =>
          i >= (currentPage - 1) * limit &&
          i < (currentPage - 1) * limit + limit
            ? true
            : false
        )
      : data);

  const countOfData = Math.ceil((data?.length ?? 0) / limit);

  useEffect(() => {
    if (!searchParams.get("page")) return;
    if (+searchParams.get("page") > countOfData) return;
    setPage(+searchParams.get("page"));
  }, [searchParams]);

  const previousPage = () => {
    setSearchParams({ page: currentPage - 1 });
  };
  const nextPage = () => {
    setSearchParams({ page: currentPage + 1 });
  };
  const selectPage = (page) => {
    setSearchParams({ page });
  };
  return (
    <div ref={animationParent} style={{ height, ...style }}>
      {loading ? (
        <Loader center />
      ) : (
        <div className={css.wrap}>
          {Array.isArray(data) && data.length > 0 ? (
            <table className={[css.main, className].join(" ")}>
              <thead>
                <tr className={css.trHead}>
                  {showIndex && (
                    <th style={theadStyle} className={css.th}>
                      #
                    </th>
                  )}
                  {headers &&
                    headers.map((header) => (
                      <th style={theadStyle} className={css.th} key={header}>
                        {header}
                      </th>
                    ))}
                </tr>
              </thead>
              <tbody>
                {visibleData &&
                  visibleData.map((row, key) => (
                    <tr
                      style={backgrounds && backgrounds(key)}
                      className={css.trItem}
                      key={key}
                    >
                      {showIndex && (
                        <td
                          key={key + "_summary"}
                          style={tbodyStyle}
                          className={css.td}
                        >
                          {currentPage * (key + 1)}
                        </td>
                      )}
                      {row.map((item, itemKey) => (
                        <td style={tbodyStyle} className={css.td} key={itemKey}>
                          {children ? children(item, key, itemKey) : item}
                        </td>
                      ))}
                    </tr>
                  ))}
              </tbody>
            </table>
          ) : (
            <p className={css.no_data}>{translatedText("No data")}</p>
          )}
        </div>
      )}
      {showPagination && countOfData > 1 && (
        <div className={css.pagination}>
          {currentPage > 2 && (
            <button
              onClick={() => selectPage(1)}
              style={{ marginRight: 10 }}
              className={css.pg_btn}
            >
              1
            </button>
          )}
          {currentPage > 1 && (
            <React.Fragment>
              <button onClick={previousPage} className={css.arrow}>
                {"<"}
              </button>
              <button onClick={previousPage} className={css.pg_btn}>
                {currentPage - 1}
              </button>
            </React.Fragment>
          )}
          <button className={[css.pg_btn, css.current_pg].join(" ")}>
            {currentPage}
          </button>
          {currentPage != countOfData && (
            <React.Fragment>
              <button onClick={nextPage} className={css.pg_btn}>
                {currentPage + 1}
              </button>
              <button onClick={nextPage} className={css.arrow}>
                {">"}
              </button>{" "}
            </React.Fragment>
          )}
          {currentPage + 1 < countOfData && (
            <button
              onClick={() => selectPage(countOfData)}
              style={{ marginLeft: 10 }}
              className={css.pg_btn}
            >
              {countOfData}
            </button>
          )}
        </div>
      )}
    </div>
  );
}
Table.propTypes = {
  headers: PropTypes.array,
  data: PropTypes.array,
  limit: PropTypes.number,
  showPagination: PropTypes.bool,
  loading: PropTypes.bool,
  height: PropTypes.any,
  style: PropTypes.object,
  children: PropTypes.func,
  backgrounds: PropTypes.func,
  theadStyle: PropTypes.object,
  tbodyStyle: PropTypes.object,
};

export default React.memo(
  Table,
  (prev, next) => JSON.stringify(prev) === JSON.stringify(next)
);
