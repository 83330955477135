import axios from "axios";
import { $authHost } from ".";

export async function addUserContacts(value, typeId = 11) {
  const { data } = await $authHost.post("/contacts", {
    value,
    typeId,
    priority: 1,
  });
  return data;
}

export async function updateUserContacts(value, id, typeId = 11) {
  const { data } = await $authHost.put("/contacts/" + id, {
    value,
    typeId,
    priority: 1,
  });
  return data;
}

export async function deleteUserContacts(id) {
  const { data } = await $authHost.delete("/contacts/" + id);
  return data;
}

export async function sendSmsToPhone(phone, text) {
  const dataReq = [
    {
      sender: "2310",
      recipient: phone + "",
      "message-id": "123",
      text,
    },
  ];

  const { data } = await axios.post("http://94.158.55.100/sms", dataReq, {
    auth: { username: "comnet", password: "aAfrPxrd7" },
  });
  return data;
}

export async function restManager(id) {
  const { data } = await axios.get(
    "https://serv.comnet.uz/api/manager/rest",
    {
      headers: {
        Authorization: `Bearer 3|YOz6gV9lvSlVao1VfTzboRMDSpipDNuSMeSLaNFo`,
      },
    }
  );
  return (data?.data || []).find(d => d?.manager_id == id);
}
export async function sendCodeVerification(phone, username) {
  const { data } = await axios.post(
    "https://serv.comnet.uz/api/shpion_sms",
    { phone, username },
    {
      headers: {
        Authorization: `Bearer 3|YOz6gV9lvSlVao1VfTzboRMDSpipDNuSMeSLaNFo`,
      },
    }
  );
  return data;
}
export async function checkVerification(code, username) {
  const { data } = await axios.post(
    "https://serv.comnet.uz/api/shpion_sms/" + username,
    { code },
    {
      headers: {
        Authorization: `Bearer 3|YOz6gV9lvSlVao1VfTzboRMDSpipDNuSMeSLaNFo`,
      },
    }
  );
  return data?.data;
}
