import React from "react";
import CheckboxGroup from "../../../../components/CheckboxGroup";
import { translatedText } from "../../../../services/translatedText";

export default function Step4({ onPossible, onImpossible }) {
  const checkBoxData = () => [
    "Uzonline",
    "Turon Telecom",
    "Sarkor Telecom",
    "Freelink",
    "Netco",
    translatedText("Other1"),
  ];

  return (
    <div className="step">
      <h1 className="step-title">{translatedText("Which ISP switch")}</h1>
      <CheckboxGroup
        lowPriority
        style={{ maxHeight: 150, marginBottom: 40 }}
        gap={25}
        // columnGap={10}
        onChangeWithData={(data) => {
          var result = data.filter((d) => d?.value === true)[0]?.data;
          if (result) onPossible && onPossible(result);
          else onImpossible && onImpossible();
        }}
        data={checkBoxData()}
      />
    </div>
  );
}
